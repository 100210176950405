import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import * as auth from "../_redux/authRedux";
import { FORGOT_PASSWORD } from "../_redux/authCrud";
import { Snackbar, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const initialValues = {
  email: "",
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ForgotPassword(props) {
  const { intl } = props;
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const [snackBarText, setSnackBarText] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      forgotPassword({
        variables: {
          forgotPasswordEmailAddress: values.email,
        },
      })
        .then(() => {
          setSnackBarText("Password reset link sent to your mail");
          setSnackbarSeverity("success");
          setSnackBarOpen(true);
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Forgotten Password ?</h3>
          <div className="text-muted font-weight-bold">
            Enter your email to reset your password
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting}
            >
              Submit
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
        <div className=" d-flex flex-wrap flex-center">
          <Typography variant="h6" className="font-weight-bold text-dark-50">
            {"Back to  "}
            <Link to="/auth">Login</Link>
          </Typography>
        </div>
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={8000}
          onClose={handleSnackBarClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleSnackBarClose}
            style={{ fontSize: "16px" }}
            severity={snackbarSeverity}
          >
            {snackBarText}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
