/* eslint-disable array-callback-return */
import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import MuiAlert from "@material-ui/lab/Alert";
import Moment from "moment";

import "moment-timezone";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch } from "react-redux";

import {
  Paper,
  Grid,
  makeStyles,
  Typography,
  Divider,
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
  CircularProgress,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
// import { Dropdown } from "react-bootstrap";

// icons
import {
  faCheck,
  faPlusCircle,
  faTrashAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import {
  ExpandMore,
  ExpandLess,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
} from "@material-ui/icons";

import { actions } from "../../../Auth/_redux/authRedux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import male from "../images/male.png";
import female from "../images/female.png";

var occupations = require("./occupationsList.js");

const ACCESS_USER = gql`
  mutation GetUserAccessMutation($getUserAccessUserId: String) {
    getUserAccess(userId: $getUserAccessUserId) {
      code
      success
      message
      accessToken
      user {
        firstName
        lastName
        email
      }
    }
  }
`;

// getting notes
const GET_NOTES = gql`
  query Query($getNotesJobSeekerId: String!) {
    getNotes(jobSeekerId: $getNotesJobSeekerId) {
      notes {
        _id
        value
        createdAt
        updatedAt
      }
    }
  }
`;

// user details
const USER_DETAILS = gql`
  query Query($getJobseekerUserId: String!) {
    getJobseeker(userId: $getJobseekerUserId) {
      id
      firstName
      lastName
      email
      profileImage
      invitedBy
      isEmailVerified
      userStageJsonString
      homePhone
      cellPhone
      address
      birthDate
      gender
      quiz {
        isCompleted
        isQuizTaken
        results {
          result {
            area
            score
            description
          }
          start
          end
          total
          answers
        }
      }
      isUserPlaced
      socialMediaHandles {
        linkedin
      }
      occupationInterest
    }
  }
`;

// update is placed

const UPDATE_USER_PLACED = gql`
  mutation Mutation(
    $updateUserUser: UpdatableUserFields
    $updateUserUserId: String!
  ) {
    updateUser(user: $updateUserUser, userId: $updateUserUserId) {
      success
    }
  }
`;

// getting alternate stage list
const ALTERNATE_STAGE_LIST = gql`
  query Query {
    getChooseStageJsonStr {
      jsonStr
    }
  }
`;

// update stage
const UPDATE_STAGE = gql`
  mutation UpdateUserMutation(
    $updateUserUserId: String!
    $updateUserUser: UpdatableUserFields
  ) {
    updateUser(userId: $updateUserUserId, user: $updateUserUser) {
      success
    }
  }
`;

// saving new notes

const ADD_NOTES = gql`
  mutation Mutation(
    $createNoteValue: String!
    $createNoteJobSeekerId: String!
  ) {
    createNote(value: $createNoteValue, jobSeekerId: $createNoteJobSeekerId) {
      code
      success
      message
    }
  }
`;

// delete note
const DELETE_NOTE = gql`
  mutation DeleteNoteMutation(
    $deleteNoteNoteId: String!
    $deleteNoteJobSeekerId: String!
  ) {
    deleteNote(noteId: $deleteNoteNoteId, jobSeekerId: $deleteNoteJobSeekerId) {
      code
      success
      message
    }
  }
`;

// update user details
const UPDATE_USER_DETAILS = gql`
  mutation UpdateUserMutation(
    $updateUserUserId: String!
    $updateUserUser: UpdatableUserFields
  ) {
    updateUser(userId: $updateUserUserId, user: $updateUserUser) {
      success
    }
  }
`;

// save occupation interest
const SAVE_OCCUPATION_INTEREST = gql`
  mutation UpdateUserMutation(
    $updateUserUserId: String!
    $updateUserUser: UpdatableUserFields
  ) {
    updateUser(userId: $updateUserUserId, user: $updateUserUser) {
      success
    }
  }
`;

// get activities
const GET_ACTIVITIES = gql`
  query Query($getActivitiesJobSeekerId: String) {
    getActivities(jobSeekerId: $getActivitiesJobSeekerId) {
      activities {
        _id
        value
        createdAt
        updatedAt
      }
    }
  }
`;

// imageimport { avatar } from "../images/avatar.png";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
  },
  tableRowCell: {
    borderBottom: "none",
    padding: "2px 15px",
  },
  buttons: {
    color: "inherit",
    backgroundColor: [""].join(""),
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
}));

// const occupations = [
//   "Web Developer",
//   "Data Engineer",
//   "Data Analyst",
//   "Full Stack Developer",
// ];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export function UserProfile({ selectedUser, setSelectedUserData }) {
  const dispatch = useDispatch();
  const [accessUser, { loading: accessUserLoading }] = useMutation(ACCESS_USER);

  const classes = useStyles();
  const [editUserInformation, setIsEditUserInformation] = React.useState(false);
  const [userInformation, setUserInformation] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    // dob: "",
    name: "",
    home: "",
    cell: "",
    linkedin: "",
    address: "",
    occupationInterest: "",
  });
  const [
    alternateUserInformation,
    setAlternateUserInformation,
  ] = React.useState({});
  const [rootKeys, setRootKeys] = React.useState();
  const [allStages, setAllStages] = React.useState();
  const [collapseStages, setCollapseStages] = React.useState({});

  const [skipState, setSkipState] = React.useState(true);
  const { refetch: fetchAlternateStageList } = useQuery(ALTERNATE_STAGE_LIST, {
    skip: skipState,
  });
  const { loading, data: userData } = useQuery(USER_DETAILS, {
    variables: {
      getJobseekerUserId: selectedUser,
    },

    async onCompleted() {
      await setUserInformation({
        ...userInformation,
        firstName: userData.getJobseeker.firstName,
        lastName: userData.getJobseeker.lastName,
        email: userData.getJobseeker.email,
        home: userData.getJobseeker.homePhone,
        cell: userData.getJobseeker.cellPhone,
        gender: userData.getJobseeker.gender,
        // dob: new Date(userData.getJobseeker.birthDate).toLocaleDateString(),
        linkedin: userData.getJobseeker.socialMediaHandles.linkedin,
        address: userData.getJobseeker.address,
        occupationInterest: userData.getJobseeker.occupationInterest,
      });
      setAlternateUserInformation({
        ...alternateUserInformation,
        firstName: userData.getJobseeker.firstName,
        lastName: userData.getJobseeker.lastName,
        email: userData.getJobseeker.email,
        home: userData.getJobseeker.homePhone,
        cell: userData.getJobseeker.cellPhone,
        gender: userData.getJobseeker.gender,
        // dob: new Date(userData.getJobseeker.birthDate).toLocaleDateString(),
        linkedin: userData.getJobseeker.socialMediaHandles.linkedin,
        address: userData.getJobseeker.address,
        occupationInterest: userData.getJobseeker.occupationInterest,
      });
      setSelectedUserData(userData.getJobseeker.quiz);
      setOpen(false);
      let parsedStages = "";
      if (userData.getJobseeker.userStageJsonString)
        parsedStages = JSON.parse(userData.getJobseeker.userStageJsonString);
      else {
        await setSkipState(false);
        const d = await fetchAlternateStageList();

        parsedStages = JSON.parse(d.data.getChooseStageJsonStr.jsonStr);

        // alternateStageList.getChooseStageJsonStr.jsonStr
      }
      delete parsedStages["User Placed"];

      parsedStages["userPlaced"] = userData.getJobseeker.isUserPlaced;

      setAllStages(parsedStages);

      let rKeys = Object.keys(parsedStages);
      setRootKeys(rKeys);
      let keys = {};
      rKeys.map((key) => {
        keys[key] = false;
      });

      setCollapseStages(keys);
    },
  });

  const [updateUserDetails, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER_DETAILS,
    {
      onCompleted() {
        setSnackBarText("User Details Updated");
        setSnackBarOpenSuccess(true);
        setIsEditUserInformation(false);
        setAlternateUserInformation(userInformation);
      },
    }
  );

  const [updateUserPlaced] = useMutation(UPDATE_USER_PLACED, {
    onCompleted(status) {
      
      setSnackBarText("'User Placed' data updated ");
      setSnackBarOpenSuccess(true);
    },
  });
  const [updateStage] = useMutation(UPDATE_STAGE, {
    onCompleted() {
      setSnackBarText("Stage updated successfully");
      setSnackBarOpenSuccess(true);
    },
  });
  const [saveOccupationInterest] = useMutation(SAVE_OCCUPATION_INTEREST, {
    onCompleted() {
      setUserInformation({
        ...userInformation,
        occupationInterest: selectedOccupationInterest,
      });
      setSnackBarText("Occupation interest saved successfully");
      setSnackBarOpenSuccess(true);
    },
  });
  const [
    selectedOccupationInterest,
    setSelectedOccupationInterest,
  ] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [moreNotes, setMoreNotes] = React.useState(false);
  const [DialogState, setDialogState] = React.useState(false);
  const [confirmDelete, setDeleteState] = React.useState(false);
  const [deleteNoteId, setDeleteNoteId] = React.useState("");
  const [addNoteLoader, setAddNoteLoader] = React.useState(false);
  const [deleteNoteLoader, setDeleteNoteLoader] = React.useState("");
  const [newNote, setNewNote] = React.useState("");
  const [activities, setActivities] = React.useState([]);

  const { data: latestActivities } = useQuery(GET_ACTIVITIES, {
    variables: {
      getActivitiesJobSeekerId: selectedUser,
    },
    onCompleted() {
      let allActivities = latestActivities.getActivities.activities.map(
        (key) => {
          return { activity: key.value, date: new Date(key.createdAt) };
        }
      );
      setActivities(allActivities);
    },
  });

  const { data: notesList, refetch: notesRefetch } = useQuery(GET_NOTES, {
    variables: {
      getNotesJobSeekerId: selectedUser,
    },
  });
  const [createNote] = useMutation(ADD_NOTES, {
    onCompleted({ createNote }) {
      if (createNote) {
        notesRefetch();

        setDialogState(false);
        setSnackBarText("Note Added Successfully");
        setSnackBarOpenSuccess(true);
        setAddNoteLoader(false);
        setNewNote("");
      } else {
        setSnackBarOpenError(true);
      }
    },
  });
  const [deleteNote] = useMutation(DELETE_NOTE, {
    onCompleted({ deleteNote }) {
      if (deleteNote) {
        setDeleteState(false);
        handleSnackBarText("Note Deleted Successfully");
        setSnackBarOpenSuccess(true);
        setDeleteNoteLoader("");
        notesRefetch();
      } else {
        setSnackBarOpenError(true);
      }
    },
  });

  const [openAddNoteError, setSnackBarOpenError] = React.useState(false);
  const [openAddNoteSuccess, setSnackBarOpenSuccess] = React.useState(false);
  const [snackBarText, setSnackBarText] = React.useState("");
  const [notesLimit, setNotesLimit] = React.useState(3);
  const [notesoverflow, setNotesoverflow] = React.useState("hidden");
  const [notesListHeight, setNotesListHeight] = React.useState("100%");

  var newNotes = [];
  if (notesList) {
    newNotes = notesList.getNotes.notes.slice(0, notesLimit).map((note) => {
      return {
        createdAt: new Date(note.createdAt),
        value: note.value,
        id: note._id,
      };
    });
  }
  const handleClick = () => {
    setOpen(!open);
  };
  const handleStage = (key) => {
    let stage = "";
    Object.keys(collapseStages).map((mainStage) => {
      if (collapseStages[mainStage] === true) stage = mainStage;
    });
    setCollapseStages({
      ...collapseStages,
      [key]: !collapseStages[key],
      [stage]: false,
    });

    // setResume(!resume);
  };

  const handleSingleStage = (key) => {
  
    setOpen(false);
    setAllStages({ ...allStages, [key]: !allStages[key] });
    if (key === "userPlaced") {
      let isUserPlaced = !allStages[key];

      updateUserPlaced({
        variables: {
          updateUserUser: {
            isUserPlaced: isUserPlaced,
          },
          updateUserUserId: selectedUser,
        },
      });
    }
  };
  const selectingStage = (mainStage, subStage) => {
    setOpen(!open);

    delete allStages["userPlaced"];
    let updatedStage = allStages;

    if (subStage === "clearStatus") {
      Object.keys(allStages[mainStage]).map((key) => {
        updatedStage[mainStage][key] = false;
      });
    } else {
      updatedStage[mainStage][subStage] = !updatedStage[mainStage][subStage];
    }

    updateStage({
      variables: {
        updateUserUserId: selectedUser,
        updateUserUser: {
          userStageJsonString: JSON.stringify(updatedStage),
        },
      },
    });
  };

  const handleDialogOpen = () => {
    setDialogState(true);
  };

  const handleDialogClose = () => {
    setDialogState(false);
    setDeleteState(false);
  };

  const handleNewNote = (evt) => {
    setNewNote(evt.target.value);
  };
  const handleAddNote = () => {
    setAddNoteLoader(true);
    if (newNote.length > 0) {
      createNote({
        variables: {
          createNoteValue: newNote,
          createNoteJobSeekerId: selectedUser,
        },
      });
    }
  };

  const handleConfirmDelete = () => {
    deleteNote({
      variables: {
        deleteNoteNoteId: deleteNoteId,
        deleteNoteJobSeekerId: selectedUser,
      },
    });
    setDeleteNoteLoader(deleteNoteId);
  };
  const handleDeleteNote = (id) => {
    setDeleteNoteId(id);
    setDeleteState(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpenSuccess(false);
    setSnackBarOpenError(false);
  };

  const handleSnackBarText = (text) => {
    setSnackBarText(text);
  };

  const handleNotesLimit = () => {
    setMoreNotes(!moreNotes);
    if (notesLimit === 3) {
      setNotesLimit(notesList.getNotes.notes.length);
      setNotesoverflow("scroll");
      setNotesListHeight("300px");
    } else {
      setNotesLimit(3);
      setNotesoverflow("hidden");
      setNotesListHeight("100%");
    }
  };

  const handleIsEditUserInformation = () => {
    if (editUserInformation === true) {
      setUserInformation(alternateUserInformation);
    }
    setIsEditUserInformation(!editUserInformation);
  };

  const handleEditUserInformation = (evt, key) => {
    setUserInformation({ ...userInformation, [key]: evt.target.value });
  };

  const handleSaveUserInformation = () => {
    updateUserDetails({
      variables: {
        updateUserUserId: selectedUser,
        updateUserUser: {
          socialMediaHandles: {
            linkedin: userInformation.linkedin,
          },

          homePhone: userInformation.home,
          cellPhone: userInformation.cell,
          address: userInformation.address,
          gender: userInformation.gender,
          // birthDate: userInformation.dob,
          isUserPlaced: allStages["userPlaced"],
        },
      },
    });
  };

  const handleNewOccupation = async (key) => {
    if (key.keyCode === 13) {
      key.target.blur();
      // setSelectedOccupationInterest(key.target.value);
      await saveOccupationInterest({
        variables: {
          updateUserUserId: selectedUser,
          updateUserUser: {
            occupationInterest: key.target.value,
          },
        },
      });
    }
  };
  const handleOccupationInterest = (evt) => {
    occupations.map((key) => {
      if (key === evt.target.value) {
        evt.target.blur();
        setSelectedOccupationInterest(evt.target.value);
        saveOccupationInterest({
          variables: {
            updateUserUserId: selectedUser,
            updateUserUser: {
              occupationInterest: evt.target.value,
            },
          },
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <Grid container direction="row" justify="center">
          <Grid item>
            <CircularProgress className="mx-1" style={{ color: "#3699FF" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={4} lg={3} xs={12}>
            <Paper className={[classes.paper, "p-5"]}>
              <Grid container item direction="row" justify="flex-end">
                <FontAwesomeIcon
                  size="2x"
                  icon={faEdit}
                  style={{ cursor: "pointer" }}
                  onClick={handleIsEditUserInformation}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                item
                style={{ marginTop: "-20px" }}
              >
                <Grid item className="text-center" xs={9}>
                  <Paper
                    style={{
                      backgroundColor: "#665ddb",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid
                      container
                      direction="cloumn"
                      className=" pb-3"
                      justify="center"
                      style={{ marginTop: "50px" }}
                    >
                      <Grid item>
                        <img
                          src={
                            userInformation.gender === "FEMALE" ? female : male
                          }
                          alt="dwf"
                          style={{
                            height: 100,
                            width: 100,
                            borderRadius: 100,
                            marginTop: "-50px",
                          }}
                        />
                      </Grid>
                      <Grid item container direction="row">
                        <Grid xs="12" item className="mt-2">
                          <Typography variant="h5" style={{ color: "#ffffff" }}>
                            {`${userInformation.firstName} ${userInformation.lastName}` ||
                              "--"}
                          </Typography>
                        </Grid>

                        <Grid item xs="12">
                          <Typography
                            variant="subtitle1"
                            style={{ color: "#ffffff" }}
                          >
                            {userInformation.occupationInterest || "--"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              <Divider className="m-5" />
              {editUserInformation ? (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  className="px-5"
                  spacing={2}
                >
                  {/* <Grid item>
                    <TextField
                      id="date"
                      label="Birthday"
                      type="date"
                      defaultValue={Moment(userInformation.dob).format(
                        "YYYY-MM-DD"
                      )}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      color="secondary"
                      fullWidth={true}
                      onChange={(evt) => {
                        handleEditUserInformation(evt, "dob");
                      }}
                    />
                  </Grid> */}
                  <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <FormLabel component="legend">Gender : </FormLabel>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={userInformation.gender}
                        onChange={(evt) => {
                          handleEditUserInformation(evt, "gender");
                        }}
                        row
                      >
                        <FormControlLabel
                          value="MALE"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="FEMALE"
                          control={<Radio />}
                          label="Female"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="outlined-helperText"
                      label="Email"
                      defaultValue={userInformation.email}
                      variant="outlined"
                      color="secondary"
                      disabled
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      id="outlined-helperText"
                      label="Home"
                      type="number"
                      defaultValue={userInformation.home}
                      variant="outlined"
                      color="secondary"
                      onChange={(evt) => {
                        handleEditUserInformation(evt, "home");
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="outlined-helperText"
                      label="Cell"
                      type="number"
                      defaultValue={userInformation.cell}
                      variant="outlined"
                      color="secondary"
                      onChange={(evt) => {
                        handleEditUserInformation(evt, "cell");
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      id="outlined-helperText"
                      label="LinkedIn"
                      defaultValue={userInformation.linkedin}
                      variant="outlined"
                      color="secondary"
                      onChange={(evt) => {
                        handleEditUserInformation(evt, "linkedin");
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="outlined-helperText"
                      label="Address"
                      defaultValue={userInformation.address}
                      variant="outlined"
                      color="secondary"
                      onChange={(evt) => {
                        handleEditUserInformation(evt, "address");
                      }}
                    />
                  </Grid>
                  <Grid item container direction="row" justify="space-between">
                    <Grid item>
                      <Button
                        onClick={handleIsEditUserInformation}
                        variant={"contained"}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={
                          updateUserLoading
                            ? () => {}
                            : handleSaveUserInformation
                        }
                        color="secondary"
                        variant={"contained"}
                        className="px-9 py-2"
                      >
                        {updateUserLoading ? (
                          <CircularProgress
                            size={20}
                            style={{
                              color: "#ffffff",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          />
                        ) : (
                          <Typography style={{ width: "30px", height: "20px" }}>
                            SAVE
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="column" spacing={5} justify="center">
                  <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    spacing={2}
                  >
                    {/* <Grid item xs={11} md={10}>
                      <Typography variant="h6">Date of Birth :</Typography>
                      <Typography variant="subtitle1">
                        {userInformation.dob && userInformation.dob.length
                          ? Moment(userInformation.dob).format("DD/MM/YYYY")
                          : "--/--/----"}
                      </Typography>
                    </Grid> */}
                    <Grid item xs={11} md={10}>
                      <Typography variant="h6">Gender :</Typography>
                      <Typography variant="subtitle1">
                        {userInformation.gender || "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} md={10}>
                      <Typography variant="h6">Email :</Typography>
                      <Typography variant="subtitle1">
                        {userInformation.email || "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} md={10}>
                      <Typography variant="h6">Home :</Typography>
                      <Typography variant="subtitle1">
                        {userInformation.home || "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} md={10}>
                      <Typography variant="h6">Cell :</Typography>
                      <Typography variant="subtitle1">
                        {userInformation.cell || "--"}
                      </Typography>
                    </Grid>

                    <Grid item xs={11} md={10}>
                      <Typography variant="h6">LinkedIn :</Typography>
                      <Typography variant="subtitle1">
                        {userInformation.linkedin || "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} md={10}>
                      <Typography variant="h6">Address :</Typography>
                      <Typography variant="subtitle1">
                        {userInformation.address || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container justify="center">
                    <Grid item xs={10} md={9}>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ width: "100%", color: "white" }}
                        onClick={
                          accessUserLoading
                            ? () => {}
                            : () => {
                                accessUser({
                                  variables: {
                                    getUserAccessUserId: selectedUser,
                                  },
                                }).then(
                                  ({
                                    data: {
                                      getUserAccess: { accessToken },
                                    },
                                  }) => {
                                    dispatch(
                                      actions.login(accessToken, null, true)
                                    );
                                  }
                                );
                              }
                        }
                      >
                        {accessUserLoading ? (
                          <CircularProgress
                            style={{ color: "#3699FF" }}
                            size={22}
                          />
                        ) : (
                          <Typography variant="subtitle1">
                            ACCESS JOBSEEKER{" "}
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
          <Grid item md={8} lg={9} xs={12}>
            <Paper className={classes.paper}>
              <Grid container direction="column" spacing={3} className="p-5">
                <Grid item>
                  <Grid item container directrion="row" justify="space-around">
                    <Grid
                      item
                      lg={4}
                      className="text-center"
                      style={{ height: "80px" }}
                    >
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="h5" style={{ fontWeight: 400 }}>
                            Stage
                          </Typography>
                        </Grid>

                        <Grid item className="py-2 ">
                          <List
                            component="div"
                            aria-labelledby="nested-list-subheader"
                            style={{
                              border: "1px solid #c4c4c4",
                              backgroundColor: "white",
                              padding: 0,
                              borderRadius: "5px",
                              maxHeight: "350px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                            className={classes.root}
                            hover={false}
                          >
                            <ListItem
                              button
                              onClick={handleClick}
                              style={{
                                paddingTop: "4px",
                                paddingBottom: "4px",
                                backgroundColor: "white",
                              }}
                            >
                              <ListItemText
                                className="text-capitalize"
                                primary={"Choose Stage"}
                                style={{ color: "#adadad" }}
                              />
                              {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                              <List
                                component="div"
                                className="px-3"
                                disablePadding
                              >
                                {rootKeys &&
                                  rootKeys.map((key) => (
                                    <div>
                                      {typeof allStages[key] !== "object" ? (
                                        <ListItem
                                          button
                                          onClick={() => {
                                            handleSingleStage(key);
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                          >
                                            <Grid item>
                                              <ListItemText
                                                class="text-capitalize"
                                                primary={key
                                                  .replace(/([A-Z])/g, " $1")
                                                  .trim()}
                                              />
                                            </Grid>
                                            <Grid item xs="1">
                                              <ListItemIcon>
                                                {allStages[key] ? (
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                    color="green"
                                                  />
                                                ) : (
                                                  <div></div>
                                                )}
                                              </ListItemIcon>
                                            </Grid>
                                          </Grid>
                                        </ListItem>
                                      ) : (
                                        <>
                                          <ListItem
                                            button
                                            onClick={() => {
                                              handleStage(key);
                                            }}
                                          >
                                            <Grid
                                              container
                                              direction="row"
                                              justify="space-between"
                                            >
                                              <Grid item>
                                                <ListItemText
                                                  class="text-capitalize"
                                                  primary={key
                                                    .replace(/([A-Z])/g, " $1")
                                                    .trim()}
                                                />
                                              </Grid>
                                              <Grid item>
                                                {typeof allStages[key] ===
                                                "object" ? (
                                                  collapseStages[key] ? (
                                                    <ExpandLess />
                                                  ) : (
                                                    <ExpandMore />
                                                  )
                                                ) : (
                                                  <span />
                                                )}
                                              </Grid>
                                            </Grid>
                                          </ListItem>
                                          <Collapse
                                            in={collapseStages[key]}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <List
                                              className="px-3"
                                              component="div"
                                              disablePadding
                                            >
                                              {Object.keys(allStages[key]).map(
                                                (key2) => (
                                                  <ListItem
                                                    button
                                                    className={classes.nested}
                                                    onClick={() => {
                                                      selectingStage(key, key2);
                                                    }}
                                                  >
                                                    <Grid
                                                      container
                                                      direction="row"
                                                      justify="space-between"
                                                    >
                                                      <Grid item xs="auto">
                                                        <ListItemText
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                          className="text-capitalize"
                                                          primary={key2
                                                            .replace(
                                                              /([A-Z])/g,
                                                              " $1"
                                                            )
                                                            .trim()}
                                                        />
                                                      </Grid>
                                                      <Grid item xs="1">
                                                        <ListItemIcon>
                                                          {allStages[key][
                                                            key2
                                                          ] ? (
                                                            <FontAwesomeIcon
                                                              icon={faCheck}
                                                              color="green"
                                                            />
                                                          ) : (
                                                            <div></div>
                                                          )}
                                                        </ListItemIcon>
                                                      </Grid>
                                                    </Grid>
                                                  </ListItem>
                                                )
                                              )}
                                            </List>
                                          </Collapse>
                                        </>
                                      )}
                                    </div>
                                  ))}
                              </List>
                            </Collapse>
                          </List>
                        </Grid>

                        <Grid item></Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg="4" className="text-center">
                      <Grid item>
                        <Typography variant="h5" style={{ fontWeight: "400" }}>
                          Occupation Interest
                        </Typography>
                      </Grid>
                      <Grid item className="py-2 ">
                        <Autocomplete
                          id="combo-box-demo"
                          options={occupations}
                          // getOptionLabel={(option) => option.occupation}

                          size="small"
                          onSelect={handleOccupationInterest}
                          onKeyUp={handleNewOccupation}
                          renderInput={(params) => (
                            <TextField
                              color="secondary"
                              {...params}
                              placeholder={"Choose Occupation"}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid item container directrion="row" justify="space-around">
                    <Grid item xs="auto" className="text-center">
                      <Grid item>
                        <Typography variant="h5" style={{ fontWeight: "400" }}>
                          Resume
                        </Typography>
                      </Grid>
                      <Grid item className="p-3">
                        {allStages && allStages["resume"]["resumeCompleted"] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="green"
                            size="2x"
                          />
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs="auto" className="text-center">
                      <Grid item>
                        <Typography variant="h5" style={{ fontWeight: "400" }}>
                          Cover Letter
                        </Typography>
                      </Grid>
                      <Grid item className="p-3">
                        {allStages &&
                        allStages["coverLetter"]["coverLetterCompleted"] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="green"
                            size="2x"
                          />
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs="auto" className="text-center">
                      <Grid item>
                        <Typography variant="h5" style={{ fontWeight: "400" }}>
                          References
                        </Typography>
                      </Grid>
                      <Grid item className="p-3">
                        {allStages &&
                        allStages["references"]["referencesCompleted"] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="green"
                            size="2x"
                          />
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs="auto" className="text-center">
                      <Grid item>
                        <Typography variant="h5" style={{ fontWeight: "400" }}>
                          LinkedIn
                        </Typography>
                      </Grid>
                      <Grid item className="p-3">
                        {allStages &&
                        allStages["linkedIn"]["linkedInCompleted"] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="green"
                            size="2x"
                          />
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs="auto" className="text-center">
                      <Grid item>
                        <Typography variant="h5" style={{ fontWeight: "400" }}>
                          Mock Interview
                        </Typography>
                      </Grid>
                      <Grid item className="p-3"></Grid>
                    </Grid>
                    <Grid item xs="auto" className="text-center">
                      <Grid item>
                        <Typography variant="h5" style={{ fontWeight: "400" }}>
                          Jobs Applied
                        </Typography>
                      </Grid>
                      <Grid item className="p-3">
                        {allStages &&
                        allStages["jobApplications"]["applyingForJobs"] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="green"
                            size="2x"
                          />
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                      <Box
                        className="pb-3"
                        boxShadow={0}
                        style={{
                          backgroundColor: "#f5f5f5",
                          borderRadius: "15px",
                          padding: "2px 15px",
                          height: "100%",
                          minHeight: "100%",
                        }}
                      >
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-left ">
                                  <Typography
                                    variant="h6"
                                    style={{ fontWeight: 400 }}
                                  >
                                    Latest Activities
                                  </Typography>
                                </TableCell>
                                <TableCell className="text-center">
                                  <Typography
                                    variant="h6"
                                    style={{ fontWeight: 400 }}
                                  >
                                    Date
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {activities && activities.length ? (
                              <TableBody>
                                {activities.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      classes={{
                                        root: classes.tableRowCell,
                                      }}
                                    >
                                      <Typography variant="subtitle1">
                                        {row.activity}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      className="text-center"
                                      classes={{
                                        root: classes.tableRowCell,
                                      }}
                                    >
                                      <Typography variant="subtitle1">
                                        {Moment(row.date).format("DD/MM/YYYY")}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            ) : (
                              <TableRow justify="center">
                                <TableCell
                                  colSpan={2}
                                  className="text-center"
                                  style={{ borderBottom: "none" }}
                                >
                                  <Typography
                                    style={{ color: "grey" }}
                                    variant="subtitle1"
                                  >
                                    No Activities
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Box
                    style={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: "15px",
                    }}
                  >
                    <Grid container direction="column" className="p-5">
                      <Grid
                        item
                        container
                        direction="row"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography variant="h6" style={{ fontWeight: 400 }}>
                            {Moment(new Date()).format("MMMM D, YYYY")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Dialog
                            open={DialogState}
                            onClose={handleDialogClose}
                            aria-labelledby="form-dialog-title"
                            fullWidth="100%"
                            maxWidth={"sm"}
                          >
                            <DialogTitle id="form-dialog-title">
                              <Typography variant="h5">Add Note</Typography>
                            </DialogTitle>
                            <DialogContent>
                              <textarea
                                className="form-control form-control-solid h-auto py-5 px-6"
                                autoFocus
                                id="note"
                                placeholder="Add your Note here...."
                                rows="5"
                                onChange={handleNewNote}
                              />
                            </DialogContent>
                            <DialogActions className="mb-4">
                              <Grid
                                className="px-5"
                                container
                                direction="row"
                                justify="space-between"
                              >
                                <Grid item>
                                  <Button
                                    size="large"
                                    onClick={handleDialogClose}
                                    variant={"contained"}
                                  >
                                    Cancel
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    size="large"
                                    onClick={handleAddNote}
                                    color="secondary"
                                    variant={"contained"}
                                    className="px-10"
                                    disabled={newNote.length ? false : true}
                                  >
                                    {addNoteLoader ? (
                                      <CircularProgress
                                        size="20px"
                                        style={{
                                          color: "#ffffff",
                                          marginLeft: "5px",
                                          marginRight: "5px",
                                        }}
                                      />
                                    ) : (
                                      <Typography
                                        style={{
                                          width: "30px",
                                          height: "20px",
                                        }}
                                      >
                                        Add
                                      </Typography>
                                    )}
                                  </Button>
                                </Grid>
                              </Grid>
                            </DialogActions>
                          </Dialog>
                        </Grid>

                        <Grid item>
                          <Button
                            className={classes.buttons}
                            style={{}}
                            startIcon={
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                size="2x"
                                style={{ color: "black" }}
                              />
                            }
                            disableRipple={true}
                            onClick={handleDialogOpen}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 400,
                                textTransform: "none",
                                color: "black",
                              }}
                            >
                              Add Note
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                      {newNotes && newNotes.length ? (
                        <Grid
                          item
                          style={{
                            height: "100%",
                            maxHeight: notesListHeight,
                            overflowX: "hidden",
                            overflowY: notesoverflow,
                          }}
                        >
                          {newNotes.map((note, index) => (
                            <Grid
                              item
                              container
                              direction="row"
                              className="px-3 pt-2"
                              justify="space-between"
                              key={index}
                            >
                              <Grid item>
                                <Typography
                                  variant="subtitle1"
                                  style={{ fontWeight: 500 }}
                                >
                                  {Moment(note.createdAt).format(
                                    "MMMM D, YYYY HH:mm a"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="row"
                                className="px-3 pb-3 pt-1"
                                spacing={1}
                              >
                                {/* <Grid item xs={1}>
                            <FontAwesomeIcon icon={faEdit} size="2x" />
                          </Grid> */}
                                <Grid item xs={11} alignItems="center">
                                  <Typography
                                    variant="subtitle1"
                                    style={{ fontWeight: 400 }}
                                  >
                                    {note.value}
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  {note.id === deleteNoteLoader ? (
                                    <CircularProgress
                                      size="18px"
                                      style={{ color: "#fc7e7e" }}
                                      className="mx-4 "
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      color="red"
                                      className="mx-3"
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleDeleteNote(note.id);
                                      }}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography
                          className="text-center my-5"
                          style={{ color: "grey" }}
                          variant="subtitle1"
                        >
                          No Notes' to show
                        </Typography>
                      )}

                      <Grid item>
                        <Dialog
                          open={confirmDelete}
                          onClose={handleDialogClose}
                          aria-labelledby="form-dialog-title"
                        >
                          <DialogTitle id="form-dialog-title">
                            <Typography variant="h5">
                              Are you sure want delete ?
                            </Typography>
                          </DialogTitle>

                          <DialogActions>
                            <Grid
                              className="px-5"
                              container
                              direction="row"
                              justify="space-between"
                            >
                              <Grid item>
                                <Button
                                  size="large"
                                  onClick={handleDialogClose}
                                  variant={"contained"}
                                  className="px-11"
                                >
                                  No
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  size="large"
                                  onClick={handleConfirmDelete}
                                  style={{
                                    backgroundColor: "red",
                                    color: "#ffffff",
                                  }}
                                  variant={"contained"}
                                  className={(classes.buttons, "px-10 mb-4")}
                                >
                                  {deleteNoteId === deleteNoteLoader ? (
                                    <CircularProgress
                                      size="20px"
                                      style={{
                                        color: "#ffffff",
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                      }}
                                    />
                                  ) : (
                                    <Typography
                                      style={{ width: "30px", height: "20px" }}
                                    >
                                      Yes
                                    </Typography>
                                  )}
                                </Button>
                              </Grid>
                            </Grid>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid item>
                        <Snackbar
                          open={openAddNoteSuccess}
                          autoHideDuration={5000}
                          onClose={handleSnackBarClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <Alert
                            style={{ fontSize: "15px" }}
                            onClose={handleSnackBarClose}
                            severity="success"
                          >
                            {snackBarText}
                          </Alert>
                        </Snackbar>
                      </Grid>
                      <Grid item>
                        <Snackbar
                          open={openAddNoteError}
                          autoHideDuration={5000}
                          onClose={handleSnackBarClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <Alert
                            style={{ fontSize: "15px" }}
                            onClose={handleSnackBarClose}
                            severity="error"
                          >
                            Error occured!
                          </Alert>
                        </Snackbar>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid item container direction="row" justify="space-around">
                    <Button
                      className={classes.buttons}
                      disableRipple={true}
                      variant={"text"}
                      startIcon={
                        moreNotes ? (
                          <KeyboardArrowUp style={{ fontSize: 35 }} />
                        ) : (
                          <KeyboardArrowDown style={{ fontSize: 35 }} />
                        )
                      }
                      onClick={handleNotesLimit}
                    >
                      <Typography
                        className="px-3"
                        variant="h6"
                        style={{ textTransform: "none" }}
                      >
                        Show More
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
