module.exports = [
  "Business Manager",
  "Facilities Manager",
  "Facility Manager",
  "General Manager (GM)",
  "Operations Director",
  "Operations Manager",
  "Plant Manager",
  "Plant Superintendent",
  "Production Manager",
  "Store Manager",
  "General and Operations Managers",
  "Corporate Sustainability Process Manager",
  "Director of Sustainability",
  "Director of Sustainability Programs",
  "Director of Sustainable Design",
  "Sustainability Coordinator",
  "Sustainability Director",
  "Vice President, Corporate Social Responsibility and Sustainability (VP CSR and Sustainability)",
  "Chief Sustainability Officers",
  "Legislators",
  "Chief Diversity Officer (CDO)",
  "Chief Executive Officer (CEO)",
  "Chief Financial Officer (CFO)",
  "Chief Nursing Officer",
  "Chief Operating Officer (COO)",
  "Executive Director",
  "Executive Vice President (EVP)",
  "Operations Vice President",
  "President",
  "Vice President",
  "Chief Executives",
  "District Sales Manager",
  "National Sales Manager",
  "Regional Sales Manager",
  "Sales and Marketing Vice President",
  "Sales Director",
  "Sales Manager",
  "Sales Representative",
  "Sales Supervisor",
  "Sales Vice President",
  "Sales Managers",
  "Green Marketers",
  "Account Supervisor",
  "Brand Manager",
  "Business Development Director",
  "Business Development Manager",
  "Commercial Lines Manager",
  "Market Development Executive",
  "Marketing Coordinator",
  "Marketing Director",
  "Marketing Manager",
  "Product Manager",
  "Marketing Managers",
  "Account Executive",
  "Advertising Manager (Ad Manager)",
  "Advertising Sales Manager",
  "Classified Advertising Manager",
  "Communications Director",
  "Communications Manager",
  "Creative Services Director",
  "Marketing and Promotions Manager",
  "Promotions Director",
  "Promotions Manager",
  "Advertising and Promotions Managers",
  "Chief School Finance Officer",
  "Comptroller",
  "Controller",
  "Director of Finance",
  "Finance Director",
  "Finance Manager",
  "Finance Vice President",
  "School Treasurer",
  "Treasurer",
  "Treasurers and Controllers",
  "Maintenance Manager",
  "Operations Supervisor",
  "Plant Supervisor",
  "Power Plant Operations Manager",
  "Site Manager",
  "Geothermal Production Managers",
  "Assistant Manager",
  "Assistant Vice President (AVP)",
  "Banking Center Manager (BCM)",
  "Banking Officer",
  "Branch Manager",
  "Credit Administration Manager",
  "Financial Center Manager",
  "Regional Manager",
  "Service Center Manager",
  "Financial Managers, Branch or Department",
  "Annual Giving Director",
  "Community Relations Director",
  "Development Director",
  "Public Affairs Director",
  "Public Relations Director",
  "Public Relations Manager (PR Manager)",
  "Public Relations and Fundraising Managers",
  "Global Logistics Manager",
  "Integrated Logistics Programs Director",
  "Logistics Analytics Manager",
  "Logistics Director",
  "Logistics Operations Director",
  "Logistics Operations Manager",
  "Logistics Solution Manager",
  "Logistics Team Leader",
  "Logistics Vice President",
  "Supply Chain Logistics Manager",
  "Logistics Managers",
  "Athletic Director",
  "Elementary Principal",
  "High School Principal",
  "Middle School Principal",
  "Principal",
  "School Administrator",
  "School Superintendent",
  "Special Education Director",
  "Superintendent",
  "Vice Principal",
  "Education Administrators, Elementary and Secondary School",
  "Administrator",
  "Childcare Director",
  "Early Head Start Director",
  "Education Coordinator",
  "Education Director",
  "Education Site Manager",
  "Preschool Director",
  "Preschool Program Director",
  "Site Coordinator",
  "Education Administrators, Preschool and Childcare Center/Program",
  "Analytical Research Program Manager",
  "Biodiesel Division Manager",
  "Biofuels Manager",
  "Chief Technology Officer (CTO)",
  "Laboratory Manager (Lab Manager)",
  "Manager of Business Development and New Technology",
  "Project Development Director",
  "Scientist",
  "Senior Research Associate",
  "Senior Research Scientist",
  "Biofuels/Biodiesel Technology and Product Development Managers",
  "Financial Managers",
  "Development Manager",
  "Education and Development Manager",
  "Learning and Development Director",
  "Learning Manager",
  "Staff Training and Development Manager",
  "Training and Development Coordinator",
  "Training and Development Director",
  "Training Director",
  "Training Manager",
  "Training and Development Managers",
  "Academic Affairs Vice President",
  "Academic Dean",
  "Admissions Director",
  "College President",
  "Dean",
  "Financial Aid Director",
  "Institutional Research Director",
  "Provost",
  "Registrar",
  "Students Dean",
  "Education Administrators, Postsecondary",
  "Fuel Manager",
  "Maintenance Supervisor",
  "Operations and Maintenance Manager",
  "Production Supervisor",
  "Production Support Manager",
  "Production Support Supervisor",
  "Biomass Power Plant Managers",
  "Employee Relations Manager",
  "Human Resources Administration Director",
  "Human Resources Director (HR Director)",
  "Human Resources Manager (HR Manager)",
  "Human Resources Operations Manager",
  "Human Resources Vice President",
  "Human Resources Managers",
  "Farm Manager",
  "Garden Center Manager",
  "Greenhouse Manager",
  "Grower",
  "Harvesting Manager",
  "Horticulturist",
  "Nursery Manager",
  "Perennial House Manager",
  "Propagation Manager",
  "Nursery and Greenhouse Managers",
  "Education Administrators, All Other",
  "Chief Engineer",
  "Civil Engineering Manager",
  "Director of Engineering",
  "Engineering Group Manager",
  "Engineering Manager",
  "Engineering Program Manager",
  "Principal Engineer",
  "Project Engineer",
  "Project Engineering Manager",
  "Project Manager",
  "Architectural and Engineering Managers",
  "Application Development Director",
  "Computing Services Director",
  "Data Processing Manager",
  "Information Systems Director (IS Director)",
  "Information Systems Manager (IS Manager)",
  "Information Systems Supervisor (IS Supervisor)",
  "Information Technology Director (IT Director)",
  "Information Technology Manager (IT Manager)",
  "MIS Director (Management Information Systems Director)",
  "Technical Services Manager",
  "Computer and Information Systems Managers",
  "Hydro Station Supervisor",
  "Maintenance Superintendent",
  "Power Plant Superintendent",
  "Power Plant Supervisor",
  "Power Project Manager",
  "Hydroelectric Production Managers",
  "Biofuels Production Manager",
  "Cellulosic Ethanol Production Plant Manager",
  "Energy Operations Vice President",
  "Ethanol Operations Manager",
  "Lead Operator",
  "Plant Operations Vice President",
  "Production Coordinator",
  "Biofuels Production Managers",
  "Benefits Coordinator",
  "Benefits Manager",
  "Compensation and Benefits Manager",
  "Compensation Director",
  "Compensation Manager",
  "Compensation Vice President",
  "Employee Benefits Coordinator",
  "Employee Benefits Director",
  "Employee Benefits Manager",
  "Payroll Manager",
  "Compensation and Benefits Managers",
  "Cold Storage Supervisor",
  "Distribution Center Manager",
  "Distribution Manager",
  "Load Out Supervisor",
  "Shipping Manager",
  "Shipping Supervisor",
  "Terminal Manager",
  "Warehouse Manager",
  "Warehouse Operations Manager",
  "Warehouse Supervisor",
  "Storage and Distribution Managers",
  "Chief Wellness Officer",
  "Executive Wellness Programs Director",
  "Fitness and Wellness Director",
  "Fitness Coordinator",
  "Fitness Director",
  "Fitness Supervisor",
  "Fitness/Wellness Director",
  "Group Fitness Manager (GFM)",
  "Recreational Sports Director",
  "Wellness Director",
  "Fitness and Wellness Coordinators",
  "Administrative Coordinator",
  "Administrative Director",
  "Administrative Manager",
  "Administrative Officer",
  "Administrative Specialist",
  "Business Administrator",
  "Office Manager",
  "Administrative Services Managers",
  "Associate Director, QA",
  "Director of Quality",
  "Lab Manager",
  "Laboratory Manager",
  "Quality Assurance Manager (QA Manager)",
  "Quality Control",
  "Quality Control Manager (QC Manager)",
  "Quality Control Supervisor (QC Supervisor)",
  "Quality Manager",
  "Quality Control Systems Managers",
  "Director of Operations",
  "Fleet Manager",
  "Freight Coordinator",
  "Global Transportation Manager",
  "Traffic Manager",
  "Train Operations Manager",
  "Trainmaster",
  "Transportation Director",
  "Transportation Manager",
  "Transportation Supervisor",
  "Transportation Managers",
  "Methane/Landfill Gas Collection System Operators",
  "Concrete Foreman",
  "Construction Area Manager",
  "Construction Foreman",
  "Construction Manager",
  "Construction Superintendent",
  "General Contractor",
  "Job Superintendent",
  "Project Executive",
  "Project Superintendent",
  "Construction Managers",
  "Category Purchasing Manager",
  "Commodity Manager",
  "Materials Director",
  "Materials Manager",
  "Procurement Director",
  "Procurement Manager",
  "Procurement Officer",
  "Purchasing Director",
  "Purchasing Supervisor",
  "Strategic Sourcing Director",
  "Purchasing Managers",
  "Accredited Farm Manager (AFM)",
  "Cash Crop Farmer",
  "Dairy Farmer",
  "Farm Operator",
  "Farmer",
  "Grain Farmer",
  "Ranch Manager",
  "Rancher",
  "Sow Farm Manager",
  "Farm and Ranch Managers",
  "Aquaculture Cooperative Marketing Director",
  "Aquaculture Director",
  "Aquaculture Program Director",
  "Facility Operations Manager",
  "Farm Operations Technical Director",
  "Fish Hatchery Manager",
  "Hatchery Manager",
  "Hatchery Supervisor",
  "Recirculating Aquaculture Systems Specialist",
  "Shellfish Manager",
  "Aquacultural Managers",
  "Area Plant Manager",
  "General Production Manager",
  "Manufacturing Coordinator",
  "Manufacturing Manager",
  "Plant Senior Manager",
  "Product Line Manager",
  "Production Control Manager",
  "Sub Plant Manager",
  "Industrial Production Managers",
  "Transportation, Storage, and Distribution Managers",
  "Farmers, Ranchers, and Other Agricultural Managers",
  "Banquet Manager",
  "Catering Manager",
  "Food and Beverage Director",
  "Food and Beverage Manager",
  "Food Service Director",
  "Food Service Manager",
  "Food Service Supervisor",
  "Kitchen Manager",
  "Restaurant General Manager",
  "Restaurant Manager",
  "Food Service Managers",
  "Bed and Breakfast Innkeeper",
  "Front Desk Manager",
  "Front Office Director",
  "Front Office Manager",
  "Guest Relations Manager",
  "Guest Service Manager",
  "Hotel Manager",
  "Night Manager",
  "Resort Manager",
  "Rooms Director",
  "Lodging Managers",
  "Clinical Program Coordinator",
  "Clinical Program Manager",
  "Clinical Research Administrator",
  "Clinical Research Associate (CRA)",
  "Clinical Research Coordinator",
  "Clinical Research Manager",
  "Clinical Research Nurse Coordinator",
  "Clinical Trial Coordinator",
  "Clinical Trial Manager",
  "Research Coordinator",
  "Clinical Research Coordinators",
  "Delivery Supervisor",
  "Distribution Operation Supervisor (SDO)",
  "Distribution Operations Manager",
  "Distribution Operations Supervisor",
  "Mail Delivery Supervisor",
  "Postal Supervisor",
  "Postmaster",
  "Postmaster Relief (PMR)",
  "Remote Encoding Center Manager",
  "Remote Encoding Operations Supervisor",
  "Postmasters and Mail Superintendents",
  "Assistant Casino Shift Manager",
  "Bingo Manager",
  "Casino Manager",
  "Casino Shift Manager",
  "Gaming Director",
  "Gaming Manager",
  "Slot Manager",
  "Slot Operations Director",
  "Table Games Manager",
  "Table Games Shift Manager",
  "Gaming Managers",
  "Arranging Funeral Director",
  "Assistant Manager of Operations",
  "Funeral Home Location Manager",
  "Funeral Home Manager",
  "Funeral Service Manager",
  "Location Manager",
  "Market Manager",
  "Mortuary Operations Manager",
  "Sales Manager, Prearranged Funerals",
  "Scheduling Manager",
  "Funeral Service Managers",
  "Chief Learning Officer",
  "Coordinator of Online Programs",
  "Director of Distance Learning",
  "Distance Education Faculty Liaison",
  "Distance Learning Coordinator",
  "Distance Learning Technician",
  "Distance Learning Unit Leader",
  "Head of Academic Technology",
  "Online Facilitator",
  "Program Facilitation Director for Distance Learning",
  "Distance Learning Coordinators",
  "Clinical Director",
  "Health Information Management Corporate Director",
  "Health Information Management Director",
  "Health Manager",
  "Mental Health Program Manager",
  "Nurse Manager",
  "Nursing Director",
  "Practice Administrator",
  "Program Manager",
  "Medical and Health Services Managers",
  "Environmental Program Manager",
  "Fisheries Director",
  "Health Sciences Manager",
  "Natural Science Manager",
  "Research and Development Director",
  "Research Manager",
  "Senior Investigator",
  "Senior Scientist",
  "Water Team Leader",
  "Natural Sciences Managers",
  "Buyer",
  "Category Manager",
  "Grocery Buyer",
  "Merchandise Manager",
  "Procurement Specialist",
  "Purchaser",
  "Purchasing Coordinator",
  "Retail Buyer",
  "Trader",
  "Wholesale and Retail Buyers, Except Farm Products",
  "Affirmative Action Officer (AA Officer)",
  "Civil Rights Investigator",
  "Civil Rights Representative",
  "Complaint Investigations Officer",
  "Equal Employment Opportunity Officer (EEO Officer)",
  "Equal Employment Opportunity Representative (EEO Representative)",
  "Equal Opportunity Commission Investigator (EOC Investigator)",
  "Equal Opportunity Director",
  "Equal Opportunity Specialist",
  "Field Representative",
  "Equal Opportunity Representatives and Officers",
  "Adoption Services Manager",
  "Child Welfare Services Director",
  "Children's Service Supervisor",
  "Clinical Services Director",
  "Community Services Block Grant/Outreach Social Worker (CSBG/Outreach Social Worker)",
  "Community Services Director",
  "Psychiatric Social Worker Supervisor",
  "Social Services Director",
  "Transitional Care Director",
  "Vocational Rehabilitation Administrator",
  "Social and Community Service Managers",
  "Brownfield and Economic Development Director",
  "Brownfield Program Coordinator",
  "Chief of Planning",
  "Deputy Commissioner",
  "Director",
  "Director of Development",
  "Manager, Division of Environmental Quality",
  "Senior Environmental Practice Leader",
  "Brownfield Redevelopment Specialists and Site Managers",
  "Procurement Official",
  "Purchasing Administrator",
  "Purchasing Agent",
  "Purchasing Agents, Except Wholesale, Retail, and Farm Products",
  "Hydrogeologist",
  "National Stormwater Leader",
  "Owner, Consulting Engineer",
  "Owner, Professional Engineer",
  "Research Hydraulic Engineer",
  "Senior Group Manager",
  "Senior Hydrogeologist",
  "Senior Water Resources Engineer",
  "VP-Senior Principal Water Resources Engineer",
  "Water Resources Business Segment Leader",
  "Water Resource Specialists",
  "Accreditation Lieutenant",
  "Accreditation Manager",
  "Compliance Director",
  "Compliance Manager",
  "Compliance Operations Manager",
  "Environmental Health and Safety Director",
  "Environmental Manager",
  "Health, Safety, and Environmental Manager (HSE Manager)",
  "Risk Manager",
  "Compliance Managers",
  "Emergency Management Consultant",
  "Emergency Management Coordinator",
  "Emergency Management Specialist",
  "Emergency Management System Director (EMS Director)",
  "Emergency Planner",
  "Emergency Preparedness Coordinator",
  "Emergency Preparedness Program Specialist",
  "Emergency Services Director",
  "Hazard Mitigation Officer",
  "Public Safety Director",
  "Emergency Management Directors",
  "Acquisition Cost Estimator",
  "Construction Estimator",
  "Cost Analyst",
  "Cost and Risk Analysis Manager",
  "Cost Consultant",
  "Cost Engineer",
  "Cost Estimator",
  "Estimator",
  "Estimator Project Manager",
  "Preconstruction Manager",
  "Cost Estimators",
  "Compliance Investigator",
  "Enforcement Officer",
  "Environmental Compliance Officer",
  "Environmental Protection Specialist",
  "Environmental Quality Analyst",
  "Environmental Specialist",
  "Oil Program Compliance Specialist",
  "Resource Conservation and Recovery Act Enforcement Officer (RCRA Enforcement Officer)",
  "Toxics Program Officer",
  "Waste Management Specialist",
  "Environmental Compliance Inspectors",
  "Claims Adjusters, Examiners, and Investigators",
  "Wind Farm Operations Manager",
  "Wind Field Manager",
  "Wind Operations Manager",
  "Wind Operations Supervisor",
  "Wind Plant Manager",
  "Wind Site Manager",
  "Wind Energy Operations Managers",
  "Assistant Vice President, Investment Analysis",
  "Equity Analyst",
  "Fixed Income Portfolio Manager",
  "Investment Analyst",
  "Lead Portfolio Manager",
  "Portfolio Manager",
  "Senior Investment Analyst",
  "Senior Portfolio Manager",
  "Vice President and Portfolio Manager",
  "Vice President, Fixed Income",
  "Investment Fund Managers",
  "Apartment Manager",
  "Commercial Property Services Coordinator",
  "Community Manager",
  "Condominium Association Property Manager",
  "Consulting Property Manager",
  "Lease Administration Supervisor",
  "Leasing Manager",
  "On Site Property Manager",
  "Property Manager",
  "Resident Manager",
  "Property, Real Estate, and Community Association Managers",
  "Global Regulatory Affairs Director (Global RA Director)",
  "Global Regulatory Affairs Manager (Global RA Manager)",
  "Quality Affairs Vice President (Quality Affairs VP)",
  "Regulatory Affairs Director (RA Director)",
  "Regulatory Affairs Manager (RA Manager)",
  "Regulatory Affairs Quality Assurance Director (RA QA Director)",
  "Regulatory Affairs Vice President (RA VP)",
  "Regulatory Director",
  "Regulatory Science Director",
  "Regulatory Vice President (Regulatory VP)",
  "Regulatory Affairs Managers",
  "Managers, All Other",
  "Claims Adjuster",
  "Claims Analyst",
  "Claims Examiner",
  "Claims Manager",
  "Claims Representative",
  "Claims Specialist",
  "Claims Supervisor",
  "Corporate Claims Examiner",
  "Customer Care Specialist",
  "Home Office Claim Specialist",
  "Claims Examiners, Property and Casualty Insurance",
  "Corporate Physical Security Supervisor",
  "Corporate Security Manager",
  "Director Security Management",
  "Director, Corporate Security",
  "Manager, Security and Safety",
  "Manager, Security Infrastructure and Enterprise Services",
  "Manager, Security Services and Safety System Support",
  "Security Director",
  "Security Manager",
  "Security Managers",
  "Business Developer",
  "Development Associate",
  "Project Developer",
  "Project Development Leader",
  "Renewable Project Management and Construction Director",
  "Wind Energy Project Managers",
  "Grain Buyer",
  "Grain Merchandiser",
  "Grain Merchandising Manager",
  "Grain Origination Specialist",
  "Merchandiser",
  "Merchandising Manager",
  "Tobacco Buyer",
  "Buyers and Purchasing Agents, Farm Products",
  "Adjuster",
  "Claim Representative",
  "General Adjuster",
  "Insurance Adjuster",
  "Litigation Claim Representative",
  "Insurance Adjusters, Examiners, and Investigators",
  "Agent",
  "Artist Agent",
  "Artist Manager",
  "Artist Representative",
  "Athlete Marketing Agent",
  "Booking Agent",
  "Talent Agent",
  "Talent Buyer",
  "Talent Manager",
  "Theatrical Agent",
  "Agents and Business Managers of Artists, Performers, and Athletes",
  "Asset Protection Manager",
  "Logistics Loss Prevention Manager",
  "Loss Control Manager",
  "Loss Prevention Director",
  "Loss Prevention Manager",
  "Loss Prevention Operations Director",
  "Loss Prevention Operations Manager",
  "Loss Prevention Supervisor",
  "Loss Prevention Vice President (Loss Prevention VP)",
  "Market Asset Protection Manager",
  "Loss Prevention Managers",
  "Global Consumer Sector Vice President",
  "Global Supply Chain Director",
  "Global Supply Chain Vice President",
  "Material Requirements Planning Manager",
  "Solution Design and Analysis Manager",
  "Supply Chain Director",
  "Supply Chain Manager",
  "Supply Chain Vice President",
  "Supply Chain Managers",
  "Compliance Officers",
  "Drug Regulatory Affairs Specialist",
  "Regulatory Affairs Analyst (RA Analyst)",
  "Regulatory Affairs Associate (RA Associate)",
  "Regulatory Affairs Consultant (RA Consultant)",
  "Regulatory Affairs Specialist (RA Specialist)",
  "Regulatory Affairs Strategist (RA Strategist)",
  "Regulatory Engineer",
  "Regulatory Services Consultant",
  "Regulatory Specialist",
  "Regulatory Submissions Associate",
  "Regulatory Affairs Specialists",
  "Driver Examiner",
  "Driver License Agent",
  "Driver License Examiner",
  "Examiner",
  "License Examiner",
  "License Registration Examiner",
  "Licensing Analyst",
  "Motor Vehicle Clerk",
  "Public Service Representative (PSR)",
  "Transportation Services Representative (TSR)",
  "Licensing Examiners and Inspectors",
  "Analyst",
  "Certified Financial Planner (CFP)",
  "Financial Advisor",
  "Financial Consultant",
  "Financial Counselor",
  "Financial Planner",
  "Investment Advisor",
  "Registered Representative",
  "Personal Financial Advisors",
  "Information Analyst",
  "Information Technology Specialist (IT Specialist)",
  "LAN Specialist (Local Area Network Specialist)",
  "Local Area Network Administrator (LAN Administrator)",
  "Network Administrator",
  "Network Coordinator",
  "Network Manager",
  "Network Specialist",
  "Systems Administrator",
  "Network and Computer Systems Administrators",
  "Designer",
  "Technology Applications Engineer",
  "Web Architect",
  "Web Design Specialist",
  "Web Designer",
  "Web Developer",
  "Web Development Director",
  "Web Development Instructor",
  "Webmaster",
  "Web Developers",
  "Analyst Programmer",
  "Application Programmer Analyst",
  "Computer Programmer",
  "Computer Programmer Analyst",
  "Internet Programmer",
  "Java Developer",
  "Programmer",
  "Programmer Analyst",
  "Web Applications Programmer",
  "Web Programmer",
  "Computer Programmers",
  "Data Administrator",
  "Data Architect",
  "Data Officer",
  "Database Architect",
  "Database Consultant",
  "Information Architect",
  "Information Modeling Engineer Specialist",
  "Information Technology Architect (IT Architect)",
  "System Engineer",
  "Technical Operations Vice President",
  "Database Architects",
  "Accounting Supervisor",
  "Budget Analyst",
  "Budget Coordinator",
  "Budget Officer",
  "Cost Accountant",
  "Financial Services Officer",
  "Management and Budget Analyst",
  "Policy Analyst",
  "Staff Analyst",
  "Budget Analysts",
  "Mathematical Technicians",
  "Assurance Manager",
  "Assurance Senior",
  "Audit Manager",
  "Audit Partner",
  "Auditor",
  "Auditor-in-Charge",
  "Financial Auditor",
  "Internal Audit Director",
  "Internal Auditor",
  "Revenue Tax Specialist",
  "Auditors",
  "Design Engineer",
  "Network Analyst",
  "Network and Security Engineer",
  "Network Consultant",
  "Network Systems Consultant",
  "Networking Systems and Distributed Systems Engineer",
  "Solutions Architect",
  "Telecommunications Analyst",
  "Computer Network Architects",
  "Design Director",
  "Designer/Writer",
  "Game Designer",
  "Game Designer/Creative Director",
  "Lead Designer",
  "Lead Game Designer",
  "Lead Level Designer",
  "Mid Level Game Designer",
  "Senior Game Designer",
  "World Designer",
  "Video Game Designers",
  "Corporate Recruiter",
  "Employment Representative",
  "HR Analyst (Human Resources Analyst)",
  "HR Coordinator (Human Resources Coordinator)",
  "HR Generalist (Human Resources Generalist)",
  "HR Representative (Human Resources Representative)",
  "Human Resources Specialist (HR Specialist)",
  "Personnel Analyst",
  "Personnel Officer",
  "Recruiter",
  "Human Resources Specialists",
  "Business Intelligence Analyst",
  "Business Intelligence Manager",
  "Commercial Intelligence Manager",
  "Competitive Intelligence Analyst",
  "Consultant, Strategic Business and Technology Intelligence",
  "Director of Enterprise Strategy",
  "Director of Market Intelligence",
  "Director, Global Intelligence",
  "Intelligence Analyst",
  "Manager, Market Intelligence",
  "Business Intelligence Analysts",
  "Farm Labor Contractor",
  "Field Manager",
  "Field Supervisor",
  "Farm Labor Contractors",
  "Business Development Specialist",
  "Client Service and Consulting Manager",
  "Communications Specialist",
  "Demographic Analyst",
  "Market Analyst",
  "Market Research Analyst",
  "Market Research Consultant",
  "Market Research Specialist",
  "Market Researcher",
  "Marketing Research Coordinator",
  "Market Research Analysts and Marketing Specialists",
  "Investment Underwriters",
  "Computer Specialist",
  "Computer Support Specialist",
  "Computer Technician",
  "Desktop Support Technician",
  "Help Desk Analyst",
  "Help Desk Technician",
  "Network Technician",
  "Support Specialist",
  "Technical Support Specialist",
  "Computer User Support Specialists",
  "Consultant",
  "Principal Consultant",
  "Registered Communications Distribution Designer (RCDD)",
  "Senior Consultant",
  "Senior Telecommunications Consultant",
  "Telecom Network Manager (Telecommunication Network Manager)",
  "Telecommunication Engineer",
  "Telecommunication Systems Designer",
  "Telecommunications Consultant",
  "Telecommunications Engineering Specialists",
  "City Tax Auditor",
  "Delinquent Tax Collection Assistant",
  "Revenue Agent",
  "Revenue Collector",
  "Revenue Officer",
  "Revenue Specialist",
  "Tax Collector",
  "Tax Compliance Officer",
  "Tax Examiner",
  "Tax Examiners and Collectors, and Revenue Agents",
  "Appraiser",
  "Certified Real Estate Appraiser",
  "Certified Residential Real Estate Appraiser",
  "Commercial Real Estate Appraiser",
  "Real Estate Appraiser",
  "Real Property Appraiser",
  "Residential Fee Appraiser",
  "Residential Real Estate Appraiser",
  "Staff Appraiser",
  "Valuation Consultant",
  "Appraisers, Real Estate",
  "Architect",
  "Electronic Data Interchange System Developer (EDI System Developer)",
  "Network and Infrastructure Engineer",
  "Network Engineer",
  "Research Systems Architect",
  "Solution Architect",
  "System Architect",
  "Systems Consultant",
  "Systems Engineer",
  "Computer Systems Engineers/Architects",
  "Channel Supervisor",
  "Director of Audience Generation, Search, & Analytics",
  "Director of Online Marketing Strategy & Performance",
  "Director of Search Engine Optimization (Director of SEO)",
  "Director, Search Marketing Strategies",
  "Ecommerce Marketing Manager",
  "Internet Marketing Consultant",
  "Internet Marketing Specialist",
  "Senior Search Engine Optimization Associate (Senior SEO Associate)",
  "Senior Search Engine Optimization Specialist (Senior SEO Specialist)",
  "Search Marketing Strategists",
  "Applications Analyst",
  "Business Analyst",
  "Business Systems Analyst",
  "Computer Analyst",
  "Computer Systems Analyst",
  "Computer Systems Consultant",
  "Information Systems Analyst (ISA)",
  "Information Technology Analyst (IT Analyst)",
  "System Analyst",
  "Systems Analyst",
  "Computer Systems Analysts",
  "Geographic Information System Analyst (GIS Analyst)",
  "Geographic Information Systems Administrator (GIS Administrator)",
  "Geographic Information Systems Analyst (GIS Analyst)",
  "Geographic Information Systems Coordinator (GIS Coordinator)",
  "Geographic Information Systems Director (GIS Director)",
  "Geographic Information Systems Manager (GIS Manager)",
  "Geographic Information Systems Specialist (GIS Specialist)",
  "Geospatial Intelligence Subject Matter Expert",
  "Geospatial Program Management Officer",
  "Resource Analyst",
  "Geospatial Information Scientists and Technologists",
  "Computer Occupations, All Other",
  "Agricultural Engineer",
  "Agricultural Safety and Health Program Director",
  "Agricultural Systems Specialist",
  "Conservation Engineer",
  "Engineer",
  "Product Engineer",
  "Product Technology Scientist",
  "Research Agricultural Engineer",
  "Research Leader",
  "Agricultural Engineers",
  "Architectural Project Manager",
  "Design Architect",
  "Principal Architect",
  "Principal, Architectural Firm",
  "Project Architect",
  "Senior Architect/Design Manager",
  "Senior Architectural Designer",
  "Senior Planner",
  "Specifications Writer",
  "Architects, Except Landscape and Naval",
  "Director, Security Risk Management",
  "Operations Staff Specialist, Security",
  "Physical Security Engineer",
  "Physical Security Specialist",
  "Principal Engineer, Security Engineering and Applied Science",
  "Section Chief, Physical Security Specialist",
  "Security Analyst",
  "Security Consultant",
  "Security Specialist",
  "Security Management Specialists",
  "Automobile Appraiser (Auto Appraiser)",
  "Automobile Damage Appraiser (Auto Damage Appraiser)",
  "Damage Appraiser",
  "Field Appraiser",
  "Field Inspector",
  "Insurance Appraiser",
  "Material Damage Appraiser",
  "Outside Physical Damage Appraiser",
  "Physical Damage Appraiser",
  "Insurance Appraisers, Auto Damage",
  "Commercial Lending Vice President",
  "Financial Aid Administrator",
  "Financial Aid Advisor",
  "Financial Aid Counselor",
  "Financial Aid Officer",
  "Financial Assistance Advisor",
  "Loan Counselor",
  "Peer Financial Counselor",
  "Loan Counselors",
  "Computer Scientist",
  "Control System Computer Scientist",
  "HPC Applications Manager (High Performance Computing Applications Manager)",
  "Research Scientist",
  "Scientific Programmer Analyst",
  "Computer and Information Research Scientists",
  "Administrative Analyst",
  "Employment Programs Analyst",
  "Leadership Development Manager",
  "Management Analyst",
  "Management Consultant",
  "Organizational Development Consultant",
  "Program Management Analyst",
  "Quality Control Analyst",
  "Management Analysts",
  "Computer Training Specialist",
  "Corporate Trainer",
  "E-Learning Developer",
  "Job Training Specialist",
  "Management Development Specialist",
  "Senior Instructor",
  "Supervisory Training Specialist",
  "Technical Trainer",
  "Trainer",
  "Training Specialist",
  "Training and Development Specialists",
  "Accountants and Auditors",
  "Assessor",
  "Commercial Appraiser",
  "County Assessor",
  "Deputy Assessor",
  "Personal Property Appraiser",
  "Residential Appraiser",
  "Tax Assessor",
  "Assessors",
  "Logistics Engineer",
  "Reliability Engineer",
  "Logistics Engineers",
  "Bank Secrecy Act Anti-Money Laundering Officer (BSA/AML Officer)",
  "Community Reinvestment Act Officer (CRA Officer)",
  "Credit Union Examiner",
  "Credit Union Field Examiner",
  "Examining Officer",
  "Home Mortgage Disclosure Act Specialist (HMDA Specialist)",
  "Principal Examiner",
  "Senior Capital Markets Specialist",
  "Senior Examiner",
  "Supervisory Examiner",
  "Financial Examiners",
  "Financial Specialists, All Other",
  "Clinical Informatics Analyst",
  "Clinical Informatics Nurse",
  "Clinical Informatics Specialist",
  "Clinical Informatics Systems Analyst",
  "Digital Diabetes Research Officer",
  "Nursing Informatics Officer",
  "Nursing Informatics Specialist",
  "Nursing Information Systems Coordinator",
  "Registered Nurse Clinical Information Systems Coordinator (RN Clinical Information Systems Coordinator)",
  "Registered Nurse Clinical Information Systems Educator (RN Clinical Information Systems Educator)",
  "Informatics Nurse Specialists",
  "Account Underwriter",
  "Automobile and Property Underwriter",
  "Commercial Lines Underwriter",
  "Health Underwriter",
  "Life Underwriter",
  "Personal Lines Underwriter",
  "Underwriter",
  "Underwriting Consultant",
  "Underwriting Director",
  "Underwriting Manager",
  "Insurance Underwriters",
  "Credit Products Officer",
  "Equity Research Analyst",
  "Financial Analyst",
  "Planning Analyst",
  "Real Estate Analyst",
  "Securities Analyst",
  "Trust Officer",
  "Financial Analysts",
  "Applied Research Director",
  "Global Analytics Head",
  "Global Credit Quantitative Analysis Head",
  "Investment Strategist",
  "Quantitative Analyst",
  "Quantitative Equity Head",
  "Quantitative Research Analyst",
  "Quantitative Strategy Analyst",
  "Research Analyst",
  "Financial Quantitative Analysts",
  "Agent-Based Modeler",
  "Computational Scientist",
  "Cryptographer",
  "Cryptographic Vulnerability Analyst",
  "Director of Quantitative Research",
  "Emerging Solutions Executive",
  "Image Scientist",
  "Lead Simulation Modeling Engineer",
  "Mathematicians",
  "Airport Operations Officer",
  "Asset Manager",
  "Housing Inspector",
  "Housing Management Representative",
  "Housing Quality Standard Inspector (HQS Inspector)",
  "Neighborhood Conservation Officer",
  "Quality Assurance Specialist",
  "Quality Control Manager",
  "Rehabilitation Construction Specialist",
  "Government Property Inspectors and Investigators",
  "Corporate Webmaster",
  "Web Administrator",
  "Web Content Coordinator",
  "Web Content Manager",
  "Web Director",
  "Web Manager",
  "Web Site Manager",
  "Web Technologies Administrator",
  "Web Administrators",
  "Developer",
  "Infrastructure Engineer",
  "Publishing Systems Analyst",
  "Senior Software Engineer",
  "Software Architect",
  "Software Developer",
  "Software Engineer",
  "Systems Coordinator",
  "Software Developers, Systems Software",
  "Data Warehouse Analyst",
  "Data Warehouse Manager",
  "Data Warehouse Solution Architect",
  "Data Warehousing Specialists",
  "Credit Administrator",
  "Credit Analyst",
  "Credit and Collections Analyst",
  "Credit Manager",
  "Credit Representative",
  "Credit Risk Analyst",
  "Risk Analyst",
  "Credit Analysts",
  "Certified Consumer Credit and Housing Counselor",
  "Certified Credit Counselor",
  "Certified Personal Finance Counselor",
  "Counseling Program Leader",
  "Counselor",
  "Credit Counselor",
  "Financial Coach",
  "Financial Health Counselor",
  "Financial Wellness Coach",
  "Credit Counselors",
  "Advanced Analytics Associate",
  "Analytical Strategist",
  "Business Analytics Director",
  "Business Insight and Analytics Manager",
  "Decision Analyst",
  "Operations Research Analyst",
  "Operations Research Director",
  "Operations Research Group Manager",
  "Operations Research Manager",
  "Optimization Analyst",
  "Operations Research Analysts",
  "Mathematical Science Occupations, All Other",
  "Benefits Administrator",
  "Benefits Analyst",
  "Benefits Specialist",
  "Compensation Analyst",
  "Compensation Consultant",
  "Compensation Coordinator",
  "Compensation Specialist",
  "Compensation/Benefits Specialist",
  "Personnel Specialist",
  "Position Classification Specialist",
  "Compensation, Benefits, and Job Analysis Specialists",
  "Catering Director",
  "Conference Planner",
  "Conference Planning Manager",
  "Conference Services Director",
  "Conference Services Manager",
  "Convention Services Director",
  "Convention Services Manager (CSM)",
  "Event Coordinator",
  "Events Manager",
  "Special Events Coordinator",
  "Meeting, Convention, and Event Planners",
  "Accountant",
  "Accounting Manager",
  "Accounting Officer",
  "Certified Public Accountant (CPA)",
  "General Accountant",
  "Project Accountant",
  "Staff Accountant",
  "Accountants",
  "Credit Risk Management Director",
  "Global Risk Management Director",
  "Operational Risk Management Vice President",
  "Purchasing and Risk Services Director",
  "Risk Management and Benefits Vice President",
  "Risk Management Director",
  "Risk Management Manager",
  "Risk Specialist",
  "Risk Management Specialists",
  "Database Administration Manager",
  "Database Administrator (DBA)",
  "Database Analyst",
  "Database Coordinator",
  "Database Developer",
  "Database Programmer",
  "Information Systems Manager",
  "Management Information Systems Director (MIS Director)",
  "System Administrator",
  "Database Administrators",
  "Clinical Data Associate",
  "Clinical Data Management Associate Director (CDM Associate Director)",
  "Clinical Data Management Director (CDM Management Director)",
  "Clinical Data Management Manager (CDM Manager)",
  "Clinical Data Manager",
  "Clinical Informatics Manager",
  "Clinical Research Associate",
  "Data Deliverables Manager",
  "Data Management Manager",
  "Data Management Specialist",
  "Clinical Data Managers",
  "Direct Response Consultant",
  "Executive Director of Development and Alumni Relations",
  "Executive Director of Development, Gift Planning",
  "Fundraising Consultant",
  "Principal Gifts Officer",
  "Vice President for Philanthropy",
  "Vice President of Major Gifts and Planned Giving",
  "Vice President, Marketing & Development",
  "Fundraisers",
  "Corporate Licensed Broker",
  "Customs Broker",
  "Customs Brokerage Manager",
  "Customs Compliance Director",
  "Customs Director",
  "Customs Manager",
  "Import Manager",
  "Customs Brokers",
  "Business Process Analyst",
  "Business Records Manager",
  "Document Control Manager",
  "Document Control, Electronic Content, and Records Manager",
  "Document Management Consultant",
  "Implementation Specialist",
  "IT Project Manager",
  "Manager, Enterprise Content Management",
  "Record Systems Analyst",
  "Records Manager",
  "Document Management Specialists",
  "Global Logistics Analyst",
  "Logistics Analyst",
  "Supply Chain Analyst",
  "Logistics Analysts",
  "Demographer",
  "Mathematical Statistician",
  "Psychometric Consultant",
  "Quantitative Methodologist",
  "Researcher",
  "Statistical Analyst",
  "Statistical Consultant",
  "Statistical Reporting Analyst",
  "Statistician",
  "Statisticians",
  "Chief Deputy Coroner",
  "Coroner",
  "Coroner/Medical Examiner",
  "County Coroner",
  "Deputy Coroner",
  "District Medical Examiner",
  "Elected County Coroner/Chief Medical Examiner",
  "Forensic Pathologist",
  "Medical Examiner",
  "Medical Legal Investigator (MLI)",
  "Coroners",
  "Certified Income Tax Preparer (CTP)",
  "Corporate Tax Preparer",
  "Enrolled Agent",
  "Income Tax Preparer",
  "Master Tax Advisor",
  "Tax Advisor",
  "Tax Associate",
  "Tax Consultant",
  "Tax Preparer",
  "Tax Specialist",
  "Tax Preparers",
  "Business Operations Specialists, All Other",
  "Client Services Administrator",
  "Logistician",
  "Logistics Team Lead",
  "Production Planner",
  "Supervisory Supply Management Specialist",
  "Supportability Engineer",
  "Logisticians",
  "Champion of Sustainable Design",
  "Chief Specialist, LEED (Chief Specialist, Leadership in Energy and Environmental Design)",
  "Lead Sustainability Specialist",
  "Senior Sustainability Advisor",
  "Senior Sustainability Consultant",
  "Sustainability Consultant",
  "Sustainability Specialist",
  "Sustainable Design Consultant",
  "Sustainable Design Coordinator",
  "Sustainability Specialists",
  "Geographic Information Systems Technician (GIS Technician)",
  "Geographic Information Systems Technicians",
  "Appraisers and Assessors of Real Estate",
  "Business Continuity Administrator",
  "Business Continuity Analyst",
  "Business Continuity and Crisis Management Director",
  "Business Continuity and Disaster Recovery Consultant",
  "Business Continuity Consultant",
  "Business Continuity Coordinator",
  "Business Continuity Manager",
  "Business Continuity Planning Manager",
  "Business Continuity Professional",
  "Enterprise Resiliency Manager",
  "Business Continuity Planners",
  "Business Banking Officer",
  "Commercial Banker",
  "Commercial Loan Officer",
  "Corporate Banking Officer",
  "Loan Officer",
  "Mortgage Loan Officer",
  "Mortgage Loan Originator",
  "Relationship Manager",
  "Loan Officers",
  "Building Energy Consultant",
  "Building Performance Consultant",
  "Building Performance Specialist",
  "Energy Auditor",
  "Energy Consultant",
  "Energy Rater",
  "Home Energy Rater",
  "Home Performance Consultant",
  "Quality Assurance Supervisor",
  "Residential Energy Auditor",
  "Energy Auditors",
  "Data Security Administrator",
  "Information Security Officer",
  "Information Security Specialist",
  "Information Systems Security Analyst",
  "Information Systems Security Officer",
  "Information Technology Security Analyst (IT Security Analyst)",
  "Information Technology Specialist",
  "Network Security Analyst",
  "Information Security Analysts",
  "Computer Network Specialist",
  "IT Consultant (Information Technology Consultant)",
  "Network Support Specialist",
  "Network Technical Analyst",
  "Personal Computer Network Analyst",
  "Senior IT Assistant (Senior Information Technology Assistant)",
  "Systems Specialist",
  "Computer Network Support Specialists",
  "Cloud Product Director",
  "Cybersecurity Project Manager",
  "Data Center Product Director",
  "Information Systems Project Manager (IS Project Manager)",
  "IT Developer (Information Technology Developer)",
  "IT Manager (Information Technology Manager)",
  "IT Program Manager (Information Technology Program Manager)",
  "IT Project Manager (Information Technology Project Manager)",
  "Scrum Master",
  "Transition Program Manager",
  "Information Technology Project Managers",
  "Business Agent",
  "Business Representative",
  "Field Operations Coordinator",
  "Grievance Manager",
  "Labor Relations Director",
  "Labor Relations Specialist",
  "Labor Relations Specialists",
  "Marketing Specialist",
  "Master Hearth Technician",
  "Online Marketing Manager",
  "Online Services Manager",
  "Owner, E Commerce Company",
  "Social Media Director",
  "Supervisor of Operations",
  "Vice President of Marketing",
  "Wholesale Representative",
  "Online Merchants",
  "Certified Fraud Examiner",
  "Confidential Investigator",
  "Financial Investigator",
  "Forensic Accountant",
  "Forensic Audit Expert",
  "Fraud Manager",
  "Inspector General",
  "Investigations Chief",
  "Investigator",
  "Special Investigation Unit Investigator",
  "Fraud Examiners, Investigators and Analysts",
  "Product Assurance Engineer",
  "Quality Assurance Analyst (QA Analyst)",
  "Quality Assurance Director (QA Director)",
  "Quality Assurance Engineer (QA Engineer)",
  "Quality Assurance Practice Manager (QA Practice Manager)",
  "Software Quality Assurance Engineer (SQA Engineer)",
  "Software Quality Engineer",
  "Software Test Engineer",
  "Test Engineer",
  "Software Quality Assurance Engineers and Testers",
  "Application Developer",
  "Application Integration Engineer",
  "Applications Developer",
  "Computer Consultant",
  "Software Development Engineer",
  "Technical Consultant",
  "Software Developers",
  "Associate Director of Biostatistics",
  "Biostatistician",
  "Biostatistics Director",
  "Principal Biostatistician",
  "Principal Statistical Scientist",
  "Senior Biostatistician/Group Leader",
  "Biostatisticians",
  "Actuarial Analyst",
  "Actuarial Associate",
  "Actuarial Consultant",
  "Actuary",
  "Consulting Actuary",
  "Health Actuary",
  "Pricing Actuary",
  "Pricing Analyst",
  "Product Development Actuary",
  "Retirement Actuary",
  "Actuaries",
  "Consultant in Ergonomics and Safety",
  "Ergonomist",
  "Human Factors Advisor, Lead",
  "Human Factors Engineer",
  "Human Factors Scientist",
  "Managing Cognitive Engineer",
  "PI/Senior Research Associate",
  "User Experience Team Lead",
  "Human Factors Engineers and Ergonomists",
  "Development System Efficiency Manager",
  "Dimensional Integration Engineer",
  "Director of Engineering, Quality & Program Management.",
  "Director, Product Safety",
  "Senior Engineering Team Leader",
  "Senior Project Engineer",
  "Technical Specialist",
  "Automotive Engineers",
  "Algorithm Developer",
  "Laser Engineer",
  "Optical Design Engineer",
  "Optical Engineer",
  "Optical Engineering Manager",
  "Optical Specialist",
  "Optical Systems Engineer",
  "Optoelectronics Engineer",
  "Research and Development Engineer (R & D Engineer)",
  "Research Engineer",
  "Photonics Engineers",
  "Aerial Photogrammetrist",
  "Cartographer",
  "Cartographic Designer",
  "Compiler",
  "Digital Cartographer",
  "Mapper",
  "Photogrammetric Technician",
  "Photogrammetrist",
  "Stereo Compiler",
  "Stereoplotter Operator",
  "Cartographers and Photogrammetrists",
  "Chemical Engineer",
  "Development Engineer",
  "Engineering Scientist",
  "Process Control Engineer",
  "Process Engineer",
  "Refinery Process Engineer",
  "Research Chemical Engineer",
  "Chemical Engineers",
  "Field Service Engineer",
  "Hardware Design Engineer",
  "Hardware Engineer",
  "Senior Hardware Engineer",
  "Systems Integration Engineer",
  "Computer Hardware Engineers",
  "Aeronautical Engineer",
  "Aerospace Engineer",
  "Aerospace Stress Engineer",
  "Avionics Engineer",
  "Flight Controls Engineer",
  "Flight Test Engineer",
  "Structural Analysis Engineer",
  "Aerospace Engineers",
  "Circuits Engineer",
  "Electrical Controls Engineer",
  "Electrical Design Engineer",
  "Electrical Engineer",
  "Electrical Project Engineer",
  "Instrumentation and Electrical Reliability Engineer (I&E Reliability Engineer)",
  "Power Systems Engineer",
  "Electrical Engineers",
  "Director of Landscape Architecture and Planning",
  "Golf Course Architect",
  "Land Planner",
  "Landscape Architect",
  "Landscape Architect and Planner",
  "Landscape Designer",
  "Planner",
  "Project Landscape Architect",
  "Senior Landscape Architect",
  "Landscape Architects",
  "Biomedical Electronics Technician",
  "Biomedical Engineer",
  "Biomedical Engineering Director",
  "Biomedical Engineering Technician",
  "Biomedical Equipment Technician (BMET)",
  "Biomedical Manager",
  "Biomedical Technician",
  "Product Development Director",
  "Biomedical Engineers",
  "Extra Vehicular Activity Safety Engineer (EVA Engineer)",
  "Product Safety and Standards Engineer",
  "Product Safety Consultant",
  "Product Safety Coordinator",
  "Product Safety Engineer",
  "Product Safety Manager",
  "Product Safety Officer",
  "Risk Control Product Liability Director",
  "Service Loss Control Consultant",
  "System Safety Engineer",
  "Product Safety Engineers",
  "Airport Manager",
  "Design Manager",
  "Rail Engineer",
  "Roadway Designer",
  "Roadway Engineer",
  "Traffic Engineer",
  "Traffic Operations Engineer",
  "Transportation Engineer",
  "Transportation Engineers",
  "Materials and Processes Manager",
  "Materials Branch Chief",
  "Materials Development Engineer",
  "Materials Engineer",
  "Materials Research Engineer",
  "Metallurgical Engineer",
  "Metallurgist",
  "Materials Engineers",
  "Consulting Marine Engineer",
  "Hull Outfit Supervisor",
  "Marine Consultant",
  "Marine Design Engineer",
  "Marine Engineer",
  "Marine Engineering Consultant",
  "Marine Surveyor",
  "Propulsion Machinery Service Engineer",
  "Ships Equipment Engineer",
  "Marine Engineers",
  "Air Pollution Control Engineer",
  "Chief, Pesticides and Toxic Substances Branch",
  "Environmental Analyst",
  "Environmental Engineer",
  "Environmental Remediation Specialist",
  "Global Director Air and Climate Change",
  "Hazardous Substances Engineer",
  "Marine Engineer CPVEC (Marine Engineer Commercial Passenger Vessel Environmental Compliance)",
  "Regulatory Environmental Compliance Manager",
  "Sanitary Engineer",
  "Environmental Engineers",
  "Industrial Engineer",
  "Manufacturing Specialist",
  "Operations Engineer",
  "Plant Engineer",
  "Production Engineer",
  "Supply Chain Engineer",
  "Tool Engineer",
  "Industrial Engineers",
  "City Surveyor",
  "County Surveyor",
  "Land Surveyor",
  "Mine Surveyor",
  "Registered Land Surveyor",
  "Survey Manager",
  "Survey Party Chief",
  "Survey Project Manager",
  "Survey Superintendent",
  "Surveyor",
  "Surveyors",
  "Bridge/Structure Inspection Team Leader",
  "City Engineer",
  "Civil Engineer",
  "County Engineer",
  "Railroad Design Consultant",
  "Structural Engineer",
  "Civil Engineers",
  "Marine Engineers and Naval Architects",
  "Application Engineer",
  "Design Maintenance Engineer",
  "Equipment Engineer",
  "Mechanical Design Engineer",
  "Mechanical Engineer",
  "Mechanical Engineers",
  "Acting Section Chief",
  "Assistant County Engineer",
  "Director Water and Waste Services",
  "Engineer and Geologist",
  "Principal Consulting Engineer",
  "Principal Technologist",
  "Project Manager/Design Manager",
  "Surface Water Manager",
  "Water Reuse Program Manager",
  "Water/Wastewater Engineers",
  "Boat Builder",
  "Engineering Specialist",
  "Initial Design and Naval Architecture Supervisor",
  "Marine Architect",
  "Marine Structural Designer",
  "Naval Architect",
  "Naval Architect Specialist",
  "Piping Designer",
  "Marine Architects",
  "Mine Engineer",
  "Mine Engineering Manager",
  "Mine Safety Manager",
  "Mining Consultant",
  "Mining Engineer",
  "Planning Engineer",
  "Safety Coordinator",
  "Safety Engineer",
  "Safety Representative",
  "Mining and Geological Engineers, Including Mining Safety Engineers",
  "Geodesist",
  "Geodetic Advisor",
  "Geodetic Survey Director",
  "Geodetic Surveyor",
  "Licensed Land Surveyor",
  "Regional Geodetic Advisor",
  "Research Specialist",
  "Survey Director",
  "Survey Supervisor",
  "Geodetic Surveyors",
  "Consulting Engineer",
  "Fire Protection Engineer",
  "Fire Protection Engineer and Code Consultant (FP Engineer and Code Consultant)",
  "Lead Fire Protection Engineer",
  "Senior Engineer",
  "Senior Fire Protection Engineer",
  "Fire-Prevention and Protection Engineers",
  "Automation Engineer",
  "Automation Specialist",
  "Controls Engineer",
  "Senior Design Engineer",
  "Mechatronics Engineers",
  "Health and Safety Engineers, Except Mining Safety Engineers and Inspectors",
  "Electronics Design Engineer",
  "Evaluation Engineer",
  "Integrated Circuit Design Engineer (IC Design Engineer)",
  "Radio Frequency Engineer (RF Engineer)",
  "Research and Development Engineer (R&D Engineer)",
  "Test Engineering Manager",
  "Electronics Engineers, Except Computer",
  "Deployment Engineer",
  "Electro Magnetic Compatibility Test Engineer",
  "Emerging Technologies Director",
  "Engineering and Development Director",
  "Engineering Director",
  "Quality Director",
  "RFID Engineer (Radio Frequency Identification Device Engineer)",
  "RFID Manager (Radio Frequency Identification Device Manager)",
  "RFID Systems Engineer (Radio Frequency Identification Device Systems Engineer)",
  "Technical Support Engineer",
  "Radio Frequency Identification Device Specialists",
  "Environmental Health and Safety Director (EHS Director)",
  "Health and Safety Professional",
  "Health and Safety Specialist",
  "Industrial Hygienist",
  "Industrial Safety Engineer",
  "Safety and Health Consultant",
  "Safety Manager",
  "Safety Team Leader",
  "Safety, Health, and Environment Vice President",
  "Industrial Safety and Health Engineers",
  "Nuclear Design Engineer",
  "Nuclear Engineer",
  "Nuclear Licensing Engineer",
  "Nuclear Process Engineer",
  "Nuclear Reactor Engineer",
  "Radiological Engineer",
  "Resident Inspector",
  "Nuclear Engineers",
  "Advanced Research Programs Director",
  "Microarray Operations Vice President",
  "Technical Programs Manager",
  "Nanosystems Engineers",
  "Electrical and Electronics Drafters",
  "Automation Engineering Manager",
  "Autonomous Vehicle Design Engineer",
  "Engineering Vice President",
  "Factory Automations Engineer",
  "Robotic Systems Engineer",
  "Robotics and Systems Lead",
  "Robotics Engineers",
  "Electrical and Electronic Engineering Technicians",
  "Industrial Radiographer",
  "Inspector",
  "Non-Destructive Evaluation Manager (NDE Manager)",
  "Non-Destructive Evaluation Technician (NDE Technician)",
  "Non-Destructive Testing Services Director (NDT Services Director)",
  "Non-Destructive Testing Specialist (NDT Specialist)",
  "Non-Destructive Testing Supervisor (NDT Supervisor)",
  "Non-Destructive Testing Technician (NDT Technician)",
  "Quality Engineer",
  "Non-Destructive Testing Specialists",
  "Distributed Energy Systems Consultant",
  "Energy Systems Laboratory Director",
  "Field Engineer",
  "Renewable Energy Division Manager",
  "Solar Engineer",
  "Solar Energy Systems Engineers",
  "Corporate Quality Engineer",
  "Design Assurance Manager",
  "Post Market Quality Manager",
  "Product Quality Engineer",
  "Quality Assurance Engineer",
  "Quality Management Systems Engineer",
  "Supplier Quality Engineer",
  "Validation Specialist",
  "Validation Engineers",
  "CAD Designer (Computer Aided Design Designer)",
  "CAD Operator (Computer Aided Design Operator)",
  "Design Drafter",
  "Drafter",
  "Drafting Technician",
  "Mechanical Designer",
  "Mechanical Drafter",
  "Product Designer",
  "Project Designer",
  "Mechanical Drafters",
  "Research Associate",
  "Research Fellow",
  "Research Greenhouse Supervisor",
  "University Extension Specialist",
  "Biochemical Engineers",
  "Advanced Manufacturing Engineer",
  "Advanced Manufacturing Vice President",
  "Facility Engineer",
  "Manufacturing Director",
  "Manufacturing Engineer",
  "Manufacturing Engineering Director",
  "Manufacturing Engineering Manager",
  "Process Improvement Engineer",
  "Manufacturing Engineers",
  "Control Systems Engineer",
  "MEMS Integration Engineer (Microelectrical Mechanical Integration Engineer)",
  "Microsystems Engineer",
  "Product Design Engineer",
  "Project Design Engineer",
  "Radio Frequency Design Engineer (RF Design Engineer)",
  "Microsystems Engineers",
  "Completion Engineer",
  "Completions Engineer",
  "Drilling Engineer",
  "Drilling Manager",
  "Petroleum Engineer",
  "Petroleum Production Engineer",
  "Reservoir Engineer",
  "Petroleum Engineers",
  "Integrated Circuit IC Layout Designer (IC Layout Designer)",
  "Layout Designer",
  "Mask Designer",
  "Printed Circuit Board PCB Designer (PCB Designer)",
  "Printed Circuit Board PCB Draftsman (PCB Draftsman)",
  "Senior Designer",
  "Senior Printed Circuit Board PCB Designer",
  "Electronic Drafters",
  "Civil Designer",
  "Civil Engineering Assistant",
  "Civil Engineering Designer",
  "Civil Engineering Technician",
  "Design Technician",
  "Engineer Technician",
  "Engineering Assistant",
  "Engineering Technician",
  "Transportation Engineering Technician",
  "Civil Engineering Technicians",
  "Electrical Engineering Technician",
  "Electrical Technician",
  "Generation Technician",
  "Instrument and Controls Technician (I & C Technician)",
  "Relay Tester",
  "Results Technician",
  "Test Specialist",
  "Test Technician",
  "Electrical Engineering Technicians",
  "Architectural and Civil Drafters",
  "Engineering Technicians, Except Drafters, All Other",
  "Associate Drafter",
  "CAD Technician (Computer Aided Drafting Technician)",
  "Electrical Designer",
  "Electrical Drafter",
  "Engineering Agent",
  "Engineering Associate",
  "Line Staker",
  "Staking Engineer",
  "Electrical Drafters",
  "Automation Technician",
  "Electrical and Instrumentation Technician (E and I Technician)",
  "Field Service Technician",
  "Instrument and Automation Technician",
  "Instrument Specialist",
  "Instrument Technician",
  "Instrumentation and Controls Technician",
  "Instrumentation and Process Controls Technician",
  "Process Control Technician",
  "Programmable Logic Controllers Technician",
  "Robotics Technicians",
  "Engineers, All Other",
  "Avionics Installation Technician",
  "Avionics Technician",
  "Avionics Test Technician",
  "Electronics Technician",
  "Engineering Test Technician",
  "Flight Test Instrument Technician",
  "Instrumentation Technician",
  "Systems Test Technician",
  "Aerospace Engineering and Operations Technicians",
  "Engineering Lab Technician",
  "Engineering Technical Analyst",
  "Laboratory Technician",
  "Maintenance Technician",
  "Mechanical Technician",
  "Process Engineering Technician",
  "Process Technician",
  "Research and Development Technician",
  "Mechanical Engineering Technicians",
  "Director, Hydrogen Storage Engineering",
  "Division Director",
  "Engineering Professor",
  "Professor of Chemical Engineering",
  "Scientist/Engineer",
  "Senior Research Engineer",
  "Senior Stack Engineer",
  "Fuel Cell Engineers",
  "Civil Drafter",
  "Computer-Aided Design Designer (CAD Designer)",
  "Computer-Aided Design Operator (CAD Operator)",
  "Computer-Aided Design Technician (CAD Technician)",
  "Computer-Aided Drafting and Design Drafter (CADD Drafter)",
  "Computer-Aided Drafting Designer (CAD Designer)",
  "Draftsman",
  "Draftsperson",
  "Civil Drafters",
  "Digital Tech (Digital Technician)",
  "Electronics Engineering Technician",
  "Engineering Technician (Engineering Tech)",
  "Failure Analysis Technician (FA Technician)",
  "Refurbish Technician (Refurb Tech)",
  "Senior Electronics Technician",
  "Technician",
  "Electronics Engineering Technicians",
  "Architectural Designer",
  "Architectural Drafter",
  "Architectural Draftsman",
  "Architectural Technician",
  "CAD Technician (Computer-Aided Design Technician)",
  "CADD Operator (Computer-Aided Design and Drafting Operator)",
  "Detailer",
  "Truss Designer",
  "Architectural Drafters",
  "Emissions Engineer",
  "Engineering Team Supervisor",
  "Laboratory Technician (Lab Technician)",
  "Research Technician",
  "Automotive Engineering Technicians",
  "Drafters, All Other",
  "Industrial Engineering Analyst",
  "Industrial Engineering Technician",
  "Manufacturing Technician",
  "Methods Engineer",
  "Process Documentation and Methods Analyst",
  "Production Staff Worker",
  "Quality Control Engineering Technician (QC Engineering Technician)",
  "Industrial Engineering Technicians",
  "Air Quality Instrument Specialist",
  "Environmental Engineering Assistant",
  "Environmental Engineering Technician",
  "Environmental Field Technician",
  "Environmental Technician",
  "Haz Tech (Hazardous Technician)",
  "Industrial Waste Inspector",
  "Senior Environmental Technician",
  "Environmental Engineering Technicians",
  "Distributed Generation Project Manager",
  "Energy Efficiency Engineer",
  "Energy Engineer",
  "Energy Manager",
  "Environmental Solutions Engineer",
  "Industrial Energy Engineer",
  "Measurement And Verification Engineer",
  "Project Engineering Director",
  "Resource Efficiency Manager",
  "Test and Balance Engineer",
  "Energy Engineers",
  "Project Designer, Transmission and Distribution Engineering",
  "Senior Engineer/Practice Leader-Land Development and Engineering",
  "Senior Wind Energy Consultant",
  "Turbine Measurements Engineer",
  "Utility Engineer",
  "Wind Farm Siting and Development Consultant",
  "Wind Science and Planning",
  "Wind Turbine Design Engineer",
  "Wind Energy Engineers",
  "Fuel Cell Technicians",
  "Associate Product Integrity Engineer",
  "Head of Operation and Logistics",
  "Liaison Engineer",
  "Manager, Asset Management",
  "Materials Planner/Production Planner",
  "Planner/Scheduler",
  "Production Control Supervisor",
  "Quality Management Coordinator",
  "Quality Tech",
  "Senior Quality Methods Specialist",
  "Industrial Engineering Technologists",
  "Research Staff Member",
  "Scientific Research Associate",
  "Staff Scientist",
  "Technical Research Scientist",
  "Nanotechnology Engineering Technologists",
  "Surveying and Mapping Technicians",
  "Aerotriangulation Specialist",
  "CAD Technician (Computer Aided Design Technician)",
  "Geospatial Analyst",
  "Mapping Editor",
  "Mapping Technician",
  "Photogrammetric Compilation Specialist",
  "Photogrammetric Stereo Compiler",
  "Tax Map Technician",
  "Mapping Technicians",
  "Agronomist",
  "Agronomy Research Manager",
  "Agronomy Specialist",
  "Crop Nutrition Scientist",
  "Extension Specialist",
  "Microbiology Soil Scientist",
  "On-Site Soil Evaluator",
  "Research Soil Scientist",
  "Soil Fertility Extension Specialist",
  "Soil Scientist",
  "Soil and Plant Scientists",
  "Engineering Tech",
  "Instrumentation and Electrical Preventive Maintenance Inspector IE PM Inspector",
  "Process Control Tech",
  "R&D Lab Technician",
  "Senior Design Engineering Specialist",
  "Senior Mechanical Designer",
  "Senior Tech Manufacturing Engineering",
  "Electromechanical Engineering Technologists",
  "Design Tech",
  "Electrical Tech/Project Manager",
  "Engineering Technologist",
  "Senior Analysis Specialist",
  "Senior Engineering Tech",
  "Senior Process Control Tech",
  "Technologist",
  "Technologist Electronic Design or Technical Director",
  "Technologist, Development",
  "Electrical Engineering Technologists",
  "Coating Manager",
  "Fiber Optics Technician",
  "Laser Technician",
  "Lead Technician",
  "Optomechanical Technician",
  "Photonic Laboratory Technician (Photonic Lab Tech)",
  "Photonics Technician",
  "Ruling Technician",
  "Photonics Technicians",
  "Communications Technologist",
  "Electronics Department Manager",
  "Electronics Technology Department Chair",
  "Electronics Technology Instructor",
  "Field Technical Specialist",
  "System Technologist",
  "Systems Technician",
  "Systems Technologist",
  "Electronics Engineering Technologists",
  "Experimental Machining Lab Manager",
  "Final Operations Technician",
  "Metallurgical Lab Technician",
  "Quality Assurance Technician",
  "Quality Technician",
  "Service Technician",
  "Support Technician",
  "Tool Room Supervisor",
  "Value Stream Manager",
  "Manufacturing Production Technicians",
  "Chainman",
  "Instrument Man (I-Man)",
  "Instrument Operator",
  "Instrument Person",
  "Rodman",
  "Survey Crew Chief",
  "Survey Technician",
  "Surveying Technicians",
  "Animal Nutrition Consultant",
  "Animal Nutritionist",
  "Animal Scientist",
  "Beef Cattle Specialist",
  "Dairy Nutrition Consultant",
  "Nutritionist",
  "Research and Development Director (R&D Director)",
  "Research Center Partner",
  "Research Nutritionist",
  "Animal Scientists",
  "Food and Drug Research Scientist",
  "Food Chemist",
  "Food Scientist",
  "Food Technologist",
  "Product Development Scientist",
  "Quality Control Inspector (QC Inspector)",
  "Research Chef",
  "Research Food Technologist",
  "Seafood Technology Specialist",
  "Food Scientists and Technologists",
  "Electro-Mechanic",
  "Electro-Mechanical Technician (E/M Technician)",
  "Electronic Technician",
  "Product Test Specialist",
  "Tester",
  "Electro-Mechanical Technicians",
  "Engineer Technical Staff",
  "Mechanical Designer/Wind-Chill Administrator",
  "Senior Process Analyst",
  "Technical Staff Engineer",
  "Tooling Engineering Tech",
  "Mechanical Engineering Technologists",
  "Manufacturing Technology Analyst",
  "Manufacturing Engineering Technologists",
  "Aquatic Biologist",
  "Aquatic Scientist",
  "Biological Scientist",
  "Biologist",
  "Botanist",
  "Marine Biologist",
  "Research Biologist",
  "Biologists",
  "Microfabrication Engineer Manager",
  "Nanofabrication Specialist",
  "Research Support Specialist",
  "Technical Associate",
  "Nanotechnology Engineering Technicians",
  "Bacteriologist",
  "Clinical Laboratory Scientist",
  "Clinical Microbiologist",
  "Microbiological Analyst",
  "Microbiological Laboratory Technician",
  "Microbiologist",
  "Microbiology Director",
  "Microbiology Laboratory Manager",
  "Quality Control Microbiologist (QC Microbiologist)",
  "Study Director",
  "Microbiologists",
  "Conservation Resources Management Biologist",
  "Fish and Wildlife Biologist",
  "Fisheries Biologist",
  "Fisheries Management Biologist",
  "Habitat Biologist",
  "Migratory Game Bird Biologist",
  "Wildlife Biologist",
  "Wildlife Manager",
  "Zoologist",
  "Zoologists and Wildlife Biologists",
  "Analytical Research Chemist",
  "Chief Scientific Officer",
  "Director of the Biophysics Facility",
  "Laboratory Director",
  "Professor of Physics/Researcher in Biophysics",
  "Research Assistant",
  "Biochemists and Biophysicists",
  "Conservation Scientists",
  "Molecular Biology Director",
  "Molecular Biology Professor",
  "Molecular and Cellular Biologists",
  "Conservationist",
  "Erosion Control Specialist",
  "Land Manager",
  "Land Reclamation Specialist",
  "Land Resource Specialist",
  "Resource Conservation Specialist",
  "Resource Conservationist",
  "Soil Conservationist",
  "Watershed Program Manager",
  "Soil and Water Conservationists",
  "Grassland Conservationist",
  "Habitat Management Coordinator",
  "Land Management Supervisor",
  "Natural Resource Manager",
  "Natural Resource Specialist",
  "Range Technician",
  "Rangeland Management Specialist",
  "Resource Manager",
  "Range Managers",
  "Biophysics Scientist",
  "Health Physicist",
  "Physicist",
  "Research Consultant",
  "Research Physicist",
  "Physicists",
  "Area Forester",
  "Chief Unit Forester",
  "Environmental Protection Forester",
  "Fire Prevention Forester",
  "Forest Practices Field Coordinator",
  "Forester",
  "Regional Forester",
  "Resource Forester",
  "Silviculturist",
  "Urban Forester",
  "Foresters",
  "Bioinformaticist",
  "Bioinformatics Scientist",
  "Director of Bioinformatics and Trait Discovery",
  "Director of Translation and Experimental Medicine Bioinformatics",
  "Director, Informatics",
  "Scientific Database Curator",
  "Scientific Informatics Project Leader",
  "Bioinformatics Scientists",
  "Life Scientists, All Other",
  "Astronomer",
  "Astronomy Professor",
  "Astrophysicist",
  "Observatory Director",
  "Professor",
  "Space Sciences Director",
  "Astronomers",
  "Biological Scientists, All Other",
  "Clinical Cytogenetics Director",
  "Medical Geneticist",
  "Medical Genetics Director",
  "Geneticists",
  "Associate Director, Experimental Medicine",
  "Clinical Pharmacologist",
  "Post-Doctoral Fellow",
  "Medical Scientists, Except Epidemiologists",
  "Chronic Disease Epidemiologist",
  "Communicable Disease Specialist",
  "Environmental Epidemiologist",
  "Epidemiologist",
  "Epidemiology Investigator",
  "Infection Control Practitioner (ICP)",
  "Nurse Epidemiologist",
  "Public Health Epidemiologist",
  "Research Epidemiologist",
  "State Epidemiologist",
  "Epidemiologists",
  "Materials Scientist",
  "Micro Electrical/Mechanical Systems Device Scientist (MEMS Device Scientist)",
  "Polymer Materials Consultant",
  "Research and Development Scientist (R and D Scientist)",
  "Senior Materials Scientist",
  "Staff Research Scientist",
  "Technology Officer",
  "Vice President Research",
  "Materials Scientists",
  "Economist",
  "Environmental Economist",
  "Natural Resource Economist",
  "Principal Associate",
  "Principal Research Economist",
  "Resource Economist",
  "Senior Economist",
  "Environmental Economists",
  "Broadcast Meteorologist",
  "Forecaster",
  "Forensic Meteorologist",
  "General Forecaster",
  "Hydrometeorological Technician",
  "Meteorologist",
  "Research Meteorologist",
  "Space Weather Forecaster",
  "Warning Coordination Meteorologist",
  "Weather Forecaster",
  "Atmospheric and Space Scientists",
  "Physical Scientists, All Other",
  "Economic Analyst",
  "Economic Consultant",
  "Economic Development Specialist",
  "Forensic Economist",
  "Project Economist",
  "Revenue Research Analyst",
  "Tax Economist",
  "Economists",
  "Ecologist",
  "Environmental Consultant",
  "Environmental Programs Manager",
  "Environmental Protection Agency Counselor",
  "Environmental Services Director",
  "Industrial Ecologists",
  "Psychologists, All Other",
  "Clinical, Counseling, and School Psychologists",
  "Data Analyst",
  "Field Interviewer",
  "Market Survey Representative",
  "Methodologist",
  "Public Opinion Analyst",
  "Research Interviewer",
  "Survey Research Consultant",
  "Telephone Interviewer",
  "Survey Researchers",
  "Child Psychologist",
  "Clinical Psychologist",
  "Clinical Therapist",
  "Forensic Psychologist",
  "Licensed Clinical Psychologist",
  "Licensed Psychologist",
  "Licensed Psychologist Manager",
  "Pediatric Psychologist",
  "Psychologist",
  "Clinical Psychologists",
  "Earth Observations Chief Scientist (NASA)",
  "Environmental Affairs Corporate Director",
  "Geographic Information Systems Program Director (GIS Program Director)",
  "GIS Geographer (Geographic Information Systems Geographer)",
  "GIS Physical Scientist (Geographic Information Systems Physical Scientist)",
  "Supervisory Geographer",
  "Geographers",
  "Engineering Geologist",
  "Environmental Protection Geologist",
  "Exploration Geologist",
  "Geological Specialist",
  "Geologist",
  "Geophysicist",
  "Geoscientist",
  "Mine Geologist",
  "Petroleum Geologist",
  "Project Geologist",
  "Geoscientists, Except Hydrologists and Geographers",
  "Archaeologist",
  "Associate Director",
  "Curator",
  "Director of Research Center",
  "Principal Archaeologist",
  "Project Director",
  "Research Archaeologist",
  "Archeologists",
  "Air Quality Chemist",
  "Analytical Chemist",
  "Chemical Laboratory Scientist",
  "Chemist",
  "Forensic Chemist",
  "Forensic Scientist",
  "Quality Control Chemist (QC Chemist)",
  "Research Chemist",
  "Senior Chemist",
  "Chemists",
  "Anthropologists and Archeologists",
  "Education Specialist",
  "Environmental Education Specialist",
  "Environmental Educator",
  "Interpretive Naturalist",
  "Naturalist",
  "Park Activities Coordinator",
  "Park Interpretive Specialist",
  "Park Naturalist",
  "Park Ranger",
  "Program Production Specialist",
  "Park Naturalists",
  "Environmental Health and Safety Specialist",
  "Environmental Programs Specialist",
  "Environmental Scientist",
  "Hazardous Substances Scientist",
  "Registered Environmental Health Specialist (REHS)",
  "Research Environmental Scientist",
  "Senior Environmental Scientist",
  "Environmental Scientists and Specialists, Including Health",
  "Data Analytics Chief Scientist",
  "Geospatial Intelligence Analyst",
  "Remote Sensing Analyst",
  "Remote Sensing Program Manager",
  "Remote Sensing Scientist",
  "Sensor Specialist",
  "Remote Sensing Scientists and Technologists",
  "Research and Development Manager (R&D Manager)",
  "Research and Evaluation Manager",
  "Research Center Director",
  "Social Scientist",
  "Sociologist",
  "Sociologists",
  "International Affairs Vice President",
  "State-Federal Relations Deputy Director",
  "Political Scientists",
  "Social Scientists and Related Workers, All Other",
  "Environmental Health Specialist",
  "Laboratory Specialist",
  "Process Laboratory Specialist",
  "Public Health Sanitarian",
  "Sanitarian",
  "Water Quality Analyst",
  "Water Quality Specialist",
  "Environmental Science and Protection Technicians, Including Health",
  "American Indian Policy Specialist",
  "Applied Anthropologist",
  "Applied Cultural Anthropologist",
  "Evaluation Specialist",
  "Forensic Anthropologist",
  "Medical Anthropology Director",
  "Project Evaluator",
  "Research Anthropologist",
  "Research Director",
  "Anthropologists",
  "Biological Science Laboratory Technician",
  "Biological Science Technician",
  "Biological Technician",
  "Marine Fisheries Technician",
  "Wildlife Biology Technician",
  "Biological Technicians",
  "Planning Director",
  "Seaport Planning Manager",
  "Strategic Planning and Development Vice President",
  "Traffic Engineering Director",
  "Traffic Incident Management Manager",
  "Transit Planning Director",
  "Transit Planning Manager",
  "Transportation Analyst",
  "Transportation Planner",
  "Transportation Planners",
  "Counselors, All Other",
  "Core Inspector",
  "Electron Microprobe Operator",
  "Environmental Field Services Technician",
  "Environmental Sampling Technician",
  "Materials Technician",
  "Organic Section Technical Lead",
  "Physical Science Technician",
  "Quality Control Technician (QC Technician)",
  "Geological Sample Test Technicians",
  "Adult Probation Officer",
  "Correctional Counselor",
  "Deputy Juvenile Officer",
  "Deputy Probation Officer (DPO)",
  "Juvenile Probation Officer",
  "Parole Agent",
  "Parole Officer",
  "Probation and Parole Officer",
  "Probation Counselor",
  "Probation Officer",
  "Probation Officers and Correctional Treatment Specialists",
  "Applied Behavior Science Specialist (ABSS)",
  "Chemical Dependency Therapist",
  "Counseling Psychologist",
  "Counseling Services Director",
  "Licensed Professional Counselor (LPC)",
  "Psychotherapist",
  "Senior Staff Psychologist",
  "Staff Psychologist",
  "Counseling Psychologists",
  "Auxiliary Operator",
  "Equipment Operator",
  "Licensed Nuclear Operator",
  "Non-Licensed Nuclear Equipment Operator (NLO)",
  "Non-Licensed Nuclear Plant Operator (NLO)",
  "Nuclear Auxiliary Operator",
  "Nuclear Equipment Operator (NEO)",
  "Nuclear Plant Equipment Operator (NAPEO)",
  "Operations Technician",
  "Systems Operator",
  "Nuclear Equipment Operation Technicians",
  "Assessment Services Manager",
  "Consulting Psychologist",
  "Industrial Psychologist",
  "Industrial/Organizational Psychologist (I/O Psychologist)",
  "Organizational Consultant",
  "Organizational Development Consultant (OD Consultant)",
  "Organizational Psychologist",
  "Industrial-Organizational Psychologists",
  "Advanced Clinical Specialist",
  "Clinician",
  "Family Therapist",
  "Licensed Marriage and Family Therapist (LMFT)",
  "Marriage and Family Therapist (MFT)",
  "Therapist",
  "Marriage and Family Therapists",
  "Chemical Analyst",
  "Chemical Technician",
  "Formulation Technician",
  "Laboratory Analyst (Lab Analyst)",
  "Laboratory Technician (Lab Tech)",
  "Laboratory Tester (Lab Tester)",
  "Organic Preparation Analyst (Organic Prep Analyst)",
  "Water Quality Technician",
  "Chemical Technicians",
  "Exploration Manager",
  "Geological E-Logger",
  "Geological Technician",
  "Geoscience Technician",
  "Geotechnician",
  "Observer",
  "Soils Technician",
  "Technical Assistant",
  "Geophysical Data Technicians",
  "Life, Physical, and Social Science Technicians, All Other",
  "Aviation Neuropsychologist",
  "Clinical Neuropsychologist",
  "Mental Health Director",
  "Neuropsychologist",
  "Neuropsychology Director",
  "Neuropsychology Division Chief",
  "Neuropsychology Medical Consultant",
  "Neuropsychology Service Director",
  "Pediatric Neuropsychologist",
  "Neuropsychologists and Clinical Neuropsychologists",
  "Chemistry Technician",
  "Health Physics Technician (HP Technician)",
  "Nuclear Chemistry Technician",
  "Radiation Control Technician (Radcon Technician)",
  "Radiation Protection Specialist (RP Specialist)",
  "Radiation Protection Technician (RP Technician)",
  "Radiation Technician",
  "Radiochemical Technician",
  "Senior Health Physics Technician",
  "Senior Radiation Protection Technician",
  "Nuclear Monitoring Technicians",
  "Social Workers, All Other",
  "Geological and Petroleum Technicians",
  "Principal Scientist",
  "Research Associate, Policy",
  "Senior Advisor",
  "Senior Advocate",
  "Senior Associate, Climate and Energy Program",
  "Senior Climate Advisor",
  "Senior Policy Analyst",
  "Senior Policy Associate",
  "Staff Climate Scientist",
  "Climate Change Analysts",
  "City Planner",
  "Community Development Director",
  "Community Development Planner",
  "Housing Development Specialist",
  "Housing Grant Analyst",
  "Neighborhood Planner",
  "Regional Planner",
  "Urban Design Consultant",
  "Urban and Regional Planners",
  "Autism Consultant",
  "Bilingual School Psychologist",
  "Challenging Behavior Consultant",
  "Early Intervention School Psychologist",
  "Educational Diagnostician",
  "Learning Consultant",
  "School Psychologist",
  "School Psychometrist",
  "School Psychologists",
  "Groundwater Consultant",
  "Groundwater Programs Director",
  "Hydrologist",
  "Physical Scientist",
  "Research Hydrologist",
  "Source Water Protection Specialist",
  "Water Resources Program Director",
  "Hydrologists",
  "Agricultural and Food Science Technicians",
  "Addictions Counselor",
  "Case Manager",
  "Chemical Dependency Counselor (CD Counselor)",
  "Chemical Dependency Professional",
  "Clinical Counselor",
  "Correctional Substance Abuse Counselor",
  "Drug and Alcohol Treatment Specialist (DATS)",
  "Prevention Specialist",
  "Substance Abuse Counselor (SA Counselor)",
  "Substance Abuse and Behavioral Disorder Counselors",
  "Clinical Social Worker",
  "Dialysis Social Worker",
  "Director of Social Work",
  "Hospice Social Worker",
  "Medical Social Worker",
  "Nephrology Social Worker",
  "Oncology Social Worker",
  "Renal Social Worker",
  "Social Work Case Manager",
  "Social Worker",
  "Healthcare Social Workers",
  "Clinical Research Assistant",
  "Graduate Assistant",
  "Graduate Research Assistant",
  "Research Aide",
  "Social Research Assistant",
  "Survey Analyst",
  "Social Science Research Assistants",
  "Coastal and Estuary Specialist",
  "Director, Forest Restoration Institute",
  "Director, River Restoration",
  "Fish and Wildlife Biologist, Endangered Species Conservation and Recovery",
  "Fisheries Habitat Restoration Specialist",
  "Marine Habitat Resource Specialist",
  "Program Manager, Environmental Planning",
  "Watershed Coordinator",
  "Environmental Restoration Planners",
  "Central Lab Technician (CLT)",
  "Food Science Technician",
  "Lab Tech (Laboratory Technician)",
  "Laboratory Assistant (Lab Assistant)",
  "Quality Analyst",
  "Technical Services Analyst",
  "Food Science Technicians",
  "Mental Health Therapist",
  "Substance Abuse Counselor",
  "Mental Health and Substance Abuse Social Workers",
  "Certified Diabetes Educator",
  "Clinical Instructor",
  "Community Health Consultant",
  "Health Education Coordinator",
  "Health Education Specialist",
  "Health Educator",
  "Health Promotion Specialist",
  "Public Health Educator",
  "Public Information Officer",
  "Health Educators",
  "Digital Cartographic Technician",
  "Geospatial Extractor, Analysis",
  "IP/Mosaic Technician",
  "Meteorologist Liaison",
  "Remote Sensing Technicians",
  "Behavior Analyst",
  "Behavior Support Specialist (BSS)",
  "Mental Health Counselor",
  "Mental Health Specialist",
  "Mental Health Counselors",
  "Crop Specialist",
  "Independent Crop Consultant",
  "Nutrient Management Specialist",
  "Precision Agriculture Specialist",
  "Precision Farming Coordinator",
  "Soil Fertility Specialist",
  "Precision Agriculture Technicians",
  "Nuclear Technicians",
  "Administrative Volunteer",
  "County Historian",
  "County Records Management Officer (County RMO)",
  "Historian",
  "Historic Interpreter",
  "Historic Sites Registrar",
  "Historical Interpreter",
  "Programs Director",
  "Historians",
  "Agricultural Research Technician",
  "Agricultural Research Technologist",
  "Agricultural Technician",
  "Seed Analyst",
  "Senior Agricultural Assistant",
  "Agricultural Technicians",
  "Community Planner",
  "Development and Housing Director",
  "Development Technician",
  "Planning Aide",
  "Planning Assistant",
  "Planning Intern",
  "Planning Technician",
  "Zoning Technician",
  "City and Regional Planning Aides",
  "Crime Laboratory Analyst",
  "Crime Scene Analyst",
  "Crime Scene Technician (Crime Scene Tech)",
  "CSI (Crime Scene Investigator)",
  "Evidence Technician",
  "Forensic Science Examiner",
  "Forensic Specialist",
  "Latent Fingerprint Examiner",
  "Latent Print Examiner",
  "Forensic Science Technicians",
  "Adjunct Instructor",
  "Adjunct Professor",
  "Architecture Professor",
  "Assistant Professor",
  "Associate Professor",
  "Faculty Member",
  "Instructor",
  "Interior Design Professor",
  "Lecturer",
  "Architecture Teachers, Postsecondary",
  "Appellate Law Clerk",
  "Career Law Clerk",
  "Child Support Officer",
  "Clerk to Justice",
  "Deputy Clerk",
  "Federal Law Clerk",
  "Judicial Assistant",
  "Judicial Clerk",
  "Judicial Law Clerk",
  "Law Clerk",
  "Judicial Law Clerks",
  "Adoption Social Worker",
  "Case Worker",
  "Child Protective Services Social Worker (CPS Social Worker)",
  "Family Protection Specialist",
  "Family Resource Coordinator",
  "Family Service Worker",
  "Foster Care Social Worker",
  "School Social Worker",
  "Youth Services Specialist",
  "Child, Family, and School Social Workers",
  "Human Services Care Specialist",
  "Job Coach",
  "Program Coordinator",
  "Program Specialist",
  "Rehabilitation Counselor",
  "Rehabilitation Specialist",
  "Vocational Case Manager",
  "Vocational Placement Specialist",
  "Vocational Rehabilitation Counselor (VRC)",
  "Rehabilitation Counselors",
  "Community and Social Service Specialists, All Other",
  "Education Instructor",
  "Education Professor",
  "Special Education Professor",
  "Education Teachers, Postsecondary",
  "Environmental Engineering Professor",
  "Environmental Sciences Professor",
  "Environmental Studies Professor",
  "Environmental Science Teachers, Postsecondary",
  "Physical Science Professor",
  "Physics Instructor",
  "Physics Lecturer",
  "Physics Professor",
  "Science Instructor",
  "Physics Teachers, Postsecondary",
  "Clinical Psychology Professor",
  "Psychology Instructor",
  "Psychology Professor",
  "Psychology Teachers, Postsecondary",
  "Social Sciences Teachers, Postsecondary, All Other",
  "Bachelor of Social Work Program Coordinator (BSW Program Coordinator)",
  "Clinical Professor",
  "Social Work Instructor",
  "Social Work Lecturer",
  "Social Work Professor",
  "Social Work Teachers, Postsecondary",
  "Adjunct History Instructor",
  "History Instructor",
  "History Professor",
  "History Teacher",
  "History Teachers, Postsecondary",
  "Humanities Professor",
  "Philosophy Instructor",
  "Philosophy Professor",
  "Religion Professor",
  "Religious Studies Professor",
  "Theology Professor",
  "Philosophy and Religion Teachers, Postsecondary",
  "Foreign Languages Professor",
  "French Professor",
  "German Professor",
  "Modern Languages Professor",
  "Spanish Instructor",
  "Spanish Professor",
  "Foreign Language and Literature Teachers, Postsecondary",
  "Anatomy Instructor",
  "Biological Sciences Professor",
  "Biology Instructor",
  "Biology Professor",
  "Physiology Instructor",
  "Biological Science Teachers, Postsecondary",
  "Classification Instructor",
  "Information Science Professor",
  "Library Instructor",
  "Library Science Professor",
  "Library Technology Instructor",
  "Library Science Teachers, Postsecondary",
  "Legal Support Workers, All Other",
  "Assistant Attorney General",
  "Assistant Counsel",
  "Associate Attorney",
  "Attorney",
  "Attorney at Law",
  "City Attorney",
  "Deputy Attorney General",
  "General Counsel",
  "Lawyer",
  "Partner",
  "Lawyers",
  "Adjunct Mathematics Instructor",
  "Math Teacher",
  "Mathematical Sciences Professor",
  "Mathematics Instructor (Math Instructor)",
  "Mathematics Lecturer",
  "Mathematics Professor",
  "Mathematical Science Teachers, Postsecondary",
  "Social Science Instructor",
  "Sociology Instructor",
  "Sociology Professor",
  "Sociology Teachers, Postsecondary",
  "Advocate",
  "Caseworker",
  "Community Coordinator",
  "Family Support Worker",
  "Home based Assistant",
  "Human Services Program Specialist",
  "Mental Health Technician",
  "Outreach Specialist",
  "Social Services Assistant",
  "Social Work Associate",
  "Social and Human Service Assistants",
  "Alternative Dispute Resolution Coordinator (ADR Coordinator)",
  "Alternative Dispute Resolution Mediator (ADR Mediator)",
  "Arbiter",
  "Arbitrator",
  "Commissioner",
  "Family Mediator",
  "Federal Mediator",
  "Labor Arbitrator",
  "Mediator",
  "Public Employment Mediator",
  "Arbitrators, Mediators, and Conciliators",
  "Adjudicator",
  "Administrative Hearing Officer",
  "Administrative Judge",
  "Administrative Law Judge",
  "Appeals Examiner",
  "Appeals Referee",
  "Claims Adjudicator",
  "Hearing Officer",
  "Judge",
  "Workers' Compensation Hearings Officer",
  "Administrative Law Judges, Adjudicators, and Hearing Officers",
  "Anthropology Instructor",
  "Anthropology Professor",
  "Archaeology Professor",
  "Cultural Anthropology Professor",
  "Anthropology and Archeology Teachers, Postsecondary",
  "Communication Arts Professor",
  "Communication Instructor",
  "Communication Professor",
  "Mass Communications Professor",
  "Speech Instructor",
  "Speech Professor",
  "Communications Teachers, Postsecondary",
  "Criminal Justice Instructor",
  "Criminal Justice Professor",
  "Digital Forensics Instructor",
  "Justice Professor",
  "Criminal Justice and Law Enforcement Teachers, Postsecondary",
  "Atmospheric Sciences Professor",
  "Geology Professor",
  "Meteorology Professor",
  "Oceanography Professor",
  "Research Professor",
  "Atmospheric, Earth, Marine, and Space Sciences Teachers, Postsecondary",
  "Chemical Engineering Professor",
  "Electrical Engineering Professor",
  "Engineering Instructor",
  "Mechanical Engineering Professor",
  "Engineering Teachers, Postsecondary",
  "Geography Instructor",
  "Geography Professor",
  "Human Geography Instructor",
  "Human Geography Professor",
  "Physical Geography Professor",
  "Geography Teachers, Postsecondary",
  "Apprise Counselor",
  "Assistant Director of Nutrition and Wellness Programs",
  "Chief Program Officer",
  "Community Health Outreach Worker",
  "Community Health Program Coordinator",
  "Community Health Program Representative (Community Health Program Rep)",
  "Community Health Promoter",
  "Community Health Worker (CHW)",
  "Community Nutrition Educator",
  "HIV CTS Specialist (Human Immunodeficiency Virus Counseling and Testing Services Specialist)",
  "Community Health Workers",
  "American Studies Professor",
  "Black Studies Professor",
  "Ethnic Studies Professor",
  "Women's Studies Professor",
  "Area, Ethnic, and Cultural Studies Teachers, Postsecondary",
  "Economics Instructor",
  "Economics Lecturer",
  "Economics Professor",
  "Finance Professor",
  "Economics Teachers, Postsecondary",
  "Adult Ministries Director",
  "Children's Ministries Director",
  "Christian Education Director",
  "Religious Education Coordinator",
  "Religious Education Director",
  "Senior Adults Director",
  "Student Ministries Director",
  "Women's Ministry Director",
  "Youth Director",
  "Youth Ministry Director",
  "Directors, Religious Activities and Education",
  "Occupational Therapy Professor",
  "Pharmacology Professor",
  "Physical Therapy Professor",
  "Public Health Professor",
  "Health Specialties Teachers, Postsecondary",
  "Certified Paralegal",
  "Corporate Law Assistant",
  "Law Associate",
  "Legal Analyst",
  "Legal Assistant",
  "Litigation Paralegal",
  "Paralegal",
  "Paralegal Assistant",
  "Paralegal Specialist",
  "Real Estate Paralegal",
  "Paralegals and Legal Assistants",
  "Accounting Instructor",
  "Accounting Professor",
  "Business Administration Professor",
  "Business Instructor",
  "Business Professor",
  "Management Professor",
  "Marketing Professor",
  "Business Teachers, Postsecondary",
  "Circuit Court Judge",
  "Circuit Judge",
  "County Judge",
  "Court of Appeals Judge",
  "District Court Judge",
  "Justice of the Peace",
  "Magistrate",
  "Municipal Court Judge",
  "Superior Court Judge",
  "Judges, Magistrate Judges, and Magistrates",
  "Extension Professor",
  "Forest Technology Professor",
  "Forestry Extension Specialist",
  "Forestry Professor",
  "Forestry and Conservation Science Teachers, Postsecondary",
  "Creative Writing Professor",
  "English Instructor",
  "English Professor",
  "Literature Professor",
  "English Language and Literature Teachers, Postsecondary",
  "Biochemistry Professor",
  "Chemistry Faculty Member",
  "Chemistry Instructor",
  "Chemistry Professor",
  "Organic Chemistry Professor",
  "Chemistry Teachers, Postsecondary",
  "Academic Advisor",
  "Academic Counselor",
  "Admissions Counselor",
  "Career Counselor",
  "College Counselor",
  "Guidance Counselor",
  "School Adjustment Counselor",
  "School Counselor",
  "Student Development Advisor",
  "Student Services Coordinator",
  "Educational, Guidance, School, and Vocational Counselors",
  "Certified Shorthand Reporter (CSR)",
  "Court Recording Monitor",
  "Court Reporter",
  "Court Stenographer",
  "Deposition Reporter",
  "Digital Court Reporter",
  "Freelance Court Reporter",
  "Official Court Reporter",
  "Realtime Court Reporter",
  "Stenographer",
  "Court Reporters",
  "Agriculture Instructor",
  "Agriculture Professor",
  "Agronomy Professor",
  "Animal Science Professor",
  "Horticulture Instructor",
  "Horticulture Professor",
  "Plant Science Professor",
  "Agricultural Sciences Teachers, Postsecondary",
  "Government Professor",
  "Political Science Instructor",
  "Political Science Professor",
  "Public Administration Professor",
  "Political Science Teachers, Postsecondary",
  "Biological Science Aide",
  "County Ranger",
  "Forest Technician",
  "Forestry Aide",
  "Forestry Technician",
  "Resource Specialist",
  "Resource Technician",
  "Timber Appraiser",
  "Forest and Conservation Technicians",
  "Abstracter",
  "Abstractor",
  "Commercial Title Examiner",
  "Searcher",
  "Title Abstractor",
  "Title Agent",
  "Title Department Manager",
  "Title Examiner",
  "Title Officer",
  "Title Searcher",
  "Title Examiners, Abstractors, and Searchers",
  "Religious Workers, All Other",
  "Art History Professor",
  "Art Instructor",
  "Art Professor",
  "Music Instructor",
  "Music Professor",
  "Theatre Professor",
  "Art, Drama, and Music Teachers, Postsecondary",
  "Catholic Priest",
  "Children's Minister",
  "Confessor",
  "Congregational Care Pastor",
  "Minister",
  "Missionary Coordinator",
  "Pastor",
  "Priest",
  "Rabbi",
  "Rector",
  "Clergy",
  "Clinical Nursing Instructor",
  "Nurse Educator",
  "Nursing Instructor",
  "Nursing Professor",
  "Nursing Instructors and Teachers, Postsecondary",
  "Analyst Microbiology Lab",
  "Analytical Lab Analyst",
  "Ethanol Quality Leader",
  "Lab Tech",
  "Lab Technician",
  "Laboratory Analyst",
  "Micro Lab Analyst",
  "Quality Assurance Technician (QA Technician)",
  "Quality Control Analyst (QC Analyst)",
  "Quality Control Analysts",
  "Computer Information Systems Instructor (CIS Instructor)",
  "Computer Science Instructor",
  "Computer Science Professor",
  "Information Technology Instructor (IT Instructor)",
  "Computer Science Teachers, Postsecondary",
  "Education, Training, and Library Workers, All Other",
  "Art Director",
  "Art Supervisor",
  "Creative Director",
  "Creative Guru",
  "Director of Creative Services, Consumer Products",
  "Group Art Supervisor",
  "Senior Art Director",
  "Art Directors",
  "Decorating Consultant",
  "Interior Design Consultant",
  "Interior Design Coordinator",
  "Interior Design Director",
  "Interior Design Principal",
  "Interior Design Project Manager",
  "Interior Designer",
  "Showroom Executive Director",
  "Interior Designers",
  "Designers, All Other",
  "Audio Engineer",
  "Board Operator",
  "Broadcast Engineer",
  "Broadcast Maintenance Engineer",
  "Broadcast Operations Engineer",
  "Broadcast Technician",
  "Control Operator",
  "Maintenance Engineer",
  "Production Assistant",
  "Broadcast Technicians",
  "English Teacher",
  "Language Arts Teacher",
  "Mathematics Teacher (Math Teacher)",
  "Middle School Teacher",
  "Music Teacher",
  "Physical Education Teacher (PE Teacher)",
  "Reading Teacher",
  "Science Teacher",
  "Social Studies Teacher",
  "Teacher",
  "Middle School Teachers, Except Special and Career/Technical Education",
  "Business Education Teacher",
  "Business Teacher",
  "Career and Technology Education Teacher (CTE Teacher)",
  "Computer Teacher",
  "Family and Consumer Sciences Teacher (FACS Teacher)",
  "Industrial Arts Teacher",
  "Industrial Technology Teacher",
  "Technology Education Teacher (Tech Ed Teacher)",
  "Technology Teacher",
  "Career/Technical Education Teachers, Middle School",
  "Music Directors and Composers",
  "Choir Member",
  "Chorister",
  "Gospel Singer",
  "Musician",
  "Opera Singer",
  "Singer",
  "Singing Telegram Performer",
  "Songwriter",
  "Vocal Performer",
  "Vocalist",
  "Singers",
  "Writers and Authors",
  "Early Childhood Special Education Teacher (ECSE Teacher)",
  "Early Intervention Teacher",
  "Exceptional Student Education Teacher (ESE Teacher)",
  "Handicapped Teacher",
  "Preschool Special Education Teacher",
  "Resource Teacher",
  "Severe/Profound Mental Handicaps Special Education Teacher",
  "Special Education Resource Teacher",
  "Special Education Teacher",
  "Special Education Teachers, Preschool",
  "Artist",
  "Creative Manager",
  "Graphic Artist",
  "Graphic Designer",
  "Online Producer",
  "Production Artist",
  "Publications Designer",
  "Graphic Designers",
  "Lighting Technicians",
  "Announcer",
  "Bingo Caller",
  "Disc Jockey (DJ)",
  "Emcee",
  "Entertainer",
  "Event Host",
  "Master of Ceremonies (MC)",
  "Mobile Disc Jockey (Mobile DJ)",
  "Public Address Announcer (PA Announcer)",
  "Track Announcer",
  "Public Address System and Other Announcers",
  "Art Teacher",
  "Classroom Teacher",
  "Educator",
  "Elementary Education Teacher",
  "Elementary School Teacher",
  "Elementary Teacher",
  "Fifth Grade Teacher",
  "First Grade Teacher",
  "Second Grade Teacher",
  "Elementary School Teachers, Except Special Education",
  "Apparel Fashion Designer",
  "Clothing Designer",
  "Costume Designer",
  "Dance Costume Designer",
  "Fashion Designer",
  "Historic Clothing and Costume Maker",
  "Latex Fashions Designer",
  "Product Developer",
  "Fashion Designers",
  "Special Education Teachers, All Other",
  "Producers and Directors",
  "Advertising Associate",
  "Advertising Copy Writer",
  "Advertising Writer",
  "Copy Writer",
  "Copywriter",
  "Freelance Copywriter",
  "Production Director",
  "Web Content Writer",
  "Copy Writers",
  "Animation Director",
  "Artistic Director",
  "Commercial Director",
  "Production Stage Manager",
  "Stage Director",
  "Stage Manager",
  "Theater Director",
  "TV Director (Television Director)",
  "Directors- Stage, Motion Pictures, Television, and Radio",
  "Anchor",
  "Content Director",
  "News Anchor",
  "News Director",
  "Radio News Anchor",
  "Radio Talk Show Host",
  "Sports Director",
  "Television News Anchor (TV News Anchor)",
  "Weekend Anchor",
  "News analysts, reporters, and journalists",
  "Child Development Teacher",
  "Early Childhood Teacher",
  "Group Teacher",
  "Infant Teacher",
  "Montessori Preschool Teacher",
  "Nursery Teacher",
  "Pre-Kindergarten Teacher (Pre-K Teacher)",
  "Preschool Teacher",
  "Toddler Teacher",
  "Preschool Teachers, Except Special Education",
  "4-H Youth Development Specialist",
  "Agricultural Extension Educator",
  "Extension Agent",
  "Extension Educator",
  "Family and Consumer Sciences Extension Agent",
  "Family Development Extension Specialist",
  "Family Resource Management Extension Specialist",
  "Marine Extension Agent",
  "Natural Resources Extension Educator",
  "Farm and Home Management Advisors",
  "Basketball Referee",
  "Commissioner of Officials",
  "Director of Officiating",
  "Diving Judge",
  "Horse Show Judge",
  "Major League Baseball Umpire",
  "Referee",
  "Softball Umpire",
  "Sports Official",
  "Supervisor of Officials",
  "Umpires, Referees, and Other Sports Officials",
  "Baseball Pitcher",
  "Baseball Player",
  "Basketball Player",
  "Golf Professional",
  "Hockey Player",
  "Major League Baseball Player",
  "Minor League Baseball Player",
  "Professional Athlete",
  "Professional Golf Tournament Player",
  "Race Car Driver",
  "Athletes and Sports Competitors",
  "Early Childhood Special Educator (EC Special Educator)",
  "Emotional Disabilities Teacher",
  "Hearing Impaired Itinerant Teacher (HI Itinerant Teacher)",
  "Learning Support Teacher",
  "Resource Program Teacher",
  "Severe Emotional Disorders Elementary Teacher (SED Elementary Teacher)",
  "Special Education Inclusion Teacher",
  "Special Education Teachers, Kindergarten and Elementary School",
  "Circulation Clerk",
  "Library Aide",
  "Library Assistant",
  "Library Associate",
  "Library Clerk",
  "Library Media Technician",
  "Library Specialist",
  "Library Technical Assistant (LTA)",
  "Library Technician",
  "Page Technician",
  "Library Technicians",
  "Children's Choir Director",
  "Choir Director",
  "Conductor",
  "Handbell Choir Director",
  "Liturgical Music Director",
  "Music Director",
  "Music Minister",
  "Music Ministries Director",
  "Orchestra Director",
  "Music Directors",
  "Documentation Designer",
  "Documentation Specialist",
  "Engineering Writer",
  "Expert Medical Writer",
  "Information Developer",
  "Narrative Writer",
  "Requirements Analyst",
  "Senior Technical Writer",
  "Technical Communicator",
  "Technical Writer",
  "Technical Writers",
  "Audio Visual Coordinator",
  "Audio Visual Specialist",
  "Audio Visual Technician",
  "Audio-Visual Aids Technician",
  "Instructional Technology Specialist",
  "Library Media Specialist",
  "Media Specialist",
  "Media Technician",
  "Multimedia Services Coordinator",
  "Multimedia Specialist",
  "Audio-Visual and Multimedia Collections Specialists",
  "Dance Instructor",
  "Dance Teacher",
  "Driving Instructor",
  "Flight Instructor",
  "Martial Arts Instructor",
  "Piano Teacher",
  "Self-Enrichment Education Teachers",
  "Court Interpreter",
  "Deaf Interpreter",
  "Educational Interpreter",
  "Interpreter",
  "Medical Interpreter",
  "Paraprofessional Interpreter",
  "Sign Language Interpreter",
  "Spanish Interpreter",
  "Technical Translator",
  "Translator",
  "Interpreters and Translators",
  "Business Law Professor",
  "Clinical Law Professor",
  "Law Instructor",
  "Law Professor",
  "Legal Writing Professor",
  "Law Teachers, Postsecondary",
  "General Assignment Reporter",
  "News Reporter",
  "Reporter",
  "Sports Writer",
  "Staff Writer",
  "Television News Reporter",
  "Television Reporter (TV Reporter)",
  "Reporters and Correspondents",
  "Casting Agent",
  "Casting Assistant",
  "Casting Associate",
  "Casting Coordinator",
  "Casting Director",
  "Extras Casting Director",
  "Model Booker",
  "Talent Producer",
  "Talent Scout",
  "Talent Directors",
  "Collections Curator",
  "Collections Manager",
  "Education Curator",
  "Exhibitions Curator",
  "Exhibits Curator",
  "Gallery Director",
  "Museum Curator",
  "Photography Curator",
  "Vertebrate Zoology Curator",
  "Curators",
  "Ballet Director",
  "Choreographer",
  "Dance Director",
  "Dance Maker",
  "Musical Choreographer",
  "Choreographers",
  "Ballerina",
  "Ballet Company Member",
  "Ballet Dancer",
  "Ballet Soloist",
  "Belly Dancer",
  "Company Dancer",
  "Dancer",
  "Latin Dancer",
  "Performing Artist",
  "Soloist Dancer",
  "Dancers",
  "Design Chief",
  "Display Coordinator",
  "Exhibit Designer",
  "Exhibit Preparator",
  "Production Designer",
  "Scenic Designer",
  "Set Decorator",
  "Set Designer",
  "Show Design Supervisor",
  "Set and Exhibit Designers",
  "Audio Operator",
  "Master Control Operator",
  "Mixer",
  "Recording Engineer",
  "Sound Engineer",
  "Sound Technician",
  "Studio Engineer",
  "Sound Engineering Technicians",
  "Automotive Instructor",
  "Automotive Technology Instructor",
  "Cosmetology Instructor",
  "HVAC-R Instructor (Heating, Ventilation, Air Conditioning, And Refrigeration Instructor)",
  "Medical Assistant Instructor",
  "Welding Instructor",
  "Vocational Education Teachers, Postsecondary",
  "Advertising Photographer",
  "Commercial Photographer",
  "Newspaper Photographer",
  "Owner/Photographer",
  "Photo Editor",
  "Photographer",
  "Photojournalist",
  "Portrait Photographer",
  "Sports Photographer",
  "Studio Owner",
  "Photographers",
  "Corporate Communications Specialist",
  "Media Relations Specialist",
  "Public Affairs Specialist",
  "Public Information Specialist",
  "Public Relations Coordinator",
  "Public Relations Specialist (PR Specialist)",
  "Public Relations Specialists",
  "High School Science Teacher",
  "Secondary Teacher",
  "Spanish Teacher",
  "Secondary School Teachers, Except Special and Career/Technical Education",
  "Art Preparator",
  "Conservation Technician",
  "Conservator",
  "Exhibit Technician",
  "Museum Registrar",
  "Museum Technician",
  "Objects Conservator",
  "Paintings Conservator",
  "Paper Conservator",
  "Preparator",
  "Museum Technicians and Conservators",
  "Decorator",
  "Display Associate",
  "Display Decorator",
  "Display Specialist",
  "In-Store Marketing Associate",
  "Visual Manager",
  "Visual Merchandiser (VM)",
  "Visual Merchandising Manager",
  "Visual Merchandising Specialist",
  "Merchandise Displayers and Window Trimmers",
  "Artists and Related Workers, All Other",
  "Teachers and Instructors, All Other",
  "Adapted Physical Education or Adapted Physical Activity Specialist",
  "Adapted Physical Education Specialist",
  "Adapted Physical Education Specialist-Workshop Leader",
  "Adapted Physical Education Teacher",
  "Certified Adapted Physical Educator",
  "Physical Education Teacher",
  "Adapted Physical Education Specialists",
  "Animation Producer",
  "Associate Producer",
  "Executive Producer",
  "News Producer",
  "Newscast Producer",
  "Producer",
  "Promotions Producer",
  "Radio Producer",
  "Television News Producer",
  "Television Producer (TV Producer)",
  "Producers",
  "Acquisitions Editor",
  "Business Editor",
  "Editor",
  "Features Editor",
  "Legal Editor",
  "News Editor",
  "Newspaper Copy Editor",
  "Science Editor",
  "Sports Editor",
  "Web Editor",
  "Editors",
  "Postsecondary Teachers, All Other",
  "Health and Human Performance Professor",
  "Health and Physical Education Professor (HPE Professor)",
  "Health, Physical Education, and Recreation Professor (HPER Professor)",
  "Kinesiology Professor",
  "Physical Education Professor (PE Professor)",
  "Recreation and Fitness Studies Teachers, Postsecondary",
  "Instructional Designer",
  "Instructional Technologist",
  "IT Senior Analyst (Instructional Technology Senior Analyst)",
  "Lead Performance Support Analyst",
  "Learning Development Specialist",
  "Senior Instructional Designer",
  "Team Lead, Teacher Support and Student Intervention",
  "Instructional Designers and Technologists",
  "Audio Technician",
  "Audio Visual Specialist (AV Specialist)",
  "Audio Visual Technician (AV Technician)",
  "Master Control Operator (MCO)",
  "Multimedia Educational Specialist",
  "Stagehand",
  "Video Technician",
  "Audio and Video Equipment Technicians",
  "Graduate Fellow",
  "Graduate Student",
  "Graduate Student Instructor (GSI)",
  "Graduate Teaching Assistant (GTA)",
  "Research Assistant (RA)",
  "Teaching Assistant (TA)",
  "Teaching Fellow",
  "Graduate Teaching Assistants",
  "Adult Basic Education Instructor (ABE Instructor)",
  "Adult Basic Education Teacher (ABE Teacher)",
  "Adult Education Instructor",
  "Adult Education Teacher",
  "ESL Instructor (English as a Second Language Instructor)",
  "ESL Teacher (English as a Second Language Teacher)",
  "ESOL Teacher (English for Speakers of Other Languages Teacher)",
  "GED Instructor (General Educational Development Instructor)",
  "GED Teacher (General Educational Development Teacher)",
  "Adult Basic and Secondary Education and Literacy Teachers and Instructors",
  "Academic Guidance Specialist",
  "Accounting/Finance Tutor",
  "Adult Basic Education Instructor",
  "Center Director",
  "Director of Academic Support",
  "Educational Advisor",
  "Learning Services Coordinator",
  "Professional Tutor",
  "Reading Efficiency Course Director",
  "Tutorial Laboratory Supervisor (Tutorial Lab Supervisor)",
  "Tutors",
  "Archival Records Clerk",
  "Archivist",
  "Film Archivist",
  "Museum Archivist",
  "Reference Archivist",
  "State Archivist",
  "University Archivist",
  "Archivists",
  "Bilingual Kindergarten Teacher",
  "Kinder Teacher",
  "Kindergarten Classroom Teacher",
  "Kindergarten Teacher",
  "Title One Kindergarten Teacher",
  "Transitional Kindergarten Teacher",
  "Kindergarten Teachers, Except Special Education",
  "Catalog Librarian",
  "Children's Librarian",
  "Librarian",
  "Library Director",
  "Public Services Librarian",
  "Reference Librarian",
  "School Librarian",
  "Technical Services Librarian",
  "Youth Services Librarian",
  "Librarians",
  "Agricultural Education Teacher",
  "Allied Health Teacher",
  "Cosmetology Teacher",
  "Drafting Instructor",
  "Technology Education Teacher",
  "Vocational Teacher",
  "Career/Technical Education Teachers, Secondary School",
  "Exceptional Children Teacher (EC Teacher)",
  "Inclusion Teacher",
  "Intervention Specialist",
  "Middle School Special Education Teacher",
  "Self-Contained Special Education Teacher",
  "Special Education Teachers, Middle School",
  "Media and Communication Workers, All Other",
  "DJ (Disc Jockey)",
  "Host",
  "Morning Show Host",
  "Radio Announcer",
  "Radio and Television Announcers",
  "Actor",
  "Actress",
  "Comedian",
  "Comic",
  "Community Theater Actor",
  "Ensemble Member",
  "Narrator",
  "Performer",
  "Tour Actor",
  "Voice-Over Artist",
  "Actors",
  "Airline Operations Agent",
  "Communications Technician",
  "Flight Communications Specialist",
  "Flight Follower",
  "Police Communications Operator",
  "Radio Electronics Officer (REO)",
  "Radio Electronics Technician (RET)",
  "Radio Operator",
  "Telecommunication Operator",
  "Radio Operators",
  "Composer",
  "Film Composer",
  "Jingle Writer",
  "Music Arranger",
  "Music Composer",
  "Music Producer",
  "Music Composers and Arrangers",
  "Career and Transition Teacher",
  "High School Special Education Teacher",
  "Interrelated Special Education Teacher",
  "Learning Disabilities Special Education Teacher (LD Special Education Teacher)",
  "Special Day Class Teacher (SDC Teacher)",
  "Special Education Teachers, Secondary School",
  "Child Development Instructor",
  "Dietetics Professor",
  "Family and Consumer Sciences Professor (FCS Professor)",
  "Food and Nutrition Professor",
  "Human Development Professor",
  "Home Economics Teachers, Postsecondary",
  "3D Animator (Three-Dimensional Animator)",
  "3D Artist (Three-Dimensional Artist)",
  "Animator",
  "Digital Artist",
  "Illustrator",
  "Motion Graphics Artist",
  "Multimedia Producer",
  "Multimedia Artists and Animators",
  "Industrial Designer",
  "Mold Designer",
  "Product Development Engineer",
  "Sign Designer",
  "Commercial and Industrial Designers",
  "Automotive Artist",
  "Blacksmith",
  "Fine Artist",
  "Ice Carver",
  "Muralist",
  "Painter",
  "Portrait Artist",
  "Sculptor",
  "Fine Artists, Including Painters, Sculptors, and Illustrators",
  "Floral Artist",
  "Floral Clerk",
  "Floral Department Specialist",
  "Floral Designer",
  "Floral Manager",
  "Florist",
  "Flower Shop Laborer/Designer",
  "Wedding Decorator",
  "Floral Designers",
  "Author",
  "Book Author",
  "Children's Book Author",
  "Creative Writer",
  "Fiction and Nonfiction Author",
  "Freelance Writer",
  "Lyricist",
  "Novelist",
  "Poet",
  "Poets, Lyricists and Creative Writers",
  "Ceramic Artist",
  "Fiber Artist",
  "Fine Craft Artist",
  "Furniture Maker",
  "Glass Artist",
  "Glass Blower",
  "Goldsmith",
  "Hand-Weaver",
  "Craft Artists",
  "Broadcast Director",
  "News Technical Director",
  "Newscast Director",
  "Studio Director",
  "Technical Director",
  "Technical Directors/Managers",
  "Educational Assistant",
  "Instructional Assistant",
  "Paraeducator",
  "Paraprofessional",
  "Special Education Aide",
  "Special Education Paraprofessional",
  "Special Education Teacher Assistant",
  "Teacher Aide",
  "Teacher Assistant",
  "Teaching Assistant",
  "Teacher Assistants",
  "Curriculum and Assessment Director",
  "Curriculum and Instruction Director",
  "Curriculum Coordinator",
  "Curriculum Director",
  "Curriculum Specialist",
  "Instructional Coach",
  "Instructional Systems Specialist",
  "Program Administrator",
  "School Standards Coach",
  "Instructional Coordinators",
  "Program Director",
  "Programming Director",
  "Station Manager",
  "Television Program Director (TV Program Director)",
  "Program Directors",
  "Camera Operator",
  "Cameraman",
  "News Videographer",
  "Production Technician",
  "Studio Camera Operator",
  "Television News Photographer",
  "Truck Operator",
  "Videographer",
  "Camera Operators, Television, Video, and Motion Picture",
  "Baseball Coach",
  "Basketball Coach",
  "Coach",
  "Cross Country Coach",
  "Football Coach",
  "Gymnastics Coach",
  "Soccer Coach",
  "Softball Coach",
  "Track and Field Coach",
  "Volleyball Coach",
  "Coaches and Scouts",
  "Musicians and Singers",
  "Assistant Film Editor",
  "Film Editor",
  "News Video Editor",
  "News Videotape Editor",
  "Non-Linear Editor",
  "Online Editor",
  "Video Editor",
  "Film and Video Editors",
  "Cellist",
  "English Horn Player",
  "Horn Player",
  "Oboist",
  "Orchestra Musician",
  "Percussionist",
  "Timpanist",
  "Violinist",
  "Violist",
  "Musicians, Instrumental",
  "Associate Doctor",
  "Chiropractic Care",
  "Chiropractic Doctor (DC)",
  "Chiropractic Neurologist",
  "Chiropractic Physician",
  "Chiropractor",
  "Chiropractor, Sole Practitioner",
  "Doctor of Chiropractic",
  "Doctorate of Chiropractic",
  "Physician",
  "Chiropractors",
  "Entertainers and Performers, Sports and Related Workers, All Other",
  "Associate Dentist",
  "Dental Surgery Doctor (DDS)",
  "Dentist",
  "Dentist/Owner",
  "Doctor of Dental Medicine (DMD)",
  "Doctor of Dental Surgery (DDS)",
  "Family Dentist",
  "General Dentist",
  "General Dentist/Owner",
  "Pediatric Dentist",
  "Dentists, General",
  "Doctor of Dental Science, Prosthodontist",
  "Maxillofacial Prosthodontist",
  "Prosthetic Dentist",
  "Prosthodontist",
  "Prosthodontist, Assistant Clinical Professor",
  "Prosthodontist/Educator",
  "Prosthodontist/Owner",
  "Prosthodontist/Restorative/Reconstructive Dentist",
  "Removable Prosthodontist",
  "Prosthodontists",
  "Dental Service Chief",
  "Oral and Maxillofacial Surgeon (OMS)",
  "Oral and Maxillofacial Surgeon-Practice Owner",
  "Oral and Maxillofacial Surgery",
  "Oral and Maxillofacial Surgery Resident",
  "Oral Surgeon",
  "Resident Physician",
  "Resident Surgeon",
  "Surgeon",
  "Oral and Maxillofacial Surgeons",
  "Board Certified Orthodontist",
  "Orthodontist",
  "Orthodontist, Small Business Owner",
  "Orthodontist, Vice President",
  "Specialist in Orthodontics and Dentofacial Orthopedics",
  "Orthodontists",
  "Dentists, All Other Specialists",
  "Chief, Optometry Service",
  "Doctor of Optometry (OD)",
  "Eye Clinic Manager",
  "Optometrist",
  "Optometrist, Owner",
  "Optometrist, President/Practice Owner",
  "Optometrist/Practice Owner",
  "Optometrists",
  "Anesthesia Associate",
  "Anesthesia Director",
  "Anesthesia Resident",
  "Anesthesiologist",
  "Attending Anesthesiologist",
  "Medical Doctor (MD)",
  "Obstetrical Anesthesiologist",
  "Physician Anesthesiologist",
  "Staff Anesthesiologist",
  "Staff Anesthetist",
  "Anesthesiologists",
  "Clinical Pharmacist",
  "Hospital Pharmacist",
  "Outpatient Pharmacy Manager",
  "Pharmacist",
  "Pharmacist in Charge (PIC)",
  "Pharmacist in Charge, Owner (PIC, Owner)",
  "Pharmacy Informaticist",
  "Registered Pharmacist",
  "Staff Pharmacist",
  "Staff Pharmacist, Hospital",
  "Pharmacists",
  "Board Certified Family Physician",
  "Family Medicine Physician",
  "Family Physician",
  "Family Practice Medical Doctor (FP MD)",
  "Family Practice Physician",
  "Family Practitioner",
  "Medical Staff Physician",
  "Primary Care Physician",
  "Family and General Practitioners",
  "Attending Physician",
  "Clinic MD Associate (Clinic Medical Doctor Associate)",
  "Gastroenterologist",
  "General Internist",
  "Internal Medicine Doctor",
  "Internal Medicine Physician (IM Physician)",
  "Internist",
  "Internists, General",
  "Adolescent Medicine Specialist",
  "Chief, General Pediatric Clinic",
  "General Pediatrician",
  "Group Practice Pediatrician",
  "Pediatrician",
  "Pediatrician, Partner in Private Medical Practice",
  "Primary Care Pediatrician",
  "Pediatricians, General",
  "Gynecologist",
  "OB/GYN (Obstetrician / Gynecologist)",
  "OB/GYN Physician (Obstetrics and Gynecology Physician)",
  "Obstetrician/Gynecologist (OB/GYN)",
  "Obstetrics Gynecology MD (OB/GYN MD)",
  "Obstetrics Gynecology Physician (OB/GYN Physician)",
  "Physician Gynecologist",
  "Obstetricians and Gynecologists",
  "Clinical Dietician",
  "Clinical Dietitian",
  "Correctional Food Service Supervisor",
  "Dietary Manager",
  "Dietitian",
  "Outpatient Dietitian",
  "Pediatric Clinical Dietician",
  "Registered Dietician",
  "Registered Dietitian",
  "Dietitians and Nutritionists",
  "Allergist",
  "Allergist/Immunologist",
  "Allergist/Immunologist, Physician",
  "Allergist/MD",
  "Allergist/Pediatric Pulmonologist",
  "Allergy and Immunology Specialist",
  "Allergy Physician",
  "MD Pediatric Allergist",
  "Physician, Owner of Independent Medical Practice",
  "Allergists and Immunologists",
  "Adult Psychiatrist",
  "Child & Adolescent Psychiatrist",
  "Child Psychiatrist",
  "Consulting Psychiatrist",
  "Outpatient Psychiatrist",
  "Prison Psychiatrist",
  "Psychiatrist",
  "Staff Psychiatrist",
  "Psychiatrists",
  "Adult and Pediatric Neurologist",
  "Adult Neurologist",
  "Director of Adult Epilepsy",
  "Director, Inpatient Headache Program",
  "General Neurologist",
  "Medical Director of MS Treatment and Research Center",
  "Neurologist",
  "Neurologist and Director of Medical Research",
  "Pediatric Neurologist",
  "Neurologists",
  "Physicians and Surgeons, All Other",
  "Cardiovascular Surgeon",
  "General Surgeon",
  "Hand Surgeon",
  "Orthopaedic Surgeon",
  "Orthopedic Surgeon",
  "Plastic Surgeon",
  "Thoracic Surgeon",
  "Vascular Surgeon",
  "Surgeons",
  "Attending Physiatrist, Residency Program Director",
  "Doctor of Medicine/Physical Medicine and Rehabilitation PM&R Specialist",
  "Medical Director",
  "Medical Director Acute Rehabilitation Unit Physiatrist",
  "Pain Management Physician",
  "Pediatric Physiatrist",
  "Physiatrist",
  "Physical Medicine and Rehabilitation Physician, Medical Director",
  "Rehabilitation Physician",
  "Physical Medicine and Rehabilitation Physicians",
  "Academic Hospitalist",
  "Associate Chief, Section of Hospital Medicine",
  "Chief of Hospital Medicine",
  "Chief of Internal Medicine",
  "Hospital Medicine Director",
  "Hospitalist",
  "Hospitalist Medical Director",
  "Hospitalist Program Director",
  "Inpatient Services Director",
  "Hospitalists",
  "Occupational Medicine Physician",
  "Occupational Physician",
  "Preventive Medicine Physician",
  "Primary Clinician",
  "Public Health Director",
  "Public Health Officer",
  "Public Health Physician",
  "Regional Medical Director",
  "Residency Director",
  "Preventive Medicine Physicians",
  "Doctor",
  "Doctor of Podiatric Medicine (DPM)",
  "Doctor Podiatric Medicine (DPM)",
  "Foot and Ankle Surgeon",
  "Podiatric Physician",
  "Podiatric Surgeon",
  "Podiatrist",
  "Practitioner",
  "Podiatrists",
  "Bariatric Weight Loss Clinic Manager and Counselor",
  "Cardiac Rehabilitation Program Director",
  "Clinical Coordinator, Heart Failure Cardiac Rehabilitation",
  "Clinical Exercise Physiologist",
  "Clinical Exercise Specialist",
  "Coordinator Cardiopulmonary Services",
  "Director of Cardiac Rehabilitation",
  "Director of Rehabilitation and Wellness",
  "Exercise Physiologist",
  "Exercise Physiologist, Lifestyle and Weight Management Consultant",
  "Exercise Physiologists",
  "Associate Veterinarian",
  "Doctor of Veterinary Medicine (DVM)",
  "Emergency Veterinarian",
  "Equine Vet (Equine Veterinarian)",
  "Mixed Animal Veterinarian",
  "Small Animal Veterinarian",
  "Staff Veterinarian",
  "Veterinarian (VET)",
  "Veterinary Medicine Doctor (DVM)",
  "Veterinary Surgeon",
  "Veterinarians",
  "Activities Director",
  "Activity Assistant",
  "Activity Coordinator",
  "Activity Director",
  "Certified Therapeutic Recreation Specialist (CTRS)",
  "General Activities Therapist",
  "Recreation Therapist",
  "Recreational Therapist",
  "Rehabilitation Therapist",
  "Therapeutic Recreation Specialist",
  "Recreational Therapists",
  "Dermatologist",
  "Dermatologist and Dermatopathologist",
  "Dermatologist, Clinical Investigator, Dermatopathologist",
  "Dermatologist, Managing Partner",
  "MD Physician-Dermatologist",
  "Medical Doctor MD",
  "MOHS Surgeon/General Dermatologist",
  "Practicing Dermatologist",
  "Senior Physician",
  "Dermatologists",
  "Comprehensive Ophthalmologist",
  "Director, Emergency Ophthalmology Services",
  "General Ophthalmologist",
  "Ophthalmologist",
  "Ophthalmologist-Retina Specialist",
  "Retina Subspecialist",
  "Retinal Surgeon",
  "Vitreo-Retinal Surgeon",
  "Ophthalmologists",
  "Anatomic Pathologist",
  "Associate Pathologist",
  "Attending Pathologist",
  "Cytopathologist",
  "Dermatopathologist",
  "Oral Pathologist",
  "Pathologist",
  "Pathology Laboratory Director",
  "Pathologists",
  "Anesthesia Assistant",
  "Anesthesia Tech",
  "Anesthesia Technician",
  "Anesthesiologist Assistant",
  "Anesthesiologist Assistant-Certified",
  "Anesthesiologists' Assistant",
  "Cardiothoracic Anesthesia Technician",
  "Certified Anesthesia Technician and Technologist",
  "Oral Surgery Assistant",
  "Oral Surgery Assistant-Clinical Coordinator",
  "Anesthesiologist Assistants",
  "Director of Nuclear Medicine",
  "Medical Director, Nuclear Medicine Department",
  "Medical Doctor, Nuclear Medicine",
  "Nuclear Medicine Medical Director",
  "Nuclear Medicine Physician",
  "Nuclear Medicine Specialist",
  "Nuclear Medicine Physicians",
  "Assistive Technology Trainer",
  "Early Intervention Occupational Therapist",
  "Industrial Rehabilitation Consultant",
  "Occupational Therapist (OT)",
  "Pediatric Occupational Therapist",
  "Registered Occupational Therapist",
  "Rehabilitation Supervisor",
  "Staff Occupational Therapist",
  "Staff Therapist",
  "Occupational Therapists",
  "Athletic Team Physician",
  "Director of Athletic Medicine, Head Team Physician",
  "Director of Sports Medicine",
  "Family Medicine/Sports Medicine Specialist/Team Physician",
  "Head Orthopedic Team Physician",
  "Nonsurgical Primary Care Sports Medicine Physician",
  "Sports Medicine Physician",
  "Team Physician",
  "Sports Medicine Physicians",
  "Attending Radiologist",
  "Diagnostic Radiologist",
  "Interventional Neuroradiologist",
  "Interventional Radiologist",
  "Neuroradiologist",
  "Radiologist",
  "Radiologist, Chief of Breast Imaging",
  "Radiology Resident",
  "Staff Radiologist",
  "Radiologists",
  "Certified Physician Assistant (PA-C)",
  "Clinical Physician Assistant",
  "Family Practice Physician Assistant",
  "General Surgery Physician Assistant",
  "Midlevel Provider",
  "Orthopaedic Physician Assistant",
  "Physician Assistant Certified (PAC)",
  "Physician Extender",
  "Physician's Assistant",
  "Surgical Physician Assistant",
  "Physician Assistants",
  "Cardiopulmonary Rehabilitation Respiratory Therapist",
  "Cardiopulmonary Technician and EEG Tech (Cardiopulmonary Technician and Electroencephalogram Technician)",
  "Certified Respiratory Therapist (CRT)",
  "Clinical Coordinator of Respiratory Therapy",
  "Registered Respiratory Therapist (RRT)",
  "Respiratory Care Practitioner (RCP)",
  "Respiratory Therapist (RT)",
  "Respiratory Therapy Director",
  "Staff Respiratory Therapist",
  "Respiratory Therapists",
  "Board Certified Music Therapist",
  "Director of Music Therapy",
  "Music Therapist",
  "Music Therapist, Primary Service Coordinator",
  "Music Therapist, Public School System",
  "Music Therapists",
  "Chief Physical Therapist",
  "Home Care Physical Therapist",
  "Outpatient Physical Therapist",
  "Pediatric Physical Therapist",
  "Per Diem Physical Therapist",
  "Physical Therapist (PT)",
  "Physical Therapist, Director of Rehabilitation",
  "Registered Physical Therapist (RPT)",
  "Rehabilitation Services Director",
  "Staff Physical Therapist (Staff PT)",
  "Physical Therapists",
  "Acute Care Physician",
  "Attending Urologist",
  "Chief of Pediatric Urology",
  "MD Urologist",
  "Medical Doctor",
  "Urologic Surgeon",
  "Urologist",
  "Urologist MD",
  "Urologists",
  "Art Psychotherapist",
  "Art Therapist",
  "Art Therapist/Licensed Professional Counselor (Art Therapist/LPC)",
  "Bereavement Program Coordinator",
  "Children's Program Coordinator",
  "Counselor/Art Therapist",
  "Day Treatment Clinician/Art Therapist",
  "Group Therapist",
  "Registered & Board Certified Art Therapist",
  "Art Therapists",
  "Clinical Cytogeneticist Scientist (CCS)",
  "Clinical Laboratory Specialist in Cytogenetics (CLSp(CG))",
  "Cytogenetic Technologist",
  "Cytogenetics Laboratory Manager (Cytogenetics Lab Manager)",
  "Head of Cytogenetics",
  "Lead Cytogenetic Technologist",
  "Lead Technologist in Cytogenetics",
  "Senior Cytogenetic Technologist",
  "Senior Cytogenetics Laboratory Director",
  "Technical Specialist, Cytogenetics",
  "Cytogenetic Technologists",
  "Chief Radiation Therapist (Chief RT)",
  "Computed Tomography Simulation Therapist (CT Simulation Therapist)",
  "Dosimetrist",
  "Lead Radiation Therapist",
  "Medical Dosimetrist",
  "Radiation Therapist",
  "Radiation Therapy Technologist (RTT)",
  "Registered Radiation Therapist",
  "Senior Radiation Therapist",
  "Staff Radiation Therapist",
  "Radiation Therapists",
  "Certified Orthoptist",
  "Chief Orthoptist",
  "Clinical Orthoptist (CO)",
  "Orthoptist",
  "Orthoptists",
  "Charge Nurse",
  "Charge Nurse, Cardiac Interventional Care",
  "Clinical Educator",
  "Clinical Nurse Educator",
  "Clinical Staff Educator",
  "Preceptor",
  "Progressive Care Unit Registered Nurse",
  "Staff Nurse",
  "Acute Care Nurses",
  "Cardiac Sonographer",
  "Cardiac/Vascular Sonographer",
  "Diagnostic Medical Sonographer",
  "Medical Sonographer",
  "Registered Diagnostic Medical Sonographer (RDMS)",
  "Sonographer",
  "Staff Sonographer",
  "Ultrasonographer",
  "Ultrasound Technician (Ultrasound Tech)",
  "Ultrasound Technologist (Ultrasound Tech)",
  "Diagnostic Medical Sonographers",
  "Health Diagnosing and Treating Practitioners, All Other",
  "Doctor (Dr)",
  "Doctor of Naturopathic Medicine",
  "Naturopathic Doctor",
  "Naturopathic Oncology Provider",
  "Naturopathic Physician",
  "Naturopathic Physicians",
  "Computed Tomography Technologist (CT Technologist)",
  "Mammographer",
  "Mammography Technologist",
  "Radiographer",
  "Radiologic Technologist (RT)",
  "Radiological Technologist",
  "Radiology Technologist",
  "Staff Technologist",
  "X-Ray Technologist (X-Ray Tech)",
  "Radiologic Technologists",
  "Chief Medical Technologist",
  "Clinical Laboratory Scientist (CLS)",
  "Clinical Laboratory Technologist",
  "Histologist Technologist",
  "Medical Laboratory Technologist (Medical Lab Tech)",
  "Medical Technologist (MT)",
  "Medical Technologist, Clinical Laboratory Scientist",
  "Microbiology Technologist",
  "Medical and Clinical Laboratory Technologists",
  "Administrative Director, Behavioral Health Services",
  "Adult Psychiatric Mental Health Nurse Practitioner",
  "Advanced Practice Nurse Psychotherapist (APN Psychotherapist)",
  "Advanced Practice Psychiatric Nurse",
  "Associate Chief Nurse",
  "Associate Director of Nursing",
  "Coordinator of the Psychiatric Liaison Service",
  "Deputy Director of Clinical Specialty Services",
  "Psychiatric Clinical Nurse Specialist",
  "Psychiatric Mental Health Nurse (PMH Nurse)",
  "Advanced Practice Psychiatric Nurses",
  "Director of Nursing (DON)",
  "Emergency Department RN (Emergency Department Registered Nurse)",
  "Oncology RN (Oncology Registered Nurse)",
  "Operating Room Registered Nurse (OR RN)",
  "Public Health Nurse (PHN)",
  "Registered Nurse (RN)",
  "School Nurse",
  "Staff RN (Staff Registered Nurse)",
  "Registered Nurses",
  "Associate Professor Program Director Nurse Anesthesia",
  "Certified Registered Nurse Anesthetist (CRNA)",
  "Chief Certified Registered Nurse Anesthetist (Chief CRNA)",
  "Chief Nurse Anesthetist",
  "Nurse Anesthetist",
  "Professor/Nurse Anesthetist",
  "Senior Certified Registered Nurse Anesthetist (Senior CRNA)",
  "Staff Certified Registered Nurse Anesthetist (Staff CRNA)",
  "Staff Certified Registered Nurse Anesthetist, Anesthesia Service (Staff CRNA, Anesthesia Service)",
  "Staff Nurse Anesthetist",
  "Nurse Anesthetists",
  "Acute Care Nurse Practitioner",
  "Adult Nurse Practitioner",
  "Advanced Practice Registered Nurse (APRN)",
  "Family Nurse Practitioner",
  "Family Practice Certified Advanced Registered Nurse Practitioner",
  "Gastroenterology Nurse Practitioner",
  "Nurse Practitioner (NP)",
  "Nurse Practitioner, Adult",
  "Pediatric Nurse Practitioner",
  "Women's Health Care Nurse Practitioner",
  "Nurse Practitioners",
  "Therapists, All Other",
  "Certified Nurse Midwife (CNM)",
  "Certified Nurse-Midwife",
  "Clinical Site Coordinator and Supervisor",
  "Nurse Midwife",
  "Senior Instructor, Certified Nurse Midwife",
  "Staff Certified Nurse Midwife",
  "Staff Midwife",
  "Staff Nurse Midwife",
  "Staff Nurse-Midwife",
  "Nurse Midwives",
  "Cardiac Catheterization Laboratory Technologist",
  "Cardiac Catheterization Technician",
  "Cardiac Technician",
  "Cardiology Technician",
  "Cardiopulmonary Technician",
  "Cardiovascular Technician",
  "Cardiovascular Technologist (CVT)",
  "Electrocardiogram Technician (EKG Technician)",
  "Registered Cardiovascular Invasive Specialist (RCIS)",
  "Cardiovascular Technologists and Technicians",
  "Burn Center Nurse",
  "Cardiac Catheterization Laboratory Registered Nurse",
  "Catheterization Laboratory Senior Manager (Cath Lab Senior Manager)",
  "Critical Care Registered Nurse (CCRN)",
  "Critical Care Unit Manager",
  "ICU Nurse (Intensive Care Unit Nurse)",
  "ICU Staff Nurse (Intensive Care Unit Staff Nurse)",
  "Registered Nurse Supervisor (RN Supervisor)",
  "Staff Nurse, ICU Resource Team (Staff Nurse, Intensive Care Unit Resource Team)",
  "Critical Care Nurses",
  "Board Certified Clinical Nurse Specialist, Diabetes",
  "Cardiology Clinical Nurse Specialist",
  "Cardiothoracic Surgery Clinical Nurse Specialist",
  "Cardiovascular Clinical Nurse Specialist",
  "Clinical Nurse Specialist",
  "Critical Care Clinical Nurse Specialist",
  "Emergency Department Clinical Nurse Specialist",
  "Intensive Care Unit Clinical Nurse Specialist",
  "Pediatric Clinical Nurse Specialist",
  "Clinical Nurse Specialists",
  "Audiologist",
  "Audiology Director",
  "Audiology Doctor (AUD)",
  "Certificate of Clinical Competence in Audiology Licensed Audiologist (CCC-A Licensed Audiologist)",
  "Clinical Audiologist",
  "Dispensing Audiologist",
  "Doctor of Audiology",
  "Educational Audiologist",
  "Pediatric Audiologist",
  "Audiologists",
  "Acupuncture Physician",
  "Acupuncturist",
  "Licensed Acupuncturist",
  "Acupuncturists",
  "Bilingual Speech-Language Pathologist",
  "Communication Specialist",
  "Educational Speech-Language Clinician",
  "Speech and Language Clinician",
  "Speech and Language Specialist",
  "Speech Pathologist",
  "Speech Therapist",
  "Speech-Language Pathologist (SLP)",
  "Speech/Language Therapist",
  "Teacher of the Speech and Hearing Handicapped",
  "Speech-Language Pathologists",
  "Charge Histotechnologist",
  "Clinical Laboratory Manager",
  "Histologic Technician",
  "Histology Specialist",
  "Histology Technician",
  "Histology Technologist",
  "Histotechnologist",
  "Histotechnologist Supervisor",
  "Pathology Supervisor",
  "Histotechnologists and Histologic Technicians",
  "Certified Dietary Manager (CDM)",
  "Cook Chill Technician (CCT)",
  "Diet Assistant",
  "Diet Clerk",
  "Diet Tech (Diet Technician)",
  "Diet Tech (Dietetic Technician)",
  "Diet Technician Registered (DTR)",
  "Dietary Aid",
  "Dietary Aide",
  "Dietetic Technicians",
  "Certified Low Vision Therapist",
  "Certified Orientation & Mobility Specialist",
  "Certified Orientation and Mobility Specialist (COMS)",
  "Mobility Specialist",
  "Orientation & Mobility Specialist",
  "Orientation and Mobility Instructor",
  "Orientation and Mobility Specialist",
  "Teacher of Students with Visual Impairments (TVI)",
  "TVI (Teacher of the Visually Impaired)",
  "Vision Rehabilitation Therapist (VRT)",
  "Low Vision Therapists, Orientation and Mobility Specialists, and Vision Rehabilitation Therapists",
  "Certified Respiratory Therapy Technician (CRTT)",
  "Pulmonary Function Technician (PF Technician)",
  "Registered Pulmonary Function Technologist (RPFT)",
  "Respiratory Care Assistant (RCA)",
  "Respiratory Director",
  "Respiratory Supervisor",
  "Respiratory Technician",
  "Respiratory Therapy Assistant",
  "Respiratory Therapy Technician (RTT)",
  "Respiratory Therapy Technicians",
  "Cytology Laboratory Manager (Cytology Lab Manager)",
  "Cytology Manager",
  "Cytology Supervisor",
  "Cytotechnologist",
  "Cytotechnologist Supervisor",
  "Cytotechnologist/Cytology Supervisor",
  "Senior Cytotechnologist",
  "Staff Cytotechnologist",
  "Technical Specialist, Cytology",
  "Cytotechnologists",
  "Advisory Industrial Hygienist",
  "Certified Industrial Hygienist (CIH)",
  "Construction Safety Consultant",
  "Health and Safety Technician",
  "Industrial Hygiene Consultant",
  "Industrial Hygiene Engineer",
  "Safety and Environmental Compliance Officer",
  "Safety Research Professional",
  "Safety, Health, and Environmental Engineer (SHE Engineer)",
  "Occupational Health and Safety Technicians",
  "Coder",
  "Health Information Clerk",
  "Health Information Specialist",
  "Health Information Technician (Health Information Tech)",
  "Medical Records Analyst",
  "Medical Records Clerk",
  "Medical Records Coordinator",
  "Medical Records Director",
  "Medical Records Technician (Medical Records Tech)",
  "Registered Health Information Technician (RHIT)",
  "Medical Records and Health Information Technicians",
  "Accredited Pharmacy Technician",
  "Billing and Quality Technician",
  "Certified Pharmacy Technician (CPhT)",
  "Compounding Technician",
  "Lead Pharmacy Tech, Certified Pharmacy Technician (Lead Pharmacy Tech, CPhT)",
  "Lead Pharmacy Technician (Lead Pharmacy Tech)",
  "Pharmacy Technician (Pharmacy Tech)",
  "Senior Pharmacy Technician",
  "Technician, Inventory Specialist",
  "Pharmacy Technicians",
  "Certified Veterinary Technician (CVT)",
  "Emergency Veterinary Technician",
  "Internal Medicine Veterinary Technician",
  "Licensed Veterinary Technician (LVT)",
  "Medical Technologist",
  "Registered Veterinary Technician (RVT)",
  "Veterinary Assistant",
  "Veterinary Laboratory Technician (Veterinary Lab Tech)",
  "Veterinary Nurse",
  "Veterinary Technician (Vet Tech)",
  "Veterinary Technologists and Technicians",
  "Health Technologists and Technicians, All Other",
  "Chief Magnetic Resonance Imaging Technologist (Chief MRI Technologist)",
  "Magnetic Resonance Imaging Coordinator (MRI Coordinator)",
  "Magnetic Resonance Imaging Director",
  "Magnetic Resonance Imaging Quality Assurance Coordinator (MRI Quality Assurance Coordinator)",
  "Medical Imaging Director",
  "MRI Specialist (Magnetic Resonance Imaging Specialist)",
  "MRI Supervisor (Magnetic Resonance Imaging Supervisor)",
  "MRI Technologist (Magnetic Resonance Imaging Technologist)",
  "Magnetic Resonance Imaging Technologists",
  "American Board Certified Orthotist (ABC Orthotist)",
  "Certified Orthotist (CO)",
  "Certified Prosthetist (CP)",
  "Certified Prosthetist/Orthotist (CPO)",
  "Licensed Prosthetist/Orthotist (LPO)",
  "Orthotic/Prosthetic Practitioner",
  "Orthotist",
  "Orthotist/Prosthetist",
  "Pedorthist",
  "Prosthetist",
  "Orthotists and Prosthetists",
  "Certified Neurodiagnostic Technologist",
  "Clinical Supervisor, Epilepsy Monitoring Unit",
  "Electroneurodiagnostic Technologist",
  "Electroneurodiagnostic Technologist Coordinator",
  "Lead Neurodiagnostic Technologist",
  "Manager, Neurodiagnostic Laboratory & Epilepsy Center (Manager, Neurodiagnostic Lab & Epilepsy Center)",
  "Neurodiagnostic Technologist",
  "Registered Electroneurodiagnostic Technologist",
  "Registered Polysomnographic Technologist",
  "Senior Technologist",
  "Neurodiagnostic Technologists",
  "Certified Optician",
  "Contact Lens Technician",
  "Dispensing Optician",
  "Licensed Dispensing Optician (LDO)",
  "Licensed Optician",
  "Ophthalmic Dispenser",
  "Optical Technician",
  "Optician",
  "Optometric Assistant",
  "Optometric Technician",
  "Opticians, Dispensing",
  "Emergency Medical Technician (EMT)",
  "Emergency Medical Technician, Basic (EMT, B)",
  "Emergency Medical Technician/Driver (EMT/DRIVER)",
  "EMT Intermediate (Emergency Medical Technician, Intermediate)",
  "EMT, Paramedic (Emergency Medical Technician, Paramedic)",
  "EMT/Dispatcher (Emergency Medical Technician/Dispatcher)",
  "First Responder",
  "Flight Paramedic",
  "Multi Care Technician (Multi Care Tech)",
  "Paramedic",
  "Emergency Medical Technicians and Paramedics",
  "Dental Hygienist",
  "Dental Hygienist, Mobile Coordinator",
  "Hygienist",
  "Implant Coordinator",
  "Pediatric Dental Hygienist",
  "Registered Dental Hygienist (RDH)",
  "Registered Dental Hygienist, Part Time Clinical Faculty",
  "Dental Hygienists",
  "Certified Surgical Tech/First Assistant",
  "Certified Surgical Technician",
  "Certified Surgical Technologist (CST)",
  "Operating Room Surgical Technician (OR St)",
  "Operating Room Technician (OR Tech)",
  "Operating Room Technologist (OR Tech)",
  "Surgical Scrub Technician",
  "Surgical Scrub Technologist (Surgical Scrub Tech)",
  "Surgical Technician",
  "Surgical Technologist (Surgical Tech)",
  "Surgical Technologists",
  "Certified Clinical Laboratory Technician",
  "Clinical Laboratory Technician (Clinical Lab Technician)",
  "Laboratory Supervisor",
  "Medical Laboratory Technician (MLT)",
  "Medical Laboratory Technicians (Medical Lab Technician)",
  "Medical Technician",
  "Medical and Clinical Laboratory Technicians",
  "Certified Nuclear Medicine Technologist (CNMT)",
  "Lead Nuclear Medicine Technologist (Lead Nuc Med Tech)",
  "Nuclear Cardiology Technologist",
  "Nuclear Medicine PET-CT Technologist (Nuclear Medicine Positron Emission Tomography - Computed Tomography Technologist)",
  "Nuclear Medicine Technologist (Nuclear Med Tech)",
  "Radiation Safety Officer",
  "Registered Nuclear Medicine Technologist",
  "Senior Nuclear Medicine Technologist",
  "Staff Nuclear Medicine Technologist",
  "Supervisor Nuclear Medicine",
  "Nuclear Medicine Technologists",
  "Behavioral Health Technician",
  "Health Care Technician",
  "Licensed Psychiatric Technician (LPT)",
  "Mental Health Assistant (MHA)",
  "Mental Health Associate",
  "Mental Health Technician (MHT)",
  "Mental Health Worker",
  "Psychiatric Technician (PT)",
  "Residential Aide (RA)",
  "Psychiatric Technicians",
  "Chemical Hygiene Officer",
  "Environmental Health and Safety Officer",
  "Environmental, Health, and Safety Officer (EHS Officer)",
  "Industrial Hygienist Consultant",
  "Safety Consultant",
  "Safety Management Consultant",
  "Safety Officer",
  "Safety Specialist",
  "Occupational Health and Safety Specialists",
  "Chief Technician, X-Ray (Chief Tech, X-Ray)",
  "Limited Radiology Technician",
  "Radiologic Technician (RT)",
  "Radiology Technician (Radiology Tech)",
  "Registered Radiographer",
  "X-Ray Technician (X-Ray Tech)",
  "Radiologic Technicians",
  "Certified First Assistant (CFA)",
  "Certified Registered Nurse First Assistant (CRNFA)",
  "Certified Surgical Assistant (CSA)",
  "Certified Surgical First Assistant (CSFA)",
  "Gastrointestinal Technician (GI Technician)",
  "Registered Nurse First Assistant (RNFA)",
  "Surgical First Assistant",
  "Surgical Scrub Technician (Surgical Scrub Tech)",
  "Surgical Technician (Surgical Tech)",
  "Surgical Assistants",
  "Clinic Licensed Practical Nurse (CLINIC LPN)",
  "Clinic Nurse",
  "Licensed Practical Nurse (LPN)",
  "Licensed Practical Nurse, Clinic Nurse (LPN, Clinic Nurse)",
  "Licensed Vocational Nurse (LVN)",
  "Office Nurse",
  "Pediatric Licensed Practical Nurse (PEDIATRIC LPN)",
  "Private Duty Nurse",
  "Triage Licensed Practical Nurse (TRIAGE LPN)",
  "Licensed Practical and Licensed Vocational Nurses",
  "Apparatus Operator",
  "Fire Captain",
  "Fire Chief",
  "Fire Engineer",
  "Fire Equipment Operator",
  "Fire Fighter",
  "Firefighter",
  "Fireman",
  "Volunteer Firefighter",
  "Municipal Firefighters",
  "Certified Ophthalmic Assistant",
  "Certified Ophthalmic Medical Technician",
  "Certified Ophthalmic Technician",
  "Certified Ophthalmic Technician with Surgical Assisting",
  "Certified Ophthalmic Technician-Surgical Assistant (COT-SA)",
  "Ophthalmic Assistant",
  "Ophthalmic Medical Assistant",
  "Ophthalmic Medical Technician",
  "Ophthalmic Technician",
  "Surgical Coordinator",
  "Ophthalmic Medical Technicians",
  "Attendant",
  "Operating Room Assistant",
  "Orderly",
  "Patient Care Assistant (PCA)",
  "Patient Care Technician (PCT)",
  "Patient Escort",
  "Patient Transporter",
  "Radiology Transporter",
  "Resident Assistant",
  "Transporter",
  "Orderlies",
  "Fire Management Specialist",
  "Fire Rescue Technician",
  "Fire Technician",
  "Forest Fire Suppression Specialist",
  "Forestry Fire Technician",
  "Hot Shot",
  "On-Scene Supporter",
  "Wildland Firefighter",
  "Forest Firefighters",
  "Audioprosthologist",
  "Board Certified Hearing Instrument Dispenser",
  "Board Certified Hearing Instrument Specialist",
  "Certified Hearing Instrument Dispenser",
  "Hearing Aid Consultant",
  "Hearing Aid Specialist",
  "Hearing Care Practitioner",
  "Hearing Instrument Specialist",
  "Hearing Specialist",
  "Senior Hearing Specialist, Audio Prosthologist",
  "Hearing Aid Specialists",
  "Certified Diagnostic Ophthalmic Sonographer and Registered Ophthalmic Ultrasound Biometrist (CDOS and ROUB)",
  "Certified Ophthalmic Medical Technologist (COMT)",
  "Certified Ophthalmic Surgical Assistant",
  "Clinical Supervisor",
  "Ophthalmic Medical Technologist",
  "Ophthalmic Technologist (Ophthalmic Tech)",
  "Ophthalmology Surgical Technician",
  "Ophthalmic Medical Technologists",
  "Bodywork Therapist",
  "Certified Massage Therapist (CMT)",
  "Clinical Massage Therapist",
  "Integrated Deep Tissue Massage Therapist",
  "Licensed Massage Practitioner (LMP)",
  "Licensed Massage Therapist",
  "Massage Therapist",
  "Medical Massage Therapist",
  "Registered Massage Therapist",
  "Therapeutic Massage Technician",
  "Massage Therapists",
  "Certified Medical Assistant (CMA)",
  "Lab Liaison Technician",
  "Medical Lab Assistant",
  "Mobile Examiner",
  "Patient Service Technician (PST)",
  "Phlebotomist",
  "Phlebotomy Technician",
  "Registered Phlebotomist",
  "Phlebotomists",
  "Certified Professional Midwife",
  "Certified Professional Midwife, Licensed Midwife",
  "Director of Midwifery/Staff Midwife",
  "Lay Midwife",
  "Licensed Direct Entry Midwife",
  "Licensed Midwife",
  "Licensed Midwife, Certified Professional Midwife",
  "Midwife",
  "Midwife and Birth Center Owner",
  "Midwives",
  "Fire Management Officer",
  "Fire Operations Forester",
  "Fire Prevention Officer",
  "Fire Prevention Technician",
  "Forest Officer",
  "Forest Patrolman",
  "Forestry Patrolman",
  "Wildfire Mitigation Specialist",
  "Wildfire Prevention Specialist",
  "Forest Fire Inspectors and Prevention Specialists",
  "First-Line Supervisors of Protective Service Workers, All Other",
  "Assistant Speech-Language Pathologist",
  "Communication Assistant",
  "Speech - Language Pathology Assistant (SLPA)",
  "Speech and Language Assistant",
  "Speech and Language Tutor",
  "Speech Language Pathologist Assistant",
  "Speech Language Pathology Assistant",
  "Speech Pathologist Assistant",
  "Speech Therapist Technician",
  "Speech-Language Assistant",
  "Speech-Language Pathology Assistants",
  "Certified Genetic Counselor",
  "Clinical Coordinator, Pediatric Genetics",
  "Coordinator of Genetic Services",
  "Genetic Counselor",
  "Hereditary Cancer Program Coordinator",
  "Medical Science Liaison",
  "Prenatal and Pediatric Genetic Counselor",
  "Reproductive Genetic Counseling Coordinator",
  "Senior Genetic Counselor",
  "Staff Genetic Counselor",
  "Genetic Counselors",
  "Certified Medication Aide (CMA)",
  "Certified Nurse Aide (CNA)",
  "Certified Nurses Aide (CNA)",
  "Certified Nursing Assistant (CNA)",
  "Geriatric Nursing Assistant (GNA)",
  "Licensed Nursing Assistant (LNA)",
  "Nurses' Aide",
  "Nursing Aide",
  "Nursing Assistant",
  "State Tested Nursing Assistant (STNA)",
  "Nursing Assistants",
  "Certified Dental Assistant (CDA)",
  "Certified Registered Dental Assistant",
  "Dental Assistant (DA)",
  "Expanded Duty Dental Assistant (EDDA)",
  "Expanded Function Dental Assistant",
  "Orthodontic Assistant (Ortho Assistant)",
  "Orthodontic Technician",
  "Registered Dental Assistant (RDA)",
  "Surgical Dental Assistant",
  "Dental Assistants",
  "Caregiver",
  "Certified Home Health Aide (CHHA)",
  "Certified Medical Aide (CMA)",
  "Home Attendant",
  "Home Care Aide",
  "Home Health Aide (HHA)",
  "Home Health Provider",
  "Hospice/Home Health Aide",
  "In Home Caregiver",
  "Home Health Aides",
  "Assistant Athletic Trainer",
  "Athletic Instructor",
  "Athletic Trainer",
  "Certified Athletic Trainer",
  "Graduate Assistant Athletic Trainer",
  "Head Athletic Trainer",
  "Head Athletic Trainer/Strength Coach",
  "Resident Athletic Trainer",
  "Sports Medicine Coordinator",
  "Athletic Trainers",
  "Captain",
  "Correctional Lieutenant",
  "Correctional Officer Captain",
  "Correctional Officer Lieutenant",
  "Correctional Officer Sergeant",
  "Correctional Sergeant",
  "Corrections Sergeant",
  "Lieutenant",
  "Sergeant",
  "Shift Supervisor",
  "First-Line Supervisors of Correctional Officers",
  "Certified Physical Therapist Assistant (CPTA)",
  "Home Health Physical Therapist Assistant",
  "Licensed Physical Therapist Assistant (LPTA)",
  "Outpatient Physical Therapist Assistant",
  "Per Diem Physical Therapist Assistant (Per Diem PTA)",
  "Physical Therapist Assistant (PTA)",
  "Physical Therapist Assistant and Nurse Aide",
  "Physical Therapy Assistant (PTA)",
  "Physical Therapy Technician (Physical Therapy Tech)",
  "Staff Physical Therapy Assistant",
  "Physical Therapist Assistants",
  "Ancillary",
  "Certified Pharmacist Assistant",
  "Drug Purchaser",
  "Front Counter Clerk",
  "Pharmacist Assistant",
  "Pharmacy Aide",
  "Pharmacy Ancillary",
  "Pharmacy Assistant",
  "Pharmacy Cashier",
  "Pharmacy Clerk",
  "Pharmacy Aides",
  "Certified Occupational Rehabilitation Aide (CORA)",
  "Direct Service Professional",
  "Direct Support Staff",
  "Occupational Therapy Aide (OT Aide)",
  "Occupational Therapy Technician (OT Tech)",
  "Rehabilitation Aide (Rehab Aide)",
  "Rehabilitation Services Aide",
  "Rehabilitation Technician (Rehab Tech)",
  "Restorative Aide",
  "Restorative Coordinator",
  "Occupational Therapy Aides",
  "Assistant Unit Forester",
  "Crew Boss",
  "District Fire Management Officer",
  "Engine Boss",
  "Firefighter Type One (FFT1)",
  "Forest Fire Specialist Supervisor",
  "Section Forest Fire Warden",
  "Squad Boss",
  "Forest Fire Fighting and Prevention Supervisors",
  "Clinical Medical Transcriptionist",
  "Medical Language Specialist",
  "Medical Secretary",
  "Medical Transcriber",
  "Medical Transcription",
  "Medical Transcription Supervisor",
  "Medical Transcriptionist",
  "Radiology Transcriptionist",
  "Transcriptionist",
  "Medical Transcriptionists",
  "Firefighters",
  "Chiropractor Assistant",
  "Clinical Assistant",
  "Doctor's Assistant",
  "Medical Assistant (MA)",
  "Medical Office Assistant",
  "Registered Medical Assistant (RMA)",
  "Medical Assistants",
  "Arson Division Chief",
  "Arson Investigator",
  "Canine Handler (K9 Handler)",
  "Fire and Explosion Investigator",
  "Fire Investigator",
  "Fire Lieutenant",
  "Fire Marshal",
  "State Fire Marshal",
  "Fire Investigators",
  "First-Line Supervisors of Fire Fighting and Prevention Workers",
  "Fire Inspectors and Investigators",
  "Healthcare Practitioners and Technical Workers, All Other",
  "Mental Health Aide (MHA)",
  "Mental Health Worker (MHW)",
  "Psychiatric Aide",
  "Psychiatric Nursing Aide",
  "Psychiatric Nursing Assistant",
  "Resident Care Technician",
  "Residential Counselor",
  "Therapeutic Program Worker (TPW)",
  "Psychiatric Aides",
  "Clinical Rehabilitation Aide",
  "Physical Therapist Aide (PTA)",
  "Physical Therapist Technician (Physical Therapy Tech)",
  "Physical Therapy Aide (PTA)",
  "Physical Therapy Attendant",
  "Physical Therapy Technician",
  "Rehabilitation Aide",
  "Rehabilitation Attendant",
  "Restorative Aide (RA)",
  "Restorative Care Technician",
  "Physical Therapist Aides",
  "Certified Endoscopy Technician",
  "Certified Flex Endoscope Reprocessor",
  "Certified Flexible Endoscope Reprocessor (CFER)",
  "Certified Flexible Endoscopy Reprocessor",
  "Chief Technician",
  "Endoscope Technician",
  "Endoscopy Specialty Technician",
  "Endoscopy Technician",
  "Endoscopy Technicians",
  "Acute Care Occupational Therapy Assistant",
  "Certified Occupational Therapist Assistant (COTA)",
  "Certified Occupational Therapist Assistant/Licensed (COTA/L)",
  "Certified Occupational Therapy Assistant (COTA)",
  "Certified Occupational Therapy Assistant-Licensed (COTA-L)",
  "Licensed Occupational Therapy Assistant",
  "Occupational Therapist Assistant",
  "Occupational Therapy Assistant (OTA)",
  "School Based Certified Occupational Therapy Assistant",
  "Staff Certified Occupational Therapist Assistant/Licensed (Staff COTA/L)",
  "Occupational Therapy Assistants",
  "Healthcare Support Workers, All Other",
  "Central Processing Technician (CPT)",
  "Central Service Technician (CST)",
  "Central Sterile Supply Technician (CSS Technician)",
  "Certified Registered Central Service Technician (CRCST)",
  "Sterile Preparation Technician",
  "Sterile Processing and Distribution Technician (SPD Technician)",
  "Sterile Processing Technician",
  "Sterile Technician",
  "Sterilization Technician",
  "Medical Equipment Preparers",
  "Battalion Chief",
  "Battalion Fire Chief",
  "Fire Battalion Chief",
  "Fire Suppression Captain",
  "Lieutenant Fire Fighter",
  "Training Officer",
  "Municipal Fire Fighting and Prevention Supervisors",
  "Deputy Fire Marshal",
  "Fire Code Inspector",
  "Fire Inspector",
  "Fire Official",
  "Fire Prevention Inspector",
  "Fire Prevention Specialist",
  "Fire Protection Specialist",
  "Fire Safety Inspector",
  "Fire Inspectors",
  "Animal Care Provider",
  "Animal Caregiver",
  "Avian Keeper",
  "Emergency Veterinary Assistant",
  "Research Animal Attendant",
  "Small Animal Caretaker",
  "Technician Assistant",
  "Veterinarian Assistant",
  "Veterinary Assistant (Vet Assistant)",
  "Veterinary Technician Assistant (Vet Tech Assistant)",
  "Veterinary Assistants and Laboratory Animal Caretakers",
  "Bar Manager",
  "Barista",
  "Catering Barista",
  "Sales Associate",
  "Baristas",
  "Buffet Hostess",
  "General Teller",
  "Greeter",
  "Hospitality Coordinator",
  "Host Coordinator",
  "Hostess",
  "Seater",
  "Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop",
  "Detectives and Criminal Investigators",
  "Adult Crossing Guard",
  "Community Service Officer",
  "Community Service Officer Coordinator",
  "Crossing Guard",
  "Road Crossing Guard",
  "School Crossing Guard",
  "School Crossing Guard Supervisor",
  "School Traffic Supervisor",
  "Substitute Crossing Guard",
  "Crossing Guards",
  "Asset Protection Associate (APA)",
  "Asset Protection Lead",
  "Loss Prevention Agent",
  "Loss Prevention Associate (LPA)",
  "Loss Prevention Detective",
  "Loss Prevention Investigator",
  "Loss Prevention Leader",
  "Loss Prevention Officer",
  "Loss Prevention Specialist",
  "Retail Asset Protection Specialist",
  "Retail Loss Prevention Specialists",
  "Alcohol Law Enforcement Agent (ALE Agent)",
  "Law Enforcement Officer",
  "Officer",
  "Patrol Officer",
  "Peace Officer",
  "Police Officer",
  "Police Patrol Officer",
  "Public Safety Officer",
  "State Trooper",
  "Uniform Patrol Police Officer",
  "Police Patrol Officers",
  "Appetizer Preparer",
  "Back Line Cook",
  "Banquet Cook",
  "Breakfast Cook",
  "Broil Cook",
  "Cook",
  "Fry Cook",
  "Grill Cook",
  "Line Cook",
  "Prep Cook (Preparation Cook)",
  "Cooks, Restaurant",
  "Banquet Server",
  "Buffet Server",
  "Cocktail Server",
  "Food Runner",
  "Food Server",
  "Restaurant Server",
  "Server",
  "Waiter",
  "Waitress",
  "Waitstaff",
  "Waiters and Waitresses",
  "Criminal Investigator",
  "Detective",
  "Detective Sergeant",
  "Fugitive Detective",
  "Fugitive Investigator",
  "Narcotics Detective",
  "Narcotics Investigator",
  "Police Detective",
  "Police Detectives",
  "Alpine Patroller",
  "Beach Attendant",
  "Beach Lifeguard",
  "Lifeguard",
  "Marine Safety Officer",
  "Ocean Lifeguard",
  "Ocean Lifeguard Specialist",
  "Pool Attendant",
  "Pool Lifeguard",
  "Ski Patroller",
  "Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers",
  "Buildings and Grounds Supervisor",
  "Custodian",
  "Environmental Services Supervisor (EVS)",
  "Executive Housekeeper",
  "Head Custodian",
  "Housekeeping Director",
  "Housekeeping Supervisor",
  "First-Line Supervisors of Housekeeping and Janitorial Workers",
  "Chief of Police",
  "Patrol Sergeant",
  "Police Captain",
  "Police Chief",
  "Police Lieutenant",
  "Police Sergeant",
  "First-Line Supervisors of Police and Detectives",
  "District Wildlife Manager",
  "Fisheries Enforcement Officer",
  "Game Warden",
  "Law Enforcement Officer (LEO)",
  "Natural Resource Officer",
  "State Game Warden",
  "State Wildlife Officer",
  "Wildlife Conservation Officer",
  "Wildlife Officer",
  "Fish and Game Wardens",
  "Food Preparation and Serving Related Workers, All Other",
  "Cafe Server",
  "Cafeteria Server",
  "Cafeteria Worker",
  "Food Service Assistant",
  "Food Service Worker",
  "Line Server",
  "Sandwich Artist",
  "Snack Bar Attendant",
  "Counter Attendants, Cafeteria, Food Concession, and Coffee Shop",
  "Cafeteria Manager",
  "Dietary Supervisor",
  "Food and Nutrition Services Supervisor",
  "Food Production Supervisor",
  "Kitchen Supervisor",
  "First-Line Supervisors of Food Preparation and Serving Workers",
  "Caterer",
  "Deli Cook (Delicatessen Cook)",
  "Food and Beverage Attendant",
  "Pizza Maker",
  "Short Order Cook",
  "Snack Bar Cook",
  "Cooks, Short Order",
  "Bailiff",
  "Court Bailiff",
  "Court Officer",
  "Court Security Officer",
  "Deputy Bailiff",
  "Deputy Sheriff Court Services",
  "Security Officer",
  "Bailiffs",
  "Security Screener",
  "Transportation Security Officer (TSO)",
  "Transportation Security Screeners",
  "Animal Attendant",
  "Animal Control Officer",
  "Animal Enforcement Officer",
  "Animal Ordinance Enforcement Officer",
  "Animal Park Code Enforcement Officer",
  "Animal Safety Officer",
  "Community Safety Officer",
  "Dog Control Officer",
  "Animal Control Workers",
  "Certified Personal Chef (CPC)",
  "Personal Chef",
  "Private Chef",
  "Cooks, Private Household",
  "FBI Special Agent (Federal Bureau of Investigation Special Agent)",
  "Master Police Detective",
  "Special Agent",
  "Criminal Investigators and Special Agents",
  "Asset Protection Detective",
  "Field Investigator",
  "Loss Prevention Associate",
  "Private Investigator",
  "Special Investigator",
  "Private Detectives and Investigators",
  "Golf Course Superintendent",
  "Grounds Crew Supervisor",
  "Grounds Foreman",
  "Grounds Maintenance Supervisor",
  "Grounds Manager",
  "Grounds Supervisor",
  "Groundskeeper Supervisor",
  "Landscape Manager",
  "Landscape Supervisor",
  "First-Line Supervisors of Landscaping, Lawn Service, and Groundskeeping Workers",
  "Campus Security Officer",
  "Custom Protection Officer",
  "Customer Service Security Officer",
  "Hotel Security Officer",
  "Safety and Security Officer",
  "Security Agent",
  "Security Guard",
  "Security Supervisor",
  "Security Guards",
  "Canine Enforcement Officer (K-9 Enforcement Officer)",
  "Customs and Border Protection Officer (CBPO)",
  "Customs Inspector",
  "Customs Officer",
  "Customs Port Director",
  "Import Specialist",
  "Supervisory CBP Officer",
  "US Customs and Border Protection Officer (US CBPO)",
  "Immigration and Customs Inspectors",
  "Casino Enforcement Agent",
  "Gaming Investigator",
  "Surveillance Agent",
  "Surveillance Investigator",
  "Surveillance Monitor",
  "Surveillance Observer",
  "Surveillance Officer",
  "Surveillance Operator",
  "Surveillance Technician",
  "Gaming Surveillance Officers and Gaming Investigators",
  "Diet Aide",
  "Dietary Assistant",
  "Dietary Service Aide",
  "Room Server",
  "Room Service Server",
  "Tray Server",
  "Food Servers, Nonrestaurant",
  "Protective Service Workers, All Other",
  "Bar Back",
  "Bus Boy",
  "Bus Person",
  "Busboy",
  "Dining Room Attendant",
  "Server Assistant",
  "Shift Manager",
  "Dining Room and Cafeteria Attendants and Bartender Helpers",
  "Banquet Bartender",
  "Bar Captain",
  "Bar Supervisor",
  "Bartender",
  "Mixologist",
  "Bartenders",
  "Deli Clerk (Delicatessen Clerk)",
  "Food Preparer",
  "Food Service Aide",
  "Nutrition Aide",
  "Pantry Cook",
  "Slicer",
  "Food Preparation Workers",
  "Correctional Officer",
  "Corrections Officer (CO)",
  "Custody Assistant",
  "Deputy Jailer",
  "Detention Deputy",
  "Detention Officer",
  "Jail Officer",
  "Jailer",
  "Jailor",
  "Correctional Officers and Jailers",
  "Dish Machine Operator (DMO)",
  "Dish Room Worker",
  "Dish Technician",
  "Dish Washer",
  "Dishwasher",
  "Kitchen Helper",
  "Kitchen Steward",
  "Stewarding Supervisor",
  "Utility Aide",
  "Dishwashers",
  "Car Hop",
  "Deli Worker (Delicatessen Worker)",
  "School Cafeteria Cook",
  "Combined Food Preparation and Serving Workers, Including Fast Food",
  "Patrol Man",
  "Patrolman",
  "Police Specialist",
  "Railroad Police",
  "Railroad Police Officer",
  "Transit Police Officer",
  "Transit and Railroad Police",
  "Deep Fat Fryer Operator",
  "Fast Food Cook",
  "Fryer",
  "Pizza Cook",
  "Prep Cook (Preparatory Cook)",
  "Cooks, Fast Food",
  "Crime Analyst",
  "Criminal Intelligence Analyst",
  "Criminal Intelligence Analyst Supervisor",
  "Criminal Intelligence Specialist",
  "Criminal Research Specialist",
  "Detective and Intelligence Analyst",
  "Intelligence Officer",
  "Intelligence Research Specialist",
  "Supervisor Intelligence Analyst",
  "Intelligence Analysts",
  "College Service Officer",
  "Parking Control Officer",
  "Parking Enforcement Officer (PEO)",
  "Parking Enforcement Technician",
  "Parking Enforcer",
  "Parking Officer",
  "Parking Regulation Enforcement Officer",
  "Parking Technician",
  "Ticket Writer",
  "Parking Enforcement Workers",
  "Police and Sheriff's Patrol Officers",
  "Cafeteria Cook",
  "Chef",
  "Dietary Cook",
  "Dinner Cook",
  "Food Service Specialist",
  "School Cook",
  "Sous Chef",
  "Cooks, Institution and Cafeteria",
  "Breakfast and Room Attendant",
  "Cottage Attendant",
  "Environmental Services Aide",
  "Environmental Services Worker",
  "Housekeeper",
  "Housekeeping",
  "Housekeeping Aide",
  "Housekeeping Laundry Worker",
  "Room Attendant",
  "Room Cleaner",
  "Maids and Housekeeping Cleaners",
  "Banquet Chef",
  "Certified Executive Chef (CEC)",
  "Chef, Instructor",
  "Corporate Executive Chef",
  "Executive Chef (Ex Chef)",
  "Executive Sous Chef",
  "Head Cook",
  "Chefs and Head Cooks",
  "Crime Scene Evidence Technician",
  "Crime Scene Investigator",
  "Crime Scene Technician",
  "Criminalist",
  "Field Identification Specialist",
  "Identification Officer",
  "Identification Technician",
  "Police Identification and Records Officers",
  "Cooks, All Other",
  "Building Custodian",
  "Building Service Worker",
  "Building Services Technician",
  "Cleaner",
  "Custodial Worker",
  "Floor Tech (Floor Technician)",
  "Heavy Duty Custodian",
  "Institutional Custodian",
  "Janitor",
  "Janitors and Cleaners, Except Maids and Housekeeping Cleaners",
  "Barber",
  "Barber Shop Operator",
  "Barber Stylist",
  "Stylist",
  "Barbers",
  "Costume Draper",
  "Costume Seamstress",
  "Costume Shop Manager",
  "Costumer",
  "Draper",
  "Dresser",
  "Wardrobe Assistant",
  "Wardrobe Attendant",
  "Wardrobe Manager",
  "Wardrobe Supervisor",
  "Costume Attendants",
  "Apprentice Cosmetologist",
  "Apprentice Stylist",
  "Assistant Hairstylist",
  "Hair Assistant",
  "Shampoo Assistant",
  "Shampoo Person",
  "Shampoo Technician",
  "Shampooer",
  "Stylist Assistant",
  "Shampooers",
  "Gardener",
  "Greenskeeper",
  "Grounds Maintenance Worker",
  "Grounds Person",
  "Grounds Worker",
  "Grounds/Maintenance Specialist",
  "Groundskeeper",
  "Landscape Specialist",
  "Landscape Technician",
  "Outside Maintenance Worker",
  "Landscaping and Groundskeeping Workers",
  "Funeral Arrangement Director",
  "Funeral Arranger",
  "Funeral Counselor",
  "Funeral Director",
  "Funeral Location Manager",
  "Funeral Pre-Need Consultant",
  "Funeral Prearrangement Counselor",
  "Licensed Funeral Director",
  "Licensed Mortician",
  "Mortician",
  "Morticians, Undertakers, and Funeral Directors",
  "Account Manager",
  "Accounts Executive",
  "Accounts Manager",
  "Advertising Account Executive",
  "Advertising Account Representative",
  "Advertising Consultant",
  "Advertising Representative",
  "Advertising Sales Representative (Ad Sales Representative)",
  "Marketing Consultant",
  "Advertising Sales Agents",
  "Commercial Makeup Artist",
  "Hair and Makeup Designer",
  "Makeup Artist",
  "Prosthetic Makeup Designer",
  "Special Effects Makeup Artist",
  "Special Makeup Effects Artist",
  "Makeup Artists, Theatrical and Performance",
  "Gaming Service Workers, All Other",
  "Childcare Professional",
  "Family Manager",
  "Household Manager",
  "Nanny",
  "Nannies",
  "Casino Floorperson",
  "Casino Supervisor",
  "Floor Supervisor",
  "Gaming Floor Supervisor",
  "Pit Boss",
  "Pit Supervisor",
  "Slot Shift Manager",
  "Table Games Supervisor",
  "Gaming Supervisors",
  "Assistant Teacher",
  "Child Care Worker",
  "Child Caregiver",
  "Childcare Provider",
  "Childcare Worker",
  "Daycare Teacher",
  "Daycare Worker",
  "Childcare Workers",
  "Parts Advisor",
  "Parts Clerk",
  "Parts Consultant",
  "Parts Counter Sales Person",
  "Parts Counterperson",
  "Parts Manager",
  "Parts Person",
  "Parts Salesman",
  "Parts Salesperson",
  "Parts Specialist",
  "Parts Salespersons",
  "Animal Care Giver (ACG)",
  "Aquarist",
  "Dog Bather",
  "Dog Groomer",
  "Groomer",
  "Kennel Attendant",
  "Kennel Technician (Kennel Tech)",
  "Pet Groomer",
  "Pet Stylist",
  "Zookeeper",
  "Nonfarm Animal Caretakers",
  "Bakery Manager",
  "Delicatessen Manager",
  "Department Manager",
  "Department Supervisor",
  "Grocery Manager",
  "Key Carrier",
  "Meat Department Manager",
  "Parts Sales Manager",
  "First-Line Supervisors of Retail Sales Workers",
  "Financial Representative",
  "Investment Consultant",
  "Investment Executive",
  "Investment Representative",
  "Investment Specialist",
  "Stock Broker",
  "Sales Agents, Securities and Commodities",
  "Agility Instructor",
  "Dog Obedience Instructor",
  "Dog Trainer",
  "Guide Dog Instructor",
  "Guide Dog Mobility Instructor (GDMI)",
  "Guide Dog Trainer",
  "Horse Trainer",
  "Racehorse Trainer",
  "Service Dog Trainer",
  "Animal Trainers",
  "Insurance Agent",
  "Insurance Broker",
  "Insurance Sales Agent",
  "Sales Agent",
  "Underwriting Sales Representative",
  "Insurance Sales Agents",
  "Certified Pest Control Technician",
  "Commercial Pest Control Technician",
  "Exterminator",
  "Pest Control Applicator",
  "Pest Control Chemical Technician",
  "Pest Control Operator",
  "Pest Control Technician",
  "Pest Technician",
  "Residential Pest Control Technician",
  "Termite Technician",
  "Pest Control Workers",
  "Bell Captain",
  "Bell Person",
  "Bellhop",
  "Bellman",
  "Bellperson",
  "Doorman",
  "Ground Support Agent",
  "Sky Cap",
  "Skycap",
  "Valet",
  "Baggage Porters and Bellhops",
  "Docent",
  "Event Staff",
  "Lobby Attendant",
  "Ticket Attendant",
  "Ticket Taker",
  "Usher",
  "Visitor Services Assistant",
  "Visitor Services Associate",
  "Visitor Services Representative",
  "Visitor Services Specialist",
  "Ushers, Lobby Attendants, and Ticket Takers",
  "Beautician",
  "Cosmetologist",
  "Hair Dresser",
  "Hair Stylist",
  "Hairdresser",
  "Hairstylist",
  "Manager Stylist",
  "Master Cosmetologist",
  "Hairdressers, Hairstylists, and Cosmetologists",
  "Aesthetician",
  "Clinical Esthetician",
  "Esthetician",
  "Facialist",
  "Lead Esthetician",
  "Medical Esthetician",
  "Skin Care Specialist",
  "Skin Care Technician",
  "Skin Care Therapist",
  "Spa Technician",
  "Skincare Specialists",
  "Activities Assistant",
  "Activity Aide",
  "Program Assistant",
  "Recreation Assistant",
  "Recreation Coordinator",
  "Recreation Supervisor",
  "Recreation Workers",
  "Securities, Commodities, and Financial Services Sales Agents",
  "Booth Manager",
  "Booth Operator",
  "Booth Supervisor",
  "Booth Usher",
  "Motion Picture Projectionist",
  "Movie Projectionist",
  "Projection Technician",
  "Projectionist",
  "Projector Booth Operator",
  "Motion Picture Projectionists",
  "Area Sales Manager",
  "Inside Sales Manager",
  "Manager",
  "Outside Sales Manager",
  "Vice President of Sales",
  "First-Line Supervisors of Non-Retail Sales Workers",
  "Cage Cashier",
  "Cashier",
  "Center Aisle Cashier",
  "Central Aisle Cashier",
  "Checker",
  "Customer Assistant",
  "Mutuel Clerk",
  "Toll Collector",
  "Visitor Service Associate",
  "Cashiers",
  "Assistant Spa Director",
  "Assistant Spa Manager",
  "Associate Spa Director",
  "Director of Spa and Guest Experience",
  "Manager Massage Department",
  "Salon Manager",
  "Salon/Spa Manager",
  "Spa Director",
  "Spa Manager",
  "Spa Manager/Owner and Certified Massage Therapist",
  "Spa Managers",
  "Apprentice Funeral Director",
  "Crematory Operator",
  "Family Services Assistant (FSA)",
  "Funeral Assistant",
  "Funeral Attendant",
  "Funeral Greeter",
  "Funeral Home Assistant",
  "Funeral Home Associate",
  "Funeral Home Attendant",
  "Funeral Service Apprentice",
  "Funeral Attendants",
  "Chemical Applicator",
  "Integrated Pest Management Technician (IPM Technician)",
  "Lawn Specialist",
  "Lawn Technician",
  "Licensed Pesticide Applicator",
  "Pesticide Applicator",
  "Spray Applicator",
  "Spray Technician",
  "Tree and Shrub Technician",
  "Pesticide Handlers, Sprayers, and Applicators, Vegetation",
  "Building Cleaning Workers, All Other",
  "Personal Care and Service Workers, All Other",
  "Cruise Counselor",
  "Guide",
  "Mountain Bike Guide",
  "River Guide",
  "Tour Coordinator",
  "Tour Director",
  "Tour Escort",
  "Tour Manager",
  "Tour Operator",
  "Tours Captain",
  "Travel Guides",
  "Black Jack Dealer",
  "Blackjack Dealer",
  "Card Dealer",
  "Casino Dealer",
  "Dealer",
  "Dual Rate Dealer",
  "Games Dealer",
  "Poker Dealer",
  "Table Games Dealer",
  "Twenty-One Dealer",
  "Gaming Dealers",
  "Arborist",
  "Climber",
  "Ground Worker",
  "Groundsman",
  "Laborer",
  "Line Clearance Foreman",
  "Plant Health Care Technician",
  "Tree Climber",
  "Tree Trimmer",
  "Trimmer",
  "Tree Trimmers and Pruners",
  "Car Salesman",
  "Clerk",
  "Retail Salesperson",
  "Sales Clerk",
  "Sales Consultant",
  "Sales Person",
  "Salesman",
  "Retail Salespersons",
  "Entertainment Attendants and Related Workers, All Other",
  "Canine Deputy (K-9 Deputy)",
  "Chief Deputy Sheriff",
  "Civil Division Deputy Sheriff",
  "Civil Process Server",
  "Corporal-Road Deputy with Sheriff Department",
  "Deputy",
  "Deputy (Patrol)",
  "Deputy Sheriff",
  "Deputy Sheriff (Generalist)-Bailiff",
  "Drug Abuse Resistance Education Officer (DARE Officer)",
  "Sheriffs and Deputy Sheriffs",
  "Adult Family Home Program Manager",
  "Caddymaster",
  "Community Life Director",
  "Direct Care Supervisor",
  "Hair Salon Manager",
  "Hotel Services Supervisor",
  "Resident Care Supervisor",
  "Residential Assistant",
  "First-Line Supervisors of Personal Service Workers",
  "Manicurist",
  "Nail Technician",
  "Pedicurist",
  "Manicurists and Pedicurists",
  "Discovery Guide",
  "Museum Educator",
  "Museum Guide",
  "Science Interpreter",
  "Tour Guide",
  "Tour Guides and Escorts",
  "Activities Attendant",
  "Golf Starter and Ranger",
  "Recreation Aide",
  "Recreation Attendant",
  "Recreation Clerk",
  "Recreation Leader",
  "Ride Operator",
  "Service Representative",
  "Ski Lift Operator",
  "Sports Complex Attendant",
  "Amusement and Recreation Attendants",
  "Key Person",
  "Slot Attendant",
  "Slot Floor Person",
  "Slot Floorperson",
  "Slot Shift Supervisor",
  "Slot Supervisor",
  "Slot Technician",
  "Slot Supervisors",
  "Apprentice Embalmer",
  "Assistant Manager/Embalmer",
  "Associate Embalmer/Funeral Director",
  "Chief Embalmer",
  "Embalmer",
  "Embalmer/Funeral Director",
  "Funeral Director/Embalmer",
  "Funeral Service Licensee",
  "Licensed Embalmer",
  "Preparation Room Manager",
  "Embalmers",
  "Athletic Equipment Manager",
  "Coat Check Attendant",
  "Coat Checker",
  "Coat Room Attendant",
  "Fitting Room Attendant",
  "Ladies Locker Room Attendant",
  "Locker Room Attendant",
  "Locker Room Manager",
  "Locker Room Supervisor",
  "Spa Attendant",
  "Locker Room, Coatroom, and Dressing Room Attendants",
  "Aerobics Instructor",
  "Fitness Instructor",
  "Fitness Specialist",
  "Fitness Trainer",
  "Group Exercise Instructor",
  "Group Fitness Instructor",
  "Personal Trainer",
  "Yoga Instructor",
  "Fitness Trainers and Aerobics Instructors",
  "Hall Coordinator",
  "Residence Director",
  "Residence Hall Director",
  "Residence Life Coordinator",
  "Residence Life Director",
  "Resident Advisor",
  "Resident Director",
  "Residential Coordinator",
  "Residential Life Director",
  "Residential Advisors",
  "Grounds Maintenance Workers, All Other",
  "Activities Concierge",
  "Chef Concierge",
  "Chief Concierge",
  "Club Concierge",
  "Concierge",
  "Conference Concierge",
  "Guest Service Agent",
  "Guest Service Supervisor",
  "Hotel Concierge",
  "Lobby Concierge",
  "Concierges",
  "Counter Clerk",
  "Counter Service Representative",
  "Leasing Consultant",
  "Rental Agent",
  "Rental Associate",
  "Rental Clerk",
  "Rental Counter Clerk",
  "Rental Sales Representative",
  "Rentals Assistant",
  "Video Clerk",
  "Counter and Rental Clerks",
  "Client Manager",
  "Financial Services Representative",
  "Financial Specialist",
  "Investment Officer",
  "Personal Banker",
  "Relationship Banker",
  "Select Banker",
  "Sales Agents, Financial Services",
  "Bingo Clerk",
  "Casino Attendant",
  "Casino Floor Runner",
  "Casino Runner",
  "Floor Runner",
  "Keno Attendant",
  "Keno Writer / Runner",
  "Keno Writer/Runner",
  "Race and Sports Book Writer",
  "Racebook Writer",
  "Gaming and Sports Book Writers and Runners",
  "Home Health Care Provider",
  "Medication Aide",
  "Personal Care Aide",
  "Personal Care Assistant (PCA)",
  "Personal Care Attendant (PCA)",
  "Resident Care Assistant (RCA)",
  "Personal Care Aides",
  "Broker",
  "Corporate Bond Trader",
  "Equity Trader",
  "Fixed Income Director",
  "Fixed Income Trading Vice President",
  "Investment Trader",
  "Option Trader",
  "Options Trader",
  "Securities Lending Trader",
  "Securities and Commodities Traders",
  "Account Development Manager",
  "Channel Sales Director",
  "Distribution Sales Manager",
  "Inside Sales Representative",
  "Marketing Representative",
  "Sales Representatives, Wholesale and Manufacturing, Technical and Scientific Products",
  "Booth Cashier",
  "Casino Banker",
  "Casino Cashier",
  "Change Person",
  "Vault Cashier",
  "Gaming Change Persons and Booth Cashiers",
  "Sales Representatives of Services",
  "Auto Travel Counselor",
  "Beach Expert",
  "Corporate Travel Consultant",
  "Destination Specialist",
  "International Travel Consultant",
  "Tour Counselor",
  "Travel Agent",
  "Travel Consultant",
  "Travel Counselor",
  "Travel Agents",
  "Brand Ambassador",
  "Demo Specialist",
  "Demonstrator",
  "Event Specialist",
  "Field Merchandiser",
  "Food Demonstrator",
  "In Store Demonstrator",
  "Product Ambassador",
  "Product Demonstrator",
  "Demonstrators and Product Promoters",
  "Outside Sales",
  "Outside Sales Representative",
  "Sales",
  "Sales Rep",
  "Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products",
  "Customer Engagement Manager",
  "Product Sales Engineer",
  "Sales Engineer",
  "Sales Engineer, Account Manager",
  "Sales Engineer, Engineered Products",
  "Senior Sales Engineer",
  "Technical Sales Engineer",
  "Sales Engineers",
  "Commercial Sales Manager",
  "Solar Consultant",
  "Solar Sales Representative",
  "Solar Sales Representatives and Assessors",
  "Commodities Broker",
  "Electricity Trader",
  "Energy Scheduler",
  "Energy Trader",
  "Real Time Trader",
  "Senior Energy Market Coordinator",
  "Senior Energy Trader",
  "Senior Power Scheduler",
  "Supply Manager",
  "Energy Brokers",
  "Art Class Model",
  "Art Model",
  "Artist's Model",
  "Fashion Model",
  "Figure Model",
  "Fine Arts Model",
  "Model",
  "Nude Model",
  "Studio Model",
  "Undraped Artist Model",
  "Models",
  "Telemarketer",
  "Telemarketing Sales Representative",
  "Telephone Sales Representative (TSR)",
  "Telephone Service Representative (TSR)",
  "Telesales Representative",
  "Telesales Specialist",
  "Telemarketers",
  "Accounting Assistant",
  "Accounting Clerk",
  "Accounts Payable Clerk",
  "Accounts Receivable Clerk",
  "Administrative Assistant",
  "Biller",
  "Billing Clerk",
  "Billing Coordinator",
  "Billing Specialist",
  "Billing, Cost, and Rate Clerks",
  "CBX Operator",
  "Information Specialist",
  "Operator",
  "PBX Operator (Private Branch Exchange Operator)",
  "Switchboard Operator",
  "Switchboard Operator, Receptionist",
  "Telecommunications Clerk",
  "Telecommunications Operator",
  "Telephone Operator",
  "Switchboard Operators, Including Answering Service",
  "Real Estate Agent",
  "Real Estate Salesperson",
  "Realtor",
  "Realtor Associate",
  "Real Estate Sales Agents",
  "Broker Assistant",
  "Broker Associate",
  "Designated Broker",
  "Managing Broker",
  "Real Estate Associate",
  "Real Estate Broker",
  "Real Estate Sales Associate",
  "Supervising Broker",
  "Real Estate Brokers",
  "411 Directory Assistance Operator",
  "Customer Service Assistant",
  "Directory Assistance Operator",
  "Live Source Operator",
  "Long Distance Operator (LD Operator)",
  "Toll Operator",
  "Telephone Operators",
  "Accounts Payable Associate",
  "Accounts Payable Coordinator",
  "Accounts Receivable Coordinator",
  "Bookkeeper",
  "Cost Accounting Clerk",
  "Billing and Posting Clerks",
  "Cage Manager",
  "Cage Supervisor",
  "Cage/Vault Supervisor",
  "Casino Cage Cashier",
  "Casino Cashier Manager",
  "Gaming Cage Worker",
  "Gaming Cashier",
  "Gaming Cage Workers",
  "Direct Sales Coach",
  "Door-to-Door Sales Trainer",
  "Independent Beauty Consultant",
  "Independent Distributor",
  "Independent Jeweler",
  "Independent Sales Associate",
  "Independent Sales Representative",
  "Street Vendor",
  "Door-To-Door Sales Workers, News and Street Vendors, and Related Workers",
  "Account Administrator",
  "Client Associate",
  "Client Service Associate",
  "Operations Clerk",
  "Operations Coordinator",
  "Registered Account Administrator",
  "Registered Sales Assistant",
  "Sales Assistant",
  "Sales Trader",
  "Trading Assistant",
  "Brokerage Clerks",
  "Financial Clerks, All Other",
  "Business Employment Specialist",
  "Career Consultant",
  "Eligibility Examiner",
  "Eligibility Specialist",
  "Housing Specialist",
  "Medicaid Analyst",
  "Program Eligibility Specialist",
  "Work Force Advisor",
  "Workforce Services Representative (WSR)",
  "Eligibility Interviewers, Government Programs",
  "Circuit Court Clerk",
  "Court Clerk",
  "Court Specialist",
  "Courtroom Clerk",
  "Deputy Clerk of Court",
  "Deputy Court Clerk",
  "Court Clerks",
  "Account Clerk",
  "Accounting Associate",
  "Accounting Specialist",
  "Accounting Technician",
  "Accounts Payable Specialist",
  "Accounts Payables Clerk",
  "Bookkeeping, Accounting, and Auditing Clerks",
  "City Clerk",
  "City Recorder",
  "City Secretary",
  "Deputy City Clerk",
  "Municipal Clerk",
  "Recorder",
  "Town Clerk",
  "Township Clerk",
  "Municipal Clerks",
  "Commercial Credit Reviewer",
  "Commercial Loan Reviewer",
  "Credit Investigator",
  "Credit Processor",
  "Credit Checkers",
  "Account Representative",
  "Call Center Representative",
  "Client Services Representative",
  "Customer Care Representative (CCR)",
  "Customer Service Agent",
  "Customer Service Representative (Customer Service Rep)",
  "Customer Service Specialist",
  "Member Services Representative",
  "Sales Facilitator",
  "Customer Service Representatives",
  "Human Resources Assistant (HR Assistant)",
  "Payroll Administrator",
  "Payroll Assistant",
  "Payroll Clerk",
  "Payroll Coordinator",
  "Payroll Representative",
  "Payroll Specialist",
  "Payroll Technician",
  "Payroll and Timekeeping Clerks",
  "Acquisitions Assistant",
  "Cataloging Assistant",
  "Circulation Supervisor",
  "Library Circulation Assistant",
  "Library Clerical Assistant",
  "Library Services Assistant",
  "Library Assistants, Clerical",
  "Claims Clerk",
  "File Clerk",
  "Manufacturing Clerk",
  "Office Assistant",
  "Police Records Clerk",
  "Records Clerk",
  "File Clerks",
  "Communications Equipment Operators, All Other",
  "Accounts Receivable Specialist (AR Specialist)",
  "Collection Agent",
  "Collection Specialist",
  "Collector",
  "Credit Clerk",
  "Debt Collector",
  "Patient Access Specialist",
  "Patient Account Representative",
  "Telephone Collector",
  "Bill and Account Collectors",
  "Credit Director",
  "Credit Authorizers",
  "Bank Teller",
  "Branch Operations Specialist",
  "Customer Relationship Specialist",
  "Customer Service Associate (CSA)",
  "Financial Services Representative (FSR)",
  "Personal Banking Representative",
  "Roving Teller",
  "Teller",
  "Tellers",
  "Court, Municipal, and License Clerks",
  "Accounts Payable Supervisor",
  "Accounts Receivable Manager",
  "Administrative Supervisor",
  "Customer Service Manager",
  "Customer Service Supervisor",
  "Office Coordinator",
  "Office Supervisor",
  "Staff Services Manager",
  "First-Line Supervisors of Office and Administrative Support Workers",
  "Account Services Representative",
  "Bookkeeping Assistant",
  "Data Processor",
  "Item Processing Clerk",
  "Reconciling Clerk",
  "Statement Clerk",
  "Statement Distribution Clerk",
  "Statement Processor",
  "Statement Services Representative",
  "Statement Clerks",
  "Credit Authorizers, Checkers, and Clerks",
  "Closer",
  "Licensed Loan Officer Assistant",
  "Loan Analyst",
  "Loan Clerk",
  "Loan Closer",
  "Loan Originator",
  "Loan Processor",
  "Mortgage Broker",
  "Mortgage Loan Processor",
  "Mortgage Processor",
  "Loan Interviewers and Clerks",
  "Procurement Assistant",
  "Procurement Technician",
  "Purchasing Assistant",
  "Purchasing Associate",
  "Purchasing Clerk",
  "Purchasing Specialist",
  "Warehouse Clerk",
  "Procurement Clerks",
  "Dealer Support Technician",
  "License and Permit Specialist",
  "License Clerk",
  "Licensing Specialist",
  "Motor Vehicle Field Representative (MVFR)",
  "Motor Vehicle License Clerk",
  "Motor Vehicle Representative",
  "Permit Specialist",
  "Program Technician",
  "License Clerks",
  "Admissions Coordinator",
  "Medicaid Service Coordinator (MSC)",
  "Patient Advocate",
  "Patient Representative",
  "Service Coordinator",
  "Patient Representatives",
  "Sales and Related Workers, All Other",
  "Chargeback Specialist",
  "Claims Correspondence Clerk",
  "Correspondence Clerk",
  "Correspondence Coordinator",
  "Correspondence Representative",
  "Correspondent",
  "Dispute Resolution Analyst",
  "Dispute Specialist",
  "Office Technician",
  "Technical Clerk",
  "Correspondence Clerks",
  "Desk Clerk",
  "Front Desk Agent",
  "Front Desk Associate",
  "Front Desk Clerk",
  "Front Desk Supervisor",
  "Front Office Agent",
  "Guest Service Representative",
  "Guest Services Agent (GSA)",
  "Night Auditor",
  "Hotel, Motel, and Resort Desk Clerks",
  "Human Resources Administrative Assistant (HR Administrative Assistant)",
  "Human Resources Administrator",
  "Human Resources Associate (HR Associate)",
  "Human Resources Coordinator (HR Coordinator)",
  "Human Resources Generalist (HR Generalist)",
  "Human Resources Representative (HR Representative)",
  "Human Resources Technician (HR Technician)",
  "Personnel Assistant",
  "Personnel Clerk",
  "Human Resources Assistants, Except Payroll and Timekeeping",
  "Admissions Clerk",
  "Admissions Representative",
  "Admitting Clerk",
  "Interviewer",
  "Market Research Interviewer",
  "Patient Services Representative",
  "Registration Clerk",
  "Interviewers, Except Eligibility and Loan",
  "Airline Ticket Agent",
  "Airport Sales Agent",
  "Reservation Agent",
  "Reservationist",
  "Reservations Agent",
  "Reservations and Ticketing Agent",
  "Station Agent",
  "Ticket Agent",
  "Tour Sales Representative",
  "Reservation and Transportation Ticket Agents and Travel Clerks",
  "Clerk Specialist",
  "Community Liaison",
  "Front Desk Receptionist",
  "Member Service Representative",
  "Receptionist",
  "Scheduler",
  "Senior Receptionist",
  "Unit Assistant",
  "Receptionists and Information Clerks",
  "Claim Processing Specialist",
  "Claim Service Representative",
  "Claim Technician",
  "Claims Customer Service Representative (Claims CSR)",
  "Claims Processor",
  "Claims Service Representative",
  "Claims Technician",
  "Insurance Claims Clerks",
  "Computer Typesetter",
  "Desktop Publishing Specialist",
  "Electronic Console Display Operator",
  "Electronic Imager",
  "Mac Operator",
  "Desktop Publishers",
  "Farm Laborer",
  "Farm Worker",
  "Field Irrigation Worker",
  "Field Worker",
  "Harvester",
  "Hired Hand",
  "Hoe Worker",
  "Irrigator",
  "Orchard Worker",
  "Picker",
  "Farmworkers and Laborers, Crop",
  "Cowboy",
  "Farm Hand",
  "Farrowing Worker",
  "Herdsman",
  "Livestock Handler",
  "Milking Worker",
  "Ranch Hand",
  "Vaccinator",
  "Wrangler",
  "Farmworkers, Farm, Ranch, and Aquacultural Animals",
  "Bulk Mail Technician",
  "Distribution Clerk",
  "Part Time Flexible Clerk (PTF Clerk)",
  "Postal Clerk",
  "Sales & Service Associate (SSA)",
  "Sales and Distribution Clerk",
  "Sales and Service Associate (SSA)",
  "Window Clerk",
  "Window/Distribution Clerk",
  "Postal Service Clerks",
  "Crew Foreman",
  "Crew Leader",
  "Crew Supervisor",
  "Feller Buncher Operator (FB Operator)",
  "Harvesting Supervisor",
  "Loader Operator Supervisor",
  "Logging Crew Foreman",
  "Logging Crew Supervisor",
  "Logging Supervisor",
  "Trucking Supervisor",
  "First-Line Supervisors of Logging Workers",
  "Information and Record Clerks, All Other",
  "Fulfillment Representative",
  "Inventory Specialist",
  "Order Filler",
  "Order Picker",
  "Order Puller",
  "Packer",
  "Parts Processor",
  "Parts Technician",
  "Picker/Puller",
  "Puller",
  "Order Fillers, Wholesale and Retail Sales",
  "Master Scheduler",
  "Material Coordinator",
  "Materials Planner",
  "Production Clerk",
  "Production Controller",
  "Production Scheduler",
  "Production, Planning, and Expediting Clerks",
  "New Accounts Representative",
  "Retail Banker",
  "Retail Service Representative",
  "New Accounts Clerks",
  "Hub Associate",
  "Materials Specialist",
  "Order Analyst",
  "Order Clerk",
  "Order Entry Administrator",
  "Order Entry Representative",
  "Order Processing Clerk",
  "Order Taker",
  "Order Clerks",
  "Data Capture Specialist",
  "Data Entry Clerk",
  "Data Entry Machine Operator",
  "Data Entry Operator",
  "Data Entry Specialist",
  "Data Transcriber",
  "Fiscal Assistant",
  "Typist",
  "Underwriting Support Specialist",
  "Data Entry Keyers",
  "Cycle Counter",
  "Fluid Operator",
  "Material Control Manager",
  "Quality Assurance Inspector (QA Inspector)",
  "Quality Assurance Lab Technician",
  "Quality Control Technician",
  "Scale Operator",
  "Supply Clerk",
  "Temperature Taker",
  "Weighers, Measurers, Checkers, and Samplers, Recordkeeping",
  "Stock Clerks and Order Fillers",
  "Insurance Claims and Policy Processing Clerks",
  "Insert Operator",
  "Inserter Operator",
  "Mail Clerk",
  "Mail Handler",
  "Mail Machine Operator",
  "Mail Processor",
  "Mail Reader",
  "Mail Sorter",
  "Mailroom Supervisor",
  "Mail Clerks and Mail Machine Operators, Except Postal Service",
  "Copy Center Operator",
  "Copy Machine Operator",
  "Copy Technician",
  "Graphic Art Technician",
  "Graphics Production Specialist",
  "Key Operator",
  "Machine Operator",
  "Print Shop Assistant",
  "Printing Services Coordinator",
  "Reprographics Technician",
  "Office Machine Operators, Except Computer",
  "Agricultural Workers, All Other",
  "Farmworkers and Laborers, Crop, Nursery, and Greenhouse",
  "Animal Care Supervisor",
  "Animal Caretaker Supervisor",
  "Broiler Supervisor",
  "Cattle Manager",
  "Facility Supervisor",
  "Feed Manager",
  "Horse Farm Manager",
  "Research Animal Facility Supervisor",
  "First-Line Supervisors of Animal Husbandry and Animal Care Workers",
  "Cargo Agent",
  "Documentation Clerk",
  "Drop Shipment Clerk",
  "Freight Broker",
  "Intermodal Dispatcher",
  "International Coordinator",
  "Load Planner",
  "Logistics Coordinator",
  "Logistics Service Representative",
  "Cargo and Freight Agents",
  "Administrative Aide",
  "Administrative Associate",
  "Administrative Secretary",
  "Executive Administrative Assistant",
  "Executive Assistant",
  "Executive Secretary",
  "Executive Secretaries and Executive Administrative Assistants",
  "Clam Digger",
  "Commercial Crabber",
  "Commercial Fisherman",
  "Commercial Fishing Vessel Operator",
  "Crew Member",
  "Deckhand",
  "Fisherman",
  "Lobsterman",
  "Menhaden Fishing Crew Member",
  "Fishers and Related Fishing Workers",
  "Confidential Secretary",
  "Coordinating Legal Practice Assistant",
  "Judicial Administrative Assistant",
  "Legal Administrative Assistant",
  "Legal Administrative Secretary",
  "Legal Coordinator",
  "Legal Office Support Assistant",
  "Legal Secretary",
  "Magistrate Assistant",
  "Secretary",
  "Legal Secretaries",
  "City Carrier",
  "City Carrier Assistant (CCA)",
  "City Letter Carrier",
  "City Mail Carrier",
  "Letter Carrier",
  "Mail Carrier",
  "Rural Carrier",
  "Rural Carrier Associate (RCA)",
  "Rural Mail Carrier",
  "Rural Route Carrier",
  "Postal Service Mail Carriers",
  "Air Export Agent",
  "Air Export Coordinator",
  "Export Coordinator",
  "GSA Coordinator",
  "Ocean Export Coordinator",
  "Ocean Forwarder",
  "Relocation Coordinator",
  "Route Specialist",
  "Special Services Agent",
  "Freight Forwarders",
  "First-Line Supervisors of Farming, Fishing, and Forestry Workers",
  "Office and Administrative Support Workers, All Other",
  "Agriculture Laborer",
  "Apple Sorter",
  "Corn Lab Technician",
  "Distribution Technician",
  "Egg Grader",
  "Egg Worker",
  "Grader",
  "Potato Grader",
  "Potato Sorter",
  "Sorter",
  "Graders and Sorters, Agricultural Products",
  "Administrative Assistant (Admin Assistant)",
  "Administrative Clerk",
  "Administrative Secretary (Admin Secretary)",
  "Administrative Specialist (Admin Specialist)",
  "Administrative Support Assistant (ASA)",
  "Administrative Technician",
  "Department Secretary",
  "Staff Assistant",
  "Secretaries and Administrative Assistants, Except Legal, Medical, and Executive",
  "Brood Hatchery Manager",
  "Fish Culture Supervisor",
  "Fish Farm Manager",
  "Fisheries Manager",
  "Fisheries Technician Supervisor",
  "Marine Site Manager",
  "Wildlife Technician Supervisor",
  "First-Line Supervisors of Aquacultural Workers",
  "Clerk Typist",
  "Keyboard Specialist",
  "Management Services Technician",
  "Principal Clerk Typist",
  "Project Assistant",
  "Word Processor",
  "Word Processors and Typists",
  "Agency Service Representative",
  "Client Process Specialist",
  "Enrollment Representative",
  "Insurance Analyst",
  "Policy Service Coordinator",
  "Policy Services Representative",
  "Premium Representative",
  "Processing Clerk",
  "Insurance Policy Processing Clerks",
  "Automation Clerk",
  "Computer Forwarding System Markup Clerk (CFS Markup Clerk)",
  "Flat Sorting Machine Clerk (FSM Clerk)",
  "Mail Handler Equipment Operator",
  "Mail Processing Clerk",
  "Parcel Post Distribution Machine Operator (PDPMO)",
  "Small Package and Bundle Sorter Clerk (SPBS Clerk)",
  "Postal Service Mail Sorters, Processors, and Processing Machine Operators",
  "Checker Stocker",
  "Checker/Stocker",
  "Dairy Clerk",
  "Grocery Clerk",
  "Night Stocker",
  "Sales Support Specialist",
  "Stock Clerk",
  "Stocker",
  "Store Clerk",
  "Stock Clerks, Sales Floor",
  "Administrative Clerk (Admin Clerk)",
  "Clerical Aide",
  "Clerical Assistant",
  "General Clerk",
  "Office Clerk",
  "Office Services Specialist",
  "Office Support Assistant",
  "Office Clerks, General",
  "Aircraft Dispatcher",
  "City Dispatcher",
  "Dispatch Manager",
  "Dispatcher (Dispatch)",
  "Motor Coach Supervisor",
  "Operations Dispatcher",
  "Rail Operations Controller",
  "School Bus Dispatcher",
  "Train Dispatcher",
  "Truck Dispatcher",
  "Dispatchers, Except Police, Fire, and Ambulance",
  "911 Dispatcher",
  "Communications Officer",
  "Communications Operator",
  "Communications Supervisor",
  "Dispatcher",
  "Emergency Communications Operator (ECO)",
  "Police Dispatcher",
  "Public Safety Dispatcher",
  "Telecommunicator",
  "Police, Fire, and Ambulance Dispatchers",
  "Health Unit Coordinator",
  "Medical Office Specialist",
  "Patient Coordinator",
  "Physician Office Specialist",
  "Unit Secretary",
  "Unit Support Representative",
  "Ward Clerk",
  "Medical Secretaries",
  "Content Editor",
  "Copy Chief",
  "Copy Editor",
  "Copyholder",
  "Desk Editor",
  "Editorial Assistant",
  "News Copy Editor",
  "Proofer",
  "Proofreader",
  "Typesetter",
  "Proofreaders and Copy Markers",
  "Assistant Research Scientist",
  "Bioinformatics Analyst",
  "Bioinformatics Developer",
  "Bioinformatics Specialist",
  "Bioinformatics Technician",
  "Biotechnician",
  "Informatics Developer",
  "Museum Informatics Specialist",
  "Scientific Informatics Analyst",
  "Bioinformatics Technicians",
  "Actuarial Assistant",
  "Actuarial Technician",
  "Assistant Statistician",
  "Statistical Clerk",
  "Statistical Technician",
  "Statistical Assistants",
  "Material Control Associate",
  "Order Fulfillment Specialist",
  "Receiver",
  "Receiving Associate",
  "Receiving Clerk",
  "Receiving Coordinator",
  "Shipper",
  "Shipping Clerk",
  "Shipping Coordinator",
  "Traffic Assistant",
  "Shipping, Receiving, and Traffic Clerks",
  "In Store Marketing Associate (ISM Associate)",
  "Inventory and Pricing Associate",
  "Label Maker",
  "Marking Clerk",
  "Pricing Associate",
  "Scan Coordinator",
  "Ticketer",
  "Warehouse Pricing and Inventory Clerk",
  "Marking Clerks",
  "Animal Technician",
  "Artificial Insemination Technician (AI Technician)",
  "Artificial Inseminator",
  "Breeder",
  "Breeding Manager",
  "Broodmare Foreman",
  "Dog Breeder",
  "Donor Manager",
  "Large Herd Specialist",
  "Stallion Manager",
  "Animal Breeders",
  "Courier",
  "Driver",
  "Laboratory Courier",
  "Mailroom Courier",
  "Messenger",
  "Security Messenger",
  "Vehicle Delivery Worker",
  "Couriers and Messengers",
  "Bay Stocker",
  "Material Handler",
  "Receiving Lead",
  "Stockroom Clerk",
  "Warehouse Representative",
  "Warehouse Worker",
  "Stock Clerks- Stockroom, Warehouse, or Storage Yard",
  "Field Technician",
  "Fieldman",
  "Meter Reader",
  "Meter Reader Inspector",
  "Meter Reading Clerk",
  "Meter Technician",
  "Utility Service Worker",
  "Water Inspector",
  "Water Meter Reader",
  "Water Use Inspector",
  "Meter Readers, Utilities",
  "Computer Console Operator",
  "Computer Operator",
  "Control Room Operator",
  "Desktop Engineer",
  "Information Technology Associate",
  "Operations and Maintenance Technician",
  "Software Technician",
  "Computer Operators",
  "Brand Inspector",
  "Consumer Safety Inspector (CSI)",
  "Deputy Brand Inspector",
  "Food Inspector",
  "Food Sanitarian",
  "Grain Inspector",
  "Inspector, Food Safety and Inspection Service (Inspector, FSIS)",
  "Seed and Fertilizer Specialist",
  "Shipping Point Inspector",
  "Agricultural Inspectors",
  "Farm Owner Operator",
  "Field Operations Farm Manager",
  "Harvest Supervisor",
  "Head Grower",
  "Orchard Manager",
  "Pest Management Supervisor",
  "Supervisor Grower",
  "Team Foreman",
  "First-Line Supervisors of Agricultural Crop and Horticultural Workers",
  "Mason",
  "Stone Derrickman and Rigger",
  "Stone Mason",
  "Stone Setter",
  "Stonemasons",
  "Floor Covering Contractor",
  "Floor Coverings Installer",
  "Floor Layer",
  "Flooring Helper",
  "Flooring Installer",
  "Flooring Mechanic",
  "Tile Installer",
  "Tile Setter",
  "Vinyl Installer",
  "Floor Layers, Except Carpet, Wood, and Hard Tiles",
  "Greenhouse Staff",
  "Greenhouse Worker",
  "Nursery Worker",
  "Plant Production Worker",
  "Propagation Worker",
  "Transplant Worker",
  "Nursery Workers",
  "Grinder",
  "Installer",
  "Terrazzo Finisher",
  "Terrazzo Grinder",
  "Terrazzo Installer",
  "Terrazzo Journeyman",
  "Terrazzo Laborer",
  "Terrazzo Mechanic",
  "Terrazzo Tile Setter",
  "Terrazzo Worker",
  "Terrazzo Workers and Finishers",
  "Baler Operator",
  "Cutter Operator",
  "Farm Equipment Operator",
  "Hay Baler",
  "Loader Operator",
  "Packing Tractor Machine Operator",
  "Rake Operator",
  "Sprayer",
  "Windrower Operator",
  "Agricultural Equipment Operators",
  "Construction Supervisor",
  "Foreman",
  "Site Superintendent",
  "Supervisor",
  "First-Line Supervisors of Construction Trades and Extraction Workers",
  "Animal Damage Control Agent",
  "Fur Trapper",
  "Hunter",
  "Hunting Guide",
  "Nuisance Wildlife Control Operator",
  "Nuisance Wildlife Trapper",
  "Predator Control Trapper",
  "Trapper",
  "Urban Wildlife Damage Control Specialist",
  "Wildlife Control Operator",
  "Hunters and Trappers",
  "Block Layer",
  "Block Mason",
  "Blockmason",
  "Brick and Block Mason",
  "Brick Mason",
  "Bricklayer",
  "Concrete Finisher",
  "Masonry Installer",
  "Tender",
  "Brickmasons and Blockmasons",
  "Log Buyer",
  "Log Check Scaler",
  "Log Grader",
  "Log Scaler",
  "Log Yard Manager",
  "Lumber Grader",
  "Procurement Forester",
  "Scaler",
  "Timber Buyer",
  "Log Graders and Scalers",
  "Attic Blower",
  "Insulation Estimator",
  "Insulation Installer",
  "Insulation Mechanic",
  "Insulation Worker",
  "Insulator",
  "Retrofit Installer",
  "Spray Foam Installer",
  "Warehouse Insulation Worker",
  "Insulation Workers, Floor, Ceiling, and Wall",
  "Chief Electrician",
  "Control Electrician",
  "Electrician",
  "Industrial Electrician",
  "Inside Wireman",
  "Journeyman Electrician",
  "Journeyman Wireman",
  "Maintenance Electrician",
  "Mechanical Trades Specialist, Electrician",
  "Qualified Craft Worker, Electrician (QCW, Electrician)",
  "Electricians",
  "Automobile Glass Technician",
  "Commercial Glazier",
  "Glass Installer",
  "Glass Technician",
  "Glazer",
  "Glazier",
  "Glazing Superintendent",
  "Journeyman Glazier",
  "Master Glazier",
  "Glaziers",
  "Pile Driver",
  "Pile Driver Foreman",
  "Pile Driver Operator",
  "Pile Driving Operator",
  "Pile-Driver Operators",
  "Carpet Installer",
  "Carpet Installer Helper",
  "Carpet Layer",
  "Carpet Mechanic",
  "Commercial Floor Covering Installer",
  "Floor Coverer",
  "Floor Covering Installer",
  "Floor Installation Mechanic",
  "Carpet Installers",
  "Logger",
  "Sawyer",
  "Timber Cutter",
  "Timber Faller",
  "Tree Faller",
  "Tree Feller",
  "Tree Topper",
  "Fallers",
  "Equipment Operator (EO)",
  "Highway Maintainer",
  "Highway Maintenance Crew Worker",
  "Highway Maintenance Technician",
  "Highway Maintenance Worker",
  "Maintenance Worker",
  "Transportation Maintenance Operator",
  "Transportation Maintenance Specialist (TMS)",
  "Transportation Worker",
  "Highway Maintenance Workers",
  "Ceramic Tile Mechanic",
  "Ceramic Tile Setter",
  "Marble Mason",
  "Tile and Marble Installer",
  "Tile and Marble Setter",
  "Tile Finisher",
  "Tile Mason",
  "Tile Mechanic",
  "Tile and Marble Setters",
  "Ceiling Installer",
  "Dry Wall Installer",
  "Drywall Finisher",
  "Drywall Hanger",
  "Drywall Installer",
  "Drywall Mechanic",
  "Drywaller",
  "Exterior Interior Specialist",
  "Metal Framer",
  "Metal Stud Framer",
  "Drywall and Ceiling Tile Installers",
  "Logging Workers, All Other",
  "Applicator",
  "Artisan Plasterer",
  "Plaster and Stucco Worker",
  "Plaster Applicator",
  "Plaster Foreman",
  "Plaster Mechanic",
  "Plasterer",
  "Plasterer Foreman",
  "Plasterer Journeyman",
  "Plastering Contractor",
  "Plasterers and Stucco Masons",
  "Asphalt Paver Operator",
  "Asphalt Raker",
  "Maintenance Equipment Operator (MEO)",
  "Paver Operator",
  "Roller Operator",
  "Screed Operator",
  "Truck Driver",
  "Paving, Surfacing, and Tamping Equipment Operators",
  "Construction Laborer",
  "Construction Worker",
  "Curb and Gutter Laborer",
  "Drain Layer",
  "Drop Crew Laborer",
  "Helper",
  "Post Framer",
  "Skill Labor",
  "Union Laborer",
  "Construction Laborers",
  "Cement Finisher",
  "Cement Mason",
  "Concrete Mason",
  "Finisher",
  "Cement Masons and Concrete Finishers",
  "Apprentice Carpenter",
  "Bridge Carpenter",
  "Bridge Repair Crew Person",
  "Carpenter",
  "Form Carpenter",
  "Journeyman Carpenter",
  "Rough Carpenter",
  "Union Carpenter",
  "Rough Carpenters",
  "Back Hoe Operator",
  "Engineering Equipment Operator",
  "Heavy Equipment Operator",
  "Motor Grader Operator",
  "Operating Engineer",
  "Track Hoe Operator",
  "Operating Engineers and Other Construction Equipment Operators",
  "Boiler Maker",
  "Boiler Mechanic",
  "Boiler Repairman",
  "Boiler Service Technician",
  "Boiler Technician",
  "Boilermaker",
  "Boilermaker Mechanic",
  "Boilermaker Pipe Fitter",
  "Boilermaker Welder",
  "Boilermakers",
  "Carpenters",
  "Fitter",
  "Fitter / Welder",
  "Iron Worker",
  "Iron Worker Foreman",
  "Ironworker",
  "Rigger",
  "Steel Fabricator",
  "Steel Worker",
  "Structural Steel Erector",
  "Tower Hand",
  "Structural Iron and Steel Workers",
  "Cable Puller",
  "Electrician Helper",
  "Electrician's Helper",
  "Helpers--Electricians",
  "Rail Maintenance Worker",
  "Track Equipment Operator (TEO)",
  "Track Inspector",
  "Track Laborer",
  "Track Maintainer",
  "Track Repairer",
  "Track Supervisor",
  "Track Walker",
  "Trackman",
  "Rail-Track Laying and Maintenance Equipment Operators",
  "Branch Operations Manager",
  "Crew Lead",
  "Installation Manager",
  "Installation Technician",
  "Residential Field Manager",
  "Solar Energy Installation Manager",
  "Solar Installation Manager",
  "Solar Installation Supervisor",
  "Solar Energy Installation Managers",
  "Delimber Operator",
  "Feller Buncher Operator",
  "Harvester Operator",
  "Log Processor Operator",
  "Logging Equipment Operator",
  "Logging Shovel Operator",
  "Skidder Driver",
  "Skidder Operator",
  "Yarder Operator",
  "Logging Equipment Operators",
  "Floor Finisher",
  "Floor Mechanic",
  "Floor Refinisher",
  "Floor Sander",
  "Hardwood Floor Finisher",
  "Hardwood Floor Refinisher",
  "Hardwood Floor Sander",
  "Floor Sanders and Finishers",
  "Conservation Officer",
  "Field Laborer",
  "Forest Resource Specialist",
  "Forestry Support Specialist",
  "Park Maintainer",
  "Reforestation Worker",
  "Tree Planter",
  "Forest and Conservation Workers",
  "Drywall Finisher Foreman",
  "Drywall Finishing Foreman",
  "Drywall Foreman",
  "Drywall Taper",
  "Taper",
  "Taper/Finisher",
  "Taping Foreman",
  "Tapers",
  "Facilities Painter",
  "Highway Painter",
  "House Painter",
  "Industrial Painter",
  "Journeyman Painter",
  "Maintenance Painter",
  "Painter Foreman",
  "Senior Painter",
  "Painters, Construction and Maintenance",
  "Assembler",
  "Cabinet Maker",
  "Carpenter Foreman",
  "Carpentry Foreman",
  "Concrete Carpenter",
  "Framer",
  "Production Worker",
  "Construction Carpenters",
  "Commercial Plumber",
  "Drain Cleaner, Plumber",
  "Drain Technician",
  "Journeyman Plumber",
  "Master Plumber",
  "Plumber",
  "Plumber Gasfitter",
  "Plumbing and Heating Mechanic",
  "Residential Plumber",
  "Service Plumber",
  "Plumbers",
  "Plumbers, Pipefitters, and Steamfitters",
  "Blast Hole Driller",
  "Diamond Driller",
  "Drill Operator",
  "Driller",
  "Hard Rock Drill Operator",
  "Highwall Drill Operator",
  "Rock Drill Operator",
  "Underground Drill Operator",
  "Water Well Driller",
  "Well Driller",
  "Earth Drillers, Except Oil and Gas",
  "Freight Maintenance Specialist",
  "Locomotive Repairman",
  "Rail Car Maintenance Mechanic",
  "Rail Car Mechanic",
  "Rail Car Repairer",
  "Rail Car Repairman",
  "Rail Car Sandblaster",
  "Rail Car Welder",
  "Railroad Car Repairman",
  "Train Car Repairman",
  "Rail Car Repairers",
  "ASE Master Mechanic (Automotive Service Excellence Master Mechanic)",
  "Auto Technician (Automotive Technician)",
  "Automotive Drivability Technician",
  "Automotive Mechanic (Auto Mechanic)",
  "Automotive Service Technician",
  "Certified ASE Master Automotive Technician (Certified Automotive Service Excellence Master Automotive Technician)",
  "Master Automotive Technician",
  "Master Technician",
  "Mechanic",
  "Transmission Rebuilder",
  "Automotive Master Mechanics",
  "Bus Mechanic",
  "Diesel Mechanic",
  "Diesel Technician",
  "Fleet Mechanic",
  "General Repair Mechanic",
  "Trailer Mechanic",
  "Transit Mechanic",
  "Truck Mechanic",
  "Bus and Truck Mechanics and Diesel Engine Specialists",
  "Solar Energy Technician",
  "Solar Hot Water Installer (SHW Installer)",
  "Solar Installer",
  "Solar Maintenance Technician",
  "Solar System Installer",
  "Solar Technician",
  "Solar Thermal Installer",
  "Solar Thermal Installers and Technicians",
  "Control Technician",
  "Electrical and Instrument Mechanic",
  "Electrical and Instrument Technician (E&I Tech)",
  "Electrical Maintenance Technician",
  "I&C Tech (Instrument and Control Technician)",
  "Instrument and Electrical Technician (I&E Tech)",
  "Repair Technician",
  "Electrical and Electronics Repairers, Commercial and Industrial Equipment",
  "Exterior Insulation and Finish System Installer (EIFS Installer)",
  "Painter Helper",
  "Plaster Helper",
  "Plaster Tender",
  "Scaffold Setter",
  "Stucco Laborer",
  "Wallboard Worker",
  "Helpers--Painters, Paperhangers, Plasterers, and Stucco Masons",
  "Pulling Unit Operator",
  "Reverse Unit Operator-Fisherman",
  "Rig Operator",
  "Service Operator",
  "Service Rig Operator",
  "Tool Pusher",
  "Well Servicing Rig Operator",
  "Wireline Operator",
  "Service Unit Operators, Oil, Gas, and Mining",
  "Lubricator",
  "Machine Repairer",
  "Maintainer",
  "Maintenance Man",
  "Oiler",
  "Overhauler",
  "Maintenance Workers, Machinery",
  "Bore Miner Operator",
  "Continuous Miner",
  "Continuous Miner Operator (CMO)",
  "Continuous Mining Machine Operator",
  "Continuous Mining Operator (CMO)",
  "Mine Technician",
  "Mine Utility Operator",
  "Miner Operator",
  "Continuous Mining Machine Operators",
  "Bolt Machine Operator",
  "Bolt Man",
  "Bolter",
  "Miner",
  "Place Change Roof Bolter",
  "Roof Bolter",
  "Roof Bolter Operator",
  "Underground Miner",
  "Underground Roof Bolter",
  "Roof Bolters, Mining",
  "Commercial Door Installer",
  "Commercial Installer",
  "Door Installer",
  "Door Technician",
  "Garage Door Installer",
  "Garage Door Technician",
  "Residential Door Installer",
  "Mechanical Door Repairers",
  "Heating, Air Conditioning, and Refrigeration Mechanics and Installers",
  "Maintenance Mechanic",
  "Maintenance Millwright",
  "Mechanical Superintendent",
  "Millwright",
  "Millwright Business Representative",
  "Millwright Instructor",
  "Precision Millwright",
  "Millwrights",
  "HVAC / R Technician (Heating, Ventilation, Air Conditioning / Refrigeration Technician)",
  "HVAC Service Technician (Heating, Ventilation, Air Conditioning Service Technician)",
  "HVAC Tech (Heating, Ventilation, Air Conditioning Service Technician)",
  "HVAC Technician (Heating, Ventilation, Air Conditioning Technician)",
  "HVAC/R Service Technician (Heating, Ventilation, and Air Conditioning/Refrigeration Service Technician)",
  "Refrigeration Mechanic",
  "Refrigeration Operator",
  "Refrigeration Technician (Refrigeration Tech)",
  "Service Technician (Service Tech)",
  "Transportation Refrigeration Technician",
  "Refrigeration Mechanics and Installers",
  "Carpenter Assistant, Installer",
  "Carpenter Helper",
  "Carpenter's Helper",
  "Carpenter/Labor",
  "Carpentry",
  "Drywall Hanger, Framer",
  "Form Setter",
  "Form Setter/Driver",
  "Framing and Hanging",
  "Hanger",
  "Helpers--Carpenters",
  "Building Code Administrator",
  "Building Inspection Engineer",
  "Building Inspector",
  "Building Official",
  "Combination Building Inspector",
  "Construction Inspector",
  "Construction Materials Testing Technician",
  "Elevator Inspector",
  "Plumbing Inspector",
  "Construction and Building Inspectors",
  "Floor Hand",
  "Galley Hand",
  "Oil Field Roustabout",
  "Production Roustabout",
  "Rig Hand",
  "Roustabout",
  "Roustabout Crew Leader",
  "Roustabout Crew Pusher",
  "Roustabout Hand",
  "Roustabout Pusher",
  "Roustabouts, Oil and Gas",
  "Electric Motor Mechanic",
  "Electric Motor Repairman",
  "Electric Motor Winder",
  "Electro Mechanic",
  "Power Tool Repair Technician",
  "Tool Repair Technician",
  "Tool Technician",
  "Electric Motor, Power Tool, and Related Repairers",
  "Mining Machine Operators, All Other",
  "Blaster",
  "Explosive Technician",
  "Powderman",
  "Unexploded Ordnance Quality Control Officer",
  "Explosives Workers, Ordnance Handling Experts, and Blasters",
  "Critical Systems Technician",
  "Electronic Bench Technician",
  "Electronics Mechanic",
  "Locomotive Electrician",
  "Power Technician (Power Tech)",
  "Ship Yard Electrical Person",
  "Electrical and Electronics Installers and Repairers, Transportation Equipment",
  "Bicycle Fitter",
  "Bicycle Mechanic",
  "Bicycle Repair Technician",
  "Bicycle Repairman",
  "Bicycle Service Technician",
  "Bicycle Technician",
  "Bike Mechanic",
  "Bike Shop Manager",
  "Head Mechanic",
  "Bicycle Repairers",
  "Coal Miner",
  "Longwall Shearer Operator",
  "Shear Operator",
  "Shearer Operator",
  "Underground Heavy Equipment Operator",
  "Mine Cutting and Channeling Machine Operators",
  "Field Service Representative",
  "Home Theater Installer",
  "Low Voltage Electrician",
  "Satellite Installer",
  "Television Analyzer",
  "Television Repairman",
  "Electronic Home Entertainment Equipment Installers and Repairers",
  "Construction Equipment Mechanic",
  "Equipment Mechanic",
  "Equipment Technician",
  "Field Mechanic",
  "Heavy Equipment Mechanic",
  "Heavy Equipment Technician",
  "Mobile Heavy Equipment Mechanic",
  "Mobile Heavy Equipment Mechanics, Except Engines",
  "Derrick Hand",
  "Derrick Man",
  "Derrick Operator",
  "Derrick Worker",
  "Derrick Operators, Oil and Gas",
  "Automotive Technician",
  "Car Audio Installer",
  "Car Electronics Installer",
  "Car Stereo Installer",
  "Electronic Equipment Installer",
  "Mobile Electronics Installation Specialist",
  "Mobile Electronics Installer",
  "Electronic Equipment Installers and Repairers, Motor Vehicles",
  "Photovoltaic Installer (PV Installer)",
  "PV Design and Installation Technician",
  "Solar Designer/Installer",
  "Solar Installer Technician",
  "Solar Photovoltaic Installer (Solar PV Installer)",
  "Solar Photovoltaic Installers",
  "Broadband Technician",
  "Central Office Technician",
  "Combination Technician",
  "Customer Service Technician (CST)",
  "Install and Repair Technician",
  "Outside Plant Technician",
  "Telecommunications Technician",
  "Telecommunications Equipment Installers and Repairers, Except Line Installers",
  "Bricker",
  "Cell Reliner",
  "Cupola Repairer",
  "Furnace Repairer",
  "Hot Repairman",
  "Ladle Liner",
  "Ladle Repairman",
  "Refractory Bricklayer",
  "Refractory Technician",
  "Refractory Worker",
  "Refractory Materials Repairers, Except Brickmasons",
  "Brick and Blocker Aid, Labor",
  "Bricklayer Helper",
  "Helper, Marble Finisher",
  "Hod Carrier",
  "Lead Mason Tender",
  "Mason Tender",
  "Mason Tender, Restoration Labor",
  "Mortar Mixer",
  "Tender, Labor",
  "Helpers--Brickmasons, Blockmasons, Stonemasons, and Tile and Marble Setters",
  "Daylight Driller",
  "Drill Foreman",
  "Drilling Rig Operator",
  "Motor Man",
  "Oil Rig Driller",
  "Oil Well Driller",
  "Relief Driller",
  "Rotary Drill Operators, Oil and Gas",
  "Extraction Workers, All Other",
  "Hitch Technician",
  "Master Certified RV Technician (Master Certified Recreational Vehicle Technician)",
  "Mobile Service RV Technician (Mobile Service Recreational Vehicle Technician)",
  "RV Body Mechanic (Recreational Vehicle Body Mechanic)",
  "RV Parts and Service Director (Recreational Vehicle Parts and Service Director)",
  "RV Repair Technician (Recreational Vehicle Repair Technician)",
  "RV Service Technician (Recreational Vehicle Service Technician)",
  "RV Technician (Recreational Vehicle Technician)",
  "RVDA Master Certified RV Technician (Recreational Vehicle Dealer Association Master Certified Recreational Vehicle Technician)",
  "Recreational Vehicle Service Technicians",
  "Boat Mechanic",
  "Boat Motor Mechanic",
  "Boat Rigger",
  "Marine Mechanic",
  "Marine Propulsion Technician",
  "Marine Technician",
  "Outboard Motor Mechanic",
  "Outboard Technician",
  "Motorboat Mechanics and Service Technicians",
  "Chainsaw Technician",
  "Golf Cart Mechanic",
  "Lawnmower Repair Mechanic",
  "Outdoor Power Equipment Service Technician",
  "Shop Mechanic",
  "Small Engine Mechanic",
  "Small Engine Technician",
  "Outdoor Power Equipment and Other Small Engine Mechanics",
  "Equipment Service Associate (ESA)",
  "Fire Sprinkler Service Technician",
  "Journeyman Pipe Fitter",
  "Journeyman Pipefitter",
  "Machine Repairman",
  "Pipe Fitter",
  "Pipe Welder",
  "Pipefitter",
  "Sprinkler Fitter",
  "Steamfitter",
  "Pipe Fitters and Steamfitters",
  "Construction and Related Workers, All Other",
  "Elevator Adjuster",
  "Elevator Constructor",
  "Elevator Mechanic",
  "Elevator Repair and Maintenance Technician",
  "Elevator Service Mechanic",
  "Elevator Service Technician",
  "Elevator Serviceman",
  "Elevator Technician",
  "Elevator Troubleshooter",
  "Escalator Service Mechanic",
  "Elevator Installers and Repairers",
  "Auto Body Man",
  "Auto Body Repair Technician (Auto Body Repair Tech)",
  "Auto Body Repairman",
  "Auto Body Technician (Auto Body Tech)",
  "Body Man",
  "Body Technician (Body Tech)",
  "Collision Repair Technician (Collision Repair Tech)",
  "Collision Technician (Collision Tech)",
  "Frame Man",
  "Refinish Technician (Refinish Tech)",
  "Automotive Body and Related Repairers",
  "Field Ironworker",
  "Iron Installer",
  "Ironworker Foreman",
  "Reinforced Ironworker",
  "Rodbuster",
  "Steel Tier",
  "Reinforcing Iron and Rebar Workers",
  "Drain Cleaner",
  "Public Works Technician",
  "Septic Cleaner",
  "Septic Pump Truck Driver",
  "Septic Tank Service Technician",
  "Sewer Bricklayer",
  "Septic Tank Servicers and Sewer Pipe Cleaners",
  "Kettleman",
  "Roof Technician",
  "Roofer Helper",
  "Roofing Laborer",
  "Helpers--Roofers",
  "A/C Tech (Air Conditioning Technician)",
  "HVAC Installer (Heating, Ventilation, Air Conditioning Installer)",
  "HVAC Mechanic (Heating, Ventilation, Air Conditioning Mechanic)",
  "HVAC Service Tech (Heating, Ventilation, Air Conditioning Service Technician)",
  "HVAC Specialist (Heating, Ventilation, and Air Conditioning Specialist)",
  "HVAC Technician (Heating, Ventilation, and Air Conditioning Technician)",
  "Systems Mechanic",
  "Heating and Air Conditioning Mechanics and Installers",
  "Fence Builder",
  "Fence Contractor",
  "Fence Erector",
  "Fence Installer",
  "Fence Laborer",
  "Fence Mechanic",
  "Fence Technician (Fence Tech)",
  "Gate Technician (Gate Tech)",
  "Wood Fence Erector",
  "Fence Erectors",
  "Building Analyst/Supervisor",
  "Director of Housing and Energy Services",
  "Energy Administrator",
  "Energy Assistant",
  "Energy Conservation Director",
  "Housing Director",
  "Lead Weatherization Installer-Technician",
  "Weatherization Director",
  "Weatherization Installer",
  "Weatherization Installers and Technicians",
  "Agricultural Mechanic",
  "Agriculture Mechanic",
  "Farm Equipment Mechanic",
  "Farm Equipment Service Technician",
  "Tractor Mechanic",
  "Tractor Technician",
  "Farm Equipment Mechanics and Service Technicians",
  "Alignment Technician",
  "Lube Technician",
  "Tire Buster",
  "Tire Center Supervisor",
  "Tire Changer",
  "Tire Installer",
  "Tire Repairer",
  "Tire Shop Mechanic",
  "Tire Technician",
  "Tire Repairers and Changers",
  "Cutter",
  "Paver",
  "Paver Installer",
  "Paver Layer",
  "Paving Stone Installer",
  "Segmental Wall Installer",
  "Segmental Pavers",
  "Pipe Crew Foreman",
  "Pipe Foreman",
  "Pipelayer",
  "Skilled Laborer",
  "Tailman",
  "Waste Water Worker",
  "Pipelayers",
  "Commercial Insulator",
  "Heat and Frost Insulator",
  "Industrial Insulator",
  "Insulation Helper",
  "Mechanic Insulator",
  "Mechanical Insulator",
  "Insulation Workers, Mechanical",
  "Abatement Worker",
  "Asbestos Abatement Worker",
  "Asbestos Hazard Abatement Worker",
  "Asbestos Remover",
  "Asbestos Worker",
  "Decontamination / Decommissioning Operator (D & D Operator)",
  "Hazmat Technician (Hazardous Materials Technician)",
  "Site Worker",
  "Waste Handling Technician",
  "Hazardous Materials Removal Workers",
  "Field Installer",
  "HVAC Sheet Metal Installer (Heating, Ventilation, and Air Conditioning Sheet Metal Installer)",
  "Journeyman Sheet Metal Worker",
  "Sheet Metal Apprentice",
  "Sheet Metal Fabricator",
  "Sheet Metal Foreman",
  "Sheet Metal Installer",
  "Sheet Metal Layout Mechanic",
  "Sheet Metal Mechanic",
  "Sheet Metal Worker",
  "Sheet Metal Workers",
  "A/C Technician (Air Conditioning Technician)",
  "Automobile Mechanic (Auto Mechanic)",
  "Automobile Technician (Auto Technician)",
  "Automotive Technician (Auto Technician)",
  "Drivability Technician",
  "Heavy Line Technician",
  "Oil Bay Technician",
  "Quick Service Technician",
  "Automotive Specialty Technicians",
  "Automotive Service Technicians and Mechanics",
  "Alarm Technician",
  "Fire Alarm Technician",
  "Home Security Alarm Installer",
  "Install Technician",
  "Security Installation Technician",
  "Security Installer",
  "Security Technician",
  "Security and Fire Alarm Systems Installers",
  "Continuous Miner Operator Helper",
  "Driller Helper",
  "Driller's Assistant",
  "Longwall Machine Operator Helper",
  "Miner Helper",
  "Mining Technician",
  "Salt Miner",
  "Helpers--Extraction Workers",
  "Avionics Repair Technician",
  "Communications Tower Technician",
  "Installation Technician (Installation Tech)",
  "Radio Technician",
  "Tower Climber",
  "Tower Technician",
  "Radio, Cellular, and Tower Equipment Installers and Repairers",
  "Fixer",
  "Industrial Machinery Mechanic",
  "Industrial Mechanic",
  "Loom Fixer",
  "Machine Adjuster",
  "Master Mechanic",
  "Industrial Machinery Mechanics",
  "Electrical Foreman",
  "Facility Maintenance Supervisor",
  "Maintenance Foreman",
  "Maintenance Planner",
  "Production Crew Supervisor",
  "First-Line Supervisors of Mechanics, Installers, and Repairers",
  "Appliance Mechanic",
  "Appliance Repair Mechanic",
  "Appliance Repair Technician (Appliance Repair Tech)",
  "Appliance Service Technician",
  "Appliance Technician (Appliance Tech)",
  "Repair Man",
  "Vacuum Repairer",
  "Home Appliance Repairers",
  "Gas Fitter",
  "Irrigation Installation Specialist",
  "Irrigation Technician",
  "Plumber's Helper",
  "Sprinkler Installer",
  "Sprinkler Repair Technician",
  "Helpers--Pipelayers, Plumbers, Pipefitters, and Steamfitters",
  "All Terrain Vehicle Technician (ATV Technician)",
  "Custom Bike Builder",
  "Master Motorcycle Technician",
  "Motorcycle Mechanic",
  "Motorcycle Service Technician",
  "Motorcycle Technician",
  "Motorsports Technician",
  "Scooter Mechanic",
  "Shop Foreman",
  "Motorcycle Mechanics",
  "Commercial Roofer",
  "Industrial Roofer",
  "Metal Roofing Mechanic",
  "Residential Roofer",
  "Roof Mechanic",
  "Roof Service Technician",
  "Roofer",
  "Roofing Foreman",
  "Roofing Technician",
  "Sheet Metal Roofer",
  "Roofers",
  "Helpers, Construction Trades, All Other",
  "Aircraft Maintenance Director",
  "Aircraft Maintenance Supervisor",
  "Aircraft Maintenance Technician (Aircraft Maintenance Tech)",
  "Aircraft Mechanic",
  "Aircraft Restorer",
  "Aircraft Technician",
  "Airframe and Powerplant Mechanic (A and P Mechanic)",
  "Aviation Maintenance Technician (AMT)",
  "Aviation Mechanic",
  "Helicopter Mechanic",
  "Aircraft Mechanics and Service Technicians",
  "Aircraft Electrical Systems Specialist",
  "Aviation Electrical Technician",
  "Aviation Electronics Technician",
  "Avionics Electronics Technician",
  "Avionics Installer",
  "Avionics Manager",
  "Avionics Systems Integration Specialist",
  "Avionics Technicians",
  "Quarry Worker",
  "Rock Splitter",
  "Splitter Operator",
  "Stone Breaker",
  "Stone Splitter",
  "Rock Splitters, Quarry",
  "ATM Technician (Automated Teller Machine Technician)",
  "Computer Repair Technician",
  "Copier Technician",
  "Customer Service Engineer",
  "Computer, Automated Teller, and Office Machine Repairers",
  "Electrical and Instrumentation Technician (E & I Technician)",
  "Instrument and Control Technician (I & C Technician)",
  "Instrumentation and Control Technician (I&C Technician)",
  "Relay Technician",
  "Substation Electrician",
  "Substation Mechanic",
  "Substation Technician",
  "Substation Wireman",
  "Wireman",
  "Electrical and Electronics Repairers, Powerhouse, Substation, and Relay",
  "Bill Board Poster",
  "Bill Poster",
  "Paper Hanger",
  "Paperhanger",
  "Vinyl Hanger",
  "Wall Covering Contractor",
  "Wall Covering Installer",
  "Wallpaper Hanger",
  "Wallpaper Installer",
  "Paperhangers",
  "Communications Systems Technician",
  "Radio Frequency Technician (RF Technician)",
  "Radio Repairman",
  "Radio Service Programmer",
  "Radio Service Technician (Radio Service Tech)",
  "Radio Technician (Radio Tech)",
  "Subscriber Technician",
  "Two-Way Radio Technician (Two-Way Radio Tech)",
  "Radio Mechanics",
  "Automotive Glass Installer (Auto Glass Installer)",
  "Automotive Glass Technician (Auto Glass Technician)",
  "Automotive Glazier (Auto Glazier)",
  "Glass Installer Technician",
  "Glass Technician/Installer",
  "Master Automotive Glass Technician (Master Auto Glass Technician)",
  "Windshield Installer",
  "Windshield Repair Technician",
  "Automotive Glass Installers and Repairers",
  "Control Valve Mechanic",
  "Control Valve Technician",
  "Electric Meter Technician",
  "Instrument and Electrical Technician (I & E Technician)",
  "Measurement Technician",
  "Valve Technician",
  "Water Plant Maintenance Mechanic",
  "Control and Valve Installers and Repairers, Except Mechanical Door",
  "Antique Clock Repairer",
  "Clock Repair Technician",
  "Clock Repairer",
  "Watch and Clock Repairer",
  "Watch Estimator",
  "Watch Repair Manager",
  "Watch Repair Person",
  "Watch Repair Technician",
  "Watch Repairer",
  "Watch Technician (Watch Tech)",
  "Watch Repairers",
  "Operations, Maintenance and Service Wind Turbine Technician (OMS Wind Turbine Technician)",
  "Senior Wind Turbine Technician",
  "Wind Farm Support Specialist",
  "Wind Technician",
  "Wind Turbine Service Technician",
  "Wind Turbine Technician",
  "Wind Turbine Service Technicians",
  "Precision Instrument and Equipment Repairers, All Other",
  "Cooler Deliverer",
  "Fountain Vending Mechanic",
  "Full Service Vending Driver",
  "Refurbish Technician",
  "Vending Mechanic",
  "Vending Service Technician",
  "Vending Technician",
  "Coin, Vending, and Amusement Machine Servicers and Repairers",
  "Building Maintenance Mechanic",
  "Building Mechanic",
  "Equipment Engineering Technician",
  "Maintenance and Repair Workers, General",
  "Bio Medical Technician",
  "Biomed Tech (Biomedical Technician)",
  "Biomedical Engineering Technician (BMET)",
  "Dental Equipment Technician",
  "X-ray Service Engineer",
  "Medical Equipment Repairers",
  "A Class Lineman",
  "Apprentice Lineman Third Step",
  "Class A Lineman",
  "Electric Lineman",
  "Electrical Lineman (Power)",
  "Electrical Lineworker",
  "Journeyman Lineman",
  "Lineman",
  "Lineworker",
  "Power Lineman",
  "Electrical Power-Line Installers and Repairers",
  "Camera Repair Technician",
  "Camera Repairman",
  "Camera Technician",
  "Photo Equipment Technician",
  "Photo Technologist",
  "Photographic Equipment Repair Technician",
  "Photographic Equipment Technician",
  "Photographic Technician (Photo Tech)",
  "Repairman",
  "Camera and Photographic Equipment Repairers",
  "Cable Splicer",
  "Cable Technician",
  "Cable Television Technician (CATV Technician)",
  "Installation and Repair Technician (I & R Technician)",
  "Telecommunications Line Installers and Repairers",
  "Certified Master Locksmith (CML)",
  "Certified Master Safe Cracker",
  "Certified Master Safecracker (CMS)",
  "Forensic Locksmith",
  "Lock Technician",
  "Locksmith",
  "Registered Safe Technician (RST)",
  "Road Service Locksmith",
  "Safe Technician",
  "Vault Technician",
  "Locksmiths and Safe Repairers",
  "Banjo Repair Person",
  "Brass Instrument Repair Technician",
  "Fretted String Instrument Repairer",
  "Guitar Repairer",
  "Instrument Repair Technician",
  "Luthier",
  "Mandolin Repair Person",
  "Piano Technician",
  "Piano Tuner",
  "Stringed Instrument Repairer",
  "Musical Instrument Repairers and Tuners",
  "Commercial Diver",
  "Dive Superintendent",
  "Dive Supervisor",
  "Diver",
  "Diver Tender",
  "Diving Supervisor",
  "Hard Hat Diver",
  "Non Destructive Testing Under Water Welder (NDT U/W Welder)",
  "Salvage Diver",
  "Commercial Divers",
  "Awning Craftsman",
  "Custom Marine Canvas Fabricator",
  "Fabric Worker",
  "Hand-Woven Carpet and Rug Mender",
  "Mender",
  "Perch Mender",
  "Sail Maker",
  "Sail Repair Person",
  "Seamstress",
  "Tarp Repairer",
  "Fabric Menders, Except Garment",
  "Control Room Operator (CRO)",
  "Instrumentation, Control, and Electrical Technician (I C & E Technician)",
  "Operator Technician",
  "Plant Technician",
  "Geothermal Technicians",
  "Signal and Communications Maintainer",
  "Signal Inspector",
  "Signal Maintainer",
  "Signal Maintenance Technician",
  "Signal Supervisor",
  "Signal System Testing Maintainer",
  "Signal Technician",
  "Signalman",
  "Train Control Electronic Technician",
  "Train Control Technician",
  "Signal and Track Switch Repairers",
  "Gantry Rigger",
  "Hand Rigger",
  "Heavy Lift Rigger",
  "Machinery Erector",
  "Machinery Mover",
  "Marine Rigger",
  "Rigging Foreman",
  "Rigging Supervisor",
  "Ship Rigger",
  "Riggers",
  "Delivery Crew Worker",
  "Master Craftsman",
  "Mobile Home Installer",
  "Mobile Home Laborer",
  "Mobile Home Set-Up Person",
  "Modular Set Crew Member",
  "Set Up Technician",
  "Manufactured Building and Mobile Home Installers",
  "Installation, Maintenance, and Repair Workers, All Other",
  "A&P Technician (Airframe and Powerplant Technician)",
  "Aircraft Line Assembler",
  "Assembly Riveter",
  "Fabricator",
  "Helicopter Technician",
  "Sheet Metal Assembler and Riveter (SMAR)",
  "Structures Mechanic",
  "Structures Technician",
  "Aircraft Structure, Surfaces, Rigging, and Systems Assemblers",
  "Assembly Supervisor",
  "Manufacturing Supervisor",
  "Molding Supervisor",
  "Quality Assurance Supervisor (QA Supervisor)",
  "Team Leader",
  "First-Line Supervisors of Production and Operating Workers",
  "Armature Winder",
  "Auto-Winder",
  "Coil Finisher",
  "Coil Winder",
  "Hand Winder",
  "Motor Winder",
  "Winder",
  "Winder Operator",
  "Winding Department Supervisor",
  "Coil Winders, Tapers, and Finishers",
  "Assembly Worker",
  "Electrical Assembler",
  "Electronic Assembler, Group Leader",
  "Electronics Assembler",
  "Factory Assembler",
  "Factory Worker",
  "Manufacturing Assembler",
  "Transformer Assembler",
  "Electrical and Electronic Equipment Assemblers",
  "Calibration Specialist",
  "Calibrator",
  "Clockmaker",
  "Horologist",
  "Time Stamp Assembler",
  "Watch Technician",
  "Watchmaker",
  "Timing Device Assemblers and Adjusters",
  "Building Equipment Operator (BEO)",
  "Facilities Maintenance Technician",
  "General Maintenance Mechanic",
  "Maintenance Helper",
  "Mechanic Helper",
  "Trades Helper",
  "Well Tender",
  "Helpers--Installation, Maintenance, and Repair Workers",
  "Bean Roaster",
  "Coffee Roaster",
  "Line Operator",
  "Oven Operator",
  "Oven Technician",
  "Roast Master",
  "Roaster",
  "Roaster Operator",
  "Roasterman",
  "Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders",
  "Fitter, Welder",
  "Layout Man",
  "Mill Beam Fitter",
  "Ship Fitter",
  "Structural Steel Fitter",
  "Tack Welder",
  "Weld Technician",
  "Welder-Fabricator",
  "Structural Metal Fabricators and Fitters",
  "Assembly Associate",
  "Assembly Line Machine Operator",
  "Assembly Line Worker",
  "Assembly Operator",
  "Certified Composites Technician (CCT)",
  "Production Associate",
  "Team Assembler",
  "Team Assemblers",
  "Beef Trimmer",
  "Breast Trimmer",
  "Chicken Cutter",
  "Deboner",
  "Fish Processor",
  "Meat Cutter",
  "Meat Trimmer",
  "Seafood Processor",
  "Wing Scorer",
  "Meat, Poultry, and Fish Cutters and Trimmers",
  "Boning Room Worker",
  "Meat Packager",
  "Meat Packer",
  "Meat Processor",
  "Meat Wrapper",
  "Saw Man",
  "Side Puller",
  "Wrapper",
  "Slaughterers and Meat Packers",
  "Baker",
  "Bakery Clerk",
  "Cake Decorator",
  "Dough Mixer",
  "Pastry Chef",
  "Processor",
  "Bakers",
  "Brake Press Operator",
  "Computer Numerical Control Lathe Operator (CNC Lathe Operator)",
  "Computer Numerical Control Machine Operator (CNC Machine Operator)",
  "Computer Numerical Control Machinist (CNC Machinist)",
  "Computer Numerical Control Mill Operator (CNC Mill Operator)",
  "Computer Numerical Control Operator (CNC Operator)",
  "Computer Numerical Control Set-Up and Operator (CNC Set-Up and Operator)",
  "Machine Set-Up Operator",
  "Machinist",
  "Computer-Controlled Machine Tool Operators, Metal and Plastic",
  "Food Processing Workers, All Other",
  "Die Cast Die Maker",
  "Fixture Builder",
  "Journeyman Patternmaker",
  "Layout Technician",
  "Metal Pattern Maker",
  "Pattern Maker",
  "Pattern Maker Programmer",
  "Pattern Repair Person",
  "Patternmaker",
  "Wax Molder",
  "Patternmakers, Metal and Plastic",
  "Anodizer",
  "Anodizing Line Operator",
  "Chrome Plater",
  "Coater Associate",
  "Coater Operator",
  "Electro Plater",
  "Galvanizer",
  "Plater",
  "Plating and Coating Machine Setters, Operators, and Tenders, Metal and Plastic",
  "Core Machine Operator",
  "Die Cast Technician",
  "Diecast Machine Operator",
  "Mold Setter",
  "Mold Technician",
  "Molder",
  "Press Operator",
  "Molding, Coremaking, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic",
  "Electromechanical Assembler",
  "Electromechanical Equipment Assembler",
  "Electronic Assembler",
  "Mechanical Assembler",
  "Wiring Technician",
  "Electromechanical Equipment Assemblers",
  "Metal Workers and Plastic Workers, All Other",
  "Core Maker",
  "Core Setter",
  "Core Stripper",
  "Journeyman Molder",
  "Mold Maker",
  "No Bake Molder",
  "Sand Molder",
  "Foundry Mold and Coremakers",
  "Cell Operator",
  "Centerless Grinder Operator",
  "CNC Operator (Computer Numerically Controlled Operator)",
  "Deburrer",
  "Die Maintenance Technician",
  "Grinder Operator",
  "Grinding Machine Operator",
  "Process Equipment Operator",
  "Grinding, Lapping, Polishing, and Buffing Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
  "Welders, Cutters, Solderers, and Brazers",
  "Alteration Specialist",
  "Couturier",
  "Custom Clothier",
  "Custom Designer",
  "Custom Seamstress",
  "Dressmaker",
  "Sewers, Hand",
  "Development Mechanic",
  "Layout Fabricator",
  "Layout Fitter",
  "Layout Inspector",
  "Layout Mechanic",
  "Layout Technician (Layout Tech)",
  "Layout Worker",
  "Layout Workers, Metal and Plastic",
  "Boarder",
  "Dry Cleaner Presser",
  "Garment Presser",
  "Ironing Machine Operator",
  "Ironing Worker",
  "Pants Presser",
  "Presser",
  "Pressing Machine Operator",
  "Shirt Presser",
  "Silk Presser",
  "Pressers, Textile, Garment, and Related Materials",
  "Cell Technician",
  "CNC Machine Setter (Computer Numerically Controlled Machine Setter)",
  "CNC Machinist (Computer Numerically Controlled Machinist)",
  "Die Setter",
  "Machine Technician",
  "Set-Up Person",
  "Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
  "Aircraft Tool Maker",
  "Carbide Tool Die Maker",
  "Die Maker",
  "Jig and Fixture Builder",
  "Jig and Fixture Repairer",
  "Tool and Die Machinist",
  "Tool and Die Maker",
  "Tool Repairer",
  "Toolmaker",
  "Trim Die Maker",
  "Tool and Die Makers",
  "Butcher",
  "Journeyman Meat Cutter",
  "Meat Clerk",
  "Meat Manager",
  "Meat Specialist",
  "Seafood and Service Meat Manager",
  "Butchers and Meat Cutters",
  "Gear Machinist",
  "Journeyman Machinist",
  "Machine Repair Person",
  "Maintenance Machinist",
  "Maintenance Specialist",
  "Production Machinist",
  "Set-Up Machinist",
  "Tool Room Machinist",
  "Machinists",
  "Fabrication Operator",
  "Machine Setter",
  "Punch Press Operator",
  "Saw Operator",
  "Set-Up Operator",
  "Slitter Operator",
  "Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic",
  "Boot Maker",
  "Lacer",
  "Leather Production Worker",
  "Leather Worker",
  "Saddle and Harness Maker",
  "Shoe Cutter",
  "Shoe Maker",
  "Shoe Repairer",
  "Shoe Repairman",
  "Shoe and Leather Workers and Repairers",
  "Binder Operator",
  "Bindery Operator",
  "Bindery Production Manager",
  "Bindery Technician",
  "Bindery Worker",
  "Book Binder",
  "Custom Bookbinder",
  "Perfect Binder Operator",
  "Print Binding and Finishing Workers",
  "Cutter Grinder",
  "OD Grinder Operator (Outer Diameter Grinder Operator)",
  "Saw Filer",
  "Tool and Cutter Grinder",
  "Tool Grinder",
  "Tool Grinders, Filers, and Sharpeners",
  "Breakdown Mill Operator",
  "Calender Operator",
  "Cold Mill Operator",
  "Mill Operator",
  "Rolling Mill Operator",
  "Roughing Mill Operator",
  "Temper Mill Operator",
  "Tube Mill Operator",
  "Weld Mill Operator",
  "Rolling Machine Setters, Operators, and Tenders, Metal and Plastic",
  "Aluminum Welder",
  "Fabrication Welder",
  "Fitter/Welder",
  "Maintenance Welder",
  "MIG Welder (Metal Inert Gas Welder)",
  "Sub Arc Operator",
  "Welder",
  "Welder-Fitter",
  "Welder/Fabricator",
  "Welders, Cutters, and Welder Fitters",
  "Batching Operator",
  "Blender",
  "Brewing Technician",
  "Compounder",
  "Dough Scaler and Mixer",
  "Process Operator",
  "Processing Operator",
  "Syrup Maker",
  "Food Batchmakers",
  "Cold Header Operator",
  "Forge Operator",
  "Forge Press Operator",
  "Forger",
  "Hammer Operator",
  "Header Set-Up Operator",
  "Forging Machine Setters, Operators, and Tenders, Metal and Plastic",
  "Batch Heat Treat Operator",
  "Burner",
  "Coating Line Worker",
  "Furnace Operator",
  "Heat Treat Furnace Operator",
  "Heat Treat Operator",
  "Heat Treat Technician",
  "Heat Treater",
  "Scarf and Anneal Operator",
  "Heat Treating Equipment Setters, Operators, and Tenders, Metal and Plastic",
  "1st Pressman",
  "2nd Pressman",
  "Flexographic Press Operator",
  "Offset Pressman",
  "Press Leader",
  "Pressman",
  "Printer",
  "Printing Press Operator",
  "Printing Pressman",
  "Printing Press Operators",
  "Boat Carpenter",
  "Chopper Gun Operator",
  "Fiberglass Finisher",
  "Fiberglass Laminator",
  "Fiberglasser",
  "Gel-Coater",
  "Hull Line Crew Member",
  "Laminator",
  "Roller",
  "Fiberglass Laminators and Fabricators",
  "CAD CAM Programmer (Computer-Aided Design Computer-Aided Manufacturing Programmer)",
  "Computer Numerical Control Machining Center Operator (CNC Machining Center Operator)",
  "Computer Numerical Control Programmer (CNC Programmer)",
  "Machine Shop Lead Man",
  "Machining Manager",
  "Computer Numerically Controlled Machine Tool Programmers, Metal and Plastic",
  "Arc / Argon Oxygen Decarborization Melter (ARC / AOD Melter)",
  "Automatic Furnace Operator",
  "Central Melt Specialist",
  "Electric Melt Operator",
  "Melt Room Operator",
  "Melter",
  "Vacuum Melter",
  "Vessel Operator",
  "Metal-Refining Furnace Operators and Tenders",
  "Extruder Operator",
  "Extrusion Operator",
  "Extrusion Press Operator",
  "Metal Inspector",
  "Setup Operator",
  "Wire Mill Operator",
  "Wire Mill Rover",
  "Extruding and Drawing Machine Setters, Operators, and Tenders, Metal and Plastic",
  "Assembly Line Brazer",
  "Brazer",
  "Connector",
  "Refrigeration Brazer/Solderer",
  "Refrigeration Specialist",
  "Solderer",
  "Wirer",
  "Solderers and Brazers",
  "Fryer Operator",
  "Kettle Fry Cook Operator",
  "Master Cook",
  "Mogul Operator",
  "Peeler Operator",
  "Retort Operator",
  "Food Cooking Machine Operators and Tenders",
  "Lathe Operator",
  "Lathe Set Up Person",
  "Numerical Control Operator (NC Operator)",
  "Screw Machine Operator",
  "Screw Machine Tool Setter",
  "Set Up / Operator",
  "Turn Operator",
  "Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
  "Bore Mill Operator",
  "Computer Numerical Control Drilling Operator (CNC Drilling Operator)",
  "Drill Press Operator",
  "Drill Setup Operator",
  "Punch Operator",
  "Radial Drill Operator",
  "Drilling and Boring Machine Tool Setters, Operators, and Tenders, Metal and Plastic",
  "Catalytic Converter Special Build Prototype",
  "Metal Model Maker",
  "Model Builder",
  "Model Maker",
  "Molding Technician",
  "Pattern Finisher",
  "Prototype Special Build",
  "Model Makers, Metal and Plastic",
  "Assemblers and Fabricators, All Other",
  "Braze Operator",
  "Finishing Technician",
  "Fitter-Welder",
  "Mig Welder",
  "Robot Operator",
  "Spot Welder",
  "Technical Associate (TA)",
  "Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders",
  "Line Closer",
  "Prototype Sewer",
  "Sample Maker",
  "Seamer",
  "Sew On Operator",
  "Sewer",
  "Sewing Machine Operator",
  "Zipper Machine Operator",
  "Sewing Machine Operators",
  "Miller",
  "Milling Operator",
  "Set Up Person",
  "Milling and Planing Machine Setters, Operators, and Tenders, Metal and Plastic",
  "Engine Assembler",
  "Engine Builder",
  "Large Engine Assembler",
  "Machine Assembler",
  "Engine and Other Machine Assemblers",
  "Casting Operator",
  "Die Cast Operator (DCO)",
  "Direct Chill Caster (DC Caster)",
  "Iron Pourer",
  "Ladleman",
  "Metal Handler",
  "Pourer",
  "Vacuum Caster",
  "Pourers and Casters, Metal",
  "Dry Cleaner",
  "Laundry Aide",
  "Laundry Assistant",
  "Laundry Attendant",
  "Laundry Housekeeper",
  "Laundry Technician",
  "Laundry Worker",
  "Personal Clothing Laundry Aide",
  "Spotter",
  "Laundry and Dry-Cleaning Workers",
  "Desktop Operator",
  "Electronic Prepress Operator (EPP Operator)",
  "Electronic Prepress Technician (EPP Tech)",
  "Plate Maker",
  "Plate Mounter",
  "Pre-Press Proofer",
  "Prepress Operator",
  "Prepress Specialist",
  "Prepress Stripper",
  "Prepress Technician",
  "Prepress Technicians and Workers",
  "Bandmill Operator",
  "Cut Off Saw Operator",
  "Edgerman",
  "Knot Saw Operator",
  "Panel Saw Operator",
  "Planer",
  "Resaw Operator",
  "Rip Saw Operator",
  "Sawing Machine Setters, Operators, and Tenders, Wood",
  "Batch Mixer",
  "Crusher Operator",
  "Cullet Trucker",
  "Preparation Operator (Prep Operator)",
  "Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders",
  "Automatic Fabric Cutter (Autocutter)",
  "Fabric Cutter",
  "Pattern Designer",
  "Pattern Technician",
  "Production Pattern Maker",
  "Sewing Pattern Layout Technician",
  "Technical Designer",
  "Fabric and Apparel Patternmakers",
  "Cutting Pressman",
  "Die Cutter Operator",
  "Flat Cutter",
  "Paper Cutter",
  "Sheeter",
  "Skiver Operator",
  "Slitter",
  "Cutting and Slicing Machine Setters, Operators, and Tenders",
  "Buffer",
  "Buffer Operator",
  "Builder Operator",
  "Recapper",
  "Retread Builder",
  "Retreader",
  "Splicer",
  "Tire Assembler",
  "Tire Builder",
  "Tire Builders",
  "Biofuels Processing Technician",
  "Chemical Operator",
  "Ethanol Operator",
  "Kettle Operator",
  "Mash Preparatory Operator",
  "Production Operator",
  "Biofuels Processing Technicians",
  "Edger Technician",
  "Finishing Lab Technician",
  "Lab Technician (Laboratory Technician)",
  "Lens Grinder and Polisher",
  "Optical Lab Technician (Optical Laboratory Technician)",
  "Polisher",
  "Surfacing Technician",
  "Ophthalmic Laboratory Technicians",
  "Glass Forming Crew Member",
  "Job Change Crew Member",
  "Tuber Operator",
  "Upkeep Mechanic",
  "Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders",
  "Methane/Landfill Gas Generation System Technicians",
  "Extruder",
  "Extrusion Line Operator",
  "Granulator",
  "Hot End Operator",
  "Pelletizer Operator",
  "Pot Tipper",
  "Spindraw Operator",
  "Stretch Operator",
  "Extruding and Forming Machine Setters, Operators, and Tenders, Synthetic and Glass Fibers",
  "Diamond Cutter",
  "Diamond Grader",
  "Diamond Picker",
  "Diamond Polisher",
  "Diamond Sawer",
  "Diamond Setter",
  "Facetor",
  "Gemologist",
  "Lapidarist",
  "Quality Control Specialist",
  "Gem and Diamond Workers",
  "Hydro Operations and Maintenance Technician (Hydro O&M Technician)",
  "Hydro Plant Technician",
  "Hydro Technician",
  "Hydroelectric Mechanic",
  "Hydroelectric Plant Technician",
  "Operations and Maintenance Technician (O&M Technician)",
  "Plant Mechanic",
  "Power Plant Mechanic",
  "Power Plant Operator",
  "Power Plant Technician",
  "Hydroelectric Plant Technicians",
  "Auxiliary Engineer",
  "Biomass Facilitator",
  "Boiler Operator",
  "Fuel Handler",
  "Fuel Quality Tech",
  "Plant Operator",
  "Biomass Plant Technicians",
  "Licensed Reactor Operator",
  "Nuclear Control Operator",
  "Nuclear Control Room Operator",
  "Nuclear Plant Operator (NPO)",
  "Nuclear Power Reactor Operator",
  "Nuclear Reactor Operator",
  "Nuclear Station Operator (NSO)",
  "Nuclear Supervising Operator (NSO)",
  "Nuclear Unit Operator",
  "Reactor Operator (RO)",
  "Nuclear Power Reactor Operators",
  "Distribution Dispatcher",
  "Distribution System Operator",
  "Electric System Operator",
  "Power System Dispatcher",
  "Power System Operator",
  "System Operator",
  "Transmission System Operator",
  "Power Distributors and Dispatchers",
  "Chlorination Operator",
  "Multiskill Operator",
  "Outside Operator",
  "Spray Dry Operator",
  "Chemical Equipment Operators and Tenders",
  "Jewelers and Precious Stone and Metal Workers",
  "Plant and System Operators, All Other",
  "Blender / Cook",
  "Brewer",
  "Cellar Worker",
  "Cheese Maker",
  "Digester Cook",
  "Machine Tender",
  "Paper Machine Tender",
  "Pulper Operator",
  "Winemaker",
  "Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders",
  "Bench Mechanic",
  "Caster",
  "Pewterer",
  "Platinum Smith",
  "Restoration Silversmith",
  "Silversmith",
  "Precious Metal Workers",
  "Back Winder",
  "Cable Operator",
  "Computer Integrated Manufacturing Operator (CIM Operator)",
  "Drawing Operator",
  "Spinner",
  "Spinning Operator",
  "Twister Operator",
  "Winder Tender",
  "Textile Winding, Twisting, and Drawing Out Machine Setters, Operators, and Tenders",
  "Cloth Cutter",
  "Denim Cutter",
  "Glass Cutter",
  "Hand Cutter",
  "Leather Cutter",
  "Offline Cutter (Cutter)",
  "Sample Cutter",
  "Cutters and Trimmers, Hand",
  "Picker / Packer",
  "Quality Assurance Auditor",
  "Quality Auditor",
  "Quality Inspector",
  "Inspectors, Testers, Sorters, Samplers, and Weighers",
  "Crown and Bridge Dental Lab Technician",
  "Dental Ceramist",
  "Dental Laboratory Technician (Dental Lab Technician)",
  "Dental Technician (Dental Tech)",
  "Denture Technician",
  "Metal Finisher",
  "Model and Dye Person",
  "Orthodontic Laboratory Technician (Orthodontic Lab Technician)",
  "Porcelain Technician",
  "Waxer",
  "Dental Laboratory Technicians",
  "Knitter",
  "Knitting Machine Operator",
  "Tufting Machine Operator",
  "Warp Knit Operator",
  "Weaver",
  "Textile Knitting and Weaving Machine Setters, Operators, and Tenders",
  "Cabinet Assembler",
  "Cabinet Builder",
  "Cabinet Installer",
  "Cabinetmaker",
  "Double End Tenon Operator",
  "Frame Builder",
  "Woodworker",
  "Cabinetmakers and Bench Carpenters",
  "Building Engineer",
  "Plant Utilities Engineer",
  "Stationary Engineer",
  "Stationary Steam Engineer",
  "Utilities Operator",
  "Stationary Engineers and Boiler Operators",
  "Clay Mixer",
  "Jigger Artisan",
  "Jigger Machine Operator",
  "Potter",
  "Production Potter",
  "Potters, Manufacturing",
  "Front Line Supervisor",
  "Receiving Manager",
  "Receiving Supervisor",
  "Terminal Operations Manager",
  "First-Line Supervisors of Helpers, Laborers, and Material Movers, Hand",
  "Carver",
  "Granite Cutter",
  "Sandblast Carver",
  "Sandblaster",
  "Stone Carver",
  "Stone Cutter",
  "Stone Fabricator",
  "Stone Cutters and Carvers, Manufacturing",
  "Cargo Supervisor",
  "Equipment Service Lead",
  "Ground Operations Supervisor",
  "Line Service Supervisor (LSS)",
  "Loadmaster",
  "Ramp and Cargo Supervisor",
  "Ramp Lead",
  "Ramp Supervisor",
  "Aircraft Cargo Handling Supervisors",
  "Beck Operator",
  "Drug Room Operator",
  "Dye Line Operator",
  "Dye Machine Operator",
  "Dye Operator",
  "Dyer",
  "Jet Dyeing Machine Operator",
  "Jet Operator",
  "Tub Operator",
  "Textile Bleaching and Dyeing Machine Operators and Tenders",
  "Alterations Expert",
  "Alterations Sewer",
  "Couture Alterations Dressmaker",
  "Couturiere",
  "Custom Dressmaker",
  "Prototype Technician",
  "Tailor",
  "Tailors, Dressmakers, and Custom Sewers",
  "Box Spring Upholsterer",
  "Furniture Upholsterer",
  "Inside Upholsterer",
  "Sofa Back Upholsterer",
  "Stapler",
  "Upholstered Goods Crafter",
  "Upholsterer",
  "Upholstery Cutter",
  "Upholstery Trimmer",
  "Upholsterers",
  "Blending Technician",
  "Ink Blender",
  "Ink Maker",
  "Issuing Operator",
  "Mixer Operator",
  "Stock Preparation Operator (Stock Prep Operator)",
  "Mixing and Blending Machine Setters, Operators, and Tenders",
  "Loader Technician",
  "Process Control Operator",
  "Process Development Associate",
  "Chemical Plant and System Operators",
  "Textile, Apparel, and Furnishings Workers, All Other",
  "Compressor Station Operator",
  "Engine Room Operator",
  "Gas Controller",
  "Gas Dispatcher",
  "Gas Plant Operator",
  "Gas System Operator",
  "Liquefied Natural Gas Specialist (LNG Specialist)",
  "Liquefied Natural Gas Technician (LNG Technician)",
  "Liquid Natural Gas Plant Operator (LNG Plant Operator)",
  "Gas Plant Operators",
  "Multicraft Operator (MCO)",
  "Operations and Maintenance Technician (O & M Technician)",
  "Plant Control Operator",
  "Senior Power Plant Operator",
  "Station Operator",
  "Unit Operator",
  "Power Plant Operators",
  "Digital Printer Operator",
  "Film Processor",
  "Film Technician",
  "Photo Lab Manager",
  "Photo Lab Specialist",
  "Photo Lab Technician (Photographic Laboratory Technician)",
  "Photo Printer",
  "Photo Specialist",
  "Photo Technician",
  "Photographic Process Workers and Processing Machine Operators",
  "Clean in Places Operator (CIP Operator)",
  "Filler Operator",
  "Parts Cleaner",
  "Sanitation Technician",
  "Sanitation Worker",
  "Sanitizer",
  "Tub Wash Operator",
  "Tub Washer",
  "Wash Crew Person",
  "Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders",
  "Boot and Shoe Repairman",
  "Inseamer",
  "Insole Department Worker",
  "Side Laster",
  "Stitcher",
  "Shoe Machine Operators and Tenders",
  "Apprentice Pattern Maker",
  "Wood Pattern Maker",
  "Patternmakers, Wood",
  "Boring Machine Operator",
  "Molder Operator",
  "Router Operator",
  "Sander",
  "Sander Operator",
  "Woodworking Machine Setters, Operators, and Tenders, Except Sawing",
  "Craftsman",
  "Product Development Carpenter",
  "Sample Builder",
  "Sample Worker",
  "Model Makers, Wood",
  "Casting Finisher",
  "Chipper",
  "Deburring Technician",
  "Jewelry Polisher",
  "Knife Grinder",
  "Grinding and Polishing Workers, Hand",
  "Automated Cutting Machine Operator",
  "CNC Cutting Operator (Computer Numerical Control Cutting Operator)",
  "Die Cut Operator",
  "Laser Operator",
  "Spread Cutter",
  "Spreader",
  "Textile Slitting Machine Operator",
  "Textile Cutting Machine Setters, Operators, and Tenders",
  "Woodworkers, All Other",
  "Device Processing Engineer",
  "Diffusion Operator",
  "Manufacture Specialist",
  "Metalorganic Chemical Vapor Deposition Engineer (MOCVD Engineer)",
  "Probe Operator",
  "Wafer Fabrication Operator",
  "Semiconductor Processors",
  "A-Operator",
  "Bundler",
  "Closing Machine Operator",
  "Fabrication Technician",
  "Packaging Operator",
  "Packing Attendant",
  "Packing Machine Operator",
  "Packaging and Filling Machine Operators and Tenders",
  "Crude Unit Operator",
  "Gauger",
  "Head Operator",
  "Hydrotreater Operator",
  "Pumper",
  "Refinery Operator",
  "Stillman",
  "Petroleum Pump System Operators, Refinery Operators, and Gaugers",
  "Bench Jeweler",
  "Earrings Fabricator",
  "Jeweler",
  "Jewelers",
  "Hand Sprayer",
  "Paint Technician",
  "Powder Coater",
  "Silk Screen Operator",
  "Spray Painter",
  "Coating, Painting, and Spraying Machine Setters, Operators, and Tenders",
  "Certified Pedorthotist",
  "Hearing Aid Repair Technician",
  "Orthopedic Technician",
  "Orthotic and Prosthetic Technician (O and P Technician)",
  "Orthotic and Prosthetic Technician (O&P Technician)",
  "Orthotic Technician",
  "Prosthetic Technician",
  "Prosthetics Technician",
  "Registered Prosthetic Orthotic Technician",
  "Medical Appliance Technicians",
  "Bed Laborer",
  "Injection Molding Machine Operator",
  "Mold Mechanic",
  "Molding Line Assistant",
  "Molding Line Operator",
  "Molding and Casting Workers",
  "Process Operator (Process Op)",
  "Relief Operator",
  "SCADA Operator (Supervisory Control and Data Acquisition Operator)",
  "Waste Water Treatment Plant Operator (WWTP Operator)",
  "Wastewater Operator (WW Operator)",
  "Water Control Dispatcher",
  "Water Plant Operator",
  "Water Treatment Operator",
  "Water Treatment Plant Operator",
  "Water and Wastewater Treatment Plant and System Operators",
  "Molders, Shapers, and Casters, Except Metal and Plastic",
  "Finish Repair Worker",
  "Furniture Finisher",
  "Hand Sander",
  "Lacquer Sprayer",
  "Sealer Sander",
  "Stain Sprayer",
  "Stain Wiper",
  "Furniture Finishers",
  "Acid Etch Operator",
  "Award Machine Operator",
  "Chemical Engraver",
  "Electronic Engraver",
  "Engraver",
  "Etcher",
  "Laser Engraver",
  "Photo Engraver",
  "Screen Making Technician",
  "Wet Process Technician",
  "Etchers and Engravers",
  "Annealing Operator",
  "Dry Kiln Operator",
  "Dryer Feeder",
  "Evaporator Operator",
  "Kiln Fireman",
  "Kiln Operator",
  "Lime Kiln and Recausticizing Operator",
  "Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders",
  "Assistant Operator",
  "Press Helper",
  "Service Person",
  "Support Team Member",
  "Utility Worker",
  "Helpers--Production Workers",
  "Production Workers, All Other",
  "Heavy Equipment Supervisor",
  "Recycle Coordinator",
  "Recycling Coordinator",
  "Recycling Director",
  "Recycling Manager",
  "Recycling Program Manager",
  "Recycling Specialist",
  "Route Supervisor",
  "Solid Waste Division Supervisor",
  "Waste Reduction Coordinator",
  "Recycling Coordinators",
  "Airport Duty Manager",
  "Airport Operations Coordinator",
  "Airport Operations Manager",
  "Airport Operations Specialist",
  "Assistant Manager Airside Operations",
  "Operations Agent",
  "Airfield Operations Specialists",
  "Delivery Driver",
  "Delivery Man",
  "Driver Sales",
  "Driver Salesman",
  "Pizza Delivery Driver",
  "Route Driver",
  "Route Sales Driver",
  "Route Salesman",
  "Sales Route Driver",
  "Driver/Sales Workers",
  "Dock Worker",
  "Line Tender",
  "Loader",
  "Merchandise Pickup/Receiving Associate",
  "Shipping and Receiving Materials Handler",
  "Laborers and Freight, Stock, and Material Movers, Hand",
  "Glue Line Operator",
  "Glue Reel Operator",
  "Gluer Machine Operator",
  "Gluing Pressman",
  "Perfect Bind Machine Operator",
  "Process Assistant",
  "Sealer Operator",
  "Adhesive Bonding Machine Operators and Tenders",
  "Auto Painter (Automobile Painter)",
  "Auto Refinisher (Automobile Refinisher)",
  "Automotive Paint Technician (Auto Paint Technician)",
  "Automotive Painter",
  "Automotive Refinish Technician",
  "Body Technician/Painter",
  "Finish Painter",
  "Paint Prepper",
  "Top Coater",
  "Painters, Transportation Equipment",
  "Bobcat Driver/Labor",
  "Box Sorter",
  "Convenience Recycle Center Tech",
  "Crane Operator",
  "Deconstruction and Decontamination Waste Operations Specialist",
  "Non-Ferrous Material Handler",
  "Sort Line Worker",
  "Transfer Station Operator",
  "Recycling and Reclamation Workers",
  "Able Bodied Seaman (AB Seaman)",
  "Able Bodied Watchman (AB Watchman)",
  "Able Seaman",
  "Boat Crew Deck Hand",
  "Bosun",
  "Deck Hand",
  "Deckhand Engineer",
  "Tankerman",
  "Sailors and Marine Oilers",
  "Load Out Person",
  "Loading Operator",
  "Oil Movements Operator",
  "PVC Loader (Polyvinyl Chloride Loader)",
  "Rail Car Loader",
  "Tank Car Loader",
  "Truck Loader",
  "Tank Car, Truck, and Ship Loaders",
  "Special Forces",
  "Assistant Engineer",
  "Barge Engineer",
  "Ferry Engineer",
  "Harbor Engineer",
  "Port Engineer",
  "Towboat Engineer",
  "Tug Boat Engineer",
  "Tugboat Engineer",
  "Ship Engineers",
  "Field Traffic Investigator",
  "Traffic Analyst",
  "Traffic Control Technician",
  "Traffic Investigator",
  "Traffic Signal Technician (TST)",
  "Traffic Survey Technician",
  "Traffic Technician",
  "Transportation Planning Technician",
  "Transportation Technician",
  "Traffic Technicians",
  "Infantry Officers",
  "Motor Vehicle Operators, All Other",
  "Boat Operator",
  "Charter Boat Operator",
  "Crew Boat Operator",
  "Launch Operator",
  "Launchman",
  "Pilot Boat Operator",
  "Watch Leader",
  "Water Taxi Operator",
  "Motorboat Operators",
  "Radar and Sonar Technicians",
  "Corrugator Operator",
  "Cup Room Technician",
  "Folder Machine Operator",
  "Gluer Operator",
  "Paper Cutter Operator",
  "Paper Machine Backtender",
  "Paper Machine Operator",
  "Stitching Machine Operator",
  "Winderman",
  "Paper Goods Machine Setters, Operators, and Tenders",
  "Dock Attendant",
  "Dock Hand",
  "Fuel Attendant",
  "Fuel Dock Attendant",
  "Gas Attendant",
  "Gas Pumper",
  "Marine Fuel Dock Attendant",
  "Service Station Attendant",
  "Automotive and Watercraft Service Attendants",
  "Aircraft Launch and Recovery Specialists",
  "Bus Driver",
  "Bus Monitor",
  "Campus Driver",
  "Rehabilitation Driver",
  "School Bus Driver",
  "Shuttle Bus Driver",
  "Special Education Bus Driver",
  "Special Education Driver",
  "Special Needs Bus Driver",
  "Bus Drivers, School or Special Client",
  "Air Traffic Control Specialist (ATCS)",
  "Air Traffic Control Specialist, Terminal",
  "Air Traffic Control Specialist/Certified Professional Controller (ATC Specialist/CPC)",
  "Air Traffic Controller (ATC)",
  "Air Traffic Controller (Enroute Option)",
  "Air Traffic Controller (Tower Option)",
  "Air Traffic Controller, Center",
  "Certified Professional Controller (CPC)",
  "Control Tower Operator",
  "Radar Air Traffic Controller",
  "Air Traffic Controllers",
  "Command and Control Center Officers",
  "Aircraft Launch and Recovery Officers",
  "Rail Transportation Workers, All Other",
  "Artillery and Missile Officers",
  "Bus Aide",
  "Bus Assistant",
  "Bus Attendant",
  "Fare Enforcement Officer",
  "School Bus Aide",
  "School Bus Monitor",
  "Transportation Aide",
  "Transportation Attendants, Except Flight Attendants",
  "Conductor and Engineer",
  "Conductor, Freight",
  "Conductor/Brakeman",
  "Freight Conductor",
  "Railroad Conductor",
  "Train Master",
  "Trainman",
  "Yardmaster",
  "Railroad Conductors and Yardmasters",
  "Bagger",
  "Inspector Packer",
  "Mini Shifter",
  "Pack Out Operator",
  "Packager",
  "Picker and Packer",
  "Sacker",
  "Selector Packer",
  "Packers and Packagers, Hand",
  "Locomotive Engineer",
  "Passenger Locomotive Engineer",
  "Railroad Engineer",
  "Through Freight Engineer",
  "Train Engineer",
  "Transportation Specialist",
  "Locomotive Engineers",
  "Charter Pilot",
  "Check Airman",
  "Chief Pilot",
  "Commercial Pilot",
  "EMS Helicopter Pilot (Emergency Medical Service Helicopter Pilot)",
  "First Officer",
  "Helicopter Pilot",
  "Line Pilot",
  "Pilot",
  "Commercial Pilots",
  "Load Haul Dump Operator (LHD Operator)",
  "Loading Machine Operator",
  "Muck Hauler",
  "Production Miner",
  "Shuttle Car Operator",
  "Under Ground Miner",
  "Loading Machine Operators, Underground Mining",
  "Aircraft Cleaner",
  "Auto Detailer",
  "Automobile Detailer",
  "Automotive Detailer",
  "Car Detailer",
  "Car Washer",
  "Reconditioner",
  "Sanitation Truck Cleaner",
  "Cleaners of Vehicles and Equipment",
  "Military Enlisted Tactical Operations and Air/Weapons Specialists and Crew Members, All Other",
  "Front Load Trash Truck Driver",
  "Garbage Collector",
  "Garbage Man",
  "Recycle Driver",
  "Rolloff Truck Driver",
  "Sanitation Laborer",
  "Swamper",
  "Trash Collector",
  "Refuse and Recyclable Material Collectors",
  "First-Line Supervisors of Weapons Specialists/Crew Members",
  "Bus Operator",
  "Charter Bus Driver",
  "Coach Operator",
  "Motor Coach Driver",
  "Motor Coach Operator",
  "Transit Bus Driver",
  "Transit Coach Operator",
  "Transit Driver",
  "Transit Operator",
  "Bus Drivers, Transit and Intercity",
  "Able Bodied Tankerman (AB Tankerman)",
  "Deck Engineer",
  "First Mate",
  "Mate",
  "Relief Master",
  "Relief Mate",
  "Tugboat Mate",
  "Vessel Master",
  "Mates- Ship, Boat, and Barge",
  "Bridge Operator",
  "Bridge Tender",
  "Lock Tender",
  "Bridge and Lock Tenders",
  "Mobile Crane Operator",
  "Overhead Crane Operator",
  "Port Crane Operator",
  "Scrap Crane Operator",
  "Winchman/Crane Operator",
  "Woodyard Crane Operator",
  "Crane and Tower Operators",
  "Ambulance Attendant",
  "Ambulance Driver",
  "Chair Car Driver",
  "CPR Ambulance Driver (Cardio Pulmonary Resuscitation Ambulance Driver)",
  "Driver Medic",
  "Emergency Care Attendant (ECA)",
  "EMS Driver (Emergency Medical Services Driver)",
  "Medical Van Driver (Medi-Van Driver)",
  "Ambulance Drivers and Attendants, Except Emergency Medical Technicians",
  "Bulk Delivery Driver",
  "Driver/Merchandiser",
  "Package Car Driver",
  "Package Delivery Driver",
  "Service Provider",
  "Light Truck or Delivery Services Drivers",
  "Brakeman",
  "Carman",
  "Locomotive Switch Operator",
  "Railroad Brakeman",
  "Railroad Switchman",
  "Switch Foreman",
  "Terminal Carman",
  "Trains Service Conductor",
  "Railroad Brake, Signal, and Switch Operators",
  "Airport Shuttle Driver",
  "Cab Driver",
  "Chauffeur",
  "Limo Driver (Limousine Driver)",
  "Shuttle Driver",
  "Taxi Cab Driver",
  "Taxi Driver",
  "Van Driver",
  "Taxi Drivers and Chauffeurs",
  "Field Operator",
  "Lease Attendant",
  "Lease Operator",
  "Oilfield Plant Operator",
  "Well Field Technician",
  "Well Head Pumper",
  "Wellhead Pumper",
  "Wellhead Pumpers",
  "Dredge Operator",
  "Dredger",
  "Dredge Operators",
  "Transportation Workers, All Other",
  "Infantry",
  "Coal Hauler Operator",
  "Ram Car Operator",
  "Mine Shuttle Car Operators",
  "Airline Captain",
  "Airline Pilot",
  "Airline Pilot (Captain)",
  "Airline Transport Pilot",
  "Co-Pilot",
  "Commuter Pilot",
  "Airline Pilots, Copilots, and Flight Engineers",
  "Boat Captain",
  "Charter Boat Captain",
  "Ferry Boat Captain",
  "Harbor Tug Captain",
  "Relief Captain",
  "Ship Captain",
  "Tug Captain",
  "Tugboat Captain",
  "Ship and Boat Captains",
  "Captains, Mates, and Pilots of Water Vessels",
  "Air Crew Members",
  "Aircraft Inspector",
  "Aircraft Quality Control Inspector",
  "Airworthiness Safety Inspector",
  "Aviation Safety Inspector",
  "Aviation Safety Inspector, Manufacturing",
  "Avionics Safety Inspector",
  "Quality Control Inspector",
  "Technical Inspector",
  "Aviation Inspectors",
  "Air Crew Officers",
  "Backhoe Operator",
  "Dragline Oiler",
  "Dragline Operator",
  "Excavator Operator",
  "Pit Operator",
  "Excavating and Loading Machine and Dragline Operators",
  "Decaler",
  "Glass Decorator",
  "In Mold Coater",
  "Pottery Decorator",
  "Silk-Screen Operator",
  "Painting, Coating, and Decorating Workers",
  "Front Services Agent",
  "Guest Services Agent",
  "Hiker",
  "Parking Attendant",
  "Parking Cashier",
  "Parking Lot Attendant",
  "Parking Ramp Attendant",
  "Valet Attendant",
  "Valet Parker",
  "Valet Parking Attendant",
  "Parking Lot Attendants",
  "Special Forces Officers",
  "First-Line Supervisors of All Other Tactical Operations Specialists",
  "Gaffer",
  "Glass Bender",
  "Glass Lathe Operator",
  "Glass Tube Bender",
  "Glassblower",
  "Neon Glass Bender",
  "Neon Tube Bender",
  "Glass Blowers, Molders, Benders, and Finishers",
  "Car Repairman",
  "Railcar Switcher",
  "Switch Crew Supervisor",
  "Switchman",
  "Yard Engineer",
  "Rail Yard Engineers, Dinkey Operators, and Hostlers",
  "Combined Rail Operator",
  "Light Rail Operator",
  "Light Rail Vehicle Operator (LRV Operator)",
  "Rail Operator",
  "Rapid Transit Operator (RTO)",
  "Streetcar Operator",
  "Train Operator",
  "Trolley Operator",
  "Subway and Streetcar Operators",
  "Assistant Passenger Locomotive Engineer",
  "Locomotive Firers",
  "Dock Supervisor",
  "Driver Manager",
  "On Car Supervisor",
  "Street Supervisor",
  "First-Line Supervisors of Transportation and Material-Moving Machine and Vehicle Operators",
  "Electrical Traveling Overhead Crane Operator (ETOC Operator)",
  "Hoist Operator",
  "Hoistman",
  "Winch Derrick Operator",
  "Hoist and Winch Operators",
  "Material Moving Workers, All Other",
  "Compressor Operator",
  "Compressor Technician",
  "Fill Plant Operator",
  "Filler",
  "Liquefied Natural Gas Plant Operator (LNG Plant Operator)",
  "Pipeline Technician",
  "Terminal Operator",
  "Gas Compressor and Gas Pumping Station Operators",
  "Line Haul Driver",
  "Log Truck Driver",
  "Over the Road Driver (OTR Driver)",
  "Production Truck Driver",
  "Road Driver",
  "Semi Truck Driver",
  "Tractor Trailer Operator",
  "Heavy and Tractor-Trailer Truck Drivers",
  "Cargo Surveyor",
  "Marine Cargo Surveyor",
  "Petroleum Inspector",
  "Freight and Cargo Inspectors",
  "First-Line Supervisors of Air Crew Members",
  "Chipper Operator",
  "Debarker Operator",
  "Flumer",
  "Package Line Operator",
  "Packaging Line Operator",
  "Packing Line Operator",
  "Strapper Operator",
  "Conveyor Operators and Tenders",
  "Boat Pilot",
  "Docking Pilot",
  "Harbor Pilot",
  "Marine Pilot",
  "Relief Docking Master",
  "River Pilot",
  "Ship Pilot",
  "State Pilot",
  "Towboat Pilot",
  "Pilots, Ship",
  "Checker Loader",
  "Fork Lift Technician",
  "Fork Truck Driver",
  "Forklift Driver",
  "Forklift Operator",
  "Lift Truck Operator",
  "Shag Truck Driver",
  "Spotter Driver",
  "Tow Motor Operator",
  "Industrial Truck and Tractor Operators",
  "Command and Control Center Specialists",
  "Flight Attendant",
  "Flight Attendant, Inflight Services",
  "Flight Attendant/Air Transportation Supervisor",
  "Flight Attendant/Inflight Manager",
  "Flight Attendant/Inflight Supervisor",
  "In-Flight Crew Member",
  "International Flight Attendant",
  "Lead Instructor/Flight Attendant",
  "Purser",
  "Flight Attendants",
  "Certified Refrigeration Operator",
  "Freezer Operator",
  "Freezer Person",
  "Ice Cream Maker",
  "Refrigeration Supervisor",
  "Refrigeration Technician",
  "Cooling and Freezing Equipment Operators and Tenders",
  "Car Inspector",
  "Chief Mechanical Officer (CMO)",
  "Emissions Inspector",
  "Quality Assurance Inspector",
  "Railroad Track Inspector",
  "Smog Technician",
  "Transit Vehicle Inspector",
  "Transportation Vehicle, Equipment and Systems Inspectors, Except Aviation",
  "Armored Assault Vehicle Officers",
  "Artillery and Missile Crew Members",
  "Day Light Relief Operator",
  "Logistics Technician",
  "Pipeline Operator",
  "Pump Station Operator",
  "Purification Operator",
  "Pump Operators, Except Wellhead Pumpers",
  "Armored Assault Vehicle Crew Members",
  "Military Officer Special and Tactical Operations Leaders, All Other",
  "Transportation Inspectors",
  "Cotton Tipper",
  "Feeder",
  "Lug Loader",
  "Machine Feeder",
  "Offbearer",
  "Sawmill Worker",
  "Sewing Line Baler",
  "Tube Puller",
  "Machine Feeders and Offbearers",
];
