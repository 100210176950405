import React, { useEffect, useState, useCallback, useRef } from "react";
import { debounce } from 'lodash'
import { Grid, Divider } from "@material-ui/core";
import { MessageDetail } from "../components/MessageDetail";
import { MessagesList } from "../components/MessagesList";
import { shallowEqual, useSelector } from "react-redux";
import { socketService } from "../../../services/socket";
import { ChatThreadEventAction, MessegeThreadEventAction, UserMessegeTypingEventAction } from "../api/rxEvents";
import { fetchThreads, fetchSearchThreads } from '../api'



export const MessegesComponent = (props) => {


  const { user } = useSelector(({ auth }) => auth, shallowEqual);

  // const meId = user.type === 'COACH' ? "618325a8ed58e1ff81119e88" : "6183260da0716aff98f48b37"
  const [selectedThread, setSelectedThread] = useState()

  const [chat, setChat] = useState([])
  const [threads, setThreads] = useState([])
  const [search, setSearch] = useState('')
  const [searchBy, setSearchBy] = useState('messages')

  const [isArchived, setIsArchived] = useState(false)
  const childRef = useRef()

  const [loading, setLoading] = useState(false)

  const fetchChat = useCallback(async (arc = false, s = '') => {
    setLoading(true)
    let data = await fetchThreads(user.id, props.jobSeek ? 'jobseeker' : 'c', arc, s)
    if (props.jobSeek) {
      setThreads([...data])
      setLoading(false)
      return
    }
    setLoading(false)
    setChat([...data])
  }, [])


  const fetchBySearch = async (s) => {
    setLoading(true)
    let data = await fetchSearchThreads(user.id, props.jobSeek ? 'jobseeker' : 'c', s, searchBy)
    if (props.jobSeek) {
      setThreads([...data])
      setLoading(false)
      return
    }
    setChat([...data])
    setLoading(false)
  }

  const onSelectThread = (thread, next = false) => {
    let newThread = thread
    if (next) {
      if (props.jobSeek) {
        let t = threads.map((t, index) => {
          if (t._id === thread._id) {
            if (threads[index + 1]) {
              newThread = threads[index + 1]
            } else if (threads[index - 1]) {
              newThread = threads[index - 1]
            } else {
              newThread = undefined
            }
          }
          return t
        })
      } else {
        let c = chat.map((c) => {
          return c.threads.map((t, index) => {
            if (t._id === thread._id) {
              if (c.threads[index + 1]) {
                newThread = c.threads[index + 1]
              } else if (c.threads[index - 1]) {
                newThread = c.threads[index - 1]
              } else {
                newThread = undefined
              }
            }
            return t
          })
        })
      }
    }
    setSelectedThread(newThread)
  }


  const onDeSelectThread = () => {
    setSelectedThread(undefined)
  }

  const onAppendData = (data) => {
    let isThreadFind = false
    if (props.jobSeek) {
      setThreads(prevThreads => {
        let newThreads = prevThreads.map((t) => {
          if (t._id === data.threadId) {
            t.messages = [...t.messages, { ...data }]
            isThreadFind = true
          }
          return t
        })
        return newThreads
      })

    } else {
      setChat(prevChat => {
        let newChat = prevChat.map((c) => {
          let newThreads = [...c.threads]
          newThreads = newThreads.map((t) => {
            if (t._id === data.threadId) {
              t.messages = [...t.messages, { ...data }]
              isThreadFind = true
            }
            return t
          })
          c.threads = newThreads
          return c
        })



        return newChat
      })
    }

    if (!isThreadFind) {
      fetchChat(isArchived, search)
    }
  }

  const onSocketRecieve = () => {

    socketService.removeListener(user.id)
    socketService.on(user.id, (data) => {
      if (data.eventType === "message") {
        childRef.current.appendMessages(data)
        onAppendData(data)
      }

      if (data.eventType === "chat") {
        fetchChat(isArchived, search)
      }

      if (data.eventType === "user-typing") {
        UserMessegeTypingEventAction.triggerAction(data)
      }

    })

    // socketService.on("disconnect", () => {
    //   console.log(socketService.id); // undefined
    // });
  }


  const onChangeSearch = (e) => {
    if (e.target.value) {
      setSearch(e.target.value)
      fetchBySearch(e.target.value)
    } else {
      setSearch(e.target.value)
      fetchChat(isArchived)
    }


  }

  // const setDebounce = (fetchBySearch(search), 300)

  const onGetArchived = (value) => {

    setIsArchived(value)
    fetchChat(value, search)
  }


  useEffect(() => {
    fetchChat()
    onSocketRecieve()
  }, [])

  const onSetSearchBy = (value) => {

    setSearchBy(value)
  }

  useEffect(() => {
    if (((chat && chat.length > 0) || (threads && threads.length > 0)) && selectedThread && selectedThread._id) {
      if (props.jobSeek) {
        let newThreads = threads.map((t) => {
          if (t._id === selectedThread._id) {
            let ids = t.messages.filter((m) => !m.readBy.includes(user.id))
            if (ids.length > 0) {
              ids = ids.map((i) => i._id)
              
              socketService.emit("readBy", { userId: user.id, type: user.type, threadId: t._id })
            }

            let messages = t.messages.map((m) => {
              if (!m.readBy.includes(user.id)) {
                let rB = [...m.readBy, user.id]
                m.readBy = rB
              }
              return m
            })
            t.messages = messages
          }
          return t
        })
        setThreads([...newThreads])
      } else {
        let newChat = chat.map((c) => {
          let newThreads = c.threads.map((t) => {
            if (t._id === selectedThread._id) {
              let ids = t.messages.filter((m) => !m.readBy.includes(user.id))
              if (ids.length > 0) {
                ids = ids.map((i) => i._id)
                
                socketService.emit("readBy", { userId: user.id, type: user.type, threadId: t._id })
              }

              let messages = t.messages.map((m) => {
                if (!m.readBy.includes(user.id)) {
                  let rB = [...m.readBy, user.id]
                  m.readBy = rB
                }
                return m
              })
              t.messages = messages
            }
            return t
          })
          c.threads = newThreads
          return c
        })
        setChat([...newChat])
      }
    }
  }, [selectedThread])

  return (
    <>
      <Grid container spacing={5} className="mb-5">
        <Grid item xs={4}>
          <MessagesList
            {...props}
            chat={chat}
            threads={threads}
            user={user}
            fetchChat={() => fetchChat(isArchived, search)}
            onGetArchived={onGetArchived}
            onChangeSearch={onChangeSearch}
            onSelectThread={onSelectThread}
            selectedThread={selectedThread}
            onSetSearchBy={onSetSearchBy}
            searchBy={searchBy}
            loading={loading}
          />
        </Grid>
        <Grid item xs={8} >
          <MessageDetail {...props} ref={childRef} thread={selectedThread} onAppendData={onAppendData} />
        </Grid>
      </Grid>
    </>
  );
}