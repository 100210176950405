import React from 'react'
import {CoachMessege} from '../modules/CoachModule/Messege/Page/CoachMessege'
import {JobSeekMessege} from '../modules/JobSeekerModule/Messege/Page/JobSeekMessege'

export const JobSeekerMessagePage = () => {
return <JobSeekMessege />
}

export const CoachMessagePage = () => {
return <CoachMessege />
}