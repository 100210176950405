import React from "react";

import { Grid, Box, Typography } from "@material-ui/core";

import { toAbsoluteUrl } from "../../_metronic/_helpers/AssetsHelpers";

const imageList = [
  {
    path: "/media/img/profiler.png",
    title: "interest profiler",
    link: "https://profiler.careerroshi.com/",
  },
  {
    path: "/media/img/resume-builder.png",
    title: "resume builder",
    link: "https://resume.careerroshi.com/",
  },
  {
    path: "/media/img/coming-soon.png",
    title: "",
    link: "",
  },
  {
    path: "/media/img/occupation-browser.png",
    title: "occupation browser",
    link: "https://careerbrowser.careerroshi.com/",
  },
  {
    path: "/media/img/college-browser.png",
    title: "college browser",
    link: "https://college.careerroshi.com/",
  },
  {
    path: "/media/img/job-board.png",
    title: "job board",
    link: "https://jobs.careerroshi.com/",
  },
];

export function ApplicationsList() {
  return (
    <>
      <Grid container justify="center" direction="column">
        <Grid item container direction="row" justify="center">
          <Grid item>
            <Typography
              variant="h4"
              style={{ color: "#5c6164", fontWeight: "bolder" }}
              className="text-center   text-uppercase"
            >
              Digital Career Hub
            </Typography>
            <Typography
              variant="h6"
              className="text-center font-weight-bold text-uppercase"
            >
              Discover. Prepare. Achieve
            </Typography>
          </Grid>
        </Grid>
        <Grid item container justify="center" direction="row">
          {imageList.slice(0, 3).map((i, index) => (
            <Grid item sm={12} md={3} alignContent="center">
              <a
                href={i.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <Box my={10} className="position-relative">
                  <img
                    className="img-fluid"
                    height="50"
                    width={i.title ? "200" : "270"}
                    src={toAbsoluteUrl(i.path)}
                    alt={i.title}
                  />
                  <Typography
                    variant="subtitle2"
                    className="position-absolute font-weight-bold text-uppercase text-center"
                    style={{
                      width: "200px",
                      bottom: "28px",
                      right: 0,
                      left: 0,
                    }}
                  >
                    {i.title}
                  </Typography>
                </Box>
              </a>
            </Grid>
          ))}
        </Grid>

        <Grid item container justify="center" direction="row">
          {imageList.slice(3, imageList.length).map((i, index) => (
            <Grid item sm={12} md={3} alignContent="center">
              <a
                href={i.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <Box
                  mb={10}
                  className="position-relative"
                  ml={index === 2 ? "35px" : ""}
                >
                  <img
                    className="img-fluid"
                    height="50"
                    width={i.title ? "200" : ""}
                    src={toAbsoluteUrl(i.path)}
                    alt={i.title}
                  />
                  <Typography
                    variant="subtitle2"
                    className="position-absolute font-weight-bold text-uppercase text-center"
                    style={{
                      width: "200px",
                      bottom: "28px",
                      right: 0,
                      left: 0,
                    }}
                  >
                    {i.title}
                  </Typography>
                </Box>
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
