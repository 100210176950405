import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { CoachDashboardPage } from "./pages/DashboardPage";
import { RedirectLogin } from "./modules/Auth/pages/RedirectLogin";
import {CoachMessagePage} from './pages/MessegePage'
import { ApplicationsList } from "./pages/AllApplicationList";

export default function CoachBasePage({ redirect, redirectPath }) {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  console.log("**************************************");
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {redirect && redirect !== "self" ? (
          <Redirect
            exact
            from="/"
            to={`/login?redirect=${redirect}&redirectPath=${redirectPath}`}
          />
        ) : (
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        )}
        <ContentRoute path="/dashboard" component={CoachDashboardPage} />
        <ContentRoute path="/applications" component={ApplicationsList} />
        <ContentRoute path="/messeges" component={CoachMessagePage} />

        <ContentRoute path="/login" component={RedirectLogin} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
