import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import * as authCrud from "../_redux/authCrud";

export function RedirectLogin({ location }) {
  const redirectTo = new URLSearchParams(location.search).get("redirect");
  const redirectPath = new URLSearchParams(location.search).get("redirectPath");
  const history = useHistory();
  if (!redirectTo || redirectTo === "self") {
    history.push("/");
  }
  useEffect(() => {
    redirect();
  });
  const { authToken } = useSelector(({ auth }) => auth, shallowEqual);
  const redirect = async () => {
    if (authToken) {
      await authCrud.getUserByToken();
      if (redirectTo === "jobboard") {
        window.location.href =
          process.env.REACT_APP_JOBS_LOGIN_REDIRECT_URL +
          "/#/jobs/login/?token=" +
          authToken +
          "&redirectPath=" +
          redirectPath;
      } else if (redirectTo === "careerbrowser") {
        window.location.href =
          process.env.REACT_APP_CAREER_BROWSER_HOST +
          "/#/auth/login/?token=" +
          authToken +
          "&redirectPath=" +
          redirectPath;
      } else if (redirectTo === "resumebuilder") {
        window.location.href =
          process.env.REACT_APP_RESUME_BUILDER_HOST +
          "/#/auth/login/?token=" +
          authToken +
          "&redirectPath=" +
          redirectPath;
      } else if (redirectTo === "interestprofiler") {
        window.location.href =
          process.env.REACT_APP_INTEREST_PROFILER_HOST +
          "/#/auth/login/?token=" +
          authToken +
          "&redirectPath=" +
          redirectPath;
      } else if (redirectTo === "college") {
        window.location.href =
          process.env.REACT_APP_COLLEGE_HOST +
          "/#/auth/login/?token=" +
          authToken +
          "&redirectPath=" +
          redirectPath;
      } else {
        history.push("/");
      }
    }
  };

  return <Box>Redirecting.....</Box>;
}
