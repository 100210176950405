import React, {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Subscription } from "rxjs";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import RefreshIcon from "@material-ui/icons/Refresh";
// import SendIcon from "@material-ui/icons/Send";
// import UploadIcon from "@material-ui/icons/CloudUpload";
import { Message } from "./Message";
import { Messages } from "../data/messages";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { archiveChatApi, fetchMesseages, sendMessage } from "../api";
import { getInitials } from "../../../../_metronic/_helpers/AssetsHelpers";
import { shallowEqual, useSelector } from "react-redux";
import { socketService } from "../../../services/socket";
import { WriteMessege } from "./WriteMesseg";
import {
  OnlineUsersEventAction,
  UserMessegeTypingEventAction,
} from "../api/rxEvents";
import Loader from "./Loader";

// const socketService = io('http://localhost:4000')
const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    height: "85vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  chatBody: {
    position: "relative",
    flex: 1,
    overflowY: "scroll",
    scrollbarWidth: "none",
    scrollbarColor: "red",
    scrollbarWidth: "0",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  titleBtns: {
    padding: 0,
    color: "grey",
    fontWeight: 400,
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  appearDotGreen: {
    background: "#44B700",
    position: "absolute",
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    border: "2px solid white",
    zIndex: 3,
    bottom: 0,
  },
  appearDotGrey: {
    background: "grey",
    position: "absolute",
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    border: "2px solid white",
    zIndex: 3,
    bottom: 0,
  },
  typingText: {
    color: 'grey',
    marginTop:10
  },
}));
export const MessageDetail = forwardRef(
  ({ thread, jobSeek, onAppendData }, ref) => {
    const classes = useStyles();

    const subscribe = new Subscription();
    const { user } = useSelector(({ auth }) => auth, shallowEqual);
    // const user.id = user.type === 'COACH' ? "618325a8ed58e1ff81119e88" : "6183260da0716aff98f48b37"
    const [messages, setMessages] = useState([]);
    const [messagesLength, setMessagesLength] = useState(0);
    const [typing, setTyping] = useState();
    const [onlineUsers, setOnlineUsers] = useState([])
    const [loading, setLoading] = useState(false)


    const fetch = useCallback(async () => {
      setLoading(true)
      let data = await fetchMesseages(thread._id, { userId: user.id });
      setMessagesLength(data.length);
      setMessages([...data]);
      setLoading(false)

    });

    useImperativeHandle(ref, () => ({
      appendMessages: (data) => appendMessages(data),
    }));

    useEffect(() => {
      if (thread && thread._id && messages && messages.length > 0) {
        onSetScroll();

        // if (message.filter((m) => m.readBy.indexOf(user.id) === -1).length > 0) {
        //   socketService.emit("readBy", { userId: user.id, type: user.type, threadId: thread._idF })
        //   console.log("this readby socket")
        //   setMessagesLength(messages.length)
        // }
      }
    }, [messages]);

    const onSentMessage = (msg) => {
      let data = {
        threadId: thread._id,
        ...msg,
      };
      if (user.id === thread.cId._id) {
        data.sender = thread.cId;
        data.reciever = thread.jsId;
      }

      if (user.id === thread.jsId._id) {
        data.sender = thread.jsId;
        data.reciever = thread.cId;
      }

      data.readBy = [data.sender._id]
      let newMessages = [...messages, { ...data }];
      setMessages([...newMessages]);
      // sendMessage(data)
      onAppendData(data);
      socketService.emit("message", data);
      // fetch()
    };

    const onSetScroll = () => {
      var elem = document.getElementById("msg_id");

      if (elem) {
        elem.scrollTop = elem.scrollHeight;
      }
    };

    const onArchivingThread = async () => {
      let data = {
        type: user.type,
        threadId: thread._id,
        messageType: "thread",
      };

      let res = await archiveChatApi(data);
    };

    useEffect(() => {
      if (thread && thread._id) {
        fetch();
      }
    }, [thread]);

    useEffect(() => {
      socketService.on("connection", () => {
        // x8WIv7-mJelg7on_ALbx
      });
      const sub = UserMessegeTypingEventAction.listen.subscribe((status) => {
        setTyping({ ...status });
      });

      const sub2 = OnlineUsersEventAction.listen.subscribe((status) => {
        let values = Object.values(status)
        if (values.length > 0) {
          setOnlineUsers([...values])
        } else {
          setOnlineUsers([])
        }
      })

      subscribe.add(sub);
      subscribe.add(sub2);

      return () => {
        subscribe.unsubscribe();
      };
    }, []);

    const appendMessages = (data) => {
      if (thread && thread._id !== data.threadId) return;
      let newMessages = messages.concat([data]);
      setMessages([...newMessages]);
    };

    const getArchivedColor = () => {
      if (user.type === "COACH" && thread.coachArchived) {
        return { color: "red", disabled: true };
      }
      if (user.type === "JOB_SEEKER" && thread.jobSeekArchived) {
        return { color: "red", disabled: true };
      }

      return { color: "", disabled: false };
    };

    const userMessageTyping = (isTyping) => {
      let data = {};

      if (user.id === thread.cId._id) {
        data.typingUser = thread.cId;
        data.to = thread.jsId._id;
      } else {
        data.typingUser = thread.jsId;
        data.to = thread.cId._id;
      }
      data.threadId = thread._id
      socketService.emit("user-typing", { ...data, isTyping: isTyping });
    };

    const getOnlineUsers = () => {
      let cUser = user.type === "COACH" ? thread.jsId : thread.cId
      if (onlineUsers && onlineUsers.length > 0 && onlineUsers.includes(cUser._id)) {
        return true
      }
      return false
    }

    return (
      <>
        {/* <Loader/> */}
        <Grid className={classes.messageWrapper}>
          {thread && messages && messages.length > 0 && (
            <>
              <Grid
                justify="space-between"
                alignItems="center"
                direction="row"
                container
              
              >
                <Grid item>
                  <ListItem className='p-0'>
                    <ListItemAvatar style={{ position: "relative" }}>
                      <Avatar
                        alt={"not found"}
                        src={getInitials(
                          thread.jsId.firstName,
                          thread.jsId.lastName
                        )}
                      />
                      <div className={getOnlineUsers() ? classes.appearDotGreen : classes.appearDotGrey}></div>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<h3 className="mt-3">{thread.subject}</h3>}
                    // secondary={
                    //   isTyping
                    //     ?
                    //     <p>typing...</p>
                    //     :
                    //     null
                    // }
                    />
                  </ListItem>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => fetch()}
                    startIcon={<RefreshIcon />}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
              <Divider />

              <Grid className={classes.chatBody} id="msg_id">
                {messages &&
                  messages.length > 0 &&
                  messages.map((message) => (
                    <React.Fragment>
                      <Message
                        message={message}
                        me={message.sender._id === user.id}
                      />
                      {/* {message.user && <Message message={message.user} />} */}
                    </React.Fragment>
                  ))}
                  {typing && typing.isTyping && typing.threadId && typing.threadId === thread._id && (
                <Typography className={classes.typingText}>
                  {typing.typingUser.firstName} Typing...
                </Typography>
              )}
              </Grid>
              {loading && <Loader />}
              
              <WriteMessege
                onSentMessage={onSentMessage}
                userMessageTyping={userMessageTyping}
              />
            </>
          )}
        </Grid>
      </>
    );
  }
);
