import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import moment from "moment";

import { actions } from "./app/modules/Auth/_redux/authRedux";

import store from "./redux/store";

const cache = new InMemoryCache();
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HOST_URL,
});

const authLink = setContext((_, { headers }) => {
  const {
    auth: { authToken, tokenExpiresAt },
  } = store.getState();

  if (moment().isAfter(moment(tokenExpiresAt))) {
    store.dispatch(actions.logout());
    return;
  }
  return {
    headers: {
      ...headers,
      Authorization: authToken ? `${authToken}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      message === "must authenticate"
        ? store.dispatch(actions.logout())
        : console.log(`[GRAPGQL ERROR] message: ${message} locations: ${locations} path: ${path}`)
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export default new ApolloClient({
  cache: cache,
  link: authLink.concat(errorLink).concat(httpLink),

  name: "auth-service",
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});
