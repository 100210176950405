/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

import store from "../../../redux/store";
import { actions } from "../../../app/modules/Auth/_redux/authRedux";
import { Link } from "react-router-dom";

export function DropdownMenu4() {
  function logout() {
    store.dispatch(actions.logout());
  }

  return (
    <>
      {/*begin::Navigation*/}
      <ul className="navi navi-hover py-5">
        <li className="navi-item">
          <Link to="/" className="navi-link">
            <span className="navi-icon">
              <i className="material-icons">dashboard</i>
            </span>
            <span className="navi-text">Dashboard</span>
          </Link>
        </li>
        <li className="navi-item">
          <a href="#" onClick={logout} className="navi-link">
            <span className="navi-icon">
              <i className="flaticon-logout"></i>
            </span>
            <span className="navi-text">Logout</span>
            <span className="navi-link-badge"></span>
          </a>
        </li>
      </ul>

      {/*end::Navigation*/}
      {/*
      <ul className="navi navi-hover py-5">
        <li className="navi-item">
          <a href={process.env.REACT_APP_LOGIN_URL} className="navi-link">
            <span className="navi-icon">
              <i className="material-icons">dashboard</i>
            </span>
            <span className="navi-text">Dashboard</span>
          </a>
        </li>
      
       <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="material-icons">create</i>
            </span>
            <span className="navi-text">Edit</span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="material-icons">system_update_alt</i>
            </span>
            <span className="navi-text">Download</span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="material-icons">backup</i>
            </span>
            <span className="navi-text">Export to word</span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="material-icons">email</i>
            </span>
            <span className="navi-text">Send to email</span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="material-icons">share</i>
            </span>
            <span className="navi-text">Share</span>
          </a>
        </li> 

       <li className="navi-separator my-3"></li> 

       <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="material-icons">content_copy</i>
            </span>
            <span className="navi-text">Duplicate</span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" onClick={logout} className="navi-link">
            <span className="navi-icon">
              <i className="material-icons">delete</i>
            </span>
            <span className="navi-text">Delete</span>
          </a>
        </li>
      </ul>
       */}
    </>
  );
}
