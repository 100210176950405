import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Grid,
  Button,
  InputBase,
  makeStyles,
  ListItemSecondaryAction,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
function AlertMessage({open,close,action}) {
    return (
        <Grid item>
        <Dialog
          open={open}
          onClose={()=>close(!open)}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="100%"
          className="m-0"
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6">ALERT</Typography>
          </DialogTitle>
          <DialogContent>
           <p>Are you sure you want to delete this chat ?

           </p>
          </DialogContent>
          <DialogActions>
            <Grid
              className="px-5 mb-4"
              container
              direction="row"
              justify="flex-end"
            >
              <Grid className='d-flex'>
              <Button
                  variant="contained"
                  type='button'
                  onClick={()=>close(!open)}
                  className="px-5 ml-auto mr-5 d-flex align-items-center justify-content-center"
                >
                  <Typography
                    style={{ height: "20px" ,margin:"auto"}}
                  >
                    Cancel
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type='button'
                  onClick={()=>{action();close(!open)}}
                  className="px-7 ml-auto"
                >
                  <Typography
                    style={{ height: "20px", color: "white" }}
                  >
                    DELETE
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    )
}

export default AlertMessage
