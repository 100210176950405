import React from "react";
// import { CircleCharts } from "../../../../../_metronic/_partials/widgets";
import {
  SavedJobs,
  SavedResumes,
  SavedOccupations,
  Assessment,

} from "../components";

import { Typography } from "@material-ui/core";
export function JobSeekerDashboard() {
  return (
    <>
      <div className="row" style={{ marginBottom: "40px" }}>
        <div className="col-sm-12">
          <Typography variant="h4" className="font-weight-bold text-muted">
            Career Hub Dashboard
          </Typography>
        </div>
      </div>
     
      <div className="row gutter-b">
        <div className="col-sm-12">
          <SavedOccupations />
        </div>
      </div>
      <div className="row gutter-b">
        <div className="col-sm-12">
          <SavedResumes />
        </div>
      </div>
      <div className="row gutter-b">
        <div className="col-sm-12">
          <SavedJobs />
        </div>
      </div>
      <div className="row gutter-b">
        <div className="col-sm-12">
          <Assessment />
        </div>
      </div>
    </>
  );
}
