import React from "react";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Button,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { shallowEqual, useSelector } from "react-redux";
// import { gql, useQuery } from "@apollo/client";

import _ from "lodash";

import { Card } from "react-bootstrap";

const definitions = {
  Realistic: {
    title: "Realistic",
    description:
      "Realistic occupations frequently involve work activities that include practical, hands-on problems and solutions. They often deal with plants, animals, and real-world materials like wood, tools, and machinery. Many of the occupations require working outside, and do not involve a lot of paperwork or working closely with others.",
  },
  Investigative: {
    title: "Investigative",
    description:
      "Investigative occupations frequently involve working with ideas, and requirean extensive amount of thinking. These occupations can involve searching for facts and figuring out problems mentally.",
  },
  Artistic: {
    title: "Artistic",
    description:
      "Artistic occupations frequently involve working with forms, designs and patterns. They often require self-expression and the work can be done without following a clear set of rules.",
  },
  Social: {
    title: "Social",
    description:
      "Social occupations frequently involve working with, communicating with, and teaching people. These occupations often involve helping or providing service to others.",
  },
  Enterprising: {
    title: "Enterprising",
    description:
      "Enterprising occupations frequently involve starting up and carrying out projects. These occupations can involve leading people and making many decisions. Sometimes they require risk taking and often deal with business.",
  },
  Conventional: {
    title: "Conventional",
    description:
      "Conventional occupations frequently involve following set procedures and routines. These occupations can include working with data and details more than with ideas. Usually there is a clear line of authority to follow.",
  },
};

const colors = [
  "rgba(231, 76, 60, 1)",
  "rgba(60, 115, 168,1)",
  "rgba(255, 205, 2, 1)",
  "rgba(13, 172, 80,1)",
  "rgba(154, 18, 179,1)",
  "rgba(243, 156, 18,1)",
];

export function Assessment({ selectedUserData }) {
  let quiz = {};
  quiz = selectedUserData;
  const { authToken } = useSelector(({ auth }) => auth, shallowEqual);

  // let quiz = {
  //   answers: "213424232143243123452324422342",
  //   isCompleted: true,
  //   isQuizTaken: true,
  //   results: {
  //     answers: "213424232143243123452324422342",
  //     end: 6,
  //     start: 1,
  //     total: 6,
  //     result: [
  //       {
  //         area: "Realistic",
  //         description:
  //           "People with Realistic interests like work that includes practical, hands-on problems and answers. Often people with Realistic interests do not like careers that involve paperwork or working closely with others. They like working with plants and animals; real-world materials like wood, tools, and machinery; and outside work.",
  //         score: 9,
  //       },
  //       {
  //         area: "Investigative",
  //         description:
  //           "People with Investigative interests like work that has to do with ideas and thinking rather than physical activity or leading people. They like searching for facts and figuring out problems.",
  //         score: 10,
  //       },
  //       {
  //         area: "Enterprising",
  //         description:
  //           "People with Enterprising interests like work that has to do with starting up and carrying out business projects. These people like taking action rather than thinking about things. They like persuading and leading people, making decisions, and taking risks for profits.",
  //         score: 9,
  //       },
  //       {
  //         area: "Social",
  //         description:
  //           "People with Social interests like working with others to help them learn and grow. They like working with people more than working with objects, machines, or information. They like teaching, giving advice, and helping and being of service to people.",
  //         score: 7,
  //       },
  //       {
  //         area: "Artistic",
  //         description:
  //           "People with Artistic interests like work that deals with the artistic side of things, such as acting, music, art, and design. They like creativity in their work and work that can be done without following a set of rules.",
  //         score: 7,
  //       },
  //       {
  //         area: "Realistic",
  //         description:
  //           "People with Realistic interests like work that includes practical, hands-on problems and answers. Often people with Realistic interests do not like careers that involve paperwork or working closely with others. They like working with plants and animals; real-world materials like wood, tools, and machinery; and outside work.",
  //         score: 9,
  //       },
  //     ],
  //   },
  // };
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#01a9f4",
      color: "white",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(16),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);
  return (
    <Card>
      {JSON.stringify(quiz) !== "{}" ? (
        <Card.Body>
          <Card.Title>
            <Grid container justify="space-between">
              <Grid item>Assessment</Grid>
              <Grid item>
                {quiz && quiz.isCompleted && (
                  <Button
                    color="primary"
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_INTEREST_PROFILER_HOST +
                        "/#/auth/login/?token=" +
                        authToken +
                        "&redirectPath=/")
                    }
                  >
                    View Detailed Results
                  </Button>
                )}
              </Grid>
            </Grid>
          </Card.Title>

          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {quiz && quiz.isCompleted ? (
              _.orderBy(quiz.results.result, ["score"], ["desc"]).map(
                (result, index) => (
                  <Box mr={2} mb={1}>
                    <Card>
                      <Card.Body>
                        <Box display="flex">
                          <Box
                            width={110}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box mb={2} display="flex" alignItems="center">
                              <Typography>{result.area}</Typography>
                              <HtmlTooltip
                                title={
                                  definitions[result.area].title +
                                  "--" +
                                  definitions[result.area].description
                                }
                                arrow
                              >
                                <IconButton aria-label="delete" size="small">
                                  <Icons.InfoRounded fontSize="inherit" />
                                </IconButton>
                              </HtmlTooltip>
                            </Box>
                            <Typography
                              variant="h3"
                              style={{ color: colors[index] }}
                            >
                              {result.score}
                            </Typography>
                          </Box>
                        </Box>
                      </Card.Body>
                    </Card>
                  </Box>
                )
              )
            ) : (
              <Typography style={{ color: "grey" }} variant="subtitle1">
                No Accessments taken
              </Typography>
            )}
          </Box>
        </Card.Body>
      ) : (
        <Card.Body>
          <Grid container direction="row" justify="center">
            <Grid item>
              <CircularProgress
                className="mx-1 text-center"
                style={{ color: "#3699FF" }}
              />
            </Grid>
          </Grid>
        </Card.Body>
      )}
    </Card>
  );
}
