import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import SearchIcon from "@material-ui/icons/Search";
import InsertInvitationOutlinedIcon from "@material-ui/icons/InsertInvitationOutlined";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";

import { Card } from "react-bootstrap";
import MuiAlert from "@material-ui/lab/Alert";

import {
  TablePagination,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  makeStyles,
  Typography,
  InputBase,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";

const GET_CLIENTS_LIST = gql`
  query Query(
    $getJobSeekerListByCoachPageNo: Int
    $getJobSeekerListByCoachLimit: Int
    $getJobSeekerListByCoachSearchText: String
  ) {
    getJobSeekerListByCoach(
      pageNo: $getJobSeekerListByCoachPageNo
      limit: $getJobSeekerListByCoachLimit
      searchText: $getJobSeekerListByCoachSearchText
    ) {
      jobSeekerList {
        id
        firstName
        lastName
        email
        cellPhone
        userStageJsonString
      }
      jobSeekerCount
      pageNo
    }
  }
`;

// add client
const REGISTER_USER = gql`
  mutation Mutation(
    $addJobSeekerFirstName: String!
    $addJobSeekerLastName: String!
    $addJobSeekerEmail: String!
    $addJobSeekerPassword: String!
  ) {
    addJobSeeker(
      firstName: $addJobSeekerFirstName
      lastName: $addJobSeekerLastName
      email: $addJobSeekerEmail
      password: $addJobSeekerPassword
    ) {
      code
      success
      message
    }
  }
`;
const SEND_INVITATION = gql`
  mutation SendInvitationMutation(
    $sendInvitationEmail: String
    $sendInvitationFirstName: String
    $sendInvitationLastName: String
  ) {
    sendInvitation(
      email: $sendInvitationEmail
      firstName: $sendInvitationFirstName
      lastName: $sendInvitationLastName
    ) {
      code
      success
      message
    }
  }
`;

// css
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  table: {
    minWidth: 1050,
  },
  tableHeadCell: {
    fontSize: "18px",
  },
  tableRowCell: {
    fontSize: "14px",
    borderBottom: "none",
    lineHeight: "40px",
    align: "left",
  },
  dropdown: {
    position: "absolute",
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f2f2f2",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "14px",
  },
  inputInput: {
    padding: theme.spacing(1.5, 1.5, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));
// end of css

const headers = ["Client Name", "Email", "Phone", "Stage"];

let rows = [];
let count;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export function ClientListTable({ setSelectedUser }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearchText] = React.useState("");
  const [DialogState, setDialogState] = React.useState(false);
  const [dialogStateForAddClient, setDialogStateForAddClient] = React.useState(
    false
  );

  const [clientInformation, setClientInformation] = React.useState({
    fName: "",
    lName: "",
    email: "",
    password: "",
  });
  const { data, refetch } = useQuery(GET_CLIENTS_LIST, {
    variables: {
      getJobSeekerListByCoachPageNo: page,
      getJobSeekerListByCoachLimit: rowsPerPage,
      getJobSeekerListByCoachSearchText: search,
    },
  });

  const [addClient, { loading: addClientLoading }] = useMutation(
    REGISTER_USER,
    {
      onCompleted(response) {
        if (response.addJobSeeker.code === "400") {
          setSnackBarText(response.addJobSeeker.message);
          setSnackbarSeverity("error");
          setSnackBarOpen(true);
        } else {
          setDialogStateForAddClient(false);
          setSnackbarSeverity("success");
          setSnackBarText("Client Added Successfully");
          setSnackBarOpen(true);
        }
      },
    }
  );
  const [snackBarText, setSnackBarText] = React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [sendInvitation, { loading: sendInvitationLoader }] = useMutation(
    SEND_INVITATION,
    {
      onCompleted(sendInvitation) {
        setDialogState(false);
        setSnackbarSeverity("success");
        setSnackBarOpen(true);
        setSnackBarText("Invitation sent Successfully");
      },
    }
  );

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const searchText = (event) => {
    setSearchText(event.target.value);

    if (event.target.value.length > 2) {
      refetch();
    } else if (event.target.value.length === 0) refetch();
  };

  const handleSelectedUser = (id) => {
    setSelectedUser(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    refetch();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDialogOpen = () => {
    setDialogState(true);
  };

  const handleDialogOpenForAddClient = () => {
    setDialogStateForAddClient(true);
  };
  const handleDialogClose = () => {
    setDialogState(false);
    setDialogStateForAddClient(false);
  };
  const classes = useStyles();

  if (typeof data === "object") {
    count = parseInt(data.getJobSeekerListByCoach.jobSeekerCount);
    rows = data.getJobSeekerListByCoach.jobSeekerList.map((key) => {
      // Stage
      let lastSelectedStage = "";
      if (key.userStageJsonString) {
        let stagesList = JSON.parse(key.userStageJsonString);
        Object.keys(stagesList).map((stages) => {
          if (typeof stagesList[stages] === "object") {
            Object.keys(stagesList[stages]).map((stage) => {
              if (stagesList[stages][stage]) {
                lastSelectedStage = stage;
              }
            });
          }
          if (typeof stagesList[stages] === "boolean") {
            if (stagesList[stages]) {
              lastSelectedStage = stages;
            } else {
              if (!lastSelectedStage.length)
                lastSelectedStage = "No stage selected";
            }
          }
        });
      } else {
        lastSelectedStage = "No stage selected";
      }
      let phone = "";
      if (key.cellPhone && key.cellPhone !== "0") {
        phone = key.cellPhone;
      }
      return {
        id: key.id,
        name: key.firstName + " " + key.lastName,
        email: key.email,
        phone: phone || "N/A",
        stage: lastSelectedStage,
      };
    });
  }
  const handleFirstName = (evt) => {
    setFirstName(evt.target.value);
  };
  const handleLastName = (evt) => {
    setLastName(evt.target.value);
  };
  const handleEmail = (evt) => {
    setEmail(evt.target.value);
  };
  const handleSendInvitation = () => {
    sendInvitation({
      variables: {
        sendInvitationEmail: email,
        sendInvitationFirstName: firstName,
        sendInvitationLastName: lastName,
      },
    });
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const handleAddClientInformation = (key, evt) => {
    setClientInformation({ ...clientInformation, [key]: evt.target.value });
  };

  const handleAddClient = () => {
    addClient({
      variables: {
        addJobSeekerFirstName: clientInformation.fName,
        addJobSeekerLastName: clientInformation.lName,
        addJobSeekerEmail: clientInformation.email,
        addJobSeekerPassword: clientInformation.password,
      },
    });
  };

  return (
    <Card className="shadow-sm">
      <Card.Body>
        <Grid container driection="column" className="px-5">
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search client by name, email and phone"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={searchText}
                />
              </div>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Grid item className="mr-10">
                  <Button
                    color="secondary"
                    className={classes.button}
                    onClick={handleDialogOpenForAddClient}
                    startIcon={
                      <GroupAddOutlinedIcon style={{ fontSize: 25 }} />
                    }
                  >
                    <Typography className="px-3" variant="h6">
                      Add Client
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    className={classes.button}
                    onClick={handleDialogOpen}
                    startIcon={
                      <InsertInvitationOutlinedIcon style={{ fontSize: 25 }} />
                    }
                  >
                    <Typography className="px-3" variant="h6">
                      Invite Client to Join you
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Dialog
              open={DialogState}
              onClose={handleDialogClose}
              aria-labelledby="form-dialog-title"
              maxWidth="sm"
              fullWidth="100%"
              className="m-0"
            >
              <DialogTitle id="form-dialog-title">
                <Typography variant="h6">INVITE CLIENT</Typography>
              </DialogTitle>
              <DialogContent>
                <input
                  className="form-control form-control-solid h-auto py-5 px-6 my-3"
                  autoFocus
                  margin="dense"
                  placeholder="First Name"
                  type="text"
                  onChange={handleFirstName}
                  required
                />
                <input
                  className="form-control form-control-solid h-auto py-5 px-6 my-3"
                  margin="dense"
                  id="lname"
                  placeholder="Last Name"
                  type="text"
                  onChange={handleLastName}
                  required
                />
                <input
                  className="form-control form-control-solid h-auto py-5 px-6 my-3"
                  margin="dense"
                  id="email"
                  placeholder="Email Address"
                  type="email"
                  onChange={handleEmail}
                  required
                />
              </DialogContent>
              <DialogActions>
                <Grid
                  className="px-5 mb-4"
                  container
                  direction="row"
                  justify="space-between"
                >
                  <Grid item>
                    <Button
                      size="large"
                      onClick={handleDialogClose}
                      variant={"contained"}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      onClick={handleSendInvitation}
                      color="secondary"
                      variant={"contained"}
                      className="px-10"
                      disabled={
                        firstName.length && lastName.length & email.length
                          ? false
                          : true
                      }
                    >
                      {sendInvitationLoader ? (
                        <CircularProgress
                          size="20px"
                          style={{
                            color: "#ffffff",
                            marginLeft: "9px",
                            marginRight: "9px",
                          }}
                          class=""
                        />
                      ) : (
                        <Typography style={{ width: "38px", height: "20px" }}>
                          Invite
                        </Typography>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item>
            <Dialog
              open={dialogStateForAddClient}
              onClose={handleDialogClose}
              aria-labelledby="form-dialog-title"
              maxWidth="sm"
              fullWidth="100%"
            >
              <DialogTitle id="form-dialog-title" className="mt-5 mb-3">
                <Typography variant="h5" className="text-center ">
                  ADD CLIENT
                </Typography>
              </DialogTitle>
              <DialogContent className="mx-12">
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <input
                      placeholder="First Name"
                      type="text"
                      className="form-control form-control-solid h-auto  py-5 px-6"
                      onChange={(evt) => {
                        handleAddClientInformation("fName", evt);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <input
                      placeholder="Last Name"
                      type="text"
                      className="form-control form-control-solid h-auto py-5 px-6 "
                      onChange={(evt) => {
                        handleAddClientInformation("lName", evt);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <input
                      placeholder="Email"
                      type="email"
                      className="form-control form-control-solid h-auto py-5 px-6 "
                      onChange={(evt) => {
                        handleAddClientInformation("email", evt);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <input
                      placeholder="Password"
                      type="password"
                      className="form-control form-control-solid h-auto py-5 px-6 "
                      onChange={(evt) => {
                        handleAddClientInformation("password", evt);
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="mb-5 mt-3">
                <Grid container direction="row" justify="center">
                  <Grid item>
                    <button
                      type="button"
                      onClick={handleAddClient}
                      className="btn btn-primary font-weight-bold px-9  my-3 mx-4"
                      disabled={
                        clientInformation.fName.length &&
                        clientInformation.lName.length &&
                        clientInformation.email.length &&
                        clientInformation.password.length
                          ? false
                          : true
                      }
                    >
                      {addClientLoading ? (
                        <CircularProgress
                          size="20px"
                          style={{ color: "#ffffff" }}
                          className="mx-3"
                        />
                      ) : (
                        <Typography className="my-1">Submit</Typography>
                      )}
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                      onClick={handleDialogClose}
                    >
                      Cancel
                    </button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Grid>

          <Grid item>
            <Snackbar
              open={snackBarOpen}
              autoHideDuration={5000}
              color="danger"
              onClose={handleSnackBarClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Alert
                onClose={handleSnackBarClose}
                style={{ fontSize: "16px" }}
                severity={snackbarSeverity}
              >
                {snackBarText}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>

        <TableContainer className="mt-10">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((item, index) => (
                  <TableCell
                    key={index}
                    align="left"
                    classes={{ root: classes.tableHeadCell }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow
                  // hover={true}
                  // selected={true}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleSelectedUser(row.id);
                  }}
                  key={index}
                >
                  <TableCell
                    classes={{
                      root: classes.tableRowCell,
                    }}
                    component="th"
                    scope="row"
                    align="left"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    classes={{ root: classes.tableRowCell }}
                  >
                    {row.email}
                  </TableCell>
                  <TableCell
                    align="left"
                    classes={{ root: classes.tableRowCell }}
                  >
                    {row.phone}
                  </TableCell>
                  <TableCell
                    align="left"
                    classes={{ root: classes.tableRowCell }}
                  >
                    <Typography class="text-capitalize">
                      {row.stage.replace(/([A-Z])/g, " $1").trim()}
                    </Typography>
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    classes={{ root: classes.tableRowCell }}
                  >
                    <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle id="dropdown-basic" className="border">
                        {" actions "}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{
                          lineHeight: 0,
                          left: -100,
                          top: 40,
                        }}
                      >
                        <Dropdown.Item
                          href="#"
                          className="p-6"
                          style={{ fontSize: "14px" }}
                        >
                          Block
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          className="p-6"
                          style={{ fontSize: "14px" }}
                        >
                          Unblock
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </TableCell>
                 */}
                </StyledTableRow>
              ))}
            </TableBody>
            <TablePagination
              count={count}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Card.Body>
    </Card>
    // </Box>
  );
}
