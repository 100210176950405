/* eslint-disable array-callback-return */
import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import MuiAlert from "@material-ui/lab/Alert";
import Moment from "moment";
import { useSelector } from "react-redux";

import "moment-timezone";
import { Autocomplete } from "@material-ui/lab";
import "./userProfile.css";
import { useDispatch } from "react-redux";

import {
  Paper,
  Grid,
  makeStyles,
  Typography,
  Divider,
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
  CircularProgress,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
// import { Dropdown } from "react-bootstrap";

// icons
import {
  faCheck,
  faPlusCircle,
  faTrashAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import {
  ExpandMore,
  ExpandLess,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";

import { actions } from "../../../Auth/_redux/authRedux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import male from "../images/male.png";
import female from "../images/female.png";

var occupations = require("./occupationsList.js");

// getting notes
const GET_NOTES = gql`
  query Query($getNotesJobSeekerId: String!) {
    getNotes(jobSeekerId: $getNotesJobSeekerId) {
      notes {
        _id
        value
        createdAt
        updatedAt
      }
    }
  }
`;

// user details
const USER_DETAILS = gql`
  query Query($getJobseekerUserId: String!) {
    getJobseeker(userId: $getJobseekerUserId) {
      id
      firstName
      lastName
      email
      profileImage
      invitedBy
      isEmailVerified
      userStageJsonString
      homePhone
      cellPhone
      address
      birthDate
      gender
      quiz {
        isCompleted
        isQuizTaken
        results {
          result {
            area
            score
            description
          }
          start
          end
          total
          answers
        }
      }
      isUserPlaced
      socialMediaHandles {
        linkedin
      }
      occupationInterest
    }
  }
`;

// update is placed

const UPDATE_USER_PLACED = gql`
  mutation Mutation(
    $updateUserUser: UpdatableUserFields
    $updateUserUserId: String!
  ) {
    updateUser(user: $updateUserUser, userId: $updateUserUserId) {
      success
    }
  }
`;

// getting alternate stage list
const ALTERNATE_STAGE_LIST = gql`
  query Query {
    getChooseStageJsonStr {
      jsonStr
    }
  }
`;

// update stage
const UPDATE_STAGE = gql`
  mutation UpdateUserMutation(
    $updateUserUserId: String!
    $updateUserUser: UpdatableUserFields
  ) {
    updateUser(userId: $updateUserUserId, user: $updateUserUser) {
      success
    }
  }
`;

// saving new notes

const ADD_NOTES = gql`
  mutation Mutation(
    $createNoteValue: String!
    $createNoteJobSeekerId: String!
  ) {
    createNote(value: $createNoteValue, jobSeekerId: $createNoteJobSeekerId) {
      code
      success
      message
    }
  }
`;

// delete note
const DELETE_NOTE = gql`
  mutation DeleteNoteMutation(
    $deleteNoteNoteId: String!
    $deleteNoteJobSeekerId: String!
  ) {
    deleteNote(noteId: $deleteNoteNoteId, jobSeekerId: $deleteNoteJobSeekerId) {
      code
      success
      message
    }
  }
`;

// update user details
const UPDATE_USER_DETAILS = gql`
  mutation Mutation($updateUserUser: UpdatableUserFields) {
    updateUser(user: $updateUserUser) {
      code
      success
      message
    }
  }
`;

// save occupation interest
const SAVE_OCCUPATION_INTEREST = gql`
  mutation UpdateUserMutation(
    $updateUserUserId: String!
    $updateUserUser: UpdatableUserFields
  ) {
    updateUser(userId: $updateUserUserId, user: $updateUserUser) {
      success
    }
  }
`;

// get activities
const GET_ACTIVITIES = gql`
  query Query($getActivitiesJobSeekerId: String) {
    getActivities(jobSeekerId: $getActivitiesJobSeekerId) {
      activities {
        _id
        value
        createdAt
        updatedAt
      }
    }
  }
`;

// imageimport { avatar } from "../images/avatar.png";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
  },
  tableRowCell: {
    borderBottom: "none",
    padding: "2px 15px",
  },
  buttons: {
    color: "inherit",
    backgroundColor: [""].join(""),
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export function UserProfile() {
  const { user } = useSelector((state) => state.auth);
  const selectedUser = user.id;
  const dispatch = useDispatch();

  const classes = useStyles();
  const [editUserInformation, setIsEditUserInformation] = React.useState(false);
  const [userInformation, setUserInformation] = React.useState({
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    home: user.homePhone || "",
    cell: user.cellPhone || "",
    gender: user.gender || "",
    dob: new Date(user.birthDate).toLocaleDateString() || "",
    linkedin: user.socialMediaHandles.linkedin || "",
    address: user.address || "",
    occupationInterest: user.occupationInterest || "",
  });
  const [
    alternateUserInformation,
    setAlternateUserInformation,
  ] = React.useState({
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    home: user.homePhone || "",
    cell: user.cellPhone || "",
    gender: user.gender || "",
    dob: new Date(user.birthDate).toLocaleDateString() || "",
    linkedin: user.socialMediaHandles.linkedin || "",
    address: user.address || "",
    occupationInterest: user.occupationInterest || "",
  });
  const [rootKeys, setRootKeys] = React.useState();
  const [allStages, setAllStages] = React.useState();
  const [collapseStages, setCollapseStages] = React.useState({});

  const loading = false;
  const [updateUserDetails, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER_DETAILS,
    {
      onCompleted() {
        dispatch(actions.requestUser());
        setSnackBarText("User Details Updated");
        setSnackBarOpenSuccess(true);
        setIsEditUserInformation(false);
        setAlternateUserInformation(userInformation);
      },
    }
  );

  const [updateUserPlaced] = useMutation(UPDATE_USER_PLACED, {
    onCompleted() {
      setSnackBarText("'User Placed' data updated ");
      setSnackBarOpenSuccess(true);
    },
  });
  const [updateStage] = useMutation(UPDATE_STAGE, {
    onCompleted() {
      setSnackBarText("Stage updated successfully");
      setSnackBarOpenSuccess(true);
    },
  });
  const [saveOccupationInterest] = useMutation(SAVE_OCCUPATION_INTEREST, {
    onCompleted() {
      setUserInformation({
        ...userInformation,
        occupationInterest: selectedOccupationInterest,
      });
      setSnackBarText("Occupation interest saved successfully");
      setSnackBarOpenSuccess(true);
    },
  });
  const [
    selectedOccupationInterest,
    setSelectedOccupationInterest,
  ] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [moreNotes, setMoreNotes] = React.useState(false);
  const [DialogState, setDialogState] = React.useState(false);
  const [confirmDelete, setDeleteState] = React.useState(false);
  const [deleteNoteId, setDeleteNoteId] = React.useState("");
  const [addNoteLoader, setAddNoteLoader] = React.useState(false);
  const [deleteNoteLoader, setDeleteNoteLoader] = React.useState("");
  const [newNote, setNewNote] = React.useState("");
  const [activities, setActivities] = React.useState([]);

  const { data: latestActivities } = useQuery(GET_ACTIVITIES, {
    variables: {
      getActivitiesJobSeekerId: selectedUser,
    },
    onCompleted() {
      let allActivities = latestActivities.getActivities.activities.map(
        (key) => {
          return { activity: key.value, date: new Date(key.createdAt) };
        }
      );
      setActivities(allActivities);
    },
  });

  const { data: notesList, refetch: notesRefetch } = useQuery(GET_NOTES, {
    variables: {
      getNotesJobSeekerId: selectedUser,
    },
  });
  const [createNote] = useMutation(ADD_NOTES, {
    onCompleted({ createNote }) {
      if (createNote) {
        notesRefetch();

        setDialogState(false);
        setSnackBarText("Note Added Successfully");
        setSnackBarOpenSuccess(true);
        setAddNoteLoader(false);
        setNewNote("");
      } else {
        setSnackBarOpenError(true);
      }
    },
  });
  const [deleteNote] = useMutation(DELETE_NOTE, {
    onCompleted({ deleteNote }) {
      if (deleteNote) {
        setDeleteState(false);
        handleSnackBarText("Note Deleted Successfully");
        setSnackBarOpenSuccess(true);
        setDeleteNoteLoader("");
        notesRefetch();
      } else {
        setSnackBarOpenError(true);
      }
    },
  });

  const [openAddNoteError, setSnackBarOpenError] = React.useState(false);
  const [openAddNoteSuccess, setSnackBarOpenSuccess] = React.useState(false);
  const [snackBarText, setSnackBarText] = React.useState("");
  const [notesLimit, setNotesLimit] = React.useState(3);
  const [notesoverflow, setNotesoverflow] = React.useState("hidden");
  const [notesListHeight, setNotesListHeight] = React.useState("100%");

  var newNotes = [];
  if (notesList) {
    newNotes = notesList.getNotes.notes.slice(0, notesLimit).map((note) => {
      return {
        createdAt: new Date(note.createdAt),
        value: note.value,
        id: note._id,
      };
    });
  }
  const handleClick = () => {
    setOpen(!open);
  };
  const handleStage = (key) => {
    let stage = "";
    Object.keys(collapseStages).map((mainStage) => {
      if (collapseStages[mainStage] === true) stage = mainStage;
    });
    setCollapseStages({
      ...collapseStages,
      [key]: !collapseStages[key],
      [stage]: false,
    });

    // setResume(!resume);
  };

  const handleSingleStage = (key) => {
    setOpen(false);
    setAllStages({ ...allStages, [key]: !allStages[key] });
    if (key === "User Placed") {
      let isUserPlaced = !allStages[key];

      updateUserPlaced({
        variables: {
          updateUserUser: {
            isUserPlaced: isUserPlaced,
          },
          updateUserUserId: selectedUser,
        },
      });
    }
  };
  const selectingStage = (mainStage, subStage) => {
    setOpen(!open);

    let updatedStage = allStages;
    if (subStage === "clearStatus") {
      Object.keys(allStages[mainStage]).map((key) => {
        updatedStage[mainStage][key] = false;
      });
    } else {
      updatedStage[mainStage][subStage] = !updatedStage[mainStage][subStage];
    }

    updateStage({
      variables: {
        updateUserUserId: selectedUser,
        updateUserUser: {
          userStageJsonString: JSON.stringify(updatedStage),
        },
      },
    });
  };

  const handleDialogOpen = () => {
    setDialogState(true);
  };

  const handleDialogClose = () => {
    setDialogState(false);
    setDeleteState(false);
  };

  const handleNewNote = (evt) => {
    setNewNote(evt.target.value);
  };
  const handleAddNote = () => {
    setAddNoteLoader(true);
    if (newNote.length > 0) {
      createNote({
        variables: {
          createNoteValue: newNote,
          createNoteJobSeekerId: selectedUser,
        },
      });
    }
  };

  const handleConfirmDelete = () => {
    deleteNote({
      variables: {
        deleteNoteNoteId: deleteNoteId,
        deleteNoteJobSeekerId: selectedUser,
      },
    });
    setDeleteNoteLoader(deleteNoteId);
  };
  const handleDeleteNote = (id) => {
    setDeleteNoteId(id);
    setDeleteState(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpenSuccess(false);
    setSnackBarOpenError(false);
  };

  const handleSnackBarText = (text) => {
    setSnackBarText(text);
  };

  const handleNotesLimit = () => {
    setMoreNotes(!moreNotes);
    if (notesLimit === 3) {
      setNotesLimit(notesList.getNotes.notes.length);
      setNotesoverflow("scroll");
      setNotesListHeight("300px");
    } else {
      setNotesLimit(3);
      setNotesoverflow("hidden");
      setNotesListHeight("100%");
    }
  };

  const handleIsEditUserInformation = () => {
    if (editUserInformation === true) {
      setUserInformation(alternateUserInformation);
    }
    setIsEditUserInformation(!editUserInformation);
  };

  const handleEditUserInformation = (evt, key) => {
    setUserInformation({ ...userInformation, [key]: evt.target.value });
  };

  const handleSaveUserInformation = () => {
    updateUserDetails({
      variables: {
        updateUserUser: {
          socialMediaHandles: {
            linkedin: userInformation.linkedin,
          },

          homePhone: userInformation.home,
          cellPhone: userInformation.cell,
          address: userInformation.address,
          gender: userInformation.gender,
          birthDate: userInformation.dob,
          // isUserPlaced: allStages["userPlaced"],
        },
      },
    });
  };

  const handleNewOccupation = async (key) => {
    if (key.keyCode === 13) {
      key.target.blur();
      // setSelectedOccupationInterest(key.target.value);
      await saveOccupationInterest({
        variables: {
          updateUserUserId: selectedUser,
          updateUserUser: {
            occupationInterest: key.target.value,
          },
        },
      });
    }
  };
  const handleOccupationInterest = (evt) => {
    occupations.map((key) => {
      if (key === evt.target.value) {
        evt.target.blur();
        setSelectedOccupationInterest(evt.target.value);
        saveOccupationInterest({
          variables: {
            updateUserUserId: selectedUser,
            updateUserUser: {
              occupationInterest: evt.target.value,
            },
          },
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <Grid container direction="row" justify="center">
          <Grid item>
            <CircularProgress className="mx-1" style={{ color: "#3699FF" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid Container direction="row" xs={10}>
          <Paper className={[classes.paper, "p-5"]}>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="space-between"
            >
              <Grid
                xs={4}
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                item
              >
                <Grid item className="text-center" xs={9}>
                  <Paper
                    style={{
                      backgroundColor: "#665ddb",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid
                      container
                      direction="cloumn"
                      className=" pb-3"
                      justify="center"
                      style={{ marginTop: "50px" }}
                    >
                      <Grid item>
                        <img
                          src={
                            userInformation.gender === "FEMALE" ? female : male
                          }
                          alt="dwf"
                          style={{
                            height: 100,
                            width: 100,
                            borderRadius: 100,
                            marginTop: "-50px",
                          }}
                        />
                      </Grid>
                      <Grid item container direction="row">
                        <Grid xs="12" item className="mt-2">
                          <Typography variant="h5" style={{ color: "#ffffff" }}>
                            {`${userInformation.firstName} ${userInformation.lastName}` ||
                              "--"}
                          </Typography>
                        </Grid>

                        <Grid item xs="12">
                          <Typography
                            variant="subtitle1"
                            style={{ color: "#ffffff" }}
                          >
                            {userInformation.occupationInterest || "--"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs="auto">
                <Divider variant="fullWidth" orientation="vertical" />
              </Grid>
              <Grid item container direction="column" xs={7}>
                {editUserInformation ? (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    className="px-5"
                    spacing={2}
                  >
                    <Grid item xs={6}>
                      <TextField
                        id="date"
                        label="Birthday"
                        type="date"
                        defaultValue={Moment(userInformation.dob).format(
                          "YYYY-MM-DD"
                        )}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onChange={(evt) => {
                          handleEditUserInformation(evt, "dob");
                        }}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      item
                      fullWidth
                      container
                      direction="row"
                      justify="start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <FormLabel component="legend">Gender : </FormLabel>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={userInformation.gender}
                          onChange={(evt) => {
                            handleEditUserInformation(evt, "gender");
                          }}
                          row
                        >
                          <FormControlLabel
                            value="MALE"
                            control={<Radio />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="FEMALE"
                            control={<Radio />}
                            label="Female"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-helperText"
                        label="Email"
                        defaultValue={userInformation.email}
                        variant="outlined"
                        color="secondary"
                        disabled
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="outlined-helperText"
                        label="Home"
                        type="number"
                        defaultValue={userInformation.home}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onChange={(evt) => {
                          handleEditUserInformation(evt, "home");
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-helperText"
                        label="Cell"
                        type="number"
                        defaultValue={userInformation.cell}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onChange={(evt) => {
                          handleEditUserInformation(evt, "cell");
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        id="outlined-helperText"
                        label="LinkedIn"
                        defaultValue={userInformation.linkedin}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onChange={(evt) => {
                          handleEditUserInformation(evt, "linkedin");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-helperText"
                        label="Address"
                        fullWidth
                        defaultValue={userInformation.address}
                        variant="outlined"
                        color="secondary"
                        onChange={(evt) => {
                          handleEditUserInformation(evt, "address");
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="space-between"
                    >
                      <Grid item>
                        <Button
                          onClick={handleIsEditUserInformation}
                          variant={"contained"}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={
                            updateUserLoading
                              ? () => {}
                              : handleSaveUserInformation
                          }
                          color="secondary"
                          variant={"contained"}
                          className="px-9 py-2"
                        >
                          {updateUserLoading ? (
                            <CircularProgress
                              size={20}
                              style={{
                                color: "#ffffff",
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            />
                          ) : (
                            <Typography
                              style={{ width: "30px", height: "20px" }}
                            >
                              SAVE
                            </Typography>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    direction="row"
                    spacing="2"
                    justify="start"
                    xs={12}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justify="start"
                      spacing={2}
                      xs={6}
                    >
                      <Grid item>
                        <Typography variant="h6">Date of Birth :</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {userInformation.dob && userInformation.dob.length
                            ? Moment(userInformation.dob).format("DD/MM/YYYY")
                            : "--/--/----"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="left"
                      spacing={2}
                      xs={6}
                    >
                      <Grid item>
                        <Typography variant="h6">Home :</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {userInformation.home || "--"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="left"
                      spacing={2}
                      xs={6}
                    >
                      <Grid item>
                        <Typography variant="h6">Gender :</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {userInformation.gender || "--"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="start"
                      spacing={2}
                      xs={6}
                    >
                      <Grid item>
                        <Typography variant="h6">Cell :</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {userInformation.cell || "--"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="start"
                      spacing={2}
                      xs={6}
                    >
                      <Grid item>
                        <Typography variant="h6">Email :</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {userInformation.email || "--"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="start"
                      spacing={2}
                      xs={6}
                    >
                      <Grid item>
                        <Typography variant="h6">LinkedIn :</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {userInformation.linkedin || "--"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="left"
                      spacing={2}
                      xs={12}
                    >
                      <Grid item>
                        <Typography variant="h6">Address :</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {userInformation.address || "--"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs="auto">
                <FontAwesomeIcon
                  size="2x"
                  icon={faEdit}
                  style={{ cursor: "pointer" }}
                  onClick={handleIsEditUserInformation}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </div>
  );
}
