export function removeCSSClass(ele, cls) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;


export const getInitials = (firstName , lastName ) => {
    const canvas = document.createElement('canvas')
    canvas.style.display = 'none'
    canvas.width = 200
    canvas.height = 200
    document.body.appendChild(canvas)
    const context = canvas.getContext('2d')
    if (context) {
        context.fillStyle = '#92A8B5'
        context.fillRect(0, 0, canvas.width, canvas.height)
        context.font = '75px Helvetica'
        context.fillStyle = '#FBFAF9'
    }
    let first
    let last
    first = firstName[0]
    last = lastName[0]
    if (last) {
        const initials = first + last
        context.fillText(
            initials.toUpperCase(),
            canvas.width / 4,
            canvas.height / 1.6
        )
    } else {
        const initials = first
        context.fillText(
            initials.toUpperCase(),
            canvas.width / 4,
            canvas.height / 1.6
        )
    }
    const data = canvas.toDataURL()
    document.body.removeChild(canvas)
    return data
 }
 
 