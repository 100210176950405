import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import moment from "moment";

import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  LogBackAsCoach: "[LOGIN COACH AGAIN] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  tokenExpiresAt: undefined,
  coachAccessToken: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "wff-auth",
    whitelist: ["user", "authToken", "tokenExpiresAt", "coachAccessToken"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, coachAccess } = action.payload;
        if (coachAccess) {
          return {
            authToken,
            coachAccessToken: state.authToken,
            user: undefined,
            tokenExpiresAt: moment()
              .add(2, "hour")
              .format(),
          };
        }
        return {
          authToken,
          user: undefined,
          tokenExpiresAt: moment()
            .add(2, "hour")
            .format(),
        };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return {
          authToken,
          user: undefined,
          tokenExpiresAt: moment()
            .add(2, "hour")
            .format(),
        };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.LogBackAsCoach: {
        return {
          authToken: state.coachAccessToken,
          user: undefined,
          tokenExpiresAt: moment()
            .add(2, "hour")
            .format(),
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, tokenExpiresAt, coachAccess) => ({
    type: actionTypes.Login,
    payload: { authToken, tokenExpiresAt, coachAccess },
  }),
  logBackAsCoach: () => ({
    type: actionTypes.LogBackAsCoach,
    payload: {},
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.LogBackAsCoach, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {
      data: { me: user },
    } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
