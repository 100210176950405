import React, { Fragment, useState } from "react";
import Linkify from 'react-linkify';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { getInitials } from "../../../../_metronic/_helpers/AssetsHelpers";
import moment from "moment";
import ImageViewPopup from './ImageViewPopup'
import { VideoPlayer } from "./VideoPlayer";

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    borderRadius: "10px 10px 10px 0px",
    background: "#d6d1d1",
    maxWidth: "80%",
    width: "fit-content",
    marginTop: 10,
  },
  inline: {
    display: "inline",
  },
  title: {
    fontWeight: 500,
  },
  messageTime: {
    fontSize: "0.8rem",
    marginLeft: 5,
  },
  subject: {
    color: "grey",
  },
  titleBtns: {
    padding: 0,
    color: "silver",
    fontWeight: 400,
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  secondaryAction: {
    height: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  secondaryActiontime: {
    fontWeight: 400,
    fontSize: 8,
  },
  message: {
    marginTop: 3,
  },
  myMessage: {
    marginLeft: "auto",
    background: "#FF4242",
    color: "white",
    borderRadius: "10px 10px 0 10px",
  },
  messageImg: {
    maxWidth: "100%",
    maxHeight: 220,
    margin: "auto",
  },
  pdfWrapper: {
    width: "100%",
    height: 40,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  }
}));

export function Message({ me, message }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onClickViewFile = () => {
    if (message.document) {
      window.open(message.document, "_blank")
    }

    if (message.image) {
      window.open(message.image, "_blank")
    }
  }
  const [viewImg, setViewImg] = useState(false)
  return (
    <Grid container direction="row" alignItems="flex-end">
      {!me && (
        <ListItemAvatar>
          <Avatar
            alt="ok"
            src={
              getInitials(message.sender.firstName, message.sender.lastName)
            }
          />
        </ListItemAvatar>
      )}
      <Grid
        item
        className={`${classes.messageWrapper} ${me && classes.myMessage}`}
      >
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginBottom: 7 }}
              >
                {!me && (
                  <Typography className={classes.title}>
                    {message.sender.firstName} {message.sender.lastName}
                  </Typography>
                )}

                <Typography
                  className={`${classes.messageTime} ${me && "text-white"}`}
                >
                  {message.createdAt ? moment(message.createdAt).format('lll') : moment().format('lll')}
                </Typography>

                {typeof message == "object" && (message.document || message.image) && (
                  <Grid container justify="flex-end">
                    <Grid
                      aria-controls="menu"
                      aria-haspopup="true"
                      style={{ cursor: "pointer" }}
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                      <MoreVertIcon fontSize="small" />
                    </Grid>
                    <Menu
                      id="menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={handleCloseMenu} onClick={() => onClickViewFile()}>View</MenuItem>
                      <MenuItem onClick={handleCloseMenu} onClick={() => onClickViewFile()}>Downlod</MenuItem>
                    </Menu>
                  </Grid>
                )}
              </Grid>
            }
            secondary={
              <React.Fragment>
                {
                  typeof message == "object" && message.document && (
                    <Grid className={classes.pdfWrapper}>
                      <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png'} className='img-fluid mr-3' width={30} />
                      <Typography>
                        <a href={message.document} target="_blank">{message.document.split('.com/')[1]}</a>
                      </Typography>
                    </Grid>
                  )
                }
                {typeof message == "object" && message.image && (
                  <Grid>
                    <img
                      src={message.image}
                      className={classes.messageImg}
                      alt="not founnd"
                      style={{ cursor: "pointer" }}
                      onClick={() => setViewImg(true)}
                    />
                  </Grid>
                )}

                {typeof message == "object" && message.video && (
                  <Grid>
                    <VideoPlayer
                      url={message.video}
                      className={classes.messageImg}
                     
                    />
                  </Grid>
                )}

                <Typography
                  className={`${classes.message} ${me && "text-white"}`}
                >
                  <Linkify>
                    {typeof message == "object" && message.message
                      ? message.message
                      : typeof message == "string"
                        ? message
                        : null}
                  </Linkify>
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </Grid>
      <ImageViewPopup img={message.image} open={viewImg} close={setViewImg} />
    </Grid>
  );
}
