import React from "react";
import { Card } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Link,
  CircularProgress,
  Snackbar,
  Box,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
// icons
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { gql, useMutation, useQuery } from "@apollo/client";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

const GET_SAVED_OCCUPATIONS = gql`
  query Query {
    getSavedOccupationForUser {
      savedOccupations {
        occupationId
        blsIndustryId
      }
      occupationsDetails {
        occupationId
        title
        description
        jobZoneId
      }
    }
  }
`;

const DELETE_OCCUPATION = gql`
  mutation DeleteSavedOccupationMutation(
    $deleteSavedOccupationOccupationId: String!
    $deleteSavedOccupationBlsIndustryId: String!
  ) {
    deleteSavedOccupation(
      occupationId: $deleteSavedOccupationOccupationId
      blsIndustryId: $deleteSavedOccupationBlsIndustryId
    ) {
      code
      success
      message
    }
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SavedOccupations() {
  const [open, setOpen] = React.useState(false);
  const [occupations, setOccupations] = React.useState({});
  const [deletedOccupation, setDeletedOccpuation] = React.useState("");
  const { loading, data: response } = useQuery(GET_SAVED_OCCUPATIONS, {
    onCompleted() {
      let data = {};
      data.occupationToIndustryMap = response.getSavedOccupationForUser.savedOccupations.reduce(
        (pre, cur) => {
          pre[cur.occupationId] = cur.blsIndustryId;
          return pre;
        },
        {}
      );
      data.occupations = response.getSavedOccupationForUser.occupationsDetails;
      setOccupations(data);
    },
  });

  const [deleteOccupation] = useMutation(DELETE_OCCUPATION, {
    onCompleted(response) {
      if (response.deleteSavedOccupation.code === "200") {
        let occupIndustry = occupations.occupationToIndustryMap;
        delete occupIndustry[deletedOccupation];
        let occup = occupations.occupations;
        const index = occup.findIndex(
          (x) => x.occupationId === deletedOccupation
        );
        occup = [...occup.slice(0, index), ...occup.slice(index + 1)];
        const updatedOccupations = {
          occupations: occup,
          occupationToIndustryMap: occupIndustry,
        };
        setOccupations(updatedOccupations);
        setOpen(true);
        setDeletedOccpuation("");
      }
    },
  });

  const handleDeleteOccupation = (occupationId) => {
    setDeletedOccpuation(occupationId);

    deleteOccupation({
      variables: {
        deleteSavedOccupationOccupationId: occupationId,
        deleteSavedOccupationBlsIndustryId:
          occupations.occupationToIndustryMap[occupationId],
      },
    });
  };
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Saved Occupations</Card.Title>
        {loading ? (
          <Box className="d-flex justify-content-center">
            <CircularProgress style={{ color: "#3699FF" }} />
          </Box>
        ) : (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" color="initial">
                      Title
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" color="initial">
                      Description
                    </Typography>
                  </TableCell>
                  <TableCell style={{ minWidth: "120px" }}>
                    <Typography align="center" variant="h6" color="initial">
                      Job Zone
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center" variant="h6" color="initial">
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {occupations.occupations &&
                  occupations.occupations.map((o, index) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <Link
                            href={`${
                              process.env.REACT_APP_CAREER_BROWSER_HOST
                            }/#/occupation/${
                              occupations.occupationToIndustryMap[
                                o.occupationId
                              ]
                            }/${o.occupationId}`}
                            color="secondary"
                            target="_blank"
                          >
                            <Typography variant="body1" color="initial">
                              {o.title}
                            </Typography>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" color="initial">
                            {o.description}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {o.jobZoneId ? (
                            <SVG
                              src={toAbsoluteUrl(
                                `/media/svg/jobzone/${o.jobZoneId}.svg`
                              )}
                            ></SVG>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {deletedOccupation === o.occupationId ? (
                            <CircularProgress
                              size="15px"
                              style={{ color: "red" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              color="red"
                              className="mx-3"
                              style={{
                                fontSize: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDeleteOccupation(o.occupationId);
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card.Body>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        color="danger"
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleSnackBarClose}
          style={{ fontSize: "16px" }}
          severity="success"
        >
          {"Resume deleted successfully"}
        </Alert>
      </Snackbar>
    </Card>
  );
}
