import React from "react";
import { useQuery, gql } from "@apollo/client";

import {
  Modal,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

const GET_RESUME_HTML = gql`
  query GetResume($getResumeResumeBody: String!, $getResumeTheme: String!) {
    getResume(resumeBody: $getResumeResumeBody, theme: $getResumeTheme) {
      html
    }
  }
`;

function getPreviewCardStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default ({ preview, onClose, resumeData, themeName, resumeName }) => {
  const classes = makeStyles((theme) => ({
    previewCard: {
      position: "absolute",
      width: `${60}%`,
      height: `${95}vh`,
    },
    cardContentStyle: {
      height: "100%",
      paddingBottom: 0,
    },
  }))();

  const { loading: getResumeLoading, data: getResumeData } = useQuery(
    GET_RESUME_HTML,
    {
      variables: {
        getResumeResumeBody: resumeData,
        getResumeTheme: themeName,
      },
      skip: !preview,
    }
  );

  return (
    <Modal
      open={!getResumeLoading && preview}
      onClose={() => onClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Card style={getPreviewCardStyle()} className={classes.previewCard}>
        <CardHeader
          className="pb-0 text-center"
          title={resumeName}
          action={
            <IconButton size="medium" onClick={() => onClose()}>
              <CloseRounded />
            </IconButton>
          }
        />
        <CardContent className={classes.cardContentStyle}>
          <iframe
            id="resumeIframe"
            title="resume-preview"
            frameBorder="0"
            width="100%"
            height="100%"
            srcDoc={getResumeData && getResumeData.getResume.html}
          ></iframe>
        </CardContent>
        {/* {currentTab !== "0" ? (
          <CardActions style={{ height: "5%" }} align="center">
            <Button
              variant="contained"
              color="primary"
              className="text-white"
              disabled={getPDFLoading}
              style={{ marginLeft: "auto", width: "118px" }}
              onClick={() => {
                // const iframeNode = document.getElementById("resumeIframe");
                // const doc = new jsPDF();
                // window.print(iframeNode);
                // print(iframeNode);
                pdfResume();
              }}
            >
              {getPDFLoading ? (
                <CircularProgress style={{color:"#3699FF"}} size="1.5rem" />
              ) : (
                "Export As PDF"
              )}
            </Button>
            <Button variant="contained" color="primary" className="text-white">
              Export As Text
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="text-white"
              style={{ width: "152px" }}
              disabled={!user || currentResumeDataHash === savedResumeHash}
              onClick={() => {
                saveResume({
                  variables: {
                    saveResumeResumeId: resumeId || null,
                    saveResumeResumeName: selectedResume,
                    saveResumeResumeData: JSON.stringify(resumeData),
                  },
                });
              }}
            >
              {saveResumeLoading ? (
                <CircularProgress style={{color:"#3699FF"}} size="1.5rem" />
              ) : (
                "Save to Dashboard"
              )}
            </Button>
          </CardActions>
        ) : (
          ""
        )} */}
      </Card>
    </Modal>
  );
};
