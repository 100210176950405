import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Grid,
  Button,
  InputBase,
  makeStyles,
  ListItemSecondaryAction,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import LockSharpIcon from "@material-ui/icons/LockSharp";
import { createChatApi, fetchJobSeekers } from "../api";
import { shallowEqual, useSelector } from "react-redux";

export function TypeMessage(props) {
  const { user } = useSelector(({ auth }) => auth, shallowEqual);

  const [selectedJobSeeker, setSelectedJobSeeker] = useState()
  const [subject, setSubject] = useState('')
  const [messege, setMessege] = useState('')
  const [jobseekers, setJobSeekers] = useState([])
  const fetchJobSeeckets = useCallback(async () => {
    let data = await fetchJobSeekers(user.id)
    setJobSeekers([...data])
  }, [])

  useEffect(() => {
    if (!props.jobSeek) {
      fetchJobSeeckets()
    }
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      subject,
      message: messege,
    }
    if (user.type === "COACH") {
      if (!selectedJobSeeker) return
      data.cId = user.id
      data.jsId = selectedJobSeeker._id
    } else {
      data.cId = user.invitedBy
      data.jsId = user.id
    }


    let createChat = await createChatApi(data)

    setSubject('')
    setMessege('')
    setSelectedJobSeeker(undefined)
    props.handlePopup()
  }

  return (
    <Grid item>
      <Dialog
        open={props.modal}
        onClose={props.handlePopup}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth="100%"
        className="m-0"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h6">NEW MESSAGE</Typography>
        </DialogTitle>
        <DialogContent>
          {!props.jobSeek ? (
            <Fragment>
              <input
                className="form-control h-auto py-2 px-6 my-3"
                autoFocus
                margin="dense"
                placeholder="Subject"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <select
                className="form-control w-75"
                style={{ marginTop: "3rem" }}
                placeholder="Select Recepient"
                value={!selectedJobSeeker ? '' : selectedJobSeeker._id}
                onChange={(e) => {
                  let find = jobseekers.find((u) => u._id === e.target.value)
                  setSelectedJobSeeker(find)
                }}
              >
                <option value={''} disabled>Select Recepient</option>
                {jobseekers && jobseekers.length > 0 && jobseekers.map((u, index) => (
                  <option value={u._id}>{u.firstName} {u.lastName}</option>
                ))}
              </select>
              <Grid item style={{ marginTop: "3.5rem" }}>
                <textarea
                  className="form-control"
                  placeholder="Write your message"
                  rows={5}
                  value={messege}
                  onChange={(e) => setMessege(e.target.value)}
                ></textarea>
              </Grid>
            </Fragment>
          ) : (
            <Grid item style={{ marginTop: "1.5rem" }}>
              <input
                className="form-control h-auto py-2 px-6 my-3"
                autoFocus
                margin="dense"
                placeholder="Subject"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <textarea
                className="form-control"
                placeholder="Write your message"
                rows={5}
                value={messege}
                onChange={(e) => setMessege(e.target.value)}
              ></textarea>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            className="px-5 mb-4"
            container
            direction="row"
            justify="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type='button'
                endIcon={
                  <SendIcon style={{ color: "white", fontSize: "1.2rem" }} />
                }
                className="px-10 ml-auto"
                onClick={onSubmit}
              >
                <Typography
                  style={{ width: "38px", height: "20px", color: "white" }}
                >
                  Send
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
