import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, ListItemSecondaryAction } from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import LockSharpIcon from "@material-ui/icons/LockSharp";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { deleteThreadsApi, archiveChatApi } from "../api";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AlertMessage from "./AlertMessage";
const useStyles = makeStyles((theme) => ({
  inline: {
    display: "inline",
  },
  title: {
    maxWidth: "60ch",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  message: {
    display: " -webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleBtns: {
    padding: 0,
    color: "silver",
    fontWeight: 400,
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  secondaryAction: {
    height: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  secondaryActiontime: {
    fontWeight: 400,
    fontSize: 8,
  },
  appearDot: {
    position: "absolute",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    zIndex: 3,
    bottom: 0,
  },
  appearDotGreen: {
    background: "#44B700",
    position: "absolute",
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    border: "2px solid white",
    zIndex: 3,
    bottom: 0,
  },
  appearDotGrey: {
    background: "grey",
    position: "absolute",
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    border: "2px solid white",
    zIndex: 3,
    bottom: 0,
  },
  appearDotYellow: {
    background: "#FFFF00",
    position: "absolute",
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    border: "2px solid white",
    zIndex: 3,
    bottom: 0,
  },
  delIcon: {
    // display:"none",
    // '&:hover':{
    //   display:"block"
    // }
  },
  messageCount: {
    width: 19,
    height: 19,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    background: "#FF4242",
    fontSize: "0.7rem",
  },
  menu:{
    marginTop:'2.8rem'
  }
}));

export function MessageItem({
  message,
  i,
  onSelectThread,
  selectedThread,
  user,
  fetchChat
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectedItem = () => {
    onSelectThread(message);
  };

  const [openAlert, setOpenAlert] = useState(false);
  const onDeleteThread = async () => {
    onSelectThread(message, true);
    let chat = await deleteThreadsApi({ ids: [message._id], userId: user.id });
  };

  const onArchivingThread = async () => {
    let data = {
      type: user.type,
      threadId: message._id,
      messageType: "thread",
    };

    let res = await archiveChatApi(data);
    fetchChat()
  };

  const onGetLastMessage = () => {
    if (!message) return;
    let lastMessage = message.messages[message.messages.length - 1];
    if (!lastMessage) return;
    if (lastMessage.message) {
      return lastMessage.message;
    }

    if (lastMessage.image) {
      return lastMessage.image.split(".com/")[1];
    }

    if (lastMessage.document) {
      return lastMessage.document.split(".com/")[1];
    }
    return "";
  };


  const getArchivedColor = () => {
    if (user.type === "COACH" && message.coachArchived) {
      return { color: "red", disabled: true };
    }
    if (user.type === "JOB_SEEKER" && message.jobSeekArchived) {
      return { color: "red", disabled: true };
    }

    return { color: "", disabled: false };
  };

  const getUnreadMeesagesAccount = () => {
    if (!message) return
    let urMessages = message.messages.filter((m) => !m.readBy.includes(user.id))

    if (urMessages.length > 0) {
      return `${urMessages.length}`
    }
    return ``
  }

  return (
    <>
      <ListItem
        alignItems="flex-start"
        button
        selected={
          selectedThread && selectedThread._id === message._id ? true : false
        }
        onClick={() => handleSelectedItem()}
        style={{
          borderBottom: "1px solid #e8e3e3",
        }}
        className='py-0 mb-2'
      >
        {/* <ListItemAvatar style={{ position: "relative" }}>
          <Avatar alt={message.subject} src={getInitials(message.cId.firstName, message.cId.lastName)} />
          <div className={i === 0 ? classes.appearDotGreen : (i === 1 ? classes.appearDotGrey : (i === 2 ? classes.appearDotYellow : classes.appearDotGreen))}></div>
        </ListItemAvatar> */}
        <ListItemText
          primary={
            <React.Fragment>
              <Grid className="d-flex mb-2 p-0">
                <IconButton
                  aria-label="more"
                  variant="raised"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  className="p-0"
                  style={{ backgroundColor: "transparent" }}
                  disableRipple
                  onClick={(e) => {
                    e.stopPropagation();
                    onArchivingThread()
                  }}
                >
                  <Button
                    disableRipple
                    className={classes.titleBtns}
                    startIcon={<ArchiveIcon style={{ fontSize: 13, color: getArchivedColor().color, }} />}
                    disabled={getArchivedColor().disabled}

                  >
                    Archived
                  </Button>
                </IconButton>
              </Grid>

              <Typography className={classes.subject}>
                {message.subject}
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography className={classes.message}>
                {onGetLastMessage()}
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction className={classes.secondaryAction}>
          <span className={classes.secondaryActiontime}>{message.lastMsg}</span>
          <div className="d-flex justify-content-center align-items-center">
            <IconButton
              aria-controls="menu"
              aria-haspopup="true"
              onClick={() => {
                setOpenAlert(true);
                handleCloseMenu();
              }}
            >
              <DeleteIcon style={{color: 'red'}} />
            </IconButton>
            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              className={classes.menu}
            >
              <MenuItem
                onClick={() => {
                  setOpenAlert(true);
                  handleCloseMenu();
                }}
              >
                Delete Thread
              </MenuItem>

            </Menu> */}
            {getUnreadMeesagesAccount() && <Grid className={classes.messageCount}>{getUnreadMeesagesAccount()}</Grid>}
          </div>

          <span />
        </ListItemSecondaryAction>
      </ListItem>
      <AlertMessage
        open={openAlert}
        close={setOpenAlert}
        action={onDeleteThread}
      />
    </>
  );
}
