/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-icon">
              <span className="material-icons material-icons-outlined">
                home
              </span>
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/applications", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/applications">
            <span className="menu-icon">
              <span className="material-icons material-icons-outlined">
                emoji_events
              </span>
            </span>
            <span className="menu-text">Career Hub</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/#", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/">
            <span className="menu-icon">
              <span className="material-icons">perm_identity</span>
            </span>
            <span className="menu-text">Employer Profile</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* Bootstrap */}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/#", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/">
            <span className="menu-icon">
              <span className="material-icons material-icons-outlined">
                today
              </span>
            </span>
            <span className="menu-text">View Events</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/messeges", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/messeges">
            <span className="menu-icon">
              <span className="material-icons material-icons-outlined">
                chat
              </span>
            </span>
            <span className="menu-text">View Messages</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/myAccount", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/myAccount">
            <span className="menu-icon">
              <span className="material-icons material-icons-outlined">
                account_circle
              </span>
            </span>
            <span className="menu-text">My Account</span>
          </NavLink>
        </li>
        {/* <li
          className={`mt-10 menu-item ${getMenuItemActive("/#", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/logout">
            <span className="menu-icon">
              <span
                className="material-icons material-icons-outlined"
                style={{ transform: "scale(1, -1)" }}
              >
                exit_to_app
              </span>
            </span>
            <span className="menu-text">Logout</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`mt-10 menu-item`}
          aria-haspopup="true"
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "red",
            listStyleType: "none",
          }}
        >
          <Button
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              backgroundColor: "white",
              color: "#007bff",
            }}
            onClick={() => {
              dispatch(actions.logBackAsCoach());
            }}
          >
            LOGIN AS COACH
          </Button>
        </li> */}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
