import axios from "axios";

import { gql } from "@apollo/client";
// import { setContext } from "@apollo/client/link/context";
// import store from "../../../../redux/store";
import client from "../../../../apolloClient";
// export const LOGIN_URL = "api/auth/login";
// export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      code
      success
      message
      accessToken
      user {
        firstName
        lastName
        id
        type
        coachId
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation SignupMutation(
    $signupFirstName: String!
    $signupLastName: String!
    $signupEmail: String!
    $signupPassword: String!
    $signupToken: String
    $signupInvitedBy: String
  ) {
    signup(
      firstName: $signupFirstName
      lastName: $signupLastName
      email: $signupEmail
      password: $signupPassword
      token: $signupToken
      invitedBy: $signupInvitedBy
    ) {
      code
      success
      message
      accessToken
      user {
        id
        firstName
        lastName
        email
        coachId
        invitedBy
        type
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordMutation(
    $resetPasswordPassword: String
    $resetPasswordToken: String
  ) {
    resetPassword(
      password: $resetPasswordPassword
      token: $resetPasswordToken
    ) {
      code
      success
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPasswordMutation($forgotPasswordEmailAddress: String) {
    forgotPassword(emailAddress: $forgotPasswordEmailAddress) {
      code
      success
      message
    }
  }
`;

export async function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  console.log("getting user");
  const GET_PROFILE = gql`
    query Profile {
      me {
        id
        firstName
        lastName
        email
        profileImage
        newEmail
        isEmailVerified
        type
        coachId
        invitedBy
        homePhone
        cellPhone
        gender
        birthDate
        address
        occupationInterest
        socialMediaHandles {
          linkedin
        }
      }
    }
  `;
  console.log("making a query");
  return client.query({
    query: GET_PROFILE,
    fetchPolicy: "no-cache",
  });
}
