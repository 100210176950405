import React from "react";
import { JobSeekerDashboard } from "../modules/JobSeekerModule/Dashboard/pages/DashboardPage";
import { CoachDashboard } from "../modules/CoachModule/Dashboard/pages/DashboardPage";

export function JobSeekerDashboardPage() {
  return <JobSeekerDashboard />;
}

export function CoachDashboardPage() {
  return <CoachDashboard />;
}
