import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { RedirectLogin } from "./modules/Auth/pages/RedirectLogin";

import { JobSeekerDashboardPage } from "./pages/DashboardPage";
import { ApplicationsList } from "./pages/AllApplicationList";
import { MyAccount } from "./modules/JobSeekerModule/account/MyAccount";
import {JobSeekerMessagePage} from './pages/MessegePage'

export default function JobSeekerBasePage({ redirect, redirectPath }) {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  console.log("------------------------------------");

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {redirect && redirect !== "self" ? (
          <Redirect
            exact
            from="/"
            to={`/login?redirect=${redirect}&redirectPath=${redirectPath}`}
          />
        ) : (
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        )}
        <ContentRoute path="/dashboard" component={JobSeekerDashboardPage} />
        <ContentRoute path="/applications" component={ApplicationsList} />
        <ContentRoute path="/messeges" component={JobSeekerMessagePage} />
        <ContentRoute path="/myAccount" component={MyAccount} />
        <ContentRoute path="/login" component={RedirectLogin} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
