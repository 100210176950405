/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import JobSeekerBasePage from "./JobSeekerBasePage";
import CoachBasePage from "./CoachBasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { socketService } from "./services/socket";
import { OnlineUsersEventAction } from "./modules/Messeges/api/rxEvents";

export function Routes() {
  const { user } = useSelector(({ auth }) => auth, shallowEqual);
  const isAuthorized = user != null;
  const urlParams = window.location.href.split("?")[1];
  const redirect = new URLSearchParams(urlParams).get("redirect") || "self";
  const redirectPath =
    new URLSearchParams(urlParams).get("redirectPath") || "/";

    useEffect(() => {
      if (user && user.id){
        socketService.emit("login", { userId: user.id })
        socketService.on('online', (data) => {
          OnlineUsersEventAction.triggerAction(data)
        })
      }
    },[user])
  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage redirect={redirect} redirectPath={redirectPath} />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect
          to={
            "/auth/login?redirect=" + redirect + "&redirectPath=" + redirectPath
          }
        />
      ) : user.type === "JOB_SEEKER" ? (
        <Layout>
          <JobSeekerBasePage redirect={redirect} redirectPath={redirectPath} />
        </Layout>
      ) : (
        <Layout>
          <CoachBasePage redirect={redirect} redirectPath={redirectPath} />
        </Layout>
      )}
    </Switch>
  );
}
