import React from "react";

function Loader() {
  return (
    <div className="w-100 d-flex align-items-center justify-content-between mb-2" >
      <div class="spinner-border text-danger mx-auto" role="status"></div>
    </div>
  );
}

export default Loader;
