import React, { useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";

const INVITE_JOB_SEEKER = gql`
  mutation Mutation($sendInvitationEmail: String) {
    sendInvitation(email: $sendInvitationEmail) {
      code
      success
      message
    }
  }
`;

export function InviteJobSeeker() {
  const [inviteJobSeeker, { loading }] = useMutation(INVITE_JOB_SEEKER);
  const [inviteEmail, setInviteEmail] = useState("");

  const onChange = (e) => {
    const value = e.target.value.trim();
    setInviteEmail(value);
  };

  return (
    <Box>
      <div class="input-group mb-3">
        <input
          type="email"
          className="form-control"
          placeholder="Email address of a Job Seeker"
          aria-label="Invite a Job Seeker"
          aria-describedby="basic-addon2"
          onChange={onChange}
        />
        <div className="input-group-append">
          <button
            disabled={loading}
            onClick={() =>
              inviteEmail &&
              inviteJobSeeker({
                variables: {
                  sendInvitationEmail: inviteEmail,
                },
              })
            }
            className="btn btn-outline-primary"
            type="button"
          >
            {" "}
            {loading ? (
              <CircularProgress style={{ color: "#3699FF" }} size="1rem" />
            ) : (
              "Invite a Job Seeker"
            )}
          </button>
        </div>
      </div>
    </Box>
  );
}
