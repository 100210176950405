import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  Grid,
  Button,
  InputBase,
  makeStyles,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import { Subscription } from "rxjs";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { MessageItem } from "./MessageItem";
import List from "@material-ui/core/List";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { TypeMessage } from "./TypeMessage";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { deleteChatApi, deleteThreadsApi } from "../api";
import { getInitials } from "../../../../_metronic/_helpers/AssetsHelpers";
import Loader from "./Loader";
import { DebounceInput } from "react-debounce-input";
import { shallowEqual, useSelector } from "react-redux";
import {
  OnlineUsersEventAction,
  UserMessegeTypingEventAction,
} from "../api/rxEvents";

const useStyles = makeStyles((theme) => ({
  messageListWrapper:{
    height:"85vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  search: {
    border: "1px solid silver",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginTop: 20,
    width: "85%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "85%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 5,
  },
  inputRoot: {
    color: "inherit",
    fontSize: "14px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "85%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  AccordionSummary: {
    padding: 0,
    boxShadow: 0,
    margin: 0,
    border: 0,
  },
  appearDotGreen: {
    background: "#44B700",
    position: "absolute",
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    border: "2px solid white",
    zIndex: 3,
    bottom: 0,
  },
  appearDotGrey: {
    background: "grey",
    position: "absolute",
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    border: "2px solid white",
    zIndex: 3,
    bottom: 0,
  },
  menu:{
    marginTop:'2.8rem'
  }
}));
export function MessagesList(props) {
  const classes = useStyles();
  const subscribe = new Subscription();
  const [Modal, setModal] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([])


  useEffect(() => {
    // const sub = ChatThreadEventAction.listen.subscribe((status) => {
    //   console.log("run")
    //   fetchChat(isArchived, search)
    // })
    // subscribe.add(sub)
    // return () => {
    //   subscribe.unsubscribe()
    // }
    const sub2 = OnlineUsersEventAction.listen.subscribe((status) => {
      let values = Object.values(status)
      if (values.length > 0) {
        setOnlineUsers([...values])
      } else {
        setOnlineUsers([])
      }
    })
  }, []);

  const handlePopup = () => {
    setModal(!Modal);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchEl, setSearchEl] = React.useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseSearchMenu = () => {
    setSearchEl(null);
  };

  const onDeleteAll = () => {
    if (props.user.type === "COACH") {
      onDeleteChat();
    } else {
      onDeleteThread();
    }
  };

  const onDeleteThread = async (id = "") => {
    let ids = props.threads.map((c) => c._id);

    let chat = await deleteThreadsApi({ ids, userId: props.user.id });
    handleCloseMenu();
  };

  const onDeleteChat = async (id = "") => {
    let ids = [];
    if (id) {
      ids = [id];
    } else {
      ids = props.chat.map((c) => c._id);
    }

    let chat = await deleteChatApi({ ids, userId: props.user.id });
    handleCloseMenu();
  };


  const { user } = useSelector(({ auth }) => auth, shallowEqual);

  const getOnlineUsers = () => {
    let cUser = user.type === "COACH" ? props.selectedThread.jsId : props.selectedThread.cId
    if (onlineUsers && onlineUsers.length > 0 && onlineUsers.includes(cUser._id)) {
      return true
    }
    return false
  }

  return (
    <Grid className={classes.messageListWrapper}>
      <Grid className="d-flex justify-content-between">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon style={{ color: "white" }} />}
          onClick={() => handlePopup()}
        >
          <b className="text-white">New Messages</b>
        </Button>
        <Button
          variant="outlined"
          onClick={() => props.fetchChat()}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
      </Grid>

      {/* -=-=--= search  -=-=-==- */}
      <Grid item className="d-flex align-items-center justify-content-between">
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <DebounceInput
            placeholder={`Search by ${props.searchBy}`}
            debounceTimeout={3000}
            style={{
              width: "100%",
              border: "none",
              outline: "none",
              borderRadius: 10,
            }}
            className={classes.inputInput}
            inputProps={{ "aria-label": "search" }}
            value={props.search}
            onChange={props.onChangeSearch}
          />
        </div>
        <IconButton
          aria-controls="menu"
          aria-haspopup="true"
          onClick={(event) => setSearchEl(event.currentTarget)}
          style={{marginTop:"1.7rem"}}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={searchEl}
          keepMounted
          open={Boolean(searchEl)}
          onClose={handleCloseSearchMenu}
          className={classes.menu}
        >
          <MenuItem
            onClick={() => {
              props.onSetSearchBy("thread");
              handleCloseSearchMenu();
            }}
          >
            Search by Thread
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.onSetSearchBy("messages");
              handleCloseSearchMenu();
            }}
          >
            Search by message
          </MenuItem>
        </Menu>
      </Grid>

      {/* -=-=--= search  -=-=-==- */}

      <Grid className="mt-5" container direction="row" justify="space-between">
        <h2>My Messages</h2>
        <IconButton
          aria-controls="menu"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          className={classes.menu}
        >
          <MenuItem
            onClick={() => {
              props.onGetArchived(false);
              handleCloseMenu();
            }}
          >
            All Messages
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.onGetArchived(true);
              handleCloseMenu();
            }}
          >
            Archived Messages
          </MenuItem>
          <MenuItem
            onClick={() => {
              onDeleteAll();
              handleCloseMenu();
            }}
          >
            Delete All
          </MenuItem>
        </Menu>
      </Grid>
      {props.loading && <Loader />}

      {!props.loading && props.threads.length === 0 && props.chat.length === 0 && (
        <Grid>
          <Typography>No Data Found !</Typography>
        </Grid>
      )}
      <Grid>
        <List className={classes.list}>
          {props.jobSeek &&
            props.threads &&
            props.threads.length > 0 &&
            props.threads.map((message, i) => (
              <MessageItem
                message={message}
                i={i}
                onSelectThread={props.onSelectThread}
                selectedThread={props.selectedThread}
                user={props.user}
                fetchChat={() => props.fetchChat()}
              />
            ))}
          {/* <Loader/> */}

          {!props.jobSeek &&
            props.chat &&
            props.chat.length > 0 &&
            props.chat.map((c, ci) => (
              <Accordion
                square
                defaultExpanded={ci == 0 ? true : false}
                className={classes.AccordionSummary}
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.AccordionSummary}
                >
                  <ListItem
                    alignItems="center"
                    className={classes.AccordionSummary}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={c.jsId.firstName}
                        src={getInitials(c.cId.firstName, c.cId.lastName)}
                      />
                      <div className={classes.appearDotGreen}></div>
                    </ListItemAvatar>

                    <ListItemText
                      primary={<h6 className="mt-2">{c.jsId.firstName}</h6>}
                    />
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={() => onDeleteChat(c._id)}
                    >
                      <DeleteIcon
                        style={{ color: "#FF4242", cursor: "pointer" }}
                      />
                    </IconButton>
                  </ListItem>
                </AccordionSummary>

                <AccordionDetails className="d-flex flex-column">
                  {c.threads.map((message, i) => (
                    <MessageItem
                      message={message}
                      onSelectThread={props.onSelectThread}
                      selectedThread={props.selectedThread}
                      i={i}
                      user={props.user}
                      fetchChat={() => props.fetchChat()}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </List>
      </Grid>
      {Modal && (
        <TypeMessage modal={Modal} handlePopup={handlePopup} {...props} />
      )}
    </Grid>
  );
}
