import React, { useEffect } from "react";
import { Table, Card } from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Button,
  IconButton,
  Snackbar,
  CircularProgress,
  Box,
} from "@material-ui/core";
// icons
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";

const GET_SAVED_JOBS = gql`
  query Query($savedJobListUserId: String) {
    savedJobList(userId: $savedJobListUserId) {
      jobId
      user
      name
      company
      location
    }
  }
`;

const DELETE_JOB = gql`
  mutation Mutation($deleteJobJobId: String, $deleteJobUserId: String) {
    deleteJob(jobId: $deleteJobJobId, userId: $deleteJobUserId) {
      success
      code
    }
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SavedJobs({ selectedUser }) {
  const [open, setOpen] = React.useState(false);
  const { loading, error, data } = useQuery(GET_SAVED_JOBS, {
    variables: {
      savedJobListUserId: selectedUser,
    },
  });

  const [jobs, setJobs] = React.useState([]);
  const [seletedJob, setSelectedJob] = React.useState("");
  const [deleteJob] = useMutation(DELETE_JOB, {
    onCompleted(response) {
      if (response.deleteJob.code === "200") {
        let updatedJobs = jobs;
        console.log(
          "🚀 ~ file: SavedJobs.js ~ line 56 ~ onCompleted ~ updatedJobs",
          updatedJobs
        );
        const index = updatedJobs.findIndex((x) => x.jobId === seletedJob);
        updatedJobs = [
          ...updatedJobs.slice(0, index),
          ...updatedJobs.slice(index + 1),
        ];
        console.log(updatedJobs);
        setJobs(updatedJobs);
        setOpen(true);
        setSelectedJob("");
      }
    },
  });

  useEffect(() => {
    if (data) {
      setJobs(data.savedJobList);
    }
  }, [data]);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleDeleteJob = (deletedJob) => {
    setSelectedJob(deletedJob);
    deleteJob({
      variables: {
        deleteJobJobId: deletedJob,
        deleteJobUserId: selectedUser,
      },
    });
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Saved Jobs</Card.Title>
        {loading ? (
          <Box className="d-flex justify-content-center">
            <CircularProgress style={{ color: "#3699FF" }} />
          </Box>
        ) : (
          <Table responsive striped>
            <thead>
              <tr>
                <th>Job Title</th>
                <th>Company Name</th>
                <th>Location</th>
                <th>Job Post Date</th>
                <th>Job Board</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                !error &&
                jobs.map((job) => {
                  return (
                    <tr key={job.jobId}>
                      <td>{job.name}</td>
                      <td>{job.company}</td>
                      <td>{job.location}</td>
                      <td>
                        {moment(new Date(Number(job.postedTime))).format(
                          "MMM Do YYYY"
                        )}
                      </td>
                      <td>{job.source}</td>
                      <td>
                        <Button
                          onClick={() => {
                            // window.location.href =
                            //   process.env.REACT_APP_JOBS_LOGIN_REDIRECT_URL +
                            //   "/#/dashboard?jobId=" +
                            //   job.jobId;
                            window.open(
                              process.env.REACT_APP_JOBS_LOGIN_REDIRECT_URL +
                                "/#/dashboard?jobId=" +
                                job.jobId
                            );
                          }}
                        >
                          View/Apply
                        </Button>
                      </td>
                      <td>
                        {job.jobId === seletedJob ? (
                          <CircularProgress
                            size="15px"
                            style={{ color: "red" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            color="red"
                            className=""
                            style={{
                              fontSize: "15px",
                              cursor: "pointer",
                              margin: "1px",
                            }}
                            onClick={() => {
                              handleDeleteJob(job.jobId);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
      </Card.Body>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        color="danger"
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleSnackBarClose}
          style={{ fontSize: "16px" }}
          severity="success"
        >
          {"Job deleted successfully"}
        </Alert>
      </Snackbar>
    </Card>
  );
}
