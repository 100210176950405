import React from "react";
// import { CircleCharts } from "../../../../../_metronic/_partials/widgets";
import {
  SavedJobs,
  SavedResumes,
  SavedOccupations,
  Assessment,
  StatsOverview,
  ClientListTable,
  // InviteJobSeeker,
} from "../components";
import { Grid } from "@material-ui/core";
import { UserProfile } from "../components/UserProfile";

export function CoachDashboard() {
  const [selectedUserData, setSelectedUserData]=React.useState({})
  const [selectedUser, setSelectedUser] = React.useState("");
  return (
    <>
      {/* <div className="row gutter-b justify-content-center">
        <div className="col-sm-8">
          <InviteJobSeeker />
        </div>
      </div> */}
      <Grid container direction="column" spacing={5} className="mb-5">
        <Grid item>
          <StatsOverview />
        </Grid>
        <Grid item>
          <ClientListTable setSelectedUser={setSelectedUser} />
        </Grid>
      </Grid>
      {selectedUser ? (
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <UserProfile
              selectedUser={selectedUser}
              setSelectedUserData={setSelectedUserData}
            />
          </Grid>
          <Grid item>
            <SavedOccupations selectedUser={selectedUser} />
          </Grid>
          <Grid item>
            <SavedResumes selectedUser={selectedUser} />
          </Grid>
          <Grid item>
            <SavedJobs selectedUser={selectedUser} />
          </Grid>
          <Grid item>
            <Assessment selectedUserData={selectedUserData} />
          </Grid>
        </Grid>
      ) : (
        <div></div>
      )}
      {/* <div className="row gutter-b">
        <div className="col-sm-12">
          <CircleCharts />
        </div>
      </div> */}

      {/* <div className="row gutter-b">
        <div className="col-sm-12">
          <SavedJobs />
        </div>
      </div> */}
      {/* <div className="row gutter-b">
        <div className="col-sm-12">
          <Assessment />
        </div>
      </div> */}
    </>
  );
}
