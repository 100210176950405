import axios from 'axios'
// import { socketService } from '../../../services/socket'

const apiUrl = `https://roshi-chat.herokuapp.com`
// const apiUrl =`http://localhost:4000`
export const fetchThreads = async (id, role, archived, s) => {
    try {
        let threads = await axios.get(`${apiUrl}/chat/${id}`, { params: { role, archived: archived, ...(s ? { search: s } : {}) } })
        return threads.data
    } catch (error) {
        console.log(error)
        return []
    }
}

export const fetchSearchThreads = async (id, role, query, searchBy) => {
    try {
        let threads = await axios.get(`${apiUrl}/search/${id}`, { params: { query, role, from: searchBy } })
        return threads.data
    } catch (error) {
        console.log(error)
        return []
    }
}

export const fetchJobSeekers = async (id) => {
    try {
        let jobseeker = await axios.get(`${apiUrl}/job-seeker-by-coach/${id}`)
        return jobseeker.data

    } catch (error) {
        console.log(error)
        return []
    }
}

export const fetchMesseages = async (id, data) => {
    try {
        let jobseeker = await axios.get(`${apiUrl}/messeges-by-thread/${id}`, { params: data })
        return jobseeker.data
    } catch (error) {
        console.log(error)
        return []
    }
}

export const createChatApi = async (body) => {
    try {
        let chat = await axios.post(`${apiUrl}/createChat`, body)
        return chat.data
    } catch (error) {
        console.log(error)
        return {}
    }
}

export const generateFileUrl = async (body) => {
    try {
        let url = await axios.post(`${apiUrl}/api/get-presigned-url/`, body)
        return url.data
    } catch (error) {
        console.log(error)
        return ''
    }
}

export const uploadFile = async (url, file) => {
    try {
        // let data = new FormData()
        // data.append('file', file)
        // console.log(file, "FILE")
        const myHeaders = new Headers({ 'Content-Type': file.type });
        let newUrl = await fetch(url, {
            method: 'PUT',
            headers: myHeaders,
            body: file
        });
        return newUrl
    } catch (error) {
        console.log(error)
        return ''
    }
}

export const deleteChatApi = async (body) => {
    try {
        let chat = await axios.delete(`${apiUrl}/chat`, { data: body })
        return chat.data
    } catch (error) {
        console.log(error)
        return {}
    }
}

export const deleteThreadsApi = async (body) => {
    try {
        let chat = await axios.delete(`${apiUrl}/threads`, { data: body })
        return chat.data
    } catch (error) {
        console.log(error)
        return {}
    }
}

export const archiveChatApi = async (body) => {
    try {
        let chat = await axios.put(`${apiUrl}/archived`, body)
        return chat.data
    } catch (error) {
        console.log(error)
        return {}
    }
}


// export const sendMessage = (data) => {
//     socketService.emit('message', data)
// }