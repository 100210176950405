import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Grid,
  Button,
  InputBase,
  makeStyles,
  ListItemSecondaryAction,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
function AlertMessage({img,open,close}) {
    return (
        <Grid item>
        <Dialog
          open={open}
          onClose={()=>close(!open)}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="100%"
          className="m-0"
        >
          <DialogContent className='d-flex align-items-center'>
          <img src={img}
            // style={}
            className=' m-auto'
          alt="none"/>
          </DialogContent>
        </Dialog>
      </Grid>
    )
}

export default AlertMessage
