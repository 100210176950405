export const messages=[
    {
      title:"Freida Bernhard",
      message:"1-800Accountant T Tax Extensions...",
      lastMsg:"5 jan 2021",
      img:"https://i.pinimg.com/474x/c4/d8/b0/c4d8b0c78b44c59041f2a93d18dcfdb1--modern-fashion-high-fashion.jpg"
    },
    {
        title:"Carroll Zoe",
        message:"1-800Accountant T Tax Extensions...",
        lastMsg:"24 Dec 2020",
        img:"https://i.pinimg.com/736x/e7/bb/4b/e7bb4b69184c306824c8d918d9314c3d.jpg"
      },
      {
        title:"Kiran Adamas",
        message:"Kelley P To access your business tax return , it is the last 5 digit of the business EIN",
        lastMsg:"8 Sep 2020",
        img:"https://d25tv1xepz39hi.cloudfront.net/2021-03-02/files/03_-_SURROUNDINGS.jpg"
      },
      {
        title:"Leonor Khalid",
        message:"David Y : Hi Boddy, if its the business return , its the last 5 digits of your EIN, if its the personal",
        lastMsg:"8 Sep 2020",
        img:"https://i.pinimg.com/736x/ac/c2/9b/acc29bd5a2579e7178aa3b2c058be72d--boho-field-photoshoot-photoshoot-ideas-forest.jpg"
      },
]

export const cochingMessages=[
  {
    title:'Javier Enriquez',
    img:'https://i.pinimg.com/736x/9f/a6/95/9fa695bf2772ff69878c6774d4ae8e84--london-photography-men-photography.jpg',
    messages:[
      {
        title:"Freida Bernhard",
        message:"Kelley P To access your business tax return , it is the last 5 digit of the business EIN",
        lastMsg:"8 Sep 2020",
        img:"https://images.unsplash.com/photo-1534864652366-f46034a7f6cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1487&q=80"
      },
      {
        title:"Carroll Zoe",
        message:"David Y : Hi Boddy, if its the business return , its the last 5 digits of your EIN, if its the personal",
        lastMsg:"8 Sep 2020",
        img:"https://i.pinimg.com/736x/ac/c2/9b/acc29bd5a2579e7178aa3b2c058be72d--boho-field-photoshoot-photoshoot-ideas-forest.jpg"
      }
    ]
  },
  {
    title:'Soyna Blade',
    img:'https://www.deccanherald.com/sites/dh/files/articleimages/2020/10/27/photography-istock-907562-1603773524.jpg',
    messages:[
      {
        title:"Freida Bernhard",
        message:"Kelley P To access your business tax return , it is the last 5 digit of the business EIN",
        lastMsg:"8 Sep 2020",
        img:"https://d25tv1xepz39hi.cloudfront.net/2021-03-02/files/03_-_SURROUNDINGS.jpg"
      },
      {
        title:"Carroll Zoe",
        message:"David Y : Hi Boddy, if its the business return , its the last 5 digits of your EIN, if its the personal",
        lastMsg:"8 Sep 2020",
        img:"https://indonesiaexpat.id/wp-content/uploads/2017/10/photographer-698908_960_720-e1618978842358.jpg"
      }
    ]
  },
  {
    title:'Bobby Palma',
    img:'https://images.squarespace-cdn.com/content/v1/59d6b903f7e0ab75856a1808/1532420664607-9SED2AQIWJTWX0KCVHY0/julia%2Btrotti-2015_080.jpg',
    messages:[
      {
        title:"Freida Bernhard",
        message:"Kelley P To access your business tax return , it is the last 5 digit of the business EIN",
        lastMsg:"8 Sep 2020",
        img:"https://d25tv1xepz39hi.cloudfront.net/2021-03-02/files/03_-_SURROUNDINGS.jpg"
      },
      {
        title:"Carroll Zoe",
        message:"David Y : Hi Boddy, if its the business return , its the last 5 digits of your EIN, if its the personal",
        lastMsg:"8 Sep 2020",
        img:"https://i.pinimg.com/736x/ac/c2/9b/acc29bd5a2579e7178aa3b2c058be72d--boho-field-photoshoot-photoshoot-ideas-forest.jpg"
      }
    ]
  },
  {
    title:'Sylvester Stallone',
    img:'https://miro.medium.com/max/1002/1*ONOG1MrfDegBp_RyMNby7Q.jpeg',
    messages:[
      {
        title:"Freida Bernhard",
        message:"Kelley P To access your business tax return , it is the last 5 digit of the business EIN",
        lastMsg:"8 Sep 2020",
        img:"https://d25tv1xepz39hi.cloudfront.net/2021-03-02/files/03_-_SURROUNDINGS.jpg"
      },
      {
        title:"Carroll Zoe",
        message:"David Y : Hi Boddy, if its the business return , its the last 5 digits of your EIN, if its the personal",
        lastMsg:"8 Sep 2020",
        img:"https://i.pinimg.com/736x/ac/c2/9b/acc29bd5a2579e7178aa3b2c058be72d--boho-field-photoshoot-photoshoot-ideas-forest.jpg"
      }
    ]
  },
  {
    title:'Johnny Depp',
    img:'https://static.toiimg.com/photo/31946854.cms?width=150&resizemode=4',
    messages:[
      {
        title:"Freida Bernhard",
        message:"Kelley P To access your business tax return , it is the last 5 digit of the business EIN",
        lastMsg:"8 Sep 2020",
        img:"https://d25tv1xepz39hi.cloudfront.net/2021-03-02/files/03_-_SURROUNDINGS.jpg"
      },
      {
        title:"Carroll Zoe",
        message:"David Y : Hi Boddy, if its the business return , its the last 5 digits of your EIN, if its the personal",
        lastMsg:"8 Sep 2020",
        img:"https://i.pinimg.com/736x/ac/c2/9b/acc29bd5a2579e7178aa3b2c058be72d--boho-field-photoshoot-photoshoot-ideas-forest.jpg"
      }
    ]
  },
  {
    title:'Tom Hanks',
    img:'https://www.goldderby.com/wp-content/uploads/2019/12/Brad-Pitt.jpg',
    messages:[
      {
        title:"Freida Bernhard",
        message:"Kelley P To access your business tax return , it is the last 5 digit of the business EIN",
        lastMsg:"8 Sep 2020",
        img:"https://d25tv1xepz39hi.cloudfront.net/2021-03-02/files/03_-_SURROUNDINGS.jpg"
      },
      {
        title:"Carroll Zoe",
        message:"David Y : Hi Boddy, if its the business return , its the last 5 digits of your EIN, if its the personal",
        lastMsg:"8 Sep 2020",
        img:"https://i.pinimg.com/736x/ac/c2/9b/acc29bd5a2579e7178aa3b2c058be72d--boho-field-photoshoot-photoshoot-ideas-forest.jpg"
      }
    ]
  },

]


export const Messages = [
    {
      me: "Hi",
      user: "Hello",
    },
    {
      me: "How are you ?",
    },
    {
      user: {
        message:"What`s your plan ? ",
        img:"https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/actor-brad-pitt-attends-the-screening-of-once-upon-a-time-news-photo-1578089601.jpg",
      },
    },
    {
      me: {
        img:'https://www.hollywoodreporter.com/wp-content/uploads/2016/10/gettyimages-597685190-h_2016.jpg'
      },
      user: {
        pdf:"https://www.learning.com/sample-pdf.pdf"
      },
    },
  ];