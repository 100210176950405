/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";

export function QuickUserToggler() {

  return (<>
        <UserProfileDropdown/>
      </>
  );
}
