import React, { useState } from "react";
import { useFormik, FormikProvider } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { REGISTER_USER } from "../_redux/authCrud";
import { useMutation } from "@apollo/client";

function Registration(props) {
  const { intl, location } = props;
  const coachId =
    new URLSearchParams(location.search).get("invitedBy") === "undefined"
      ? ""
      : new URLSearchParams(location.search).get("invitedBy");
  const firstName =
    new URLSearchParams(location.search).get("firstName") === "undefined"
      ? ""
      : new URLSearchParams(location.search).get("firstName");
  const lastName =
    new URLSearchParams(location.search).get("lastName") === "undefined"
      ? ""
      : new URLSearchParams(location.search).get("lastName");
  const token = new URLSearchParams(location.search).get("token");
  const email =
    new URLSearchParams(location.search).get("email") === "undefined"
      ? ""
      : new URLSearchParams(location.search).get("email");

  const [loading, setLoading] = useState(false);
  const userType = !token ? "JOB_SEEKER" : "";
  const [register] = useMutation(REGISTER_USER);

  const initialValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    password: "",
    changepassword: "",
    acceptTerms: false,
    coachId: coachId || "",
  };

  let validationShape = {
    firstName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  };
  // if (userType === "JOB_SEEKER") {
  //   validationShape.coachId = Yup.string()
  //     .required("Coach Id is required to register as job seeker")
  //     .nullable();
  // }
  const RegistrationSchema = Yup.object().shape(validationShape);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      const variables = {
        signupFirstName: values.firstName,
        signupLastName: values.lastName,
        signupEmail: values.email,
        signupPassword: values.password,
        signupToken: token,
        signupInvitedBy: values.coachId,
      };
      register({
        variables,
      }).then((resp) => {
        setSubmitting(false);
        if (resp.data.signup.code >= 200 && resp.data.signup.code < 300) {
          props.register(resp.data.signup.accessToken);
        } else setStatus(resp.data.signup.message);
        disableLoading();
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <div className="login-form login-signin" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">
            Enter your details to create your account
          </p>
        </div>

        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          {/* begin: Alert */}
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          {/* end: Alert */}
          {/* begin: registerAs */}
          {/* <div className="form-group">
            <div className="form-check-inline mr-5">
              <label className="form-check-label h5" htmlFor="inlineRadio1">
                Register as
              </label>
            </div>
            <div
              className="form-check form-check-inline"
              onChange={handleRadioChange}
            >
              <Field type="radio" name="type" value="COACH" />
              <label
                className="form-check-label ml-3 mr-5"
                htmlFor="inlineRadio1"
              >
                Coach
              </label>
            </div>
            <div
              className="form-check form-check-inline"
              onChange={handleRadioChange}
            >
              <Field type="radio" name="type" value="JOB_SEEKER" />
              <label className="form-check-label ml-3" htmlFor="inlineRadio1">
                Job Seeker
              </label>
            </div>
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.type}</div>
              </div>
            ) : null}
          </div> */}
          {/* end: registerAs */}

          {/* begin: coach Id */}
          {userType === "JOB_SEEKER" && (
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Coach Id   (optional)"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "coachId"
                )}`}
                name="coachId"
                {...formik.getFieldProps("coachId")}
                disabled={coachId}
              />
              {formik.touched.coachId && formik.errors.coachId ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.coachId}</div>
                </div>
              ) : null}
            </div>
          )}
          {/* end: coach Id */}

          {/* begin: firstName */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="First Name"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "firstName"
              )}`}
              name="firstName"
              {...formik.getFieldProps("firstName")}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.firstName}</div>
              </div>
            ) : null}
          </div>
          {/* end: firstName */}

          {/* begin: lastName */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Last Name"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "lastName"
              )}`}
              name="lastName"
              {...formik.getFieldProps("lastName")}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.lastName}</div>
              </div>
            ) : null}
          </div>
          {/* end: lastName */}

          {/* begin: Email */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Email"
              type="email"
              disabled={email}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          {/* end: Email */}

          {/* begin: Password */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          {/* end: Password */}

          {/* begin: Confirm Password */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Confirm Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "changepassword"
              )}`}
              name="changepassword"
              {...formik.getFieldProps("changepassword")}
            />
            {formik.touched.changepassword && formik.errors.changepassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.changepassword}
                </div>
              </div>
            ) : null}
          </div>
          {/* end: Confirm Password */}

          {/* begin: Terms and Conditions */}
          <div className="form-group">
            <label className="checkbox">
              <input
                type="checkbox"
                name="acceptTerms"
                className="m-1"
                {...formik.getFieldProps("acceptTerms")}
              />
              <Link
                to="/terms"
                target="_blank"
                className="mr-1"
                rel="noopener noreferrer"
              >
                I agree the Terms & Conditions
              </Link>
              <span />
            </label>
            {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.acceptTerms}</div>
              </div>
            ) : null}
          </div>
          {/* end: Terms and Conditions */}
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              type="submit"
              disabled={
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.values.acceptTerms
              }
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <span>Submit</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>

            <Link to="/auth/login">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </FormikProvider>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
