import React from "react";
import { UserProfile } from "./components/UserProfile";

export function MyAccount() {
  return (
    <div>
      <UserProfile />
    </div>
  );
}
