import React, { useState, useCallback, Fragment } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import UploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import AttachIcon from "@material-ui/icons/Attachment";
import ImageViewPopup from './ImageViewPopup'
import { useDropzone } from "react-dropzone";
import { generateFileUrl, uploadFile } from "../api";
import ReactPlayer from 'react-player'

const initialImageBlob = {
  apiUrl: "",
  type: "",
  name: "",
}
export const WriteMessege = ({ onSentMessage, userMessageTyping }) => {
  const [text, setText] = useState("");
  const s3Url = "https://chat-attachements.s3.amazonaws.com";
  const [imageUrl, setImageUrl] = useState("");
  const [files, setFiles] = useState([]);
  const [imageBlob, setImageBlob] = useState({ ...initialImageBlob });
  const [showImage, setShowImage] = useState(false)

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles[0].size > (6 * 1000 * 1000)) {
      alert("File Size is too large")
      return
    }
    setFiles([...acceptedFiles]);
    let name = `${new Date().valueOf()}.${acceptedFiles[0].name.split(".")[1]}`;
    let type = acceptedFiles[0].type;
    // console.log(type, "FILE TYPE")
    let url = await generateFileUrl({ name, mime: type });
    setImageBlob({
      ...imageBlob,
      apiUrl: url,
      name: name,
      type,
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onSubmitMessege = async (e) => {

    e.preventDefault();
    let data = {
      message: text,
    };
    if (files && files.length > 0) {
      let newUrl = await uploadFile(imageBlob.apiUrl, files[0]);
      if (newUrl.status === 200) {
        if (imageBlob.type && imageBlob.type.includes("image")) {
          data.image = `${s3Url}/${imageBlob.name}`;
        }
        if (imageBlob.type && imageBlob.type.includes("application")) {
          data.document = `${s3Url}/${imageBlob.name}`;
        }
        if (imageBlob.type && imageBlob.type.includes("video")) {
          data.video = `${s3Url}/${imageBlob.name}`;
        }
        if (!data.image && !data.document && !data.message && !data.video) {
          return
        }
        // onSentMessage(data)
        // setText('')
        setFiles([])
        setImageBlob({ ...imageBlob })
        setImmediate('')
      }
    }
    onSentMessage(data);
    setText("");

  }
  return (
    <Grid className="mt-4">
      {/* URL.createObjectURL(profileImg) */}
      {files.length > 0 && (
        <Grid container>
          <Grid
            style={{
              height: 50,
              display: "flex",
              alignItems: "center",
              position: "relative",
              marginRight: 10,
            }}
          >
            {files.map((file) => (
              <>
                {file.type.includes("application") ? (
                  <Typography>
                    <AttachIcon />  {file.name}
                  </Typography>
                ) : (
                  file.type.includes("image")
                    ?
                    <Fragment>
                      <Grid
                        style={{
                          position: "absolute",
                          backgroundColor: "red",
                          top: 0,
                          right: 0,
                          borderRadius: "50%",
                          width: 15,
                          height: 15,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setFiles([])}
                      >
                        <CloseIcon
                          style={{ color: "white", fontSize: "1.1rem" }}
                          className="ms-4"
                        />
                      </Grid>
                      <img
                        src={URL.createObjectURL(file)}
                        alt="none"
                        width={40}
                        style={{
                          maxHeight: 50,
                        }}
                        onClick={() => setShowImage(true)}
                      />
                    </Fragment>
                    :
                    (file.type.includes("mp4")) ?
                      <Fragment>
                        <Grid
                          style={{
                            position: "absolute",
                            backgroundColor: "red",
                            top: 0,
                            right: 0,
                            borderRadius: "50%",
                            width: 15,
                            height: 15,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => setFiles([])}
                        >
                          <CloseIcon
                            style={{ color: "white", fontSize: "1.1rem" }}
                            className="ms-4"
                          />
                        </Grid>
                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width={40} style={{
                          maxHeight: 50,
                        }} />
                        {/* <img
                          src={URL.createObjectURL(file)}
                          alt="none"
                          width={40}
                          style={{
                            maxHeight: 50,
                          }}
                        /> */}
                      </Fragment>
                      : null
                )}
              </>
            ))}
          </Grid>
        </Grid>
      )}

      <textarea
        className="form-control"
        placeholder="Write your message"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onFocus={() => userMessageTyping(true)}
        onBlur={() => userMessageTyping(false)}
      >
        {text}
      </textarea>

      <Grid
        justify="space-between"
        alignItems="center"
        direction="row"
        container
      >
        <Grid item className="mt-4">
          <div {...getRootProps()}>
            <input {...getInputProps({ multiple: false })} />
            <Button style={{ color: "grey" }} startIcon={<UploadIcon />}>
              Upload Document
            </Button>
          </div>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            type="button"
            endIcon={
              <SendIcon style={{ color: "white", fontSize: "1.2rem" }} />
            }
            onClick={onSubmitMessege}
            className="px-5 ml-auto"
          >
            <Typography
              style={{ width: "50px", height: "20px", color: "white" }}
            >
              Send
            </Typography>
          </Button>
        </Grid>
      </Grid>
      {showImage && files.length > 0 && <ImageViewPopup img={URL.createObjectURL(files[0])} open={showImage} close={setShowImage} />}
    </Grid>
  );
};
