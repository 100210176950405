import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { Brand } from "../brand/Brand";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { actions } from "../../../../app/modules/Auth/_redux/authRedux";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function Aside() {
  const uiService = useHtmlClassService();
  const { coachAccessToken } = useSelector(({ auth }) => auth, shallowEqual);
  const dispatch = useDispatch();

  const layoutProps = useMemo(() => {
    return {
      disableScroll:
        objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
        false,
      asideClassesFromConfig: uiService.getClasses("aside", true),
      disableAsideSelfDisplay:
        objectPath.get(uiService.config, "aside.self.display") === false,
      headerLogo: uiService.getLogo(),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}
      >
        <Brand />

        {/* begin::Aside Menu */}
        <div
          id="kt_aside_menu_wrapper"
          className="aside-menu-wrapper flex-column-fluid"
        >
          {layoutProps.disableAsideSelfDisplay && (
            <>
              {/* begin::Header Logo */}
              <div className="header-logo">
                <Link to="">
                  <img height="80" alt="logo" src={layoutProps.headerLogo} />
                </Link>
              </div>
              {/* end::Header Logo */}
            </>
          )}
          <AsideMenu disableScroll={layoutProps.disableScroll} />
        </div>
        {coachAccessToken && (
          <div
            style={{
              position: "absolute",
              bottom: 20,
              width: "92%",
            }}
          >
            <Button
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: "white",
                color: "#FF4242",
                width: "100%",
                borderRadius: "0px",
                fontWeight: "bold",
                letterSpacing: 2,
                fontSize: "12px",
                borderColor: "#FF4242",
              }}
              onClick={() => {
                dispatch(actions.logBackAsCoach());
              }}
            >
              LOGIN AS COACH
            </Button>
          </div>
        )}
        {/* end::Aside Menu */}
      </div>
      {/* end::Aside */}
    </>
  );
}
