import React from "react";
import { gql, useQuery } from "@apollo/client";

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Box,
  makeStyles,
  Card,
  CardContent,
  // Typography,
  // TextField,
  // IconButton,
} from "@material-ui/core";

const GET_CLIENTS_LIST = gql`
  query Query {
    getJobSeekerListByCoach {
      id
      firstName
      lastName
      email
      coachId
    }
  }
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeadCell: {
    fontSize: "18px",
  },
  tableRowCell: {
    fontSize: "14px",
  },
});

function createRowData(name, email, phone, userType) {
  return { name, email, phone, userType };
}

const headers = ["Name", "Email", "Phone", "User Type", "Action"];

const rows = [
  createRowData(
    "user 1",
    "someone@careerroshi.com",
    "555-555-5555",
    "Career Coach"
  ),
  createRowData(
    "user 2",
    "somejobseeker@careerroshi.com",
    "555-555-5555",
    "Job Seeker"
  ),
  createRowData(
    "user 3",
    "someone@careerroshi.com",
    "555-555-5555",
    "Job Seeker"
  ),
];

export function ClientListTable() {
  const {  data } = useQuery(GET_CLIENTS_LIST);
  console.log("data", data);
  const classes = useStyles();

  return (
    <Card style={{ backgroundColor: "white" }}>
      here {data}
      <CardContent>
        <Box display="flex" justifyContent="space-between" className="px-5">
          <Box>Search here</Box>
          <Box>Add new Client</Box>
        </Box>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((item) => (
                  <TableCell classes={{ root: classes.tableHeadCell }}>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    classes={{ root: classes.tableRowCell }}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableRowCell }}>
                    {row.email}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableRowCell }}>
                    {row.phone}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableRowCell }}>
                    {row.userType}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
