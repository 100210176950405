import React, { useState } from "react";
import { Card } from "react-bootstrap";
import moment from "moment";
// icons
import {
  faTrashAlt,
  faEye,
  faEdit,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
  Box,
  Snackbar,
  Grid,
} from "@material-ui/core";

import ResumePreview from "./resumePreview";

import { gql, useQuery, useMutation } from "@apollo/client";

const GET_SAVED_RESUMES = gql`
  query Query {
    getSavedResumesForUser {
      savedResumes {
        data
        name
        resumeName
        updatedAt
        createdAt
        _id
      }
    }
  }
`;
const DELETE_RESUME = gql`
  mutation Mutation($deleteResumeResumeId: String!) {
    deleteResume(resumeId: $deleteResumeResumeId) {
      success
      code
      message
    }
  }
`;

const RENAME_RESUME = gql`
  mutation Mutation($editResumeResumeId: String!, $editResumeName: String!) {
    editResume(resumeId: $editResumeResumeId, name: $editResumeName) {
      code
      success
      message
    }
  }
`;
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SavedResumes() {
  const [open, setOpen] = React.useState(false);
  const [snackbarText, setSnackbarText] = React.useState("");
  const [resumes, setResumes] = React.useState();
  const { loading, data } = useQuery(GET_SAVED_RESUMES, {
    onCompleted() {
      setResumes(data.getSavedResumesForUser.savedResumes);
    },
  });

  const [deleteResumeId, setDeleteResumeId] = React.useState("");
  const [deleteResume] = useMutation(DELETE_RESUME, {
    onCompleted(response) {
      if (response.deleteResume.code === "200") {
        let updatedResumes = resumes;
        const index = updatedResumes.findIndex((x) => x._id === deleteResumeId);
        updatedResumes = [
          ...updatedResumes.slice(0, index),
          ...updatedResumes.slice(index + 1),
        ];
        setResumes(updatedResumes);
        setOpen(true);
        setDeleteResumeId("");
        setSnackbarText("Resume deleted successfully");
      }
    },
  });

  const [openPreview, setOpenPreview] = useState(false);
  const [selectedResumeName, setSelectedResumeName] = React.useState("");
  const [selectedResume, setSelectedResume] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("");
  const [resumeNewName, setResumeNewName] = React.useState("");
  const [isRename, setIsRename] = React.useState("");
  const [renameResume, { loading: resumeLoading }] = useMutation(
    RENAME_RESUME,
    {
      onCompleted(response) {
        Object.keys(resumes).map((resume) => {
          if (isRename === resumes[resume]._id) {
            let updatedResume = [...resumes];
            let resumeField = { ...updatedResume[resume] };
            resumeField.name = resumeNewName;
            updatedResume[resume] = resumeField;
            setResumes(updatedResume);
            setIsRename("");
            setResumeNewName("");
            setSnackbarText("Resume name updated");
            setOpen(true);
          }
        });
      },
    }
  );
  const onPreviewClose = () => {
    setOpenPreview(false);
  };

  const handleDeleteResume = (resumeId) => {
    setDeleteResumeId(resumeId);

    deleteResume({
      variables: {
        deleteResumeResumeId: resumeId,
      },
    });
  };

  const handleSaveResumeNameOnEnter = (key, name) => {
    if (resumeNewName || resumeNewName !== name)
      if (key.keyCode === 13) {
        renameResume({
          variables: {
            editResumeResumeId: isRename,
            editResumeName: resumeNewName,
          },
        });
      }
  };
  const handleSaveResumeName = () => {
    renameResume({
      variables: {
        editResumeResumeId: isRename,
        editResumeName: resumeNewName,
      },
    });
  };
  // const data = response.getSavedResumesForUser;
  return (
    <Card>
      <Card.Body>
        <Card.Title>Saved Resumes</Card.Title>
        {loading ? (
          <Box className="d-flex justify-content-center">
            <CircularProgress style={{ color: "#3699FF" }} />
          </Box>
        ) : (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" color="initial">
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left" variant="h6" color="initial">
                      Last edit
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center" variant="h6" color="initial">
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resumes &&
                  resumes.map((o, index) => {
                    let date = new Date(Number(o.updatedAt));

                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item>
                              {isRename !== o._id ? (
                                <FontAwesomeIcon
                                  icon={faPencilAlt}
                                  flip="horizontal"
                                  color="#3699FF"
                                  className=""
                                  style={{
                                    fontSize: "15px",
                                    cursor: "pointer",
                                    margin: "1px",
                                  }}
                                  onClick={() => {
                                    setIsRename(o._id);
                                    setResumeNewName("");
                                  }}
                                />
                              ) : (
                                <span></span>
                              )}
                            </Grid>

                            <Grid item>
                              {isRename === o._id ? (
                                <TextField
                                  id="outlined-helperText"
                                  label="Rename Resume"
                                  type="text"
                                  size="small"
                                  defaultValue={o.name}
                                  variant="outlined"
                                  color="secondary"
                                  onChange={(key) => {
                                    setResumeNewName(key.target.value);
                                  }}
                                  onKeyUp={(key) => {
                                    handleSaveResumeNameOnEnter(key, o.name);
                                  }}
                                />
                              ) : (
                                <span>{o.name}</span>
                              )}
                            </Grid>
                            <Grid item>
                              {isRename === o._id ? (
                                <Grid container direction="row" spacing={2}>
                                  <Grid item>
                                    <Button
                                      color="secondary"
                                      disabled={
                                        resumeNewName.length === 0 ||
                                        resumeNewName === o.name
                                      }
                                      variant="contained"
                                      onClick={handleSaveResumeName}
                                    >
                                      {}
                                      {resumeLoading ? (
                                        <CircularProgress
                                          size="20px"
                                          style={{ color: "#ffffff" }}
                                        />
                                      ) : (
                                        <Typography style={{ height: "20px" }}>
                                          save
                                        </Typography>
                                      )}
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      onClick={() => {
                                        setIsRename("");
                                      }}
                                    >
                                      <Typography style={{ height: "20px" }}>
                                        cancel
                                      </Typography>
                                    </Button>
                                  </Grid>
                                </Grid>
                              ) : (
                                <span></span>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell style={{ textAlign: "left" }}>
                          {moment(date).format("MMMM Do YYYY, h:mm:ss a")}
                        </TableCell>
                        <TableCell align="center">
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            spacing={2}
                          >
                            <Grid item>
                              <a
                                href={
                                  process.env.REACT_APP_RESUME_BUILDER_HOST +
                                  "/#/resume/" +
                                  o._id
                                }
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  color="#3699FF"
                                  className=""
                                  style={{
                                    fontSize: "17px",
                                    cursor: "pointer",
                                    margin: "1px",
                                  }}
                                />
                              </a>
                            </Grid>
                            <Grid item>
                              <FontAwesomeIcon
                                icon={faEye}
                                color="#3699FF"
                                className=""
                                style={{
                                  fontSize: "17px",
                                  cursor: "pointer",
                                  margin: "1px",
                                }}
                                onClick={() => {
                                  setSelectedResume(o.data);
                                  setSelectedTheme(o.resumeName);
                                  setOpenPreview(true);
                                  setSelectedResumeName(o.name);
                                }}
                              />
                            </Grid>
                            <Grid item>
                              {o._id === deleteResumeId ? (
                                <CircularProgress
                                  size="17px"
                                  style={{ color: "red" }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  color="red"
                                  className=""
                                  style={{
                                    fontSize: "17px",
                                    cursor: "pointer",
                                    margin: "1px",
                                  }}
                                  onClick={() => {
                                    handleDeleteResume(o._id);
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card.Body>

      <ResumePreview
        onClose={onPreviewClose}
        preview={openPreview}
        resumeData={selectedResume}
        themeName={selectedTheme}
        resumeName={selectedResumeName}
      />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        color="danger"
        onClose={() => {
          setOpen(!open);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={() => {
            setOpen(!open);
          }}
          style={{ fontSize: "16px" }}
          severity="success"
        >
          <Typography>{snackbarText}</Typography>
        </Alert>
      </Snackbar>
    </Card>
  );
}
