import React, { useState } from "react";
import { Card } from "react-bootstrap";
import moment from "moment";
// icons
import { faTrashAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiAlert from "@material-ui/lab/Alert";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
  Box,
  Snackbar,
} from "@material-ui/core";

import ResumePreview from "./resumePreview";

import { gql, useQuery, useMutation } from "@apollo/client";

const GET_SAVED_RESUMES = gql`
  query Query($getSavedResumesForUserUserId: String) {
    getSavedResumesForUser(userId: $getSavedResumesForUserUserId) {
      savedResumes {
        data
        name
        resumeName
        updatedAt
        createdAt
        _id
      }
    }
  }
`;

const DELETE_RESUME = gql`
  mutation Mutation(
    $deleteResumeResumeId: String!
    $deleteResumeUserId: String
  ) {
    deleteResume(resumeId: $deleteResumeResumeId, userId: $deleteResumeUserId) {
      success
      code
      message
    }
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SavedResumes({ selectedUser }) {
  const [open, setOpen] = React.useState(false);
  const [resumes, setResumes] = React.useState();
  const { loading, data } = useQuery(GET_SAVED_RESUMES, {
    variables: {
      getSavedResumesForUserUserId: selectedUser,
    },
    onCompleted() {
      setResumes(data.getSavedResumesForUser.savedResumes);
    },
  });
  const [deleteResumeId, setDeleteResumeId] = React.useState("");
  const [deleteResume] = useMutation(DELETE_RESUME, {
    onCompleted(response) {
      if (response.deleteResume.code === "200") {
        let updatedResumes = resumes;
        const index = updatedResumes.findIndex((x) => x._id === deleteResumeId);
        updatedResumes = [
          ...updatedResumes.slice(0, index),
          ...updatedResumes.slice(index + 1),
        ];
        setResumes(updatedResumes);
        setOpen(true);
        setDeleteResumeId("");
      }
    },
  });

  const [openPreview, setOpenPreview] = useState(false);
  const [selectedResume, setSelectedResume] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("");
  const [selectedResumeName, setSelectedResumeName] = React.useState("");
  const onPreviewClose = () => {
    setOpenPreview(false);
  };

  const handleDeleteResume = (resumeId) => {
    setDeleteResumeId(resumeId);
    // setOpen(true);

    deleteResume({
      variables: {
        deleteResumeResumeId: resumeId,
        deleteResumeUserId: selectedUser,
      },
    });
  };
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const data = response.getSavedResumesForUser;
  return (
    <Card>
      <Card.Body>
        <Card.Title>Saved Resumes</Card.Title>
        {loading ? (
          <Box className="d-flex justify-content-center">
            <CircularProgress style={{ color: "#3699FF" }} />
          </Box>
        ) : (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" color="initial">
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="left" variant="h6" color="initial">
                      Last edit
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Typography align="center" variant="h6" color="initial">
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resumes &&
                  resumes.map((o, index) => {
                    let date = new Date(Number(o.updatedAt));

                    return (
                      <TableRow key={index}>
                        <TableCell>{o.name}</TableCell>

                        <TableCell style={{ textAlign: "left" }}>
                          {moment(date).format("MMMM Do YYYY, h:mm:ss a")}
                        </TableCell>
                        <TableCell align="right">
                          <FontAwesomeIcon
                            icon={faEye}
                            color="#3699FF"
                            className=""
                            style={{
                              fontSize: "17px",
                              cursor: "pointer",
                              margin: "1px",
                            }}
                            onClick={() => {
                              setSelectedResume(o.data);
                              setSelectedTheme(o.resumeName);
                              setOpenPreview(true);
                              setSelectedResumeName(o.name);
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {o._id === deleteResumeId ? (
                            <CircularProgress
                              size="15px"
                              style={{ color: "red" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              color="red"
                              className=""
                              style={{
                                fontSize: "15px",
                                cursor: "pointer",
                                margin: "1px",
                              }}
                              onClick={() => {
                                handleDeleteResume(o._id);
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card.Body>

      <ResumePreview
        onClose={onPreviewClose}
        preview={openPreview}
        resumeData={selectedResume}
        themeName={selectedTheme}
        resumeName={selectedResumeName}
      />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        color="danger"
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleSnackBarClose}
          style={{ fontSize: "16px" }}
          severity="success"
        >
          {"Resume deleted successfully"}
        </Alert>
      </Snackbar>
    </Card>
  );
}
