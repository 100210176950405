import React from "react";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
// import { Mood } from "@material-ui/icons";
import { useQuery, gql } from "@apollo/client";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const GET_STATS = gql`
  query Query {
    getStatisticsForCoach {
      totalJobSeekerCount
      quizCount
      resumesCount
      userPlacedCount
    }
  }
`;

export function StatsOverview() {
  const { data, loading } = useQuery(GET_STATS);
  const { user } = useSelector((state) => state.auth);
  const statsList = [
    {
      title: "Total Job Seekers",
      key: "totalJobSeekerCount",
    },
    {
      title: "Resume Completes",
      key: "resumesCount",
    },
    {
      title: "Assessments By Job Seekers",
      key: "quizCount",
    },
    {
      title: "Cover Letter",
      key: "65/100",
    },
    {
      title: "Clients Placed",
      key: "userPlacedCount",
    },
  ];
  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4} md={2}>
          <Card style={{ height: "150px" }} className="shadow-sm rounded-lg">
            <Card.Body className="p-8">
              <Typography variant="h6" className="text-center  mb-5">
                Coach Id
              </Typography>
              <Typography
                variant="h5"
                className="text-center font-weight-bold position-absolute pb-8"
                style={{ bottom: 0, right: 0, left: 0 }}
              >
                {user.coachId || "--------"}
              </Typography>
            </Card.Body>
          </Card>
        </Grid>
        {statsList.map((item) => (
          <Grid item xs={12} sm={4} md={2}>
            <Card style={{ height: "150px" }} className="shadow-sm rounded-lg">
              <Card.Body className="position-relative px-8 pb-8 pt-5">
                <Typography variant="h6" className="text-center mb-5">
                  {item.title}
                </Typography>
                {data ? (
                  <Typography
                    variant="h4"
                    style={{ bottom: 0, right: 0, left: 0 }}
                    className="position-absolute text-center font-weight-bold pb-5 mb-2 bottom-0"
                  >
                    {data.getStatisticsForCoach[item.key] || item.key}
                  </Typography>
                ) : loading ? (
                  <Box
                    style={{ bottom: 0, right: 0, left: 0 }}
                    className="position-absolute text-center font-weight-bold pb-5 mb-2 bottom-0"
                  >
                    <CircularProgress style={{ color: "#3699FF" }} />
                  </Box>
                ) : (
                  <Typography
                    variant="h4"
                    style={{ bottom: 0, right: 0, left: 0 }}
                    className="position-absolute text-center font-weight-bold pb-5 mb-2 bottom-0"
                  >
                    --
                  </Typography>
                )}
              </Card.Body>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
