import { Subject } from 'rxjs'




export const ChatThreadEventAction = (() => {
    const events = new Subject()
    return {
        triggerAction: (status = {}) => events.next(status),
        listen: events
    }
})()


export const MessegeThreadEventAction = (() => {
    const events = new Subject()
    return {
        triggerAction: (status = {}) => events.next(status),
        listen: events
    }
})()

export const UserMessegeTypingEventAction = (() => {
    const events = new Subject()
    return {
        triggerAction: (status = {}) => events.next(status),
        listen: events
    }
})()

export const OnlineUsersEventAction = (() => {
    const events = new Subject()
    return {
        triggerAction: (status = {}) => events.next(status),
        listen: events
    }
})()